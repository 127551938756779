import { PublicClientApplication, type AccountInfo } from '@azure/msal-browser'
import type { GraphToken } from '@/types/UserType';

export async function getUserAccessToken(instance: PublicClientApplication, account: AccountInfo, scopes: string[]) { 
    return await instance.acquireTokenSilent({
        account: account,
        scopes: scopes
    }).then((response: any) => {
        return { accessToken: response.accessToken, expiresOn: response.expiresOn } as GraphToken
    })
}

export async function getUserReadAndWriteAccessToken(instance: PublicClientApplication, account: AccountInfo, scopes: string[]) {    
    return await instance.acquireTokenPopup({
        account: account,
        scopes: scopes,
        state: btoa(window.location.href) // Encode the current URL as the state
    }).then((response: any) => {
        return { accessToken: response.accessToken, expiresOn: response.expiresOn } as GraphToken
    })
}