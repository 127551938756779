import type { MailInvite } from "@/types/MailInviteType";
import apiClient from "./main";
import type { NewUser } from "@/types/UserType";

// API resource endpoint
const RESOURCE_NAME = 'api/microsoftGraph';

export default {
    UpdateUser(accountId: string, user: any) {
        return apiClient.patch(`${RESOURCE_NAME}/UpdateUser?accountId=${accountId}`, user);
    },
    getAdminUserList() {
        return apiClient.get(`${RESOURCE_NAME}/adminUsers`);
    },
    getUserListByID(userIds: string[]) {
        return apiClient.post(`${RESOURCE_NAME}/usersById`, userIds);
    },
    getUserByEmail(email: string) {
        return apiClient.get(`${RESOURCE_NAME}/userByEmail?email=${email}`);
    },
    checkEmailExists(email: string) {
        const requestHeaders = { 'Accept': 'application/json', 'AnonymousAuthentication': 'true' } as any
        const config = {
            params: {
                email: email
            }, headers: requestHeaders
        }
        return apiClient.get(`${RESOURCE_NAME}/checkEmailExists`, config);
    },
    createUser(user: NewUser) {
        return apiClient.post(`${RESOURCE_NAME}/createUser`, user);
    },
    createRegisterUser(user: NewUser, token: string) {
        user.token = token;
        const requestHeaders = { 'AnonymousAuthentication': 'true' } as any
        const config = { headers: requestHeaders }
        return apiClient.post(`${RESOURCE_NAME}/createRegisterUser`, user, config);
    },
    updatePasswordProfile(email: string, password: string) {
        return apiClient.patch(`${RESOURCE_NAME}/updatePasswordProfile?email=${email}&password=${password}`);
    },
    createAppRoleAssignment(userId: string) {
        return apiClient.post(`${RESOURCE_NAME}/createAppRoleAssignment?userId=${userId}`);
    },
    createRegisterAppRoleAssignment(entraId: string, token: string) {
        const requestHeaders = { 'AnonymousAuthentication': 'true' } as any
        const data = { entraId, token };
        return apiClient.post(`${RESOURCE_NAME}/createRegisterAppRoleAssignment`, data, { headers: requestHeaders });
    },
    checkSuperAdminExistsByEmail(email: string) {
        return apiClient.get(`${RESOURCE_NAME}/IsUserSuperAdminByEmail?email=${email}`);
    },
    checkSuperAdminExistsByEntraID(entraId: string) {
        const requestHeaders = { 'Accept': 'application/json', 'AnonymousAuthentication': 'true' } as any
        const config = {
            params: {
                entraId: entraId
            }, headers: requestHeaders
        }   
        return apiClient.get(`${RESOURCE_NAME}/IsUserSuperAdminByID`, config);
    },
    inviteUserToSuperAdminAsync(entraId: string, token: string) {
        const requestHeaders = { 'AnonymousAuthentication': 'true' } as any
        const data = { entraId, token };
        return apiClient.post(`${RESOURCE_NAME}/InviteUserToSuperAdmin`, data, { headers: requestHeaders });
    },
    removeUserSuperAdminAsync(userId: string) {
        return apiClient.delete(`${RESOURCE_NAME}/RemoveUserFromSuperAdmin?userId=${userId}`);
    },
    sendOTPCode(emailModel: MailInvite) {
        return apiClient.post(`${RESOURCE_NAME}/GenerateEntraOTP`, emailModel);
    },
    validateOTPCode(email: string, code: string) {
        return apiClient.post(`${RESOURCE_NAME}/ValidateEntraOTP?email=${email}&otp=${code}`);
    },
    getLastSignInTime(userIds: string[]) {
        return apiClient.post(`${RESOURCE_NAME}/GetLastSignInTime`, userIds);
    },
};
