import axios from 'axios'
import { useUserStore } from '@/stores/user.store'
import { useCompanyStore } from '@/stores/company.store'

const API = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/`,
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': import.meta.env.VITE_APP_API_KEY
    },
})

//API request interceptor
API.interceptors.request.use(async (config: any) => {
    // Declare store
    const userStore = useUserStore()
    const companyStore = useCompanyStore()

    config.headers.CompanyID = companyStore.companyID

    //check if this is the request to set the anonymous token, if so then skip and just return the config.
    if (config.headers.AnonymousAuthentication != "true") {
        //try to get the access token from the user store
        let bearerToken = userStore.Token?.idToken
        if (bearerToken) {
            //token exists, set the authorization header
            config.headers.Authorization = `Bearer ${bearerToken}`
        }
    }
    
    
    return config
}, function (error) {
    return Promise.reject(error)
})

//API response interceptor
API.interceptors.response.use(function (response) {
    return response
}, function (error) {
    //API error handling (this should be improved later on)
    let msg = ""
    if (!error.response) {
        msg = "Something went wrong..."
    }
    else if (error.response.status === 400 && typeof error.response.data.messages !== "undefined") {
        msg = error.response.data
    }
    else if (error.response.status === 403) {
        msg = "Forbidden'"
    }
    else if (error.response.status === 401) {
        msg = "[Unauthorized] " + error.response.data
    }
    else if (error.response.status === 500) {
        msg = "Server Error"
    }
    else if (error.code == "ERR_NETWORK") {
        msg = "API Connection Failed"
    }
    else {
        msg = error
    }

    return Promise.reject(msg)
})

export default API;