<template>
  <div class="flex flex-col -m-2 gap-2">
    <InputGroup>
      <InputText v-model="model.name" label="Name" @click.stop @keydown="editOption()"/>
    </InputGroup>
    <span class="flex font-bold">Colors</span>
    <Menu :model="options" @click.stop>
      <template #item="{ item }">
        <div class="flex p-1 mr-2 cursor-pointer justify-between" @click.stop="selectColour(item), saveOption()">
          <div class="flex gap-2">
            <div class="rounded-full w-6 h-6" :style="'background-color:' + item.value"></div>
            <span>{{ item.name }}</span>
          </div>
          <FontAwesomeIcon v-if="optionSelected(item)" icon="fa-solid fa-check"/>
        </div>
      </template>
    </Menu>
    <Button text severity="danger" label="Delete Tag" class="p-1" @click.stop="deleteConfirm"/>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { optionColours } from '@/composables/optionColours';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import _ from 'lodash';
  import { useConfirm } from 'primevue/useconfirm';
  import { useNotificationStore } from '@/stores/notification.store';

  const notifcationStore = useNotificationStore()
  const confirm = useConfirm()
  const emit = defineEmits(['saveOption', 'deleteOption'])

  const props = defineProps<{
    editingOption: any
  }>()

  // copy readonly prop to mutable model
  const model = ref({ ...props.editingOption })
  const options = optionColours

  // delete option confirmation dialog
  const deleteConfirm = () => {
    confirm.require({
      message: 'Are you sure you want to delete this option?',
      header: 'Delete Option',
      icon: 'pi pi-exclamation-triangle',
      rejectProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true
      },
      acceptProps: {
        label: 'Delete',
        severity: 'danger',
      },
      accept: () => {
        // on accept, emit the deleteOption event
        emit('deleteOption', model.value)
      }
    })
  }

  // check if option is selected (used to show a check beside selected option)
  function optionSelected(option: any) {
    return model.value.colour === option.value
  }

  // set option colour
  function selectColour(colourOption: any) {
    colourOption.selected = true
    model.value.colour = colourOption.value
  }

  // change option name.  uses a debounce to prevent too many calls from typing
  function editOption() {
    _.debounce(() => {
      saveOption()
    }, 1000)();
  }

  // save option
  function saveOption() {
    model.value.name = model.value.name.trim()
    if(model.value.name === '') {
      notifcationStore.showWarning('Option name cannot be empty', 'Warning')
      return;
    }
    emit('saveOption', model.value)
  }
</script>

<style scoped>
/* Fix for menu focus colour */
:deep(.p-menu-item.p-focus .p-menu-item-content) {
  background-color: #f1f5f9 !important;
}
</style>