<template>
  <div class="flex flex-col w-full h-full text-center align-middle" v-if="!dataLoaded">
    <ProgressSpinner />
    <p>Loading...</p>
  </div>
  <div v-else class="flex flex-col m-5 gap-5">
    <AppHeaderPage>
      <template #title>
        {{ objectListCount }} Assets
      </template>
    </AppHeaderPage>
    <AppToolbarPage 
      :isTableViewSelected="isTableViewSelected" 
      :isDisplayViewButtonsVisible="false" 
      :sortFields="tableColumns"
      @search="searchQuery = $event"
      @selected-filter-action="filterViewBySelectedFilter"
      @set-sort-order="setSortOrder"
      >
    </AppToolbarPage>
    <AppToolbarSelection :selectedObjects="assetStore.data.selectedAssets" :items="assetTableSelectionMenuItems" @clearSelectedObjects="unselectAll" />
    <ObjectTable
      class="z-0"
      :tableHeight="hasSelectedAssets ? 'calc(100vh - 410px)' : 'calc(100vh - 330px)'"
      :selectedObjects="assetStore.data.selectedAssets"
      :object-list="assetStore.data.assets"
      :columns="tableColumns"
      :isEditMode="true"
      :page="page"
      :rowsPerPage="rowsPerPage"
      :search="searchQuery"
      @cell-edit-complete="cellEditComplete"
      @row-select="rowSelected"
      @row-unselect="rowUnselected"
      @row-select-all="selectAll"
      @row-unselect-all="unselectAll"
      @downloadAction="downloadAction"
      @favoriteAction="favoriteAction"
      @unfavoriteAction="unfavoriteAction"
      @update:page="page = $event"
      @update:rowsPerPage="rowsPerPage = $event"
    >
    </ObjectTable>
  </div>
  <DynamicDialog />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import type { Asset } from '@/types/AssetType'
import type { Field } from '@/types/FieldType'
import { useAssetStore } from '@/stores/asset.store'
import { useNotificationStore } from '@/stores/notification.store'
import { useUserStore } from '@/stores/user.store'
import { useFolderStore } from '@/stores/folder.store'
import { useStatusStore } from '@/stores/status.store'
import { useAudienceStore } from '@/stores/audience.store'
import { useTagStore } from '@/stores/tag.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import type { DataTableCellEditCompleteEvent } from 'primevue/datatable'
import { downloadAction } from '@/composables/downloadHelper'
import _ from 'lodash'
import { useUploaderStore } from '@/stores/uploader.store'
import { useRouter } from 'vue-router'
import { useDialog } from 'primevue/usedialog'
import { openConfirmEntityDialog, openViewChangesDialog, openBatchEditSingleSelectFieldDialog, openBatchEditMultiSelectFieldDialog } from '@/composables/dialogOptions'
import AppToolbarSelection from '@/components/AppToolbarSelection.vue'
import { IsFavorite } from '@/composables/objectHelpers'
import AppHeaderPage from './AppHeaderPage.vue'
import AppToolbarPage from './AppToolbarPage.vue'
import { SortAssetList } from '@/composables/sortObjectFieldValue';

const router = useRouter()

// EMITS
defineEmits(['update:isEditMode', 'update:remove-file'])

// dialog
const dialog = useDialog()

// STORES
const notificationStore = useNotificationStore()
const userStore = useUserStore()
const assetStore = useAssetStore()
const folderStore = useFolderStore()
const statusStore = useStatusStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const assetTypeStore = useAssetTypeStore()
const uploaderStore = useUploaderStore()

// LOCAL PROPERTIES
const entityList = ref([]) as any
const tableColumns = ref<Array<Field>>([])
const isTableViewSelected = ref(true)
const isTableEditMode = ref(true)
const dataLoaded = ref(false)
const sortOrder = ref('asc')
const sortFilter = ref(null);

const page = ref(1)
const rowsPerPage = ref(20)
// filter search query
const searchQuery = ref('')

// COMPUTED PROPERTIES
const disableSave = computed(() => assetStore.trackedChanges.length <= 0)
const objectListCount = computed(() => (assetStore.data.assets ? assetStore.data.assets.length : 0))
const hasSelectedAssets = computed(() => assetStore.data.selectedAssets.length > 0)
// asset IDS from the uploader
const assetIds = computed(() => {
  return uploaderStore.uploaderProfile.completedFiles.map((file: any) => file.asset.assetID)
})
const selectionIsFavs = computed(() => assetStore.data.selectedAssets.every((asset: Asset) => IsFavorite(asset.object)))
// Selection Menu Items
const assetTableSelectionMenuItems = computed(() => [
  {
    label: 'Favorite',
    icon: 'fa-regular fa-star',
    visible: !selectionIsFavs.value,
    command: () => {
      favoriteSelectedAssets()
    }
  },
  {
    label: 'Unfavorite',
    icon: 'fa-regular fa-square-minus',
    visible: selectionIsFavs.value,
    command: () => {
      unfavoriteSelectedAssets()
    }
  },
  {
    label: 'Trash',
    icon: 'fa-regular fa-trash-can',
    command: () => {
      trashAssetsConfirm()
    }
  },
  {
    label: 'Batch Edit',
    icon: 'fa-regular fa-ellipsis-vertical',
    items: [
      {
        label: 'Status',
        icon: 'fa-regular fa-circle-dashed',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Status'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'statusID',
              selectOptions: statusStore.data.statuses
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Tags',
        icon: 'fa-regular fa-tag',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Tags'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'tags',
              selectID: 'tagID',
              selectOptions: tagStore.data.tags
            }
          }
          openBatchEditMultiSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Folders',
        icon: 'fa-regular fa-folder',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Folders'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'folders',
              selectID: 'folderID',
              selectOptions: folderStore.data.folders
            }
          }
          openBatchEditMultiSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Audience',
        icon: 'fa-regular fa-users',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Audience'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'audienceID',
              selectOptions: audienceStore.data.audiences
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Asset Type',
        icon: 'fa-light fa-file',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Asset Type'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'assetTypeID',
              selectOptions: assetTypeStore.data.assetTypes
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      }
    ]
  }
])

onMounted(async () => {
  assetStore.$reset()
  await assetStore.loadSpecificAssetsData(assetIds.value)

  tableColumns.value = [
    // Key needs to start at 4 to make room for static columns
    { fieldKey: 4, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: true, isEditable: true, width: '200px' },
    {
      fieldKey: 5,
      fieldName: 'Status',
      fieldValue: 'statusID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: true,
      isEditable: true,
      isSearchable: true,
      width: '100px',
      selectID: 'statusID',
      selectOptions: statusStore.data.statuses
    },
    {
      fieldKey: 6,
      fieldName: 'Created By',
      fieldValue: 'object.createdBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    { fieldKey: 7, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    { fieldKey: 8, fieldName: 'Last Edited', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    {
      fieldKey: 6,
      fieldName: 'Edited By',
      fieldValue: 'object.lastModifiedBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    {
      fieldKey: 9,
      fieldName: 'Tags',
      fieldValue: 'tags',
      fieldType: 'multiSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      width: '300px',
      selectID: 'tagID',
      selectOptions: tagStore.data.tags
    },
    { fieldKey: 10, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true, isSortable: false, isEditable: false },
    {
      fieldKey: 11,
      fieldName: 'Folders',
      fieldValue: 'folders',
      fieldType: 'multiSelect',
      relationshipType: 'folder',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      width: '260px',
      selectID: 'folderID',
      selectOptions: folderStore.data.folders
    },
    {
      fieldKey: 12,
      fieldName: 'Description',
      fieldValue: 'description',
      fieldType: 'editor',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      width: '300px'
    },
    {
      fieldKey: 13,
      fieldName: 'Audience',
      fieldValue: 'audienceID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      selectID: 'audienceID',
      selectOptions: audienceStore.data.audiences
    },
    { fieldKey: 14, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: false, isEditable: false, isSearchable: true },
    {
      fieldKey: 15,
      fieldName: 'Asset Type',
      fieldValue: 'assetTypeID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      selectID: 'assetTypeID',
      selectOptions: assetTypeStore.data.assetTypes
    }
  ]

  dataLoaded.value = true
})

watch(
  () => uploaderStore.uploaderProfile.completedFiles,
  (newVal, oldVal) => {
    if (!_.isEqual(newVal, oldVal) && newVal.length > 0) {
      assetStore.$reset()
      assetStore.loadSpecificAssetsData(assetIds.value)
    }
  }
)

// Row Selected event handler
function rowSelected(event: any) {
  if (event !== undefined) {
    // Add the selected asset to the selectedAssets list
    assetStore.data.selectedAssets.push(event.data)
  }
}

// Row Unselected event handler
function rowUnselected(event: any) {
  if (event !== undefined) {
    // Remove the unselected asset from the selectedAssets list
    assetStore.data.selectedAssets = assetStore.data.selectedAssets.filter((asset: Asset) => asset.assetID !== event.data.assetID)
  }
}

// Row Select All event handler
function selectAll() {
  // Set the selectedAssets list to all assets
  assetStore.data.selectedAssets = assetStore.data.assets
}

// Row Unselect All event handler
function unselectAll() {
  // Clear the selectedAssets list
  assetStore.data.selectedAssets = []
}

async function  filterViewBySelectedFilter(filter?: any) {
  //set the sortFilter to the filter if there is one
  //if not the sort was toggled
  if(filter) {
    sortFilter.value = filter;
  }
  else {
    sortFilter.value = null; 
  }
  assetStore.data.assets = SortAssetList(sortFilter.value, assetStore.data.assets, sortOrder.value)
}

function setSortOrder(isAscending: boolean) {
  if(isAscending)
    sortOrder.value = 'asc'
  else 
    sortOrder.value = 'desc'

  //after the order gets set, sort the list 
  if(sortFilter.value !== null)
    filterViewBySelectedFilter(sortFilter.value)
}

// Handle cell edit complete event
function cellEditComplete(event: DataTableCellEditCompleteEvent) {
  if (event) {
    let { field, newValue, data } = event
    const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === data.assetID) as Asset
    asset[field] = newValue
  }
}

function updateBatchFields(field: string, entity: any) {
  const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === entity.assetID) as Asset
  asset[field] = entity[field]
}

// Confirm and save changes
async function openSaveChangesModal() {
  const modalOptions = {
    data: {
      entityList: assetStore.trackedChanges
    },
    emits: {
      onSubmit: async () => {
        saveChanges()
        isTableEditMode.value = false
      }
    }
  }
  openViewChangesDialog(dialog, modalOptions)
}

function saveChanges() {
  // call the saveAssetsData function in the store and handle the response from the mutation
  assetStore
    .saveAssetsData(userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Changes saved successfuly', 'Changes Saved')
      router.push({ name: 'CompanyRouterView' })
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error saving changes', 'Error')
    })
}

// Favorite action event handler
async function favoriteAction(asset: any) {
  // Call the favoriteAsset function in the store and handle the response from the mutation
  assetStore
    .favoriteAssets([asset.assetID], userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Added ${asset.name} as Favorite`, 'Favorite Added')
      assetStore.loadSpecificAssetsData(assetIds.value)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error favoriting asset', 'Error')
    })
}

// Unfavorite action event handler
async function unfavoriteAction(asset: any) {
  // Call the unfavoriteAsset function in the store and handle the response from the mutation
  assetStore
    .unfavoriteAssets([asset.assetID], userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Removed ${asset.name} as Favorite`, 'Favorite Removed')
      assetStore.loadSpecificAssetsData(assetIds.value)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error unfavoriting asset', 'Error')
    })
}

// Favorite action event handler
async function favoriteSelectedAssets() {
  let assetIDsToFavorite = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
  // Call the favoriteAssets function in the store and handle the response from the mutation
  assetStore
    .favoriteAssets(assetIDsToFavorite, userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Added selected assets as Favorites', 'Favorites Added')
      assetStore.clearSelection()
      assetStore.loadSpecificAssetsData(assetIds.value)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error favoriting assets', 'Error')
    })
}

// Unfavorite action event handler
async function unfavoriteSelectedAssets() {
  let assetIDsToUnfavorite = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
  // Call the unfavoriteAssets function in the store and handle the response from the mutation
  assetStore
    .unfavoriteAssets(assetIDsToUnfavorite, userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Removed selected assets as Favorites', 'Favorites Removed')
      assetStore.clearSelection()
      assetStore.loadSpecificAssetsData(assetIds.value)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error unfavoriting assets', 'Error')
    })
}

// shows a confirmation modal before trashing assets
function trashAssetsConfirm(asset?: any) {
  entityList.value = []
  //map assetID and assetName to the entity list from the asset store
  assetStore.data.selectedAssets.map((asset: Asset) => {
    entityList.value.push({ entityID: asset.assetID, entityName: asset.name })
  })

  const modalOptions = {
    props: {
      header: 'Move Items to Trash?'
    },
    data: {
      entityList: !asset ? entityList.value : [{ entityID: asset.assetID, entityName: asset.name }],
      isConfirmListModal: true,
      customLabels: {
        submitLabel: 'Move to Trash'
      },
      subText: 'Assets will be moved from all folders to trash.'
    },
    emits: {
      onSubmit: async () => {
        if (asset) trashAssets(asset.assetID)
        else trashAssets()
        //clear the entity list
        entityList.value = []
        asset = undefined
        //clear the selected assets
        assetStore.$patch((state: any) => {
          state.data.selectedAssets = []
        })
      }
    }
  }
  openConfirmEntityDialog(dialog, modalOptions)
}

// Trash assets
function trashAssets(assetID?: number) {
  if (assetID) {
    // Call the trashAssets function in the store and handle the response from the mutation
    assetStore
      .softDeleteAssets([assetID])
      .then(() => {
        notificationStore.showSuccess('Asset moved to trash', 'Asset Trashed')
        assetStore.loadSpecificAssetsData(assetIds.value)
      })
      .catch((error: any) => {
        console.error(error)
        notificationStore.showError('Error trashing asset', 'Error')
      })
  } else {
    let assetIDsToTrash = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
    // Call the trashAssets function in the store and handle the response from the mutation
    assetStore
      .softDeleteAssets(assetIDsToTrash)
      .then(() => {
        notificationStore.showSuccess('Assets moved to trash', 'Assets Trashed')
        assetStore.clearSelection()
        assetStore.loadSpecificAssetsData(assetIds.value)
      })
      .catch((error: any) => {
        console.error(error)
        notificationStore.showError('Error trashing assets', 'Error')
      })
  }
}

defineExpose({
  openSaveChangesModal,
  disableSave
})
</script>
