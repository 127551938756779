<template>
  <div class="flex flex-col p-5 gap-5">
    <!-- heading -->
    <AppHeaderPage>
      <template #title> User Settings </template>
      <template #buttons>
        <Button :disabled="changedFields.length == 0" size="small" class="text-black hover:text-white hover:disabled:text-black" label="Save Changes" @click="submitForm"></Button>
      </template>
    </AppHeaderPage>
    <!-- user settings -->
    <form @submit.prevent="submitForm">
      <div class="max-w-4xl mx-auto">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col sm:flex-row gap-2">
            <!-- first name -->
            <InputTextForm v-model="formData.firstName" label="First Name" required placeholder="First Name" />
            <!-- last name -->
            <InputTextForm v-model="formData.lastName" label="Last Name" required placeholder="Last Name" />
          </div>
          <div class="flex flex-col sm:flex-row gap-2 items-center">
            <!-- email -->
            <InputTextForm v-model="formData.email" label="Email" disabled required placeholder="Email" />
            <!-- change email -->
            <Button
              class="text-black hover:text-white hover:disabled:text-black w-full sm:w-1/5"
              size="small"
              severity="contrast"
              outlined
              label="Change Email"
              @click="changeEmail"
              :disabled="shouldDisableUpdateEmail"
              v-tooltip="tooltipEmailButtonValue"
            ></Button>
          </div>
          <div class="flex mt-4">
            <!-- update password -->
            <Button
              size="small"
              class="text-black hover:text-white hover:disabled:text-black w-full sm:w-max"
              label="Update Password"
              @click="showUpdatePasswordFormModal"
              :disabled="shouldDisableUpdatePassword"
              v-tooltip="tooltipValue"
            ></Button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <DynamicDialog />
</template>
<script setup lang="ts">
import { reactive, ref, onMounted, watch } from 'vue'
import { useNotificationStore } from '@/stores/notification.store'
import { useUserStore } from '@/stores/user.store'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { userSettingsSchema } from '@/schemas/userSettings.schema'
import type { UserForm, UpdatedUserType, UserUpdate, GraphUserUpdate } from '@/types/UserSettingsType'
import { GetUserByEmail, UpdatePartialUser } from '@/services/graphql/user.graphql'
import graphMeService from '@/services/api/auth/graph.me.service'
import { useMsal } from '@/composables/b2c/utils/useMsal'
import AppModalFormUserChangeEmail from '@/components/AppModalFormUserChangeEmail.vue'
import { getUserAccessToken, getUserReadAndWriteAccessToken } from '@/composables/b2c/utils/useAccessToken'
import { useDialog } from 'primevue/usedialog'
import { genericDialogProps, openChangePasswordDialog } from '@/composables/dialogOptions'
import AppHeaderPage from '@/components/AppHeaderPage.vue'

// Initialize router and stores
const notificationStore = useNotificationStore()
const userStore = useUserStore()

// MSAL instance
const { instance, accounts } = useMsal()

// Reactive states and refs
// User form helpers
const originalUser = ref({
  email: null,
  firstName: null,
  lastName: null
} as UserForm)
const changedFields = ref([] as string[])
const isFormValid = ref(false)

// userLoading
const userLoading = ref(false)

// Reactive form data object
const formData = reactive<UserForm>({
  email: null,
  firstName: null,
  lastName: null
})

// user ID
const userId = ref(0) as any

// Disable update password button
const shouldDisableUpdatePassword = ref(true)
const tooltipValue = ref({})
// Disable update email button
const shouldDisableUpdateEmail = ref(true)
const tooltipEmailButtonValue = ref({})

// Load company data on mount
onMounted(async () => {
  // check if the user access token is available for write operations
  const scopes = ['Directory.AccessAsUser.All']
  await getUserAccessToken(instance, accounts.value[0], scopes)
    .then((accessToken) => {
      // Set the access token in the user store
      userStore.setAccessToken(accessToken)
    })
    .catch(async () => {
      // If there is an error, try to get the access token for read and write operations
      await getUserReadAndWriteAccessToken(instance, accounts.value[0], scopes)
        .then((accessToken) => {
          // Set the access token in the user store
          userStore.setAccessToken(accessToken)
        })
        .catch(() => {
          notificationStore.showError('You do not have the required permissions to access this page.')
        })
    })

  // Load user settings
  if (userStore.AccessToken) {
    await loadUserSettings()
  }
})

// Get company by ID query
const { load: loadGetUserByEmail, result: resultGetUserByEmail, variables: queryVariables } = useLazyQuery(GetUserByEmail)

async function loadUserSettings() {
  userLoading.value = true

  const user = await graphMeService.getMe()
  if (user) {
    // Check if the user is a local account. If not, disable the update password button
    if (user.creationType !== 'LocalAccount') {
      shouldDisableUpdatePassword.value = true
      tooltipValue.value = { value: 'This is not a home directory for this account, ask your administrator from your home directory to assist you with your password change.' }

      shouldDisableUpdateEmail.value = true
      tooltipEmailButtonValue.value = {
        value: 'This is not a home directory for this account, ask your administrator from your home directory to assist you with your email change.'
      }
    } else {
      shouldDisableUpdatePassword.value = false
      tooltipValue.value = { value: 'Update your password' }

      shouldDisableUpdateEmail.value = false
      tooltipEmailButtonValue.value = { value: 'Change your email' }
    }

    queryVariables.value = { email: user.mail }
    await loadGetUserByEmail()

    if (resultGetUserByEmail.value && resultGetUserByEmail.value.usersAll.length > 0) {
      const user = resultGetUserByEmail.value.usersAll[0]
      formData.email = user.email
      formData.firstName = user.firstName
      formData.lastName = user.lastName
      userId.value = user.userID
    }
  }

  originalUser.value = { ...formData }

  userLoading.value = false
}

// Validate schema
const validateSchema = async () => {
  isFormValid.value = true

  userSettingsSchema.validate(formData, { abortEarly: false }).catch((error: any) => {
    // Log the detailed error information
    isFormValid.value = false
    // get all the errors
    for (const err of error.errors) {
      // assume custom error message if message
      if (err.message) {
        notificationStore.showError(err.message, err.path)
      }
    }
  })
}

// Watch for form data changes
watch(
  formData,
  () => {
    for (const key in formData) {
      const originalValue = originalUser.value[key as keyof UserForm]
      let currentValue = formData[key as keyof UserForm]
      if (currentValue === '' && originalValue == null) {
        currentValue = null
      }
      if (currentValue !== originalValue) {
        // Add to changed fields if different
        if (!changedFields.value.includes(key)) {
          changedFields.value.push(key)
        }
      } else {
        // Remove from changed fields if the same
        changedFields.value = changedFields.value.filter((field) => field !== key)
      }
    }
  },
  { deep: true }
)

// Mutation for updating company
const { mutate: updateUser, error: notifyUserError } = useMutation(UpdatePartialUser)

watch(notifyUserError, (error) => {
  if (error) {
    notificationStore.showError('Error', 'Error updating user')
  }
})

// Submit form
const submitForm = async () => {
  await validateSchema()

  if (!isFormValid.value) {
    return
  }

  // Check if any fields have changed
  if (changedFields.value.length === 0) {
    notificationStore.showInfo('No Changes', 'No changes have been made')
    return
  }

  // Create the Microsoft graph API user update object
  const graphUserUpdate = {
    givenName: formData.firstName,
    surName: formData.lastName
  } as GraphUserUpdate

  // Update user in Microsoft graph API
  await graphMeService.updateMe(graphUserUpdate)

  // Create updated user object
  const updatedUser: UpdatedUserType = {
    userID: userId.value as number,
    user: {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      updatedOn: new Date().toISOString()
    } as UserUpdate
  }

  // Remove fields that haven't changed
  for (const key in updatedUser.user) {
    if (!changedFields.value.includes(key)) {
      delete updatedUser.user[key as keyof UserUpdate]
    }
  }

  const response = await updateUser(updatedUser)
  if (!response) {
    notificationStore.showError('Error', 'Error updating user')
    return
  }

  notificationStore.showSuccess('User Updated', 'The user has been updated successfully')

  // Reset changed fields
  changedFields.value = []

  originalUser.value = { ...formData }
}

// MODAL VARIABLES
// data to be returned from the modal
const modalChangeEmailData = ref({
  NewEmail: '',
  Otp: '',
  isEmailDisplayed: true,
  userId: 0
})

const modalChangePasswordData = ref({
  OldPassword: '',
  NewPassword: '',
  ConfirmPassword: ''
})
// FORM MODAL FUNCTIONS
const dialog = useDialog()

const changeEmail = async () => {
  // Getting the user ID after the user has been loaded
  modalChangeEmailData.value.userId = userId.value

  dialog.open(AppModalFormUserChangeEmail, {
    props: {
      modal: true,
      header: 'Enter New Email',
      closable: false,
      ...genericDialogProps
    },
    data: modalChangeEmailData.value,
    emits: {
      onOTPSent: () => {
        dialog.open(AppModalFormUserChangeEmail, {
          props: {
            header: 'Confirm New Email',
            closable: false,
            ...genericDialogProps
          },
          data: modalChangeEmailData.value
        })
      }
    }
  })
}

function showUpdatePasswordFormModal() {
  const modalOptions = {
    data: {
      passwordData: modalChangePasswordData.value,
      SuperAdmin: false
    },
    ...genericDialogProps
  }
  openChangePasswordDialog(dialog, modalOptions)
}
</script>
