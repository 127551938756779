import * as yup from 'yup';

export const userSettingsSchema = yup.object().shape({
    firstName: yup.string().required().test('First Name', 'First name is required', (value) => {
        if (!value) {
            return createValidationError('First Name', 'First name is required');
        }
        return true;
    }),
    lastName: yup.string().required().test('Last Name', 'Last name is required', (value) => {
        if (!value) {
            return createValidationError('Last Name', 'Last name is required');
        }
        return true;
    })
})

// Helper function to create a validation error
const createValidationError = (path: string, message: string) => {
    return new yup.ValidationError({
        path,
        message,
    } as yup.ValidationError);
};
