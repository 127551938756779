import { formatDate } from '../composables/dateHelpers';
<template>
  <div class="flex">
    <template v-if="fieldMode === 'view'">
      <!--View Mode-->
      <template v-if="model">
        <span class="line-clamp-1 text-left">{{ formatDate(model as Date) }}</span>
      </template>
      <template v-else>
        <div class="h-6"></div>
      </template> 
    </template>
    <template v-else>
      <!--Edit Mode-->
      <DatePicker v-model="model" ></DatePicker>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { formatDate } from '@/composables/dateHelpers';
    
  // v-model
  const model = defineModel() // The data for the field

  // Props
  defineProps<{
    fieldMode: string,  // The mode of the field (EG: view, edit)
    fieldParent: string   // The type of the field (EG: table, card, form)
  }>()
</script>
