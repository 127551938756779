import { reactive } from 'vue';
import { defineStore } from 'pinia';
import type { Tag } from '@/types/TagType';
import { useNotificationStore } from '@/stores/notification.store';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { GetTagsByCompanyID, CreateTag, UpdateTag, DeleteTag } from '@/services/graphql/tag.graphql';
import _ from 'lodash';

export const useTagStore = defineStore('TagStore', () => {
  const notifcationStore = useNotificationStore();

  const data = reactive({
    tags: [] as Tag[],
    loading: false as boolean
  })

  const { load, refetch } = useLazyQuery(GetTagsByCompanyID);
  const queryVariables = reactive({
    companyID: 0
  })

  const { mutate: createTagMutation } = useMutation(CreateTag);
  const { mutate: updateTagMutation } = useMutation(UpdateTag);
  const { mutate: deleteTagMutation } = useMutation(DeleteTag);

  // load/refetch asset types data
  async function loadTagsData(companyID: number) {
    data.loading = true;
    queryVariables.companyID = companyID;

    try {
      let result = await load(GetTagsByCompanyID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } });
      if(!result) {
        result = await refetch(queryVariables);
        result = result.data;
      }

      // clone, then Sort the tags by the id field
      let tags = _.cloneDeep(result.tags);
      tags = _.sortBy(tags, ['tagID']);
      // Update the asset types array with the new data by splicing the array.
      // This will maintain the original array reference and therby maintain the reactivity of the array.
      data.tags.splice(0, data.tags.length, ...tags);
      data.loading = false;
    }
    catch (error) {
      notifcationStore.showError('Error loading asset types data', 'Error');
      console.error('Error loading asset types data:', error);
      data.loading = false;
    }
  }

  // function to call createTag mutation and return it's result
  async function createTag(tag: any) {
    const result = await createTagMutation({ input: tag });
    return result;
  }
  
  // function to call updateTag mutation and return it's result.
  async function updateTag(tag: any) {
    const tagID = tag.tagID;
    // clone the tag object and remove the __typename and tagID fields (required for the mutation)
    const updatingTag = { ...tag };
    delete updatingTag.__typename;
    delete updatingTag.tagID;
    delete updatingTag.selected;
    const result = await updateTagMutation({ tagID: tagID, input: updatingTag });
    return result;
  }

  // function to call deleteTag mutation and return it's result
  async function deleteTag(tag: any) {
    const tagID = tag.tagID;
    const result = await deleteTagMutation({ tagID: tagID });
    return result;
  }

  function $reset() {
    data.tags = [];
    data.loading = false;
  }

  return {
    data,
    loadTagsData,
    createTag,
    updateTag,
    deleteTag,
    $reset
  }
});