<template>
  <div class="flex flex-col p-5 gap-5">
    <!--heading-->
    <AppHeaderPage>
      <template #title> Manage Users </template>
      <template #buttons>
        <Button size="small" label="Invite Users" severity="contrast" outlined class="text-black hover:text-white" @click="showInviteModal()"></Button>
        <Button size="small" :disabled="!isSaveReady" label="Save" class="text-black hover:text-white hover:disabled:text-black" @click="saveUserRoles()"></Button>
      </template>
    </AppHeaderPage>
    <!--table-->
    <div class="pt-3">
      <UserTable
        :user-roles="userRoles"
        :fields="fields"
        @changed="updateChanged($event)"
        @invite="sendInvite($event)"
        @delete="deleteUser($event)"
        :users="userList"
        :loading="isLoading"
      ></UserTable>
    </div>
  </div>
  <DynamicDialog />
</template>

<script lang="ts">
import UserTable from '@/components/UserTable.vue'
import { onMounted, ref, computed, watch } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useNotificationStore } from '@/stores/notification.store'
import mailService from '@/services/api/sendgrid/mail.service'
import type { MailInvite } from '@/types/MailInviteType'
import type { GraphUser } from '@/types/UserType'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { GetUsersByCompany, CreateUser, UpdatePartialUser } from '@/services/graphql/user.graphql'
import { CreateUserPolicy, DeleteUserPolicy, PartialUpdateUserPolicy, GetUserRoles } from '@/services/graphql/userPolicy.graphql'
import { useUserStore } from '@/stores/user.store'
import { useDialog } from 'primevue/usedialog'
import { openUserInviteDialog } from '@/composables/dialogOptions'
import { useCompanyStore } from '@/stores/company.store'
import type { InviteModalData, InviteUserData, LocalUser } from '@/components/AppModalFormUserInvite.vue'
import AppHeaderPage from '@/components/AppHeaderPage.vue'

export default {
  components: {
    UserTable,
    AppHeaderPage
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()

    // variables - user list
    const userList = ref<GraphUser[]>([])
    const isLoading = ref(true)
    const getAdminListCalled = ref(false)

    // notification store
    const notificationStore = useNotificationStore()

    // is save ready
    const changedFields = ref<any[]>([])
    const isSaveReady = computed(() => changedFields.value.length > 0)

    // APOLLO
    const { mutate: createUserMutation } = useMutation(CreateUser)
    const { mutate: createUserPolicyMutation } = useMutation(CreateUserPolicy)
    const { mutate: updateUserMutation } = useMutation(UpdatePartialUser)
    const { mutate: deleteUserPolicyMutation } = useMutation(DeleteUserPolicy)
    const { mutate: updateUserPolicyMutation } = useMutation(PartialUpdateUserPolicy)
    const { refetch: refetchUserPolicy, load: loadGetByID, result: resultGetByID, variables: queryVariables, error: usersByCompanyError } = useLazyQuery(GetUsersByCompany)
    const { load: loadUserRolesQuery, result: resultUserRoles } = useLazyQuery(GetUserRoles)

    // (private) methods
    const confirm = useConfirm()
    const dialog = useDialog()

    const fields = ref(['firstName', 'lastName', 'email', 'userStatus', 'userRole'])

    const companyID = ref(companyStore.companyID)

    onMounted(() => {
      // fetch the user list
      getAdminUserList()
      // load user roles
      loadUserRoles()
    })

    const userRoles = ref([])
    // load user roles
    async function loadUserRoles() {
      // load user roles
      await loadUserRolesQuery()
      if (resultUserRoles.value.userRoles.nodes) {
        userRoles.value = resultUserRoles.value.userRoles.nodes.map((role: any) => {
          return { role: role.role, userRoleID: role.userRoleID }
        })
      }
    }
    // watch for errors
    watch(
      () => usersByCompanyError.value,
      (error: any) => {
        isLoading.value = false
        if (error) {
          notificationStore.showError('Error', 'An error occurred while fetching the user list')
        }
      }
    )

    // (private) methods
    async function getAdminUserList() {
      userList.value = []
      isLoading.value = true
      try {
        // reset changed fields
        changedFields.value = []
        const companyUserList = await getUsersForCompany()
        // set the user list
        userList.value = companyUserList
      } catch (error) {
        console.error('Error in getAdminUserList:', error)
      } finally {
        isLoading.value = false
      }
    }
    // gets all the policies for the company and returns the entraIDs
    async function getUsersForCompany() {
      queryVariables.value = {
        companyID: companyID
      }

      if (getAdminListCalled.value) await refetchUserPolicy()
      else await loadGetByID()

      getAdminListCalled.value = true
      const users = resultGetByID.value?.usersByCompany ?? []
      return users
    }

    // (public) methods
    function deleteUser(user: any) {
      confirm.require({
        header: 'Delete User',
        message: `Are you sure you want to delete user ${user.mail}?`,
        acceptLabel: 'Yes, Delete User',
        acceptClass: 'bg-primary-500 !text-black border-0',
        acceptProps: { severity: 'danger' },
        rejectLabel: 'No, Cancel',
        rejectClass: '!text-black border-2 border-black',
        rejectProps: { outlined: true },
        accept: () => {
          isLoading.value = true
          deleteUserPolicyMutation({
            userPolicyID: user.userPolicies[0].userPolicyID
          })
            .then(() => {
              getAdminUserList()
            })
            .catch((error) => {
              console.error('Error in deleteUser:', error)
            })
        }
      })
    }
    const updateChanged = (data: any[]) => {
      changedFields.value = data
    }

    // show invite modal
    function showInviteModal() {
      const modalOptions = {
        data: {
          companyID: companyID.value,
          userRoles: userRoles.value
        },
        emits: {
          onSubmit: (data: InviteModalData) => {
            // local user grabbed from the invite modal if it exists
            const localUser = {
              email: data.inviteUserData?.email,
              userID: data.localUser?.userID
            } as LocalUser
            // flags to help with the invite process
            const flags = {
              localUserButNoEntraID: data.localUserButNoEntraID,
              entraUserExists: data.entraUserExists,
              localUserExists: data.localUserExists,
              policyExists: data.policyExists
            }
            // invite the user
            inviteUser(localUser, flags, data.inviteUserData)
          }
        }
      }
      // open the dialog
      openUserInviteDialog(dialog, modalOptions)
    }

    // saves the user roles (called from @changed event in UserTable.vue)
    async function saveUserRoles() {
      isLoading.value = true

      // if one of the changed fields is userRole, update the policy
      for (const field of changedFields.value) {
        if (field.field === 'userRole') {
          const userPolicy = {
            userRoleID: field.newValue,
            isEnabled: field.isEnabled
          }
          await updateUserPolicyMutation({ userPolicyID: field.userPolicyID, userPolicy: userPolicy })
        }
      }

      // if other fields, update the user
      for (const field of changedFields.value) {
        if (field.field !== 'userRole') {
          const user = {
            [field.field]: field.newValue
          }
          await updateUserMutation({ userID: field.userID, user: user })
        }
      }

      // refresh the user list
      getAdminUserList()
    }

    async function inviteUser(localUser: LocalUser, flags: any, inviteUserData: InviteUserData | null = null) {
      isLoading.value = true

      // create local user if they dont exist, this will be temporary
      if (!flags.localUserExists && inviteUserData) {
        let userInfo = {
          userID: localUser.userID,
          firstName: inviteUserData.firstName,
          lastName: inviteUserData.lastName,
          userRoleID: inviteUserData.userRoleID,
          email: inviteUserData.email
        }
        // create local user
        await createLocalUser(userInfo).then((response) => {
          localUser.userID = response?.userID
          createPolicyAndEmail(flags, localUser, userInfo.userRoleID)
        })
      } else if (flags.localUserExists && inviteUserData) {
        let userInfo = {
          userID: localUser.userID,
          firstName: inviteUserData.firstName,
          lastName: inviteUserData.lastName,
          userRoleID: inviteUserData.userRoleID
        }
        // create local user
        await updateLocalUser(userInfo).then(() => {
          createPolicyAndEmail(flags, localUser, userInfo.userRoleID)
        })
      }
    }

    async function createPolicyAndEmail(flags: any, localUser: any, userRoleID: number) {
      // CREATE POLICY
      if (!flags.policyExists && localUser) {
        const newPolicy = {
          userID: localUser.userID,
          userRoleID: userRoleID ,
          userStatusID: 1,
          companyID: companyID.value
        }
        const upData = await createUserPolicyMutation({ userP: newPolicy })

        // send email
        await sendCompanyInvite(localUser, upData?.data.createUserPolicy.userPolicyID)

        isLoading.value = false
        // refresh the user list
        getAdminUserList()
      }
    }

    // updates the local user
    async function updateLocalUser(localUser: any) {
      const newUser = {
        firstName: localUser.firstName,
        lastName: localUser.lastName,
        updatedOn: new Date()
      }

      try {
        const response = await updateUserMutation({ userID: localUser.userID, user: newUser })
        return response?.data.updateUser
      } catch (error) {
        console.error('Error updating local user:', error)
        notificationStore.showError('User Update Failed', `An error occurred while updating user ${localUser.displayName}`)
        return null
      }
    }

    function sendCompanyInvite(localUser: any, userPolicyID: any) {
      const invitationUrl = `${window.location.origin}/redeem-invitation`
      isLoading.value = true
      // send email using sendgrid
      const mailData: MailInvite = {
        InvitationUrl: invitationUrl,
        UserName: localUser.email,
        UserEmail: localUser.email,
        Organization: companyStore.data.company.companyName,
        Recipients: [
          {
            Email: localUser.email,
            Name: localUser.email
          }
        ],
        Redirect: window.location.origin,
        UserPolicyID: userPolicyID
      }
      mailService
        .sendCompanyInviteMail(mailData)
        .then(() => {
          isLoading.value = false
          notificationStore.showSuccess('Invite Sent', `An invite has been sent to ${localUser.email}`)
        })
        .catch(() => {
          isLoading.value = false
          notificationStore.showError('Invite Failed', `An error occurred while sending an invite to ${localUser.email}`)
        })
        .finally(() => {
          // fetch the user list
          getAdminUserList()
          // load user roles
          loadUserRoles()
        })
    }

    function sendInvite(localUser: any) {
      // update the user policy (status 1 and disabled)
      updateUserPolicyMutation({ userPolicyID: localUser.userPolicies[0].userPolicyID, userPolicy: { userStatusID: 1, isEnabled: false } })
      sendCompanyInvite(localUser, localUser.userPolicies[0].userPolicyID)
    }

    //  Creates a local user in the DB
    async function createLocalUser(localUser: any) {
      const newUser = {
        email: localUser.email,
        firstName: localUser.firstName,
        lastName: localUser.lastName,
        updatedOn: new Date(),
        createdOn: new Date()
      }
      return await createUserMutation({ user: newUser })
        .then((response) => {
          return response?.data.createUser
        })
        .catch(() => {
          notificationStore.showError('User Creation Failed', `An error occurred while creating user ${localUser.email}`)
          return null
        })
    }

    // HELPERS
    // cleanEmail (remove @domain.com)
    function cleanEmail(email: string) {
      return email.split('@')[0]
    }

    return {
      deleteUser,
      showInviteModal,
      updateChanged,
      inviteUser,
      userList,
      isLoading,
      isSaveReady,
      changedFields,
      saveUserRoles,
      userRoles,
      fields,
      userStore,
      sendInvite,
      // private only for testing
      getAdminUserList,
      getUsersForCompany,
      createLocalUser,
      createUserMutation,
      cleanEmail,
      loadGetByID,
      deleteUserPolicyMutation,
      refetchUserPolicy
    }
  }
}
</script>
