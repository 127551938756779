<template>
  <div class="flex w-full">
    <InputGroup>
      <InputGroupAddon class="bg-white border-inherit px-1">
        <FontAwesomeIcon icon="fas fa-magnifying-glass" />
      </InputGroupAddon>
      <InputText
        :placeholder="placeholder"
        size="small"
        v-model="inputValue"
        pt:root:class="border-x-0 focus:shadow-none border-inherit focus:border-inherit hover:border-inherit px-1"
      />
      <InputGroupAddon class="bg-white border-inherit px-1">
        <FontAwesomeIcon icon="fas fa-spinner" class="animate-spin" v-if="loading" />
      </InputGroupAddon>
    </InputGroup>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import { debounce } from 'lodash'

// Define props for the component
const props = defineProps<{
  modelValue: string
  placeholder: string
}>()

// Define emits for the component
const emit = defineEmits(['update:modelValue'])

const loading = ref(false)

const inputValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue
  }
)

const updateModel = debounce((value: string) => {
  emit('update:modelValue', value)
  loading.value = false
}, 250)

watch(inputValue, (newValue) => {
  loading.value = true
  updateModel(newValue)
})
</script>
