export const UserRoles = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Editor' },
  { value: 3, label: 'Viewer' },
]

export const ShareStatuses = {
  PENDING: 1,
  VIEWED: 2,
  DOWNLOADED: 3
}

export const ObjectType = {
  ASSET: 1,
  FOLDER: 2,
  PRODUCT: 3,
  COLLECTION: 4
}