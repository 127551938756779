import type { AssetType } from '@/types/AssetTypeType'
import type { Audience } from '@/types/AudienceType'
import type { Status } from '@/types/StatusType'
import type { Tag } from '@/types/TagType'

export const assetFields = (assetTypes: AssetType[], audiences: Audience[], statuses: Status[], tags: Tag[]) => [
    { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSearchable: true, isSortable: true },
    { fieldKey: 2, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSearchable: true, isSortable: true },
    { fieldKey: 3, fieldName: 'Created By', fieldValue: 'object.createdBy', fieldType: 'relationship', isVisible: true, relationshipType: 'user', isSortable: true },
    { fieldKey: 4, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true },
    { fieldKey: 5, fieldName: 'Updated By', fieldValue: 'object.lastModifiedBy', fieldType: 'relationship', isVisible: true, relationshipType: 'user', isSortable: true },
    { fieldKey: 6, fieldName: 'Updated On', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true },
    { fieldKey: 7, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true },
    { fieldKey: 8, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSearchable: true },
    { fieldKey: 9, fieldName: 'Url', fieldValue: 'blobUrl', fieldType: 'url', isVisible: true },
    {
      fieldKey: 10,
      fieldName: 'Asset Type',
      fieldValue: 'assetTypeID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSearchable: true,
      selectID: 'assetTypeID',
      selectOptions: assetTypes
    },
    { fieldKey: 11, fieldName: 'Status', fieldValue: 'statusID', fieldType: 'singleSelect', isVisible: true, isSearchable: true, selectID: 'statusID', selectOptions: statuses },
    {
      fieldKey: 12,
      fieldName: 'Audience',
      fieldValue: 'audienceID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSearchable: true,
      selectID: 'audienceID',
      selectOptions: audiences
    },
    { fieldKey: 13, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isSearchable: true, selectID: 'tagID', selectOptions: tags },
    { fieldKey: 14, fieldName: 'Folders', fieldValue: 'folders', fieldType: 'relationship', isVisible: true, isSearchable: true, relationshipType: 'folder' }
  ]