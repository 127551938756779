<template>
  <div v-if="loading || folderLoading">
    <div class="flex justify-center">
      <div class="mt-20">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col">
    <div class="p-5">
      <!--Page Header-->
      <AppHeaderPage>
        <template #title>
          Trash
          <span class="block text-sm font-normal">{{ subHeading }}</span>
        </template>
        <template #buttons>
          <Button
            v-if="HasPermission('Editor')"
            size="small"
            :label="'Empty Trash'"
            severity="contrast"
            outlined
            :disabled="disableEmptyTrashBtn"
            @click="deleteAllModalData"
            class="text-black hover:text-white"
          ></Button>
        </template>
      </AppHeaderPage>
    </div>
    <!-- Set Asset and Folder accordion panels to active based on their lengths -->
    <Accordion :value="[assetStore.data.trashedAssets.length > 0 ? '1' : null, folderStore.data.trashedFolders.length > 0 ? '0' : null]" multiple :pt="accordionTabStyle">
      <AccordionPanel value="0">
        <AccordionHeader>Folders</AccordionHeader>
        <AccordionContent>
          <AppToolbarSelection
            v-if="HasPermission('Editor')"
            :selectedObjects="folderStore.data.selectedFolders"
            :items="trashAreaFoldersSelectionMenuItems"
            @clearSelectedObjects="clearSelectedFolders"
          ></AppToolbarSelection>
          <div class="mx-5 mb-2">
            <ObjectGallery
              :objectList="folderStore.data.trashedFolders"
              :selectedObjects="folderStore.data.selectedFolders"
              :galleryHeight="hasSelectedFolders ? 'calc(100vh - 410px)' : 'calc(100vh - 330px)'"
              :fields="folderCardFields"
              :menu-items="folderCardMenuItems"
              :is-select-checkbox-visible="HasPermission('Editor')"
              :is-edit-mode="true"
              objectType="folder"
              @toggleSelect="galleryFolderToggleSelect"
            >
            </ObjectGallery>
          </div>
        </AccordionContent>
      </AccordionPanel>
      <AccordionPanel value="1">
        <AccordionHeader>Assets</AccordionHeader>
        <AccordionContent>
          <div class="flex flex-col gap-5 mx-5 mb-2">
          <AppToolbarPage 
            :isTableViewSelected="isTableViewSelected" 
            :isDisplayViewButtonsVisible="true" 
            @isTableViewSelected="isTableViewSelected = $event"
            :sortFields="isTableViewSelected ? tableColumns : assetCardFields"
            @selected-filter-action="filterViewBySelectedFilter"
            @set-sort-order="setSortOrder"
          >
          </AppToolbarPage>
            <AppToolbarSelection v-if="HasPermission('Editor')" :selectedObjects="assetStore.data.selectedAssets" :items="trashAreaAssetsSelectionMenuItems" @clearSelectedObjects="unselectAll"></AppToolbarSelection>
            <template v-if="isTableViewSelected">
              <ObjectTable
                :tableHeight="hasSelectedAssets ? 'calc(100vh - 410px)' : 'calc(100vh - 330px)'"
                :objectList="assetStore.data.trashedAssets"
                :selectedObjects="assetStore.data.selectedAssets"
                :columns="tableColumns"
                :search="searchQuery"
                :isEditMode="false"
                @cell-edit-complete="cellEditComplete"
                @row-select="rowSelected"
                @row-unselect="rowUnselected"
                @row-select-all="selectAll"
                @row-unselect-all="unselectAll"
                @removeItem="removeItem"
              >
              </ObjectTable>
            </template>
            <template v-else>
              <ObjectGallery
                :key="1"
                :objectList="assetStore.data.trashedAssets"
                :selectedObjects="assetStore.data.selectedAssets"
                :galleryHeight="hasSelectedAssets ? 'calc(100vh - 410px)' : 'calc(100vh - 330px)'"
                :fields="assetCardFields"
                :menu-items="assetCardMenuItems"
                :search="searchQuery"
                :is-select-checkbox-visible="HasPermission('Editor')"
                :is-edit-mode="HasPermission('Editor')"
                objectType="asset"
                @toggleSelect="galleryToggleSelect"
              >
              </ObjectGallery>
            </template>
          </div>
        </AccordionContent>
      </AccordionPanel>
    </Accordion>
  </div>
  <DynamicDialog />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import ObjectTable from '@/components/ObjectTable.vue'
import ObjectGallery from '@/components/ObjectGallery.vue'
import { useNotificationStore } from '@/stores/notification.store'
import { useAssetStore } from '@/stores/asset.store'
import { useFolderStore } from '@/stores/folder.store'
import { useCompanyStore } from '@/stores/company.store'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { PermanentlyDeleteFolders, RestoreTrashedFolders, GetTrashedFoldersByCompanyID } from '@/services/graphql/folder.graphql'
import { PermanentlyDeleteAssets, RestoreTrashedAssets, GetTrashedAssets } from '@/services/graphql/asset.graphql'
import type { Asset } from '@/types/AssetType'
import type { DataTableCellEditCompleteEvent } from 'primevue/datatable'
import type { Field } from '@/types/FieldType'
import _ from 'lodash'
import { adjustSubfolders } from '@/composables/foldersHelpers'
import { HasPermission } from '@/composables/permissionsHelper'
import { useDialog } from 'primevue/usedialog'
import type { Folder } from '@/types/FolderType'
import { openConfirmEntityDialog } from '@/composables/dialogOptions'
import AppToolbarSelection from '@/components/AppToolbarSelection.vue'
import AppToolbarPage from '@/components/AppToolbarPage.vue'
import { SortObjectFieldValue } from '@/composables/sortObjectFieldValue';

// stores
const notificationStore = useNotificationStore()
const assetStore = useAssetStore()
const folderStore = useFolderStore()
const companyStore = useCompanyStore()
const dialog = useDialog()
const entityList = ref([]) as any
const sortOrder = ref('asc')
const sortFilter = ref({} as Field);

// filter search query
const searchQuery = ref('')

//clear the selected object arrays before navigating to a new route
onBeforeRouteLeave(() => {
  assetStore.$patch((state: any) => {
    state.data.selectedAssets = []
  })
  folderStore.$patch((state: any) => {
    state.data.selectedFolders = []
  })
})

// Get Assets for company.
const { result, loading, refetch } = useQuery(GetTrashedAssets, { companyID: companyStore.data.company.companyID }, { fetchPolicy: 'no-cache' })
const {
  result: folderResult,
  loading: folderLoading,
  refetch: folderRefetch
} = useQuery(GetTrashedFoldersByCompanyID, { companyID: companyStore.data.company.companyID }, { fetchPolicy: 'no-cache' })

const { mutate: permanentlyDeleteAssets } = useMutation(PermanentlyDeleteAssets)
const { mutate: restoreAssets } = useMutation(RestoreTrashedAssets)

const { mutate: permanentlyDeleteFolders } = useMutation(PermanentlyDeleteFolders)
const { mutate: restoreFolders } = useMutation(RestoreTrashedFolders)

watch(result, (newResult) => {
  if (newResult && newResult.trashedAssets) {
    assetStore.$patch((state: any) => {
      state.data.trashedAssets = newResult.trashedAssets
    })
  }
})
watch(folderResult, (newResult) => {
  if (newResult && newResult.trashedFolders) {
    folderStore.$patch((state: any) => {
      state.data.trashedFolders = newResult.trashedFolders
      adjustSubfolders(state.data.trashedFolders)
    })
  }
})

// Menu items for the gallery.
const assetCardMenuItems = ref([
  {
    label: 'Restore',
    icon: 'fa-regular fa-arrow-rotate-left',
    command: (assetID: number) => {
      const asset = _.find(assetStore.data.trashedAssets, { assetID: assetID })
      restoreModalData(false, asset)
    }
  },
  {
    label: 'Permanently Delete',
    icon: 'fa-regular fa-trash-xmark',
    command: (assetID: number) => {
      const asset = _.find(assetStore.data.trashedAssets, { assetID: assetID })
      deleteModalData(false, asset)
    }
  }
])

const folderCardMenuItems = ref([
  {
    label: 'Restore',
    icon: 'fa-regular fa-arrow-rotate-left',
    command: (folderID: number) => {
      const folder = _.find(folderStore.data.trashedFolders, { folderID: folderID })
      restoreModalData(true, folder)
    }
  },
  {
    label: 'Permanently Delete',
    icon: 'fa-regular fa-trash-xmark',
    command: (folderID: number) => {
      const folder = _.find(folderStore.data.trashedFolders, { folderID: folderID })
      deleteModalData(true, folder)
    }
  }
])

const subHeading = computed(() => {
  if (assetStore.data.trashedAssets.length === 0 && folderStore.data.trashedFolders.length === 0) return 0 + ' items in the trash'
  //return the total number of trashed assets and folders
  return `Restore ${assetStore.data.trashedAssets.length + folderStore.data.trashedFolders.length} items from trash`
})

const disableEmptyTrashBtn = computed(() => {
  return assetStore.data.trashedAssets.length === 0 && folderStore.data.trashedFolders.length === 0
})

const trashAreaFoldersSelectionMenuItems = computed(() => {
  return [
    {
      label: 'Restore',
      icon: 'fa-regular fa-arrow-rotate-left',
      command: () => {
        restoreModalData(true)
      }
    },
    {
      label: 'Permanently Delete',
      icon: 'fa-regular fa-trash-xmark',
      command: () => {
        deleteModalData(true)
      }
    }
  ]
})
const trashAreaAssetsSelectionMenuItems = computed(() => {
  return [
    {
      label: 'Restore',
      icon: 'fa-regular fa-arrow-rotate-left',
      command: () => {
        restoreModalData(false)
      }
    },
    {
      label: 'Permanently Delete',
      icon: 'fa-regular fa-trash-xmark',
      command: () => {
        deleteModalData(false)
      }
    }
  ]
})

// reactive variables
const isTableViewSelected = ref(false) // default to gallery view

// Table columns definition
// Eventually these will both come from the DB
const tableColumns = ref<Array<Field>>([
  // Key needs to start at 4 to make room for static columns
  { fieldKey: 4, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: true, isEditable: true, width: '200px' },
  { fieldKey: 5, fieldName: 'Status', fieldValue: 'status', fieldType: 'singleSelect', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
  { fieldKey: 6, fieldName: 'Created By', fieldValue: 'object.createdBy', fieldType: 'relationship', relationshipType: 'user', isVisible: true, isSortable: true, isEditable: false },
  { fieldKey: 7, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
  { fieldKey: 8, fieldName: 'Last Edited', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
  {
    fieldKey: 6,
    fieldName: 'Edited By',
    fieldValue: 'object.lastModifiedBy',
    fieldType: 'relationship',
    relationshipType: 'user',
    isVisible: true,
    isSortable: true,
    isEditable: false
  },
  { fieldKey: 9, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isSortable: false, isEditable: false, isSearchable: true, width: '300px' },
  { fieldKey: 10, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true, isSortable: false, isEditable: false },
  {
    fieldKey: 11,
    fieldName: 'Folders',
    fieldValue: 'folders',
    fieldType: 'relationship',
    relationshipType: 'folder',
    isVisible: true,
    isSortable: false,
    isEditable: false,
    isSearchable: true,
    width: '260px'
  },
  {
    fieldKey: 12,
    fieldName: 'Description',
    fieldValue: 'description',
    fieldType: 'editor',
    isVisible: true,
    isSortable: false,
    isEditable: false,
    isSearchable: true,
    width: '300px'
  },
  { fieldKey: 13, fieldName: 'Audience', fieldValue: 'audience', fieldType: 'singleSelect', isVisible: true, isSortable: false, isEditable: false, isSearchable: true },
  { fieldKey: 14, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: false, isEditable: false, isSearchable: true },
  { fieldKey: 15, fieldName: 'Asset Type', fieldValue: 'assetType', fieldType: 'singleSelect', isVisible: true, isSortable: false, isEditable: false, isSearchable: true }
])
const assetCardFields = ref<Array<Field>>([
  { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: true },
  { fieldKey: 2, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSortable: true  },
  { fieldKey: 3, fieldName: 'Created By', fieldValue: 'object.createdBy', fieldType: 'relationship', isVisible: true, relationshipType: 'user' },
  { fieldKey: 4, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true },
  { fieldKey: 5, fieldName: 'Updated By', fieldValue: 'object.lastModifiedBy', fieldType: 'relationship', isVisible: true, relationshipType: 'user' },
  { fieldKey: 6, fieldName: 'Updated On', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true },
  { fieldKey: 7, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true },
  { fieldKey: 8, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: true  },
  { fieldKey: 9, fieldName: 'Url', fieldValue: 'blobUrl', fieldType: 'url', isVisible: true },
  { fieldKey: 10, fieldName: 'Asset Type', fieldValue: 'assetType', fieldType: 'singleSelect', isVisible: true },
  { fieldKey: 11, fieldName: 'Status', fieldValue: 'status', fieldType: 'singleSelect', isVisible: true, isSortable: true  },
  { fieldKey: 12, fieldName: 'Audience', fieldValue: 'audience', fieldType: 'singleSelect', isVisible: true, isSortable: true  },
  { fieldKey: 13, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isSortable: true  },
  { fieldKey: 14, fieldName: 'Folders', fieldValue: 'folders', fieldType: 'relationship', isVisible: true, relationshipType: 'folder' }
])

const folderCardFields = ref<Array<Field>>([
  { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true },
  { fieldKey: 2, fieldName: 'AssociatedObjects', fieldValue: 'associatedObjects', fieldType: 'text', isVisible: true }
])

const hasSelectedAssets = computed(() => {
  return assetStore.data.selectedAssets.length > 0
})

const hasSelectedFolders = computed(() => {
  return folderStore.data.selectedFolders.length > 0
})

function filterViewBySelectedFilter(filter?: Field) {

  //check if the field value is an object.* field
  if(filter && filter.fieldValue &&  filter.fieldValue.includes('object.')) {
    sortFilter.value = filter
    assetStore.data.trashedAssets =  SortObjectFieldValue(filter, assetStore.data.trashedAssets, sortOrder.value)
  }
  else if(filter) {
    sortFilter.value = filter
    //filter the asset list based on the filter
    assetStore.data.trashedAssets = _.orderBy(assetStore.data.trashedAssets, [sortFilter.value.fieldValue], [sortOrder.value])
  } 
  else {
    sortFilter.value = { fieldKey: 0, fieldName: '', fieldValue: '', fieldType: '', isVisible: false }; 
    assetStore.data.trashedAssets = _.orderBy(assetStore.data.trashedAssets, ['folderID'], ['asc'])
  }
  
}

function setSortOrder(isAscending: boolean) {
  if(isAscending)
    sortOrder.value = 'asc'
  else 
    sortOrder.value = 'desc'
  //after the order gets set, sort the list 
  if(sortFilter.value)
    filterViewBySelectedFilter(sortFilter.value)
}

// Functions
// Cell Edit Complete event handler
function cellEditComplete(event: DataTableCellEditCompleteEvent) {
  if (event) {
    let { field, newValue, data } = event
    // Update the asset in the store
    assetStore.$patch((state: any) => {
      const asset = state.data.assets.find((asset: Asset) => asset.objectID === data.objectID)
      asset[field] = newValue
    })
  }
}

// Row Selected event handler
function rowSelected(event: any) {
  if (event !== undefined) {
    assetStore.$patch((state: any) => {
      state.data.selectedAssets.push(event.data)
    })
  }
}

// Row Unselected event handler
function rowUnselected(event: any) {
  if (event !== undefined) {
    // Remove the unselected asset from the selectedAssets list
    assetStore.$patch((state: any) => {
      state.data.selectedAssets = state.data.selectedAssets.filter((asset: Asset) => asset.objectID !== event.data.objectID)
    })
  }
}

// Row Select All event handler
function selectAll() {
  // Set the selectedAssets list to all assets
  assetStore.$patch((state: any) => {
    state.data.selectedAssets = state.data.trashedAssets
  })
}
function clearSelectedFolders() {
  folderStore.$patch((state: any) => {
    state.data.selectedFolders = []
  })
}
// Row Unselect All event handler
function unselectAll() {
  // Clear the selectedAssets list
  assetStore.$patch((state: any) => {
    assetStore.data.selectedAssets = []
  })
}

function populateEntityList(isFolder: boolean, entity?: Asset | Folder) {
  entityList.value = []

  const selectedItems = isFolder ? folderStore.data.selectedFolders : assetStore.data.selectedAssets
  const trashedItems = isFolder ? folderStore.data.trashedFolders : assetStore.data.trashedAssets

  if (!entity && selectedItems.length === 0) {
    trashedItems.map((item: Asset | Folder) => {
      if (isFolder) entityList.value.push({ entityID: item.folderID, entityName: item.name })
      else entityList.value.push({ entityID: item.assetID, entityName: item.name })
    })
  } else {
    selectedItems.map((item: Asset | Folder) => {
      if (isFolder) entityList.value.push({ entityID: item.folderID, entityName: item.name })
      else entityList.value.push({ entityID: item.assetID, entityName: item.name })
    })
  }
}
function restoreModalData(isFolder: boolean, entity?: Asset | Folder) {
  populateEntityList(isFolder, entity)

  const modalOptions = {
    props: {
      header: 'Restore Item(s)'
    },
    data: {
      entityList: !entity ? entityList.value : [{ entityID: isFolder ? entity.folderID : entity.assetID, entityName: entity.name }],
      subText: entity ? `You are about to restore 1 item` : `You are about to restore ${entityList.value.length} items`,
      customLabels: {
        submitLabel: 'Restore'
      },
      isConfirmListModal: true
    },
    emits: {
      onSubmit: async () => {
        await restoreItems(isFolder, entity)
        //clear the entity list
        entityList.value = []
        entity = undefined
        //clear the selected assets
        if (isFolder) {
          folderStore.$patch((state: any) => {
            state.data.selectedFolders = []
          })
        } else {
          assetStore.$patch((state: any) => {
            state.data.selectedAssets = []
          })
        }
      }
    }
  }

  openConfirmEntityDialog(dialog, modalOptions)
}

const deleteModalData = async (isFolder: boolean, entity?: Asset | Folder) => {
  populateEntityList(isFolder, entity)

  const modalOptions = {
    props: {
      header: 'Permanently Delete Items(s)?'
    },
    data: {
      entityList: !entity ? entityList.value : [{ entityID: isFolder ? entity.folderID : entity.assetID, entityName: entity.name }],
      subText: entity ? `You are about to permanently delete 1 item` : `You are about to delete ${entityList.value.length} items`,
      customLabels: {
        submitLabel: 'Delete'
      },
      isConfirmListModal: true
    },
    emits: {
      onSubmit: async () => {
        await trashItems(isFolder, entity)
        //clear the entity list
        entityList.value = []
        entity = undefined
        //clear the selected assets
        if (isFolder) {
          folderStore.$patch((state: any) => {
            state.data.selectedFolders = []
          })
        } else {
          assetStore.$patch((state: any) => {
            state.data.selectedAssets = []
          })
        }
      }
    }
  }

  openConfirmEntityDialog(dialog, modalOptions)
}

function restoreItems(isFolder: boolean, entity?: Asset | Folder) {
  if (isFolder) {
    let folderID = entity?.folderID
    if (folderID) {
      restoreFolders({ folderIDs: [folderID] })
        .then(() => {
          folderRefetch()
          //refetch assets to account for assets linked to folders
          refetch()
          notificationStore.showSuccess('Folder restored', 'Folder restored successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error restoring folder')
        })
    } else {
      const selectedFolderIDs = folderStore.data.selectedFolders.map((folder: Folder) => folder.folderID)
      restoreFolders({ folderIDs: selectedFolderIDs })
        .then(() => {
          //refetch assets to account or assets linked to folders
          refetch()
          folderRefetch()
          folderStore.$patch((state: any) => {
            state.data.selectedFolders = []
          })
          notificationStore.showSuccess('Folders restored', 'Folders restored successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error restoring folders')
        })
    }
  } else {
    let assetID = entity?.assetID
    if (assetID) {
      restoreAssets({ assetIDs: [assetID] })
        .then(() => {
          refetch()
          notificationStore.showSuccess('Asset restored', 'Asset restored successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error restoring asset')
        })
    } else {
      const selectedAssetIDs = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
      restoreAssets({ assetIDs: selectedAssetIDs })
        .then(() => {
          refetch()
          assetStore.$patch((state: any) => {
            state.data.selectedAssets = []
          })
          notificationStore.showSuccess('Assets restored', 'Assets restored successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error restoring assets')
        })
    }
  }
}

function trashItems(isFolder: boolean, entity?: Asset | Folder) {
  if (isFolder) {
    let folderID = entity?.folderID
    if (folderID) {
      permanentlyDeleteFolders({ folderIDs: [folderID] })
        .then(() => {
          folderRefetch()
          refetch()
          notificationStore.showSuccess('Folder deleted', 'Folder deleted successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error deleting folder')
        })
    } else {
      const selectedFolderIDs = folderStore.data.selectedFolders.map((folder: Folder) => folder.folderID)
      permanentlyDeleteFolders({ folderIDs: selectedFolderIDs })
        .then(() => {
          folderRefetch()
          refetch()
          folderStore.$patch((state: any) => {
            state.data.selectedFolders = []
          })
          notificationStore.showSuccess('Folders deleted', 'Folders deleted successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error deleting folders')
        })
    }
  } else {
    let assetID = entity?.assetID
    //if an assetID was passed we know its a single delete
    if (assetID) {
      permanentlyDeleteAssets({ assetIDs: [assetID] })
        .then(() => {
          refetch()
          notificationStore.showSuccess('Asset deleted', 'Asset deleted successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error deleting asset')
        })
    } else {
      //if no assetID was passed we know its a bulk delete
      const selectedAssetIDs = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
      permanentlyDeleteAssets({ assetIDs: selectedAssetIDs })
        .then(() => {
          refetch()
          assetStore.$patch((state: any) => {
            state.data.selectedAssets = []
          })
          notificationStore.showSuccess('Assets deleted', 'Assets deleted successfully')
        })
        .catch(() => {
          notificationStore.showError('See console for details.', 'Error deleting assets')
        })
    }
  }
}

//Open the confirmation modal with the entity list and empty all folders and assets from trash
function deleteAllModalData() {
  entityList.value = []
  //map assetID and assetName to the entity list from the asset store
  assetStore.data.trashedAssets.map((asset: Asset) => {
    entityList.value.push({ entityID: asset.assetID, entityName: asset.name })
  })
  //map folder data to entity list
  folderStore.data.trashedFolders.map((folder: Folder) => {
    entityList.value.push({ entityID: folder.folderID, entityName: folder.name })
  })

  const modalOptions = {
    props: {
      header: 'Empty Trash'
    },
    data: {
      title: 'Empty Trash',
      subText: 'You are about to permanently delete all items in the trash',
      entityList: entityList.value,
      isConfirmListModal: true,
      customLabels: {
        submitLabel: 'Empty Trash'
      },
      events: {
        onSubmit: async () => {
          emptyTrash()
          //clear the entity list
          entityList.value = []
        }
      }
    }
  }

  openConfirmEntityDialog(dialog, modalOptions)
}

function removeItem(value: any) {
  notificationStore.showInfo('Delete action clicked - ' + value.name, 'Not Implemented Yet')
}

// Toggle's selection of an object from the gallery/card view
function galleryToggleSelect(object: Asset) {
  if (!HasPermission('Editor')) return
  assetStore.$patch((state: any) => {
    if (state.data.selectedAssets.includes(object)) {
      state.data.selectedAssets = state.data.selectedAssets.filter((asset: Asset) => asset.objectID !== object.objectID)
    } else {
      state.data.selectedAssets.push(object)
    }
  })
}

// Toggle's selection of an object from the gallery/card view
function galleryFolderToggleSelect(object: Folder) {
  if (!HasPermission('Editor')) return
  folderStore.$patch((state: any) => {
    if (state.data.selectedFolders.includes(object)) {
      state.data.selectedFolders = state.data.selectedFolders.filter((folder: Folder) => folder.objectID !== object.objectID)
    } else {
      state.data.selectedFolders.push(object)
    }
  })
}

//delete all folders and assets
function emptyTrash() {
  if (folderStore.data.trashedFolders.length > 0) {
    //delete all folders
    permanentlyDeleteFolders({ folderIDs: folderStore.data.trashedFolders.map((folder: Folder) => folder.folderID) })
      .then(() => {
        folderRefetch()
        notificationStore.showSuccess('Folders deleted', 'All folders deleted successfully')
      })
      .catch(() => {
        notificationStore.showError('See console for details.', 'Error deleting folders')
      })
  }
  if (assetStore.data.trashedAssets.length > 0) {
    //delete all assets
    permanentlyDeleteAssets({ assetIDs: assetStore.data.trashedAssets.map((asset: Asset) => asset.assetID) })
      .then(() => {
        refetch()
        notificationStore.showSuccess('Assets deleted', 'All assets deleted successfully')
      })
      .catch(() => {
        notificationStore.showError('See console for details.', 'Error deleting assets')
      })
  }
}

const accordionTabStyle = { header: { class: ['bg-transparent border-0 border-b'] }, headerIcon: { class: [] }, content: { class: ['border-none'] } }
</script>
