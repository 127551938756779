<template>
  <div class="min-h-screen flex flex-col">
    <AppNotification></AppNotification>
    <AppHeader v-if="showHeader"></AppHeader>
    <!--App Viewport-->
    <div class="flex-grow" :class="showHeader ? 'mt-14' : ''">
      <RouterView />
    </div>
    <AppConfirmBase></AppConfirmBase>
    <AppFooter v-if="showFooter"></AppFooter>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import AppHeader from './components/AppHeader.vue'
import AppNotification from './components/AppNotification.vue'
import AppConfirmBase from './components/AppConfirmBase.vue'
import { dom } from '@fortawesome/fontawesome-svg-core'
import AppFooter from './components/AppFooter.vue'

export default defineComponent({
  name: 'ProdifyApp',
  components: {
    RouterView,
    AppHeader,
    AppFooter,
    AppNotification,
    AppConfirmBase
  },
  computed: {
    showHeader() {
      return !this.$route.path.includes('/shared')
    },
    showFooter() {
      // only show footer if the route is not for the admin and if the route does not have a company ID
      return !this.$route.path.includes('/admin') && !this.$route.params.company
    }
  },

  setup() {}
})

// This is needed to allow font awesome icons to be used inside primevue components
dom.watch()
</script>
