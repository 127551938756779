<template>
  <div v-if="!dataLoaded">
    <div class="flex justify-center">
      <div class="mt-20">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col p-5 gap-5">
    <!-- Breadcrumbs -->
    <Breadcrumbs></Breadcrumbs>
    <!--Form Header-->
    <AppHeaderPage>
      <template #title>
        {{ initialAsset.name }}
      </template>
      <template #buttons>
        <FontAwesomeIcon v-if="!isSharedPage" icon="fa-regular fa-arrow-down-to-bracket" class="cursor-pointer hover:text-primary" @click="downloadAsset" />
        <Button v-else size="small" outlined severity="contrast" class="text-black hover:text-white" @click="downloadAsset">
          <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-bracket"></FontAwesomeIcon>Download
        </Button>
        <FontAwesomeIcon
          v-if="!isSharedPage && userCanEdit && isAuthenticated && IsFavorite(assetObject)"
          icon="fa-solid fa-star"
          class="cursor-pointer hover:text-primary"
          @click="unfavouriteAsset"
        />
        <FontAwesomeIcon v-else-if="!isSharedPage && userCanEdit && isAuthenticated" icon="fa-regular fa-star" class="cursor-pointer hover:text-primary" @click="favouriteAsset" />
        <Button v-if="!isSharedPage && userCanEdit && formMode === 'view'" class="text-black" size="small" outlined severity="contrast" @click="shareAsset">
          <FontAwesomeIcon icon="fa-regular fa-paper-plane"></FontAwesomeIcon>
          <span class="p-button-label">Share</span>
        </Button>
        <Button
          v-if="!isSharedPage && userCanEdit && formMode === 'view'"
          class="text-black hover:text-white"
          size="small"
          label="Edit"
          severity="primary"
          @click="toggleEditMode"
        ></Button>
        <Button class="text-black hover:text-white" v-if="formMode === 'edit'" size="small" label="Cancel" severity="contrast" outlined @click="cancelEdit"></Button>
        <Button
          class="text-black hover:text-white hover:disabled:text-black"
          v-if="formMode === 'edit'"
          size="small"
          label="Save"
          severity="primary"
          :disabled="disableSave"
          @click="saveAsset"
        ></Button>
      </template>
    </AppHeaderPage>
    <!--Form Body-->
    <div class="grid grid-cols-1 md:grid-cols-8 gap-3">
      <!--Preview-->
      <div class="col-span-4">
        <div class="flex justify-center">
          <ObjectPreview :object="asset" type="full" />
        </div>
      </div>
      <!--Fields-->
      <div class="col-span-4 flex flex-col px-3 gap-4">
        <!--Visible Fields-->
        <template v-for="field in formFieldsVisible" :key="field.fieldKey">
          <div class="flex gap-1" :class="toggleFieldMode(field) === 'edit' ? 'flex-col' : ''">
            <!--Field Label-->
            <div class="font-bold">{{ field.fieldName }}:</div>
            <!--Field-->
            <component
              :is="getFieldType(field.fieldType)"
              :modelValue="_.get(asset, field.fieldValue)"
              @update:modelValue="_.set(asset, field.fieldValue, $event), updateChanges(field.fieldValue, asset)"
              :fieldName="field.fieldName"
              :formatType="field.fieldValue"
              :fieldMode="toggleFieldMode(field)"
              fieldParent="form"
              :relationshipType="field.relationshipType"
              :selectID="field.selectID"
              :options="field.selectOptions"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRefs, defineEmits } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import _ from 'lodash'
import { useAssetFormStore } from '@/stores/assetForm.store'
import { useNotificationStore } from '@/stores/notification.store'
import { useUserStore } from '@/stores/user.store'
import { useStatusStore } from '@/stores/status.store'
import { useAudienceStore } from '@/stores/audience.store'
import { useTagStore } from '@/stores/tag.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import { useCompanyStore } from '@/stores/company.store'
import { useFolderStore } from '@/stores/folder.store'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import { downloadFile } from '@/composables/downloadHelper'
import fileService from '@/services/api/file.service'
import { IsFavorite } from '@/composables/objectHelpers'
import ObjectPreview from './ObjectPreview.vue'
import { useDialog } from 'primevue/usedialog'
import { openShareDialog, openViewChangesDialog } from '@/composables/dialogOptions'
import { HasPermission } from '@/composables/permissionsHelper'
import { fieldValuesAreEqual, getFieldType } from '@/composables/fieldHelpers'
import type { Field } from '@/types/FieldType'
import type { Asset } from '@/types/AssetType'
import AppHeaderPage from './AppHeaderPage.vue'
import Breadcrumbs from './Breadcrumbs.vue'

const props = defineProps<{
  assetId: number
  isSharedPage: boolean
}>()

// Define emits
const emits = defineEmits(['objectDownloaded'])

// Initialize
const dialog = useDialog()
const isAuthenticated = useIsAuthenticated()

// STORES
const notificationStore = useNotificationStore()
const assetFormStore = useAssetFormStore()
const statusStore = useStatusStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const assetTypeStore = useAssetTypeStore()
const userStore = useUserStore()
const folderStore = useFolderStore()
const companyStore = useCompanyStore()

// LOCAL PROPERTIES
const { asset, initialAsset } = toRefs(assetFormStore.data)
const dataLoaded = ref(false)
const formMode = ref('view')
const formFields = ref<Array<Field>>([])

// COMPUTED PROPERTIES
const userCanEdit = computed(() => HasPermission('Editor'))
const disableSave = computed(() => assetFormStore.data.changes.length <= 0)
const assetObject = computed(() => asset.value.object ?? {})
// Split the form fields into visible and hidden
const formFieldsVisible = computed(() => formFields.value.filter((field) => field.isVisible))
// removed until we setup fields component
//const formFieldsHidden = computed(() => formFields.value.filter((field) => !field.isVisible))

onMounted(async () => {
  // Load the stores so they can be accessed in the Asset Form component
  await folderStore.loadFoldersData(companyStore.companyID)
  await assetFormStore.loadAssetData(companyStore.companyID, props.assetId)

  formFields.value = [
    { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: false, isEditable: true, width: '200px' },
    {
      fieldKey: 2,
      fieldName: 'Status',
      fieldValue: 'statusID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: true,
      isEditable: true,
      width: '100px',
      selectID: 'statusID',
      selectOptions: statusStore.data.statuses
    },
    {
      fieldKey: 3,
      fieldName: 'Created By',
      fieldValue: 'object.createdBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    { fieldKey: 4, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    { fieldKey: 5, fieldName: 'Last Edited', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    {
      fieldKey: 6,
      fieldName: 'Edited By',
      fieldValue: 'object.lastModifiedBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    {
      fieldKey: 7,
      fieldName: 'Tags',
      fieldValue: 'tags',
      fieldType: 'multiSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      width: '300px',
      selectID: 'tagID',
      selectOptions: tagStore.data.tags
    },
    { fieldKey: 8, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true, isSortable: false, isEditable: false },
    {
      fieldKey: 9,
      fieldName: 'Folders',
      fieldValue: 'folders',
      fieldType: 'multiSelect',
      relationshipType: 'folder',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      width: '260px',
      selectID: 'folderID',
      selectOptions: folderStore.data.folders
    },
    { fieldKey: 10, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSortable: false, isEditable: true, width: '300px' },
    {
      fieldKey: 11,
      fieldName: 'Audience',
      fieldValue: 'audienceID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      selectID: 'audienceID',
      selectOptions: audienceStore.data.audiences
    },
    { fieldKey: 12, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: false, isEditable: false },
    {
      fieldKey: 13,
      fieldName: 'Asset Type',
      fieldValue: 'assetTypeID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      selectID: 'assetTypeID',
      selectOptions: assetTypeStore.data.assetTypes
    }
  ]

  dataLoaded.value = true
})

function toggleFieldMode(field: Field) {
  if (field.isEditable) {
    return formMode.value
  } else {
    return 'view'
  }
}

function toggleEditMode() {
  formMode.value = formMode.value === 'view' ? 'edit' : 'view'
}

function cancelEdit() {
  assetFormStore.clearPendingChanges()
  toggleEditMode()
}

function downloadAsset() {
  if ((isAuthenticated.value || userStore.isDownloadAllowedForSharedUser()) && asset) {
    fileService
      .downloadAsset(asset.value.assetID)
      .then((response) => {
        // simulate button press to avoid popup blockers
        downloadFile(response.data, asset.value.name)
        // show success notification
        notificationStore.showSuccess('Downloaded ' + asset.value.name, 'Download Successful')
        // If it's a shared page, emit and event to change the ShareStatusID to downloaded.
        if (props.isSharedPage) {
          emits('objectDownloaded')
        }
      })
      .catch((error) => {
        console.error(error)
        notificationStore.showError(asset.value.name + ` ${error.response?.data}`, 'Download Failed')
      })
  } else {
    notificationStore.showError('You must be logged in to download assets.', 'Not Authenticated')
  }
}

async function favouriteAsset() {
  assetFormStore
    .favoriteAsset(userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Added ${asset.value.name} as Favorite`, 'Favorite Added')
      assetFormStore.loadAssetData(companyStore.companyID, props.assetId)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error favoriting asset', 'Error')
    })
}

async function unfavouriteAsset() {
  assetFormStore
    .unfavoriteAsset(userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Removed ${asset.value.name} as Favorite`, 'Favorite Removed')
      assetFormStore.loadAssetData(companyStore.companyID, props.assetId)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error unfavoriting asset', 'Error')
    })
}

function shareAsset() {
  let options = {
    data: {
      objects: [asset.value],
      objectType: 'asset'
    },
    emits: {
      onAssetShared: async () => {}
    }
  }
  openShareDialog(dialog, options)
}

function updateChanges(field: string, asset: Asset) {
  // Find the asset and original Asset.  This is used to compare the original value to the new value
  const ogAsset = assetFormStore.data.initialAsset
  const ogValue = _.get(ogAsset, field)
  const newValue = _.get(asset, field)
  // Check if the value has changed from the original value
  if (!fieldValuesAreEqual(newValue, ogValue)) {
    // Check if the asset field is already in the changes list.
    if (!assetFormStore.data.changes.find((change: any) => change.field === field)) {
      // Add the asset field to the changedAssets list
      assetFormStore.data.changes.push({ assetID: asset.assetID, referenceID: asset.referenceID, field: field, value: newValue })
    }
  } else {
    // Remove the asset field from the changes list
    assetFormStore.data.changes = assetFormStore.data.changes.filter((change: any) => change.field !== field)
  }
}

async function saveAsset() {
  // data to be passed to the modal
  const modalOptions = {
    data: {
      entityList: assetFormStore.data.changes
    },
    emits: {
      onSubmit: async () => {
        // call the saveAssetsData function in the store and handle the response from the mutation
        assetFormStore
          .saveAssetData(userStore.getUserID)
          .then(() => {
            notificationStore.showSuccess('Changes saved successfuly', 'Changes Saved')
            const assetID = assetFormStore.data.asset.assetID
            assetFormStore.$reset()
            assetFormStore.loadAssetData(companyStore.companyID, assetID)
            folderStore.loadFoldersData(companyStore.companyID)
          })
          .catch((error: any) => {
            console.error(error)
            notificationStore.showError('Error saving changes', 'Error')
          })
        toggleEditMode()
      }
    }
  }
  openViewChangesDialog(dialog, modalOptions)
}
</script>
