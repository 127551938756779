<template>
     
      <Toolbar class="bg-transparent border-none" :pt="{ root: { class: 'p-0 flex justify-between' } }">
        <template #start>
          <AppSearchBox v-model="search" :fields="['name', 'description']" placeholder="Search" :loading="isSearchLoading" />
          <AppSortMenu 
            class="z-20"
            :sortFields="props.sortFields" 
            @selectedFilterAction="filterSelected" 
            @setSortOrder="setSortOrder"/>
          <FontAwesomeIcon icon="far fa-gear" class="text-lg ml-3 hover:text-primary-500 cursor-pointer" @click="openFieldSettings" />
        </template>
        <template #end>
          <FontAwesomeIcon
            v-if="isDisplayViewButtonsVisible"
            icon="far fa-list-ul"
            class="text-lg ml-3 hover:text-primary-500 cursor-pointer"
            :class="isTableViewSelected ? 'text-primary-500' : ''"
            @click="changeViewType"
          />
          <FontAwesomeIcon
            v-if="isDisplayViewButtonsVisible"
            icon="far fa-grid-2"
            class="text-lg ml-3 hover:text-primary-500 cursor-pointer"
            :class="isTableViewSelected ? '' : 'text-primary-500'"
            @click="changeViewType"
          />
        </template>
      </Toolbar>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useNotificationStore } from '@/stores/notification.store'
import AppSearchBox from './AppSearchBox.vue'
import AppSortMenu from '@/components/AppSortMenu.vue'
import type { Field } from '@/types/FieldType'

// stores
const notificationStore = useNotificationStore()

// emits
const emit = defineEmits(['isTableViewSelected', 'search', 'update:loading', 'selectedFilterAction', 'setSortOrder', 'clearSortFilter'])

// props
const props = defineProps<{
  sortFields: Field []
  isTableViewSelected: boolean
  isDisplayViewButtonsVisible: boolean
}>()


const search = ref('')
const isSearchLoading = ref(false)

// assign the isTableViewSelected prop to a ref so we can mutate it
const isTableViewSelected = ref(props.isTableViewSelected)

watch(
  () => search.value,
  () => {
    emit('search', search.value)
    emit('update:loading', isSearchLoading.value)
  }
)

const setSortOrder = (isAscending: boolean) => {
  emit('setSortOrder', isAscending)
}

const openFieldSettings = () => {
  notificationStore.showInfo('Functionality not implemented yet')
}

const changeViewType = () => {
  isTableViewSelected.value = !isTableViewSelected.value
  emit('isTableViewSelected', isTableViewSelected.value)
}

watch(
  () => props.isTableViewSelected,
  (newValue) => {
    isTableViewSelected.value = newValue
  }
)

function filterSelected (filter?: Field) {
  emit('selectedFilterAction', filter)
}
</script>
