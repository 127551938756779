import { reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import type { Folder } from '@/types/FolderType'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { GetFolderByID, UpdateFolderTable } from '@/services/graphql/folder.graphql'
import { setAssociatedObjectsToFolders } from '@/composables/foldersHelpers'
import { useNotificationStore } from './notification.store'
import _ from 'lodash'

export const useFolderFormStore = defineStore('FolderFormStore', () => {
  const notificationStore = useNotificationStore()

  const data = reactive({
    folder: {} as Folder,
    initialFolder: {} as Folder,
    changes: [] as any[],
    loading: false
  })

  // Getters
  // true if there are changes to the assets
  const hasChanges = computed(() => data.changes.length > 0)

  // Query & Variables
  const { load: loadFolder, refetch: refetchFolder } = useLazyQuery(GetFolderByID)
  const folderQueryVariables = reactive({ companyID: 0 as number, folderID: 0 as number })

  // Mutations
  const { mutate: updateFoldersMutation } = useMutation(UpdateFolderTable)

  async function loadFolderData(companyID: number, folderID: number) {
    data.loading = true;
    folderQueryVariables.companyID = companyID;
    folderQueryVariables.folderID = folderID;

    try {
      // Run the query and check the result
      let result = await loadFolder(GetFolderByID, folderQueryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })
      if (!result) {
        // If result is false, then use refetch instead
        result = await refetchFolder(folderQueryVariables)
        result = result.data // Reformat the result because vue apollo is weird.
      }

      // Set the folder data using the result
      const folder = _.cloneDeep(result.folder)
      if (folder !== null && folder.subFolders) {
        setAssociatedObjectsToFolders(folder.subFolders);
      }

      // Set the folder data using the result
      // assign the folder and initialFolder to the result folder
      Object.assign(data.folder, _.cloneDeep(folder))
      Object.assign(data.initialFolder, _.cloneDeep(folder))
      data.loading = false
    }
    catch (error) {
      notificationStore.showError('Failed to load folder data', 'Error')
      console.error('Error loading folder data:', error)
    }
  }

  async function saveFolderData(userID: number) {
    const folderInput = {
      folderID: data.folder.folderID,
      objectID: data.folder.objectID,
      name: data.folder.name,
      description: data.folder.description,
      tagIDs: data.folder.tags.map((tag) => tag.tagID)
    }

    const folderResult = await updateFoldersMutation({ userID: userID, folders: [folderInput] })
    return folderResult
  }

  function clearPendingChanges() {
    data.folder = _.cloneDeep(data.initialFolder)
    data.changes = []
  }

  // Reset the state
  function $reset() {
    data.folder = {} as Folder;
    data.initialFolder = {} as Folder;
    data.changes = [];
    data.loading = false;
  }

  return {
    data,
    hasChanges,
    loadFolderData,
    saveFolderData,
    clearPendingChanges,
    $reset
  }
})
