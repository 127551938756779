<template>
  <ConfirmDialog :pt="confirmStyles">
    <template #message="slotProps">
      <div class="flex flex-col items-center w-full py-3 px-1 pb-0 space-y-2">
        <h2 class="text-xl font-extrabold" v-if="slotProps.message.header">{{ slotProps.message.header }}</h2>
        <h4 class="text-base mt-2" v-if="slotProps.message.acceptProps.subHeader">{{ slotProps.message.acceptProps.subHeader }}</h4>
        <div v-if="slotProps.message.acceptProps.severity === 'danger'" class="flex items-center space-x-2 text-red-400 mt-4">
          <FontAwesomeIcon icon="fal fa-exclamation-triangle" />
          <span>{{ slotProps.message.message }}</span>
        </div>
        <span v-else class="mt-2">{{ slotProps.message.message }}</span>
      </div>
    </template>
  </ConfirmDialog>
</template>
<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog'
import type { ConfirmPopupPassThroughOptions } from 'primevue/confirmpopup'

const confirmStyles = {
  acceptButton: {
    class: ['bg-primary-500', 'text-white', 'rounded-lg', 'p-2', 'w-32', 'hover:bg-primary-600', 'focus:outline-none']
  },
  header: {
    class: [
      'flex',
      'items-center',
      'justify-between',
      'shrink-0',
      'p-3',
      'border-t-0',
      'rounded-tl-lg',
      'rounded-tr-lg',
      'bg-surface-0',
      'dark:bg-surface-800',
      'text-surface-700',
      'dark:text-surface-0/80',
      'hidden'
    ]
  },
  // root
  root: {
    class: [
      'transition-all',
      'duration-300',
      'has-[.mask-active]:bg-transparent',
      'bg-black/40',
      'has-[.mask-active]:backdrop-blur-none',
      'backdrop-blur-sm',
      '!rounded-lg'

      // max width
    ]
  },
  // content
  content: {
    class: [
      // px-6 pb-8 pt-0 bg-surface-0 dark:bg-surface-800 text-surface-700 dark:text-surface-0/80 overflow-y-auto
      'px-6',
      'bg-surface-0',
      'dark:bg-surface-800',
      'text-surface-700',
      'dark:text-surface-0/80',
      'overflow-y-auto',

      // rounded top
      'rounded-t-lg'
    ]
  },
  footer: {
    class: [
      // tailwind classes
      'flex',
      'shrink-0',
      'text-right',
      'gap-2',
      'px-6',
      'pb-6',
      'border-t-0',
      'rounded-b-lg',
      'bg-surface-0',
      'dark:bg-surface-800',
      'text-surface-700',
      'dark:text-surface-0/80',

      // custom styles
      'justify-between',
      'p-4'
    ]
  }
} as ConfirmPopupPassThroughOptions
</script>
