<template>
  <div class="flex items-center justify-center h-screen pt-14" id="otpDiv">
    <!-- content wrapper -->
    <div class="text-center">
      <div class="text-start flex flex-row mb-5">
        <h1 class="text-3xl text-start">Confirm your account</h1>
      </div>
      <!-- heading -->
      <InputOtp v-model="code" integer-only :length="6" />
      <Button class="mt-5 w-full text-black hover:text-white" size="small" label="Submit" :loading="isLoading" @click="checkOTP()"></Button>
    </div>
  </div>
  <DynamicDialog />
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import InputOtp from 'primevue/inputotp'
import { useRoute } from 'vue-router'
import authService from '@/services/api/auth.service'
import { useNotificationStore } from '@/stores/notification.store'
import { useDialog } from 'primevue/usedialog'
import { openChangePasswordDialog } from '@/composables/dialogOptions'

const email = ref('')
const code = ref('')
const route = useRoute()
const isLoading = ref(false)
const notificationStore = useNotificationStore()

const dialog = useDialog()

onMounted(() => {
  // get login_hint
  email.value = route.query.login_hint as string
  code.value = route.query.code as string
  isLoading.value = true
  console.log('mounted')
  isLoading.value = false
})

const modalChangePasswordData = ref({
  NewPassword: '',
  ConfirmPassword: '',
  Email: ''
})

function checkOTP() {
  isLoading.value = true
  modalChangePasswordData.value.Email = email.value

  authService
    .validateOTPCode(email.value, code.value)
    .then((response) => {
      if (response.data) {
        const modalOptions = {
          data: {
            passwordData: modalChangePasswordData.value,
            SuperAdmin: true
          }
        }
        openChangePasswordDialog(dialog, modalOptions)
      } else {
        // show error
        notificationStore.showError('Invalid OTP code', 'Invalid OTP code')
      }
    })
    .catch((error) => {
      // show error
      notificationStore.showError(error.response.data.message, 'Error')
    })
    .finally(() => {
      isLoading.value = false
    })
}
</script>
