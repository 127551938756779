<template>
  <div>
    <div class="flex flex-row justify-between items-center">
      <label for="folders" class="text-white">Folders</label>
      <FontAwesomeIcon
        v-if="hasEditPermission"
        icon="fas fa-plus"
        class="text-white text-lg cursor-pointer hover:bg-primary-emphasis p-1 rounded"
        @click="openCreateNewFolderModal"
      />
    </div>
    <ObjectTree
      id="folders"
      :selected-node-id="selectedNodeFolderId"
      :data="folderNodes"
      :has-edit-permission="hasEditPermission"
      @create-new-child-object="(event, parentID) => openCreateNewFolderModal(event, parentID)"
      @filter-list-by-selected-node-object="$emit('filterListBySelectedNodeFolder', $event)"
      @open-rename-object-modal="openRenameFolderModal"
      @open-duplicate-object-modal="openDuplicateFolderModal"
      @open-share-object-modal="openShareFolderModal"
      @favorite-folder="favoriteFolder"
      @unfavorite-folder="unfavoriteFolder"
      @trash-folders-confirm="trashFoldersConfirm"
    ></ObjectTree>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ObjectTree from './ObjectTree.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useUserStore } from '@/stores/user.store'
import { useCompanyStore } from '@/stores/company.store'
import { useNotificationStore } from '@/stores/notification.store'
import { useFolderStore } from '@/stores/folder.store'
import { useAssetStore } from '@/stores/asset.store'
import { MapFoldersToTreeNodes } from '@/composables/foldersHelpers'
import { HasPermission } from '@/composables/permissionsHelper'
import { useDialog } from 'primevue/usedialog'
import type { Folder } from '@/types/FolderType'
import { openDuplicateFolder, openFolderCreateDialog, openFolderRenameDialog, openShareDialog, openDeleteFolderDialog } from '@/composables/dialogOptions'

// STORES
const userStore = useUserStore()
const companyStore = useCompanyStore()
const notificationStore = useNotificationStore()
const folderStore = useFolderStore()
const assetStore = useAssetStore()

// PROPS
defineProps<{
  selectedNodeFolderId: number
}>()

// LOCAL PROPERTIES
const dialog = useDialog()

// COMPUTED
const hasEditPermission = computed(() => HasPermission('Editor'))
const folderNodes = computed(() => MapFoldersToTreeNodes(folderStore.data.folders))

// FUNCTIONS
// Open the modal to create a new folder or child folder
function openCreateNewFolderModal(_event: any, parentID?: number) {
  const modalOptions = {
    data: {
      folderName: '', // default folder name
      parentID: parentID,
      companyID: companyStore.data.company.companyID
    },
    emits: {
      onCreate: () => {
        folderStore.loadFoldersData(companyStore.data.company.companyID)
      }
    }
  }
  openFolderCreateDialog(dialog, modalOptions)
}

// Open the modal to rename a folder
function openRenameFolderModal(folder: Folder) {
  const modalOptions = {
    data: {
      folderID: folder.folderID,
      folderName: folder.name
    },
    emits: {
      onFolderRename: () => {
        folderStore.loadFoldersData(companyStore.data.company.companyID)
      }
    }
  }
  openFolderRenameDialog(dialog, modalOptions)
}

// Open the modal to share a folder
function openShareFolderModal(folder: any) {
  const modalOptions = {
    props: {
      header: 'Share Folder'
    },
    data: {
      objects: [folder],
      objectType: 'folder'
    },
    emits: {
      onAssetShared: async () => {}
    }
  }
  openShareDialog(dialog, modalOptions)
}

// Open the modal to duplicate a folder
function openDuplicateFolderModal(folder: any) {
  const modalOptions = {
    emits: {
      onSubmit: async () => {
        createDuplicatedFolderRecursive(folder, folder.parentFolderID || undefined)
          .then(() => {
            // Show the success message
            notificationStore.showSuccess(`${folder.name} Duplicated`, 'Success')
            folderStore.loadFoldersData(companyStore.data.company.companyID)
            // reload assets so we can still filter by the newly duplicated folders
            assetStore.loadAssetsData(companyStore.data.company.companyID)
          })
          .catch(() => {
            notificationStore.showError(`${folder.name} Error duplicating the folder`, 'Error')
          })
      }
    }
  }
  openDuplicateFolder(dialog, modalOptions)
}

// recursively create duplicated folders.
async function createDuplicatedFolderRecursive(folder: Folder, parentFolderID?: number) {
  await folderStore.createDuplicatedFolder(companyStore.data.company.companyID, userStore.getUserID, folder, parentFolderID).then(async (result) => {
    // if the folder is created, then create the children folders if any exist
    if (result) {
      const newFolderID = parseInt(result.data.createDuplicatedFolder.folderID)
      // get the children folders from the store
      const folderChildren = folderStore.data.folders.filter((f) => f.parentFolderID === folder.folderID)
      if (folderChildren) {
        for (const child of folderChildren) {
          await createDuplicatedFolderRecursive(child, newFolderID)
        }
      }
    }
  })
}

async function favoriteFolder(folder: Folder) {
  await folderStore.favoriteFolders([folder.folderID], userStore.getUserID).then(() => {
    notificationStore.showSuccess('Folder has been added to favorites');
    folderStore.loadFoldersData(companyStore.data.company.companyID);
  })
}

async function unfavoriteFolder(folder: Folder) {
  await folderStore.unfavoriteFolders([folder.folderID], userStore.getUserID).then(() => {
    notificationStore.showSuccess('Folder has been removed from favorites');
    folderStore.loadFoldersData(companyStore.data.company.companyID);
  })

}

function trashFoldersConfirm(folder: Folder) {
    // Data to be passed to the modal
    const modalOptions = {
    props: {
      header: 'Trash Folder',
    },
    data: {
      subtitle: ` ${folder.name} `
    },
    emits: {
      onConfirm: (trashAssets: boolean) => {
        // Determine whether to trash a single folder or multiple folders
        const folderIDs = [folder.folderID];
        folderStore.trashFolders(trashAssets, folderIDs).then(async () => {
          //reload folders and assets 
          await folderStore.loadFoldersData(companyStore.data.company.companyID);
          await assetStore.loadAssetsData(companyStore.data.company.companyID);
          companyStore.$patch((state: any) => {
            state.folder.selectedFolderID = 0;
          })
        })
        folderStore.$patch((state: any) => {
          state.data.selectedFolders = [];
        });
        
      }
    }
  };
  
  // Open the confirmation modal
  openDeleteFolderDialog(dialog, modalOptions)
  
}

</script>
