import type { AccountInfo } from '@azure/msal-browser'
import { EventType, InteractionStatus, PublicClientApplication } from '@azure/msal-browser'
import type { Ref } from 'vue'
import { getCurrentInstance, toRefs } from 'vue'
import { useUserStore } from '@/stores/user.store'
import { getUserAccessToken } from '@/composables/b2c/utils/useAccessToken'

import type { GraphToken, User } from '@/types/UserType'

let isCallbackRegistered = false;

export type MsalContext = {
  instance: PublicClientApplication
  accounts: Ref<AccountInfo[]>
  inProgress: Ref<InteractionStatus>
}

export function useMsal(): MsalContext {
  const internalInstance = getCurrentInstance()
  if (!internalInstance) {
    throw 'useMsal() cannot be called outside the setup() function of a component'
  }
  const { instance, accounts, inProgress } = toRefs(internalInstance.appContext.config.globalProperties.$msal)

  // eslint-disable-next-line vue/no-ref-as-operand
  if (!instance || !accounts || !inProgress) {
    throw 'Please install the msalPlugin'
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.initialize().then(() => {
      instance.value.handleRedirectPromise().catch(() => {
        return
      })
    })
  }

  if (!isCallbackRegistered) {
    isCallbackRegistered = true
    // if the login is successful, get the user info
    instance.value.addEventCallback(async (message: any) => {
      const userStore = useUserStore()
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        // get the me access token silently
        const scopes = ['User.Read']
        const accessToken = await getUserAccessToken(instance.value, accounts.value[0], scopes)

        const user: User = mapUserData(accounts.value[0], accessToken)
        //pass the formatted user to the store
        userStore.setUserData(user)
      }
    })
  }

  function mapUserData(user: any, accessToken: GraphToken) {
    const User: User = {
      AccountId: user.localAccountId,
      IsAuthenticated: true,
      Name: user.idTokenClaims.name,
      Email: user.username,
      UserRole: user.idTokenClaims.extension_UserRole,
      CompanyId: user.idTokenClaims.extension_CompanyID,
      Token: {
        idToken: user.idToken,
        expiresOn: user.idTokenClaims.exp
      },
      AccessToken: accessToken
    }
    return User
  }


  return {
    instance: instance.value,
    accounts,
    inProgress
  }
}
