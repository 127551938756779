import _ from 'lodash'

// Function to extract the value based on the fieldValue
function extractValue(asset: any, fieldValue: string) {
    // Remove 'object.' prefix and split keys
    const formattedField = fieldValue.replace('object.', ''); 
    const keys = formattedField.split('.'); // Split keys

    // Access the value 
    return keys.reduce((acc: any, key: string) => acc?.[key], asset.object);
}


export function SortObjectFieldValue(filter: any, list: any, sortOrder: any) {
    return _.orderBy(list, 
        (asset: any) => {
            const value = extractValue(asset, filter.fieldValue); // Extracted value
            // Convert date strings to Date objects for correct sorting
            return filter.fieldValue.includes('createdOn') || filter.fieldValue.includes('updatedOn')
                ? new Date(value) 
                : value.firstName;//if its not a date then we know its a name
        }, 
        [sortOrder] 
    );
}

// Sort function for asset list
export function SortAssetList(filter: any, list: any, sortOrder: string) {
    if(filter?.fieldValue?.includes('object.')) {
        list =  SortObjectFieldValue(filter, list, sortOrder)
    }
    else if(filter) {
        //filter the asset list based on the filter
        list = _.orderBy(list, [filter.fieldValue], [sortOrder])
    }
    else {
        list = _.orderBy(list, ['assetID'], ['asc'])
    }

    return list;
}
