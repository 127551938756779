<template>
  <div class="flex w-full bg-cover bg-black h-14 items-center fixed top-0 justify-between px-4 z-50">
    <RouterLink id="LogoHolder" :to="logoRoute">
      <img src="../assets/prodifybeta_logo_colour.svg" alt="Logo" class="h-8" />
    </RouterLink>
    <div class="flex items-center">
      <span class="ml-2 cursor-pointer text-white mr-5" id="SA" v-if="userStore.isSuperAdmin" @click="toggle">{{ userStore.ActingAsSA?.label ?? 'SuperAdmin' }}</span>
      <AppMenuSettings></AppMenuSettings>
    </div>
  </div>
  <Popover v-if="userStore.isSuperAdmin" ref="actAsPopover" pt:content:class="p-0">
    <AppPopoverActAsRole />
  </Popover>
</template>

<script setup lang="ts">
import AppMenuSettings from './AppMenuSettings.vue'
import { useUserStore } from '@/stores/user.store'
import { ref, computed } from 'vue'
import AppPopoverActAsRole from './AppPopoverActAsRole.vue'
// manually imported
import Popover from 'primevue/popover'

const userStore = useUserStore()
const actAsPopover = ref()

const logoRoute = computed(() => {
  const userPolicies = userStore.UserObject?.userPolicies
   // Ensure userPolicies is available and not empty
   if (userPolicies && userPolicies.length > 0) {
    //set the users home route to the first company they have access to
    return { name: 'CompanyRouterView', params: { company: userPolicies[0].companyID?.toString() ?? 1 } };
  } else {
    //defaults to company 1 if no policies are available. (this shouldnt happen) 
    return { name: 'CompanyRouterView', params: { company: 1 } }; 
  }
})

function toggle() {
  actAsPopover.value.toggle(event)
}
</script>
