import { createRouter, createWebHistory } from 'vue-router'
import ErrorView from '@/views/ErrorView.vue'
import UserSettingsView from '@/views/UserSettingsView.vue'
import AdminRouterView from '@/views/Admin/AdminRouterView.vue'
import AdminManageUsersView from '@/views/Admin/AdminManageUsersView.vue'
import AdminCompanySelectorView from '@/views/Admin/AdminCompanySelectorView.vue'
import CompanyRouterView from '@/views/Company/CompanyRouterView.vue'
import AssetsView from '@/views/Company/Assets/AssetsView.vue'
import AssetFormView from '@/views/Company/Assets/AssetFormView.vue'
import FoldersView from '@/views/Company/Folders/FoldersView.vue'
import FolderFormView from '@/views/Company/Folders/FolderFormView.vue'
import CompanySettingsView from '@/views/Company/Settings/CompanySettingsView.vue'
import ManageUsersView from '@/views/Company/Settings/ManageUsersView.vue'
import CompanyTrashView from '@/views/Company/Settings/CompanyTrashView.vue'
import UserCompanySelectorView from '@/views/User/UserCompanySelectorView.vue'
import AssetUploaderView from '@/views/Company/Assets/AssetUploaderView.vue'

import { useUserStore } from '@/stores/user.store'
import { useErrorStore } from '@/stores/error.store'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs.store'

import type { UserPolicies } from '@/types/UserType'
import { useIsSuperAdminGroup } from '@/composables/b2c/utils/useIsAuthenticated'
import RedeemOTPView from '@/views/Company/Settings/RedeemOTPView.vue'
import LoginView from '@/views/Company/Settings/LoginView.vue'
import RegisterView from '@/views/User/RegisterView.vue'
import SharedUserAccessValidation from '@/views/Shared/SharedUserAccessValidation.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/shared/:urlKey',
      name: 'sharedUserAccessValidation',
      component: SharedUserAccessValidation
    },
    {
      path: '/redeem',
      name: 'redeem',
      component: RedeemOTPView
    },
    {
      path: '/error',
      name: 'errorView',
      component: ErrorView
    },
    {
      path: '/user-settings',
      name: 'userSettings',
      component: UserSettingsView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/redeem-invitation',
      name: 'register',
      component: RegisterView
    },
    {
      path: '/company-selector',
      name: 'userCompanySelector',
      component: UserCompanySelectorView
    },
    {
      path: '/admin',
      name: 'adminRouter',
      redirect: { name: 'adminCompanySelector' },
      component: AdminRouterView,
      children: [
        {
          path: 'manage-users',
          name: 'adminManageUsers',
          component: AdminManageUsersView,
          meta: { SuperAdminRequired: true }
        },
        {
          path: 'company-selector',
          name: 'adminCompanySelector',
          component: AdminCompanySelectorView,
          meta: { SuperAdminRequired: true }
        }
      ]
    },
    {
      path: '/:company',
      redirect: { name: 'assets' },
      name: 'CompanyRouterView',
      component: CompanyRouterView,
      // all company specific routes should be nested as children of this route.
      children: [
        {
          path: '/company/:company/uploader',
          name: 'assetUploader',
          component: AssetUploaderView,
          meta: { requiredRoles: ['Admin', 'Editor'] }
        },
        {
          path: 'assets',
          name: 'assets',
          component: AssetsView,
          meta: { requiredRoles: ['Admin', 'Editor', 'Viewer'] }
        },
        {
          path: 'assets/:id',
          name: 'assetForm',
          component: AssetFormView,
          meta: { requiredRoles: ['Admin', 'Editor', 'Viewer'] }
        },
        {
          path: 'folders',
          name: 'folders',
          component: FoldersView,
          meta: { requiredRoles: ['Admin', 'Editor', 'Viewer'] }
        },
        {
          path: 'folders/:id',
          name: 'folderForm',
          component: FolderFormView,
          meta: { requiredRoles: ['Admin', 'Editor', 'Viewer'] }
        },
        {
          path: 'company-settings',
          name: 'companySettings',
          component: CompanySettingsView,
          meta: { requiredRoles: ['Admin'] }
        },
        {
          path: 'manage-users',
          name: 'manageUsers',
          component: ManageUsersView,
          meta: { requiredRoles: ['Admin'] }
        },
        {
          path: 'trash',
          name: 'trash',
          component: CompanyTrashView,
          meta: { refreshRoles: true }
        }
      ]
    },
  ]
})
router.beforeEach(async (to, _from, next) => {
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const requiredRoles = to.meta.requiredRoles as string[] ?? [];

  if (await handleUnauthenticatedUser(to, next, userStore)) return;

  const companyId = to.params.company ? parseInt(to.params.company as string, 10) : null;

  const { finishMethod, userPolicies } = await fetchUserPoliciesIfNeeded(to, userStore, requiredRoles, companyId, errorStore, next)
  if (finishMethod) return;

  if (await handleNoRolesRequired(requiredRoles, to, next, userStore)) return;

  if (await handleSuperAdminAccess(to, userStore, errorStore, next)) return;

  if (await handleUserRolesAndAccess(to, userPolicies, companyId, requiredRoles, errorStore, next)) return;

  next();
});

router.afterEach(async (to, _from) => {
  const breadcrumbsStore = useBreadcrumbsStore();
  await handlerBreadcrumbs(to, breadcrumbsStore);
});

async function handlerBreadcrumbs(to: any, breadcrumbsStore: any) {
  // If the user is navigating through the app, clean the breadcrumbs.
  // It will only keeps the breadcrumbs for the assetForm and folderForm pages.
  if (to.name !== 'assetForm' && to.name !== 'folderForm') {
    breadcrumbsStore.cleanBreadcrumbs();
  }
}

async function handleUnauthenticatedUser(to: any, next: any, userStore: any) {
  // If it's a shared link or the login page, let the user through.
  if (to.fullPath.includes('/shared') || to.name === 'login' || to.name === 'register') {
    next();
    return true;
  } else if (!userStore.IsAuthenticated && to.name !== 'login') {
    // If the user is not authenticated and trying to access a page that is not the login page, redirect them to the login page.
    next({ name: 'login' });
    return true;
  }
  return false;
}

async function fetchUserPoliciesIfNeeded(to: any, userStore: any, requiredRoles: string[], companyId: number | null, errorStore: any, next: any) {
  let userPolicies: UserPolicies[] | undefined
  if (userStore.AccountId !== '' && (requiredRoles.length > 0 || to.meta.SuperAdminRequired !== undefined || to.meta.refreshRoles !== undefined)) {
    try {
      userPolicies = await userStore.setUserInfo(userStore.AccountId, companyId);
    } catch (error) {
      console.error('Error fetching user info:', error);
      errorStore.setErrorMessage('Error fetching user info', 'Error');
      next({ name: 'errorView' });
      return { finishMethod: true, userPolicies: userPolicies };
    }
  }
  return { finishMethod: false, userPolicies: userPolicies };
}

async function handleNoRolesRequired(requiredRoles: string[], to: any, next: any, userStore: any) {
  if (requiredRoles.length === 0 && to.meta.SuperAdminRequired === undefined && to.meta.refreshRoles === undefined) {
    // If it's the root path, redirect the user to the correct page according to their role.
    if (to.fullPath === '/') {
      await userStore.redirectUser();
    }

    next();
    return true;
  }
  return false;
}

async function handleSuperAdminAccess(to: any, userStore: any, errorStore: any, next: any) {
  const superAdminRequired = to.meta.SuperAdminRequired ?? false;
  if (userStore.ActingAsSA && superAdminRequired && userStore.ActingAsSA.label == 'SuperAdmin') {
    const isSuperAdmin = await useIsSuperAdminGroup(userStore.AccountId);
    if (!isSuperAdmin.value) {
      errorStore.setErrorMessage('You do not have access to this page, SuperAdmin access is required.', 'Access Denied');
      next({ name: 'errorView' });
      return true;
    } else {
      next();
      return true;
    }
  }

  if (userStore.isSuperAdmin && userStore.ActingAsSA && userStore.ActingAsSA.label === 'SuperAdmin') {
    next();
    return true;
  }
  return false;
}

async function handleUserRolesAndAccess(to: any, userPolicies: UserPolicies[] | undefined, companyId: any, requiredRoles: string[], errorStore: any, next: any) {
  let hasRole = false;
  if (requiredRoles.length > 0) {
    hasRole = userPolicies?.some((policy: any) => requiredRoles.includes(policy.userRole.role) && policy.companyID === companyId) ?? false;
  } else if (to.meta.refreshRoles !== undefined) {
    hasRole = true;
  }

  const hasCompanyAccess = companyId !== null && (userPolicies?.some((policy: any) => policy.companyID === companyId) ?? false);

  if (!hasRole || !hasCompanyAccess) {
    const message = hasCompanyAccess
      ? `You do not have access to this page, you need to be a ${requiredRoles.join(' or ')} in the selected company.`
      : 'You do not have access to this page, you need to be a user in a company.';
    errorStore.setErrorMessage(message, 'Access Denied');
    next({ name: 'errorView' });
    return true;
  }
  return false;
}

export default router;