import { defineStore } from "pinia";
import type { User, GraphToken, UserObjectType, Token } from "@/types/UserType";
import { StorageSerializers, useStorage } from '@vueuse/core';
import { ref } from "vue";
import router from "@/router";
import { GenerateSasToken } from "@/composables/storageHelper";
import authService from "@/services/api/auth.service";
import userService from "@/services/api/user.service";
import { useErrorStore } from '@/stores/error.store'

export interface UserStore {
    accountId: string;
    Email: string;
    Name: string;
    CompanyId: string;
    IsAuthenticated: boolean;
    Token: Object;
    UserRole: string;
    AccessToken: GraphToken;
    isSuperAdmin: boolean;
    ActingAsSA: { label: string; value: number } | null;
    StorageTokens: StorageToken[];
}

export interface StorageToken {
    token: string;
    expiresOn: string;
    companyID: number;
}

export const useUserStore = defineStore('UserStore', {
    state: () => {
        const Email = useStorage('Email', '');
        const Name = useStorage('Name', '');
        const IsAuthenticated = useStorage('IsAuthenticated', false);
        const CompanyId = useStorage('CompanyId', '');
        const Token = useStorage('Token', { idToken: '', expiresOn: '' } as Token);
        const UserRole = useStorage('UserRole', '');
        const AccountId = useStorage('AccountId', '');
        const AccessToken = useStorage('AccessToken', { accessToken: '', expiresOn: '' } as GraphToken);
        const isSuperAdmin = useStorage('isSuperAdmin', false);
        const StorageTokens = useStorage('StorageTokens', [] as StorageToken[], localStorage, { serializer: StorageSerializers.object });
        // only if the user is acting as a super admin
        const ActingAsSA = useStorage('ActingAsSA', null, localStorage, { serializer: StorageSerializers.object });
        const UserObject = useStorage('UserObject', ref({
            __typename: '',
            createdOn: '',
            email: '',
            firstName: '',
            lastName: '',
            updatedOn: '',
            userID: 0,
            entraID: '',
            userPolicies: []
        } as UserObjectType | null));
        const validatedSharedUser = useStorage('validatedSharedUser', '');
        const keepSharedUserSignedIn = useStorage('keepSharedUserSignedIn', false);
        return {
            Email,
            Name,
            IsAuthenticated,
            CompanyId,
            Token,
            UserRole,
            AccountId,
            AccessToken,
            UserObject,
            isSuperAdmin,
            ActingAsSA,
            StorageTokens,
            validatedSharedUser,
            keepSharedUserSignedIn
        };
    },
    getters: {
        HasPolicy: (state) => {
            return (state.UserObject?.userPolicies ?? []).length > 0;
        },
        getStorageToken: (state) => {
            return (companyID: number) => {
                GenerateSasToken(companyID);
                const storageToken = state.StorageTokens.find((t: any) => t.companyID === companyID);
                if (storageToken && new Date(storageToken.expiresOn) > new Date()) {
                    return storageToken.token;
                }
                return null;
            };
        },
        selectorRoute: async (state) => {
            // If it's SA, send them to the admin company selector
            if(state.isSuperAdmin) {
                return { name: 'adminCompanySelector' };
            // If the user is not assigned to any companies, send them to the error view.
            } else if (!state.UserObject?.userPolicies || state.UserObject.userPolicies.length == 0) {
                const errorStore = useErrorStore();
                errorStore.setErrorMessage('You are not assigned to any companies.', 'Access Denied', true);
                return { name: 'errorView' };
            // If the user has more than one policy, send them to the user company selector.
            } else if (state.UserObject.userPolicies.length > 1) {
                return { name: 'userCompanySelector' };
            // If the user has only one policy, send them to the company router view.
            } else if (state.UserObject.userPolicies.length === 1) {
                return { name: 'CompanyRouterView', params: { company: state.UserObject.userPolicies[0].companyID.toString() } };
            }
        },
        getUserID: (state) => {
            if (state.UserObject) return state.UserObject.userID;
            else return 0;
        },
        isDownloadAllowedForSharedUser: (state) => {
            return () => {
                return state.validatedSharedUser !== '';
            }
        },
        isSharedUserValidated: (state) => {
            // User is validated if the OTP code was validated or if the user is logged in in Prodidy.
            return (state.keepSharedUserSignedIn && state.validatedSharedUser !== '') || (state.UserObject != null && state.UserObject.email !== '');
        },
        getSharedValidatedUserEmail: (state) => {
            return () => {
                // Get the validated user email.
                let userEmail = state.validatedSharedUser
                if ((userEmail === null || userEmail === '') && state.UserObject != null) {
                    userEmail = state.UserObject.email
                }

                return userEmail;
            }
        }
    },
    actions: {
        setUserData(data: User) {
            this.Email = data.Email;
            this.Name = data.Name;
            this.IsAuthenticated = data.IsAuthenticated;
            this.CompanyId = data.CompanyId;
            this.Token = data.Token;
            this.UserRole = data.UserRole;
            this.AccountId = data.AccountId;
            this.AccessToken = data.AccessToken;
        },
        setAccessToken(accessToken: GraphToken) {
            this.AccessToken = accessToken;
        },
        async setUserInfo(accountId: string = '', companyId: number | null = null) {
            accountId = accountId || this.AccountId;

            // Check if the user is a SuperAdmin
            let isSuperAdmin = false;
            try {
                const { data } = await authService.checkSuperAdminExistsByEntraID(accountId);
                isSuperAdmin = data;
                this.isSuperAdmin = isSuperAdmin;
            } catch (error) {
                console.error('Error checking SuperAdmin status:', error);
            }

            // Fetch user policies or return an empty array in case of failure
            try {
                const { data } = await userService.GetUserObject(accountId);
                this.UserObject = data.data.userEnabledPolicy[0];

                if (!this.UserObject) return [];

                if (isSuperAdmin) {
                    // Create a SuperAdmin fake policy if no ActingAsSA
                    const fakePolicy = [{
                        companyID: companyId ?? -1,
                        userPolicyID: 1, // Fake ID for the policy
                        IsEnabled: true,
                        userRole: {
                            role: this.ActingAsSA?.label ?? 'SuperAdmin',
                            userRoleID: this.ActingAsSA?.value ?? 1,
                        },
                        userID: this.UserObject.userID,
                    }];

                    this.UserObject.userPolicies = fakePolicy;
                    this.ActingAsSA =  { label: fakePolicy[0].userRole.role, value: fakePolicy[0].userRole.userRoleID };
                    return fakePolicy;
                }
                // Return actual user policies if no fake policy is needed
                return this.UserObject.userPolicies ?? [];
            } catch (error) {
                console.error('Error fetching user info:', error);
                return [];
            }
        },
        logout() {
            // clear the user store
            this.Email = '';
            this.Name = '';
            this.IsAuthenticated = false;
            this.CompanyId = '';
            this.Token = { idToken: '', expiresOn: '' };
            this.UserRole = '';
            this.AccountId = '';
            this.AccessToken = { accessToken: '', expiresOn: '' };
            this.ActingAsSA = null;
            this.isSuperAdmin = false;
            // delete ActingAsSA from local storage
            localStorage.removeItem('ActingAsSA');
            localStorage.removeItem('isSuperAdmin');
            // delete UserObject from local storage

            this.UserObject = {
                __typename: '',
                createdOn: '',
                email: '',
                firstName: '',
                lastName: '',
                updatedOn: '',
                userID: 0,
                entraID: '',
                userPolicies: []
            };
        },
        //redirect the user to their landing page
        async redirectUser() {
            if (this.isSuperAdmin) {
                //if the user is an admin, send them to the admin company selector
                router.push({ name: 'adminCompanySelector' })
            } else if (!this.UserObject?.userPolicies || this.UserObject.userPolicies.length == 0) {
                //if the user is not assigned to any companies, send them to the error view
                const errorStore = useErrorStore();
                errorStore.setErrorMessage('You are not assigned to any companies.', 'Access Denied', true);
                router.push({ name: 'errorView' })
            } else if (this.UserObject.userPolicies.length > 1) {
                //otherwise send them to the user company selector
                router.push({ name: 'userCompanySelector' })
            } else {
                //if the user only has one policy, send them to the company router view
                router.push({ name: 'CompanyRouterView', params: { company: this.UserObject.userPolicies[0].companyID.toString() } })
            }
        },
        // remove storage StorageTokens
        removeStorageToken(token: string) {
            const index = this.StorageTokens.findIndex((t: any) => t.token === token);
            if (index !== -1) {
                this.StorageTokens.splice(index, 1);
            }
        },
        // add storage StorageTokens
        addStorageToken(storageToken: StorageToken) {
            // if token already exists by companyID, remove it
            const index = this.StorageTokens.findIndex((t: any) => t.companyID === storageToken.companyID);
            if (index !== -1) {
                this.StorageTokens.splice(index, 1);
            }
            this.StorageTokens.push(storageToken);
        },
        setValidatedSharedUser(email: string, keepSharedUserSignedIn: boolean) {
            this.keepSharedUserSignedIn = keepSharedUserSignedIn;
            this.validatedSharedUser = email;
        }
    }
});