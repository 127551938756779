<template>
  <div class="flex -m-2">
    <InputGroup>
      <InputText v-model="newOption.name" label="Name" @click.stop @keypress.enter="createNewOption" />
      <Button @click.stop="createNewOption"><FontAwesomeIcon icon="fa-solid fa-check"></FontAwesomeIcon></Button>
    </InputGroup>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import { useCompanyStore } from '@/stores/company.store';
  import { optionColours } from '@/composables/optionColours';
  import { useNotificationStore } from '@/stores/notification.store';

  const companyStore = useCompanyStore()
  const notifcationStore = useNotificationStore()

  const emit = defineEmits(['newOptionAdded'])

  const newOption = ref({
    companyID: companyStore.data.company.companyID,
    name: '',
    colour: optionColours[Math.floor(Math.random() * optionColours.length)].value
  })

  function createNewOption() {
    newOption.value.name = newOption.value.name.trim()
    if(newOption.value.name === '') {
      notifcationStore.showWarning('Option name cannot be empty', 'Warning')
      return;
    }
    emit('newOptionAdded', newOption.value)
  }

</script>