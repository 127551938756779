<template>
  <div>
    <!--Phone Button-->
    <div class="block sm:hidden">
      <div><FontAwesomeIcon icon="fa-solid fa-gear" class="text-primary-500 text-xl" @click="mobileMenuVisible = true"></FontAwesomeIcon></div>
      <Sidebar v-model:visible="mobileMenuVisible" position="full" class="!bg-black !text-white" :pt="mobileMenuOverlayStyle">
        <span class="text-2xl ml-2">Settings</span>
        <Menu ref="settingsMenu" :model="menuItems" class="!bg-black !border-0" :pt="mobileMenuStyle">
          <template #item="{ item, props }">
            <RouterLink v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
              <a :href="href" v-bind="props.action" @click="navigateToggleMenu(navigate, false)" class="!py-3 !px-2 !text-white flex gap-2">
                <div v-if="item.icon" :class="item.icon" class="min-w-5" />
                <div>{{ item.label }}</div>
              </a>
            </RouterLink>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action" @click="authenticate(item)" class="!py-3 !px-2 !text-white flex gap-2">
              <div v-if="item.icon" :class="item.icon" class="min-w-5" />
              <div>{{ item.label }}</div>
            </a>
          </template>
        </Menu>
      </Sidebar>
    </div>
    <!--Desktop and tablet button-->
    <div class="hidden sm:block">
      <Button
        size="small"
        outlined
        icon="fa-solid fa-gear"
        label="Settings"
        @click="toggleMenu($event)"
        aria-haspopup="true"
        aria-controls="settings_menu"
        class="focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50 hover:bg-primary-300/20 transition duration-200 ease-in-out cursor-pointer overflow-hidden select-none"
      >
      </Button>
      <Menu ref="settingsMenu" :model="menuItems" :popup="true" class="!bg-black !border-gray-700" :pt="menuStyle" focusOnHover="false">
        <template #start
          ><div class="!pt-2 !px-6 !text-white flex flex-col text-sm">
            <p>Logged in as</p>
            <p>{{ userStore.UserObject?.email }}</p>
          </div>
          <Divider :pt="{ root: { class: ['px-1', 'w-5/6', 'mx-auto', 'mb-1', 'before:border-gray-700'] } }"></Divider
        ></template>
        <template #item="{ item, props }">
          <RouterLink v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a :href="href" v-bind="props.action" @click="navigate" class="!py-1 !px-3 !text-white flex gap-2">
              <div v-if="item.icon" :class="item.icon" class="min-w-5" />
              <div>{{ item.label }}</div>
            </a>
          </RouterLink>
          <a v-else :href="item.url" :target="item.target" @click="authenticate(item)" v-bind="props.action" class="!py-1 !px-3 !text-white flex gap-2">
            <div v-if="item.icon" :class="item.icon" class="min-w-5" />
            <div>{{ item.label }}</div>
          </a>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import { useMsal } from '@/composables/b2c/utils/useMsal'
import { loginRequest } from '@/composables/b2c/authConfig'
import { HasPermission } from '@/composables/permissionsHelper'
import { useUserStore } from '@/stores/user.store'

const isAuthenticated = useIsAuthenticated()
const { instance, accounts } = useMsal()
const mobileMenuVisible = ref(false)
const settingsMenu = ref()
const authLabel = ref(isAuthenticated.value ? 'Sign Out' : 'Sign In')

// store
const userStore = useUserStore()

const route = useRoute()
const companyId = ref(route.params?.company?.toString() ?? '0')
const menuItems = ref()

const menuSettings = async () => {
  if (userStore.UserObject?.userID != 0) {
    // Add the first menu item for user settings.
    menuItems.value = [{ label: 'User Settings', icon: 'fas fa-user', route: { name: 'userSettings' } }]

    // Add the menu items when there is a company selected.
    if (companyId.value !== '0') {
      // Only add the company settings and manage users menu items if the user has the Admin permission.
      if (HasPermission('Admin', parseInt(companyId.value))) {
        menuItems.value.push(
          { label: 'Company Settings', icon: 'fas fa-industry', route: { name: 'companySettings', params: { company: companyId } } },
          { label: 'Manage Users', icon: 'fas fa-users', route: { name: 'manageUsers', params: { company: companyId } } }
        )
      }

      menuItems.value.push({ label: 'Trash', icon: 'fas fa-trash', route: { name: 'trash', params: { company: companyId } } })
    }

    // Add a separator.
    menuItems.value.push({ separator: true })

    // Add the menu items for the super admin.
    if (userStore.isSuperAdmin) {
      menuItems.value.push({ label: 'SA Users', icon: 'fas fa-user-crown', route: { name: 'adminManageUsers' } })
    }

    // Adding the last common menu items.
    menuItems.value.push(
      { label: 'Switch Company', icon: 'far fa-arrow-right-arrow-left', route: await userStore.selectorRoute },
      {
        label: authLabel.value,
        icon: 'fas fa-arrow-right-from-bracket',
        onclick: () => {
          isAuthenticated.value ? logout() : instance.loginRedirect(loginRequest)
        }
      }
    )
  }
}

onMounted(() => {
  menuSettings()
})

// watch route changes and update the companyId
watch(route, (to) => {
  companyId.value = to.params?.company?.toString() ?? '0'
  menuSettings()
})

//watch isAuthenticated and update the authLabel to change
//the menuItem label to Sign In or Sign Out
watch(isAuthenticated, async (value) => {
  authLabel.value = value ? 'Sign Out' : 'Sign In'
  //Find the menu item with the auth icon and update the label
  const authMenuItem = menuItems.value.find((item: any) => item.icon === 'fas fa-arrow-right-from-bracket')
  if (authMenuItem) {
    authMenuItem.label = authLabel.value
  }
  //remove the hash from the URL
  window.location.hash = ''
  if (value) {
    await menuSettings()
  }
})

//toggle the menu option for the user vs. admin company selector
watch(
  () => userStore.isSuperAdmin,
  async (value) => {
    await menuSettings()
  }
)

//toggle the settings menu open/closed
function toggleMenu(event: Event) {
  settingsMenu.value.toggle(event)
}

//Handle onclick for the sign in/out button
const authenticate = (item: any, hideMenu = false as boolean) => {
  if (item.onclick) {
    item.onclick()
  }
  mobileMenuVisible.value = hideMenu
}
const logout = () => {
  const account = instance.getAccount({ homeAccountId: accounts.value[0].homeAccountId })

  if (account) {
    userStore.logout()
    instance.logoutRedirect({
      account: account
    })
  } else {
    console.error('Account not found')
  }
}

const navigateToggleMenu = (navigate: any, hideMenu: boolean) => {
  if (navigate) navigate()
  mobileMenuVisible.value = hideMenu
}

//Custom Styling for the menus
const menuStyle = ref({
  itemContent: { class: 'hover:bg-gray-700 focus:bg-gray-700 mx-4 rounded' },
  separator: { class: 'border-t border-gray-700 mx-4 my-1' }
})
const mobileMenuStyle = ref({
  content: () => ({
    class: ['hover:bg-gray-700', 'focus:bg-gray-700', 'mx-0', 'rounded']
  }),
  separator: { class: 'border-t border-gray-700 mx-4 my-1' }
})
const mobileMenuOverlayStyle = ref({
  header: { class: 'bg-black text-white flex justify-end' },
  closeButton: {
    // Overrides for the tailwind close button
    class: [
      'relative',
      // Flexbox and Alignment
      'flex items-center justify-center',
      // Size and Spacing
      'mr-2',
      'mt-2',
      'w-8 h-8',
      // Shape
      'border-0',
      'rounded-full',
      // Colors
      'text-surface-500',
      'bg-transparent',
      // Transitions
      'transition duration-200 ease-in-out',
      // States
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-primary-400/50 dark:focus:ring-primary-300/50'
    ]
  }
})
</script>

<style>
/* V4 Bug, needs to override the class manually https://github.com/primefaces/primevue/issues/5710*/
.p-menu-item.p-focus .p-menu-item-content {
  background: var(--p-gray-700) !important;
}
</style>
