<template>
  <AssetForm :assetId="assetId" :isSharedPage="false" />  
  <DynamicDialog />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import AssetForm from '@/components/FormAsset.vue'

// get asset id from the route
const route = useRoute()
const assetId = ref(parseInt(route.params.id as string))
</script>
