<template>
  <div class="flex flex-col p-5 gap-5">
    <!-- heading -->
    <AppHeaderPage>
      <template #title> Company Settings </template>
      <template #buttons>
        <Button :disabled="changedFields.length === 0" class="text-black hover:text-white hover:disabled:text-black" size="small" label="Save Changes" @click="submitForm"></Button>
      </template>
    </AppHeaderPage>
    <!-- company settings -->
    <form @submit.prevent="submitForm">
      <div class="max-w-4xl mx-auto mt-5 space-y-10">
        <!-- company details -->
        <div>
          <h2 class="flex text-xl font-bold">
            <FontAwesomeIcon icon="fa-solid fa-circle-info" class="me-2 my-auto"></FontAwesomeIcon>
            Company Details
          </h2>
          <div class="flex flex-col md:flex-row w-full space-y-5 md:space-y-0 md:space-x-8 mt-4">
            <!-- file Upload -->
            <div class="relative w-1/3 md:w-1/4">
              <FileUpload
                name="demo[]"
                auto
                @uploader="onUpload($event)"
                customUpload
                :multiple="false"
                accept="image/*"
                :maxFileSize="1000000"
                :pt="fileUploadStyles"
                v-show="!logoUrl"
                ref="fileUpload"
              >
                <template #content="slotProps">
                  <div class="h-full min-h-36" v-if="slotProps.files.length > 0">
                    <!-- show preview -->
                    <img class="w-full h-full object-cover" alt="company logo" />
                  </div>
                </template>
                <template #empty>
                  <div class="flex h-full min-h-36 bg-transparent">
                    <div class="flex flex-col items-center justify-center w-full h-full bg-opacity-50 bg-white">
                      <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" class="text-3xl text-gray-400 mb-2"></FontAwesomeIcon>
                      <Button @click="openFileUpload" size="small" severity="contrast" outlined class="text-black hover:text-white" label="Upload"></Button>
                      <span class="text-sm text-gray-360">or drag/drop image.</span>
                    </div>
                  </div>
                </template>
              </FileUpload>
              <img v-show="logoUrl" class="inset-0 w-full h-full object-cover cursor-pointer" :src="logoUrl" alt="company logo" @click="openFileUpload" />
            </div>

            <!-- subscription -->
            <div class="w-full md:w-2/3 space-y-3 mt-3 md:mt-0">
              <span class="text-sm font-extrabold text-gray-600 mb-1">Subscription</span>
              <InputText v-model="formData.subscription" class="w-full" placeholder="Prodify Beta"></InputText>
              <!-- company name -->
              <InputTextForm v-model="formData.companyName" label="Company Name" required placeholder="Company Name" />
              <!-- company website -->
              <InputTextForm v-model="formData.website" label="Website" required placeholder="Website" @input="checkHttps" />
            </div>
          </div>
        </div>
        <!-- company address settings -->
        <div>
          <h2 class="flex text-xl font-bold">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" class="me-2 my-auto"></FontAwesomeIcon>
            Company Address
          </h2>
          <div class="flex flex-col md:flex-row w-full space-y-5 md:space-y-0 md:space-x-8 mt-4">
            <!-- company address -->
            <div class="w-full md:w-1/2 space-y-3">
              <span class="text-sm font-extrabold text-gray-600 mb-1">Company Address</span>
              <!-- country/state -->
              <div class="flex flex-row space-y-0 space-x-2">
                <Dropdown v-model="formData.companyCountry" :options="countries" placeholder="Country" class="w-1/4" @change="onChangeDropdown"></Dropdown>
                <Dropdown
                  v-model="formData.companyState"
                  :loading="statesLoading"
                  @change="onChangeStateDropdown"
                  :options="states"
                  placeholder="State/Province"
                  class="w-1/4"
                ></Dropdown>
                <Dropdown v-model="formData.companyCity" :loading="citiesLoading" :options="cities" placeholder="City" class="w-2/4"></Dropdown>
              </div>
              <!-- address -->
              <div class="space-y-2">
                <!-- company address 1 -->
                <InputTextForm v-model="formData.companyAddress1" label="Address Line 1" placeholder="Address Line 1" />
                <!-- company address 2 -->
                <InputTextForm v-model="formData.companyAddress2" label="Address Line 2" placeholder="Address Line 2" />
                <!-- company zip -->
                <InputTextForm v-model="formData.companyZip" label="Zip Code" placeholder="Zip Code" />
              </div>
            </div>
            <!-- billing address -->
            <div class="w-full md:w-1/2 space-y-3">
              <span class="text-sm font-extrabold text-gray-600 mb-3">Billing Address</span>
              <!-- same as company address -->
              <div class="p-2">
                <Checkbox @change="copyFields()" inputId="sameAsCompanyAddress" v-model="sameAsCompanyAddress" binary></Checkbox>
                <label class="text-sm" for="sameAsCompanyAddress"> Same as Company Address </label>
              </div>
              <!-- country / state -->
              <div class="flex flex-row space-y-0 space-x-2">
                <!-- billing address -->
                <Dropdown
                  v-model="formData.billingCountry"
                  :disabled="sameAsCompanyAddress"
                  :options="countries"
                  placeholder="Country"
                  class="w-1/3"
                  @change="onChangeBillingDropdown"
                ></Dropdown>
                <!-- billing state -->
                <Dropdown
                  v-model="formData.billingState"
                  :disabled="sameAsCompanyAddress"
                  :options="billingStates"
                  :loading="billingStatesLoading"
                  placeholder="State/Province"
                  class="w-1/3"
                  @change="onChangeStateDropdown"
                ></Dropdown>
                <!-- billing city -->
                <Dropdown
                  v-model="formData.billingCity"
                  :loading="billingCitiesLoading"
                  :disabled="sameAsCompanyAddress"
                  :options="billingCities"
                  placeholder="City"
                  class="w-1/3"
                ></Dropdown>
              </div>
              <!-- address -->
              <div class="space-y-2">
                <!-- billing Address Line 1 -->
                <InputTextForm :disabled="sameAsCompanyAddress" v-model="formData.billingAddress1" label="Address Line 1" placeholder="Address Line 1" />
                <div class="flex flex-row space-y-0 space-x-2">
                  <!-- billing Address Line 2 -->
                  <InputTextForm :disabled="sameAsCompanyAddress" v-model="formData.billingAddress2" label="Address Line 2" placeholder="Address Line 2" />
                  <!-- billing Zip Code -->
                  <InputTextForm :disabled="sameAsCompanyAddress" v-model="formData.billingZip" label="Zip Code" placeholder="Zip Code" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row w-full space-y-5 md:space-y-0 md:space-x-5 mt-4">
            <!-- Phone Number -->
            <div class="w-full md:w-2/3 space-y-3">
              <span class="text-sm font-extrabold text-gray-600 mb-3">Phone Number</span>
              <!-- same as company address -->
              <div class="flex flex-row space-y-0 space-x-2 w-full">
                <!-- phone number ((placeholder is broken until we update primevue)) -->
                <InputTextForm v-model="formData.phone" label="Phone Number" required placeholder="(###) ###-####" mask="(999) 999-9999" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <DynamicDialog />
</template>
<script setup lang="ts">
import { reactive, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useNotificationStore } from '@/stores/notification.store'
import { useErrorStore } from '@/stores/error.store'
import { useCompanyStore } from '@/stores/company.store'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { CreateAddress, UpdateAddress } from '@/services/graphql/address.graphql'
import { getAllCompanyInfo, UpdatePartialCompany } from '@/services/graphql/company.graphql'
import adminService from '@/services/api/admin.service'
import { companySettingsSchema } from '@/schemas/companySettings.schema'
import type { CompanyForm, UpdatedCompanyType, CompanyUpdate } from '@/types/CompanySettingsType'
import type { FileUploadPassThroughOptions } from 'primevue/fileupload'
import type { ButtonPassThroughOptions } from 'primevue/button'
import fileService from '@/services/api/file.service'
import AppHeaderPage from '@/components/AppHeaderPage.vue'

// Initialize router and notification store
const router = useRouter()
const notificationStore = useNotificationStore()
const errorStore = useErrorStore()
const companyStore = useCompanyStore()

// Get company ID from route
const companyId = ref(router.currentRoute.value.params.company) as any

// Reactive states and refs
// Company form helpers
const companyCopy = ref({
  subscription: null,
  companyName: '',
  website: null,
  companyCountry: null,
  companyState: null,
  companyAddress1: null,
  companyAddress2: null,
  companyCity: null,
  phone: null,
  companyZip: null,
  billingCountry: null,
  billingState: null,
  billingAddress1: null,
  billingAddress2: null,
  billingZip: null,
  billingCity: null
} as CompanyForm)
const changedFields = ref([] as string[])
const sameAsCompanyAddress = ref(false)
const isFormValid = ref(false)

// Dropdown options
const countries = ref([] as string[])
const states = ref([] as string[])
const billingStates = ref([] as string[])
const billingCities = ref([] as string[])
const cities = ref([] as string[])
const statesLoading = ref(false)
const citiesLoading = ref(false)
const billingStatesLoading = ref(false)
const billingCitiesLoading = ref(false)

// companyLoading
const companyLoading = ref(false)

// logo url
const logoUrl = ref('')

// Reactive form data object
const formData = reactive<CompanyForm>({
  subscription: null,
  companyName: '',
  website: null,
  companyCountry: null,
  companyState: null,
  companyAddress1: null,
  companyAddress2: null,
  companyCity: null,
  phone: null,
  companyZip: null,
  billingCountry: null,
  billingState: null,
  billingAddress1: null,
  billingAddress2: null,
  billingZip: null,
  billingCity: null
})

// Load company data on mount
onMounted(async () => {
  await loadCompanySettings()
})

async function loadCompanySettings() {
  companyLoading.value = true
  await loadCompanyData()
  await loadCompanyLogo()
  companyLoading.value = false
}
const fileUpload = ref(null) as any

// manual file upload
function openFileUpload() {
  fileUpload.value.choose()
}
// Load company logo
const loadCompanyLogo = async () => {
  // Load company logo
  fileService.getLogo(parseInt(companyId.value)).then((response) => {
    if (response.data) {
      logoUrl.value = response.data
    } else {
      logoUrl.value = ''
    }
  })
}

// Get company by ID query
const { load: loadGetByID, result: resultGetByID, variables: queryVariables } = useLazyQuery(getAllCompanyInfo)

// Load company data function
const loadCompanyData = async () => {
  // Load countries
  await adminService.getCountries().then((response) => {
    countries.value = response.data.map((country: string) => country)
  })
  // Handle company ID as string
  let companyIdString: string
  if (Array.isArray(companyId.value)) {
    companyIdString = companyId.value[0]
  } else {
    companyIdString = companyId.value
  }

  queryVariables.value = { companyID: parseInt(companyIdString) as number }
  await loadGetByID()
  loadCompanyDataToForm()
}

// Load company data into form
function loadCompanyDataToForm() {
  if (resultGetByID.value && resultGetByID.value.companies.nodes.length > 0) {
    const company = resultGetByID.value.companies.nodes[0]
    formData.subscription = company.subscription || null
    formData.companyName = company.companyName || ''
    formData.website = company.website || null
    formData.companyCountry = company.companyAddress?.country || null
    formData.companyState = company.companyAddress?.state || null
    formData.companyAddress1 = company.companyAddress?.addressLine1 || null
    formData.companyAddress2 = company.companyAddress?.addressLine2 || null
    formData.companyZip = company.companyAddress?.postalCode || null
    formData.companyCity = company.companyAddress?.city || null
    formData.phone = company?.phone || null // Hack until they fix InputMask
    formData.billingCountry = company.billingAddress?.country || null
    formData.billingState = company.billingAddress?.state || null
    formData.billingAddress1 = company.billingAddress?.addressLine1 || null
    formData.billingAddress2 = company.billingAddress?.addressLine2 || null
    formData.billingZip = company.billingAddress?.postalCode || null
    formData.billingCity = company.billingAddress?.city || null

    // Load states and cities if country/state is set
    if (formData.companyCountry) {
      onChangeDropdown({ value: formData.companyCountry })
      if (formData.companyState) {
        onChangeStateDropdown({ value: formData.companyState })
      }
    }

    if (formData.billingCountry) {
      onChangeBillingDropdown({ value: formData.billingCountry })
      if (formData.billingState) {
        onChangeBillingStateDropdown({ value: formData.billingState })
      }
    }

    companyCopy.value = { ...formData }
  } else if (resultGetByID.value && resultGetByID.value.companies.nodes.length === 0) {
    errorStore.setErrorMessage('Company not found', 'Error')
    // Redirect to error page.
    router.push({ name: 'errorView' })
  }
}

// File upload handlers
const onUpload = (event: any) => {
  // Handle file upload
  fileService.uploadLogo(parseInt(companyId.value), event.files[0]).then((response) => {
    if (response.data) {
      logoUrl.value = response.data
      // Note: This entire page should be changed to use the company store.
      companyStore.loadCompanyLogo()
    }
  })

  notificationStore.showSuccess('File Uploaded', 'The file has been uploaded successfully')
}

// Copy fields if same as company address
const copyFields = () => {
  if (sameAsCompanyAddress.value) {
    billingStates.value = states.value
    billingCities.value = cities.value

    formData.billingCountry = formData.companyCountry
    formData.billingState = formData.companyState
    formData.billingAddress1 = formData.companyAddress1
    formData.billingAddress2 = formData.companyAddress2
    formData.billingZip = formData.companyZip
    formData.billingCity = formData.companyCity

    // if any of the fields arent the same, add them to changed fields
    for (const key in formData) {
      if (formData[key as keyof CompanyForm] !== companyCopy.value[key as keyof CompanyForm]) {
        if (!changedFields.value.includes(key)) {
          changedFields.value.push(key)
        }
      }
    }
  }
}

// Dropdown change handlers
// get states for company country
const onChangeDropdown = async (event: any) => {
  statesLoading.value = true
  await adminService
    .getStates(event.value)
    .then((response) => {
      states.value = response.data.map((state: any) => state.state_code)
    })
    .finally(() => {
      statesLoading.value = false
    })
}

// get cities for company state
const onChangeStateDropdown = async (event: any) => {
  citiesLoading.value = true
  await adminService
    .getCities(event.value)
    .then((response) => {
      cities.value = response.data.map((city: any) => city.name)
    })
    .finally(() => {
      citiesLoading.value = false
    })
}

// get states for billing country
const onChangeBillingDropdown = async (event: any) => {
  billingStatesLoading.value = true
  await adminService
    .getStates(event.value)
    .then((response) => {
      billingStates.value = response.data.map((state: any) => state.state_code)
    })
    .finally(() => {
      billingStatesLoading.value = false
    })
}

// get cities for billing state
const onChangeBillingStateDropdown = async (event: any) => {
  billingCitiesLoading.value = true
  await adminService
    .getCities(event.value)
    .then((response) => {
      billingCities.value = response.data.map((city: any) => city.name)
    })
    .finally(() => {
      billingCitiesLoading.value = false
    })
}

// check https
const checkHttps = () => {
  if (formData.website && !formData.website.includes('https://' || 'http://') && formData.website.includes('www.')) {
    formData.website = `https://${formData.website}`
  }
}

// Validate schema
const validateSchema = async () => {
  isFormValid.value = true

  companySettingsSchema.validate(formData, { abortEarly: false }).catch((error: any) => {
    // Log the detailed error information
    isFormValid.value = false
    // get all the errors
    for (const err of error.errors) {
      // assume custom error message if message
      if (err.message) {
        notificationStore.showError(err.message, err.path)
      }
    }
  })
}

// Watch for form data changes
watch(
  formData,
  () => {
    copyFields()
    for (const key in formData) {
      const originalValue = companyCopy.value[key as keyof CompanyForm]
      let currentValue = formData[key as keyof CompanyForm]
      if (currentValue === '' && originalValue == null) {
        currentValue = null
      }
      if (currentValue !== originalValue) {
        // Add to changed fields if different
        if (!changedFields.value.includes(key)) {
          changedFields.value.push(key)
        }
      } else {
        // Remove from changed fields if the same
        changedFields.value = changedFields.value.filter((field) => field !== key)
      }
    }
  },
  { deep: true }
)

// Mutation for updating company
const { mutate: updateCompany, error: notifyCompanyError } = useMutation(UpdatePartialCompany)

watch(notifyCompanyError, (error) => {
  if (error) {
    notificationStore.showError('Error', 'Error updating company')
  }
})
// Submit form
const submitForm = async () => {
  await validateSchema()

  if (!isFormValid.value) {
    return
  }

  // Check if any fields have changed
  if (changedFields.value.length === 0) {
    notificationStore.showInfo('No Changes', 'No changes have been made')
    return
  }

  // Get billing and company address IDs (if they exist, otherwise create them)
  let billingID = resultGetByID.value.companies?.nodes[0].billingAddressID
  let companyAddressID = resultGetByID.value.companies?.nodes[0].companyAddressID

  // Handle billing address changes
  if (
    changedFields.value.includes('billingAddress1') ||
    changedFields.value.includes('billingAddress2') ||
    changedFields.value.includes('billingCountry') ||
    changedFields.value.includes('billingState') ||
    changedFields.value.includes('billingZip') ||
    changedFields.value.includes('billingCity')
  ) {
    if (!billingID) {
      billingID = await createBillingAddress()
      changedFields.value.push('billingAddressID')
    } else {
      billingID = await updateBillingAddress()
    }
  }

  // Handle company address changes
  if (
    changedFields.value.includes('companyAddress1') ||
    changedFields.value.includes('companyAddress2') ||
    changedFields.value.includes('companyCountry') ||
    changedFields.value.includes('companyState') ||
    changedFields.value.includes('companyZip') ||
    changedFields.value.includes('companyCity')
  ) {
    if (!companyAddressID) {
      companyAddressID = await createCompanyAddress()
      changedFields.value.push('companyAddressID')
    } else {
      companyAddressID = await updateCompanyAddress()
    }
  }

  // Create updated company object
  const updatedCompany: UpdatedCompanyType = {
    companyID: parseInt(companyId.value) as number,
    company: {
      companyName: formData.companyName,
      billingAddressID: billingID,
      companyAddressID: companyAddressID,
      phone: formData.phone,
      updatedOn: new Date().toISOString(),
      website: formData.website
    } as CompanyUpdate
  }

  // Remove fields that haven't changed
  for (const key in updatedCompany.company) {
    if (!changedFields.value.includes(key)) {
      delete updatedCompany.company[key as keyof CompanyUpdate]
    }
  }

  const response = await updateCompany(updatedCompany)

  if (!response) {
    notificationStore.showError('Error', 'Error updating company')
    return
  }

  notificationStore.showSuccess('Company Updated', 'The company has been updated successfully')

  // Reset changed fields
  changedFields.value = []

  companyCopy.value = { ...formData }
  companyStore.loadCompanyData(companyStore.companyID)
}

// Mutations for creating and updating addresses
const { mutate: createAddress, error: notifyUser } = useMutation(CreateAddress)
const { mutate: updateAddress } = useMutation(UpdateAddress)

// Watch for address creation errors
watch(notifyUser, (error) => {
  if (error) {
    notificationStore.showError('Error', 'Error creating address')
  }
})

// Create company address
const createCompanyAddress = async () => {
  const response = await createAddress({
    address: {
      addressLine1: formData.companyAddress1,
      addressLine2: formData.companyAddress2,
      city: formData.companyCity,
      country: formData.companyCountry,
      postalCode: formData.companyZip,
      state: formData.companyState
    }
  })

  if (!response) {
    notificationStore.showError('Error', 'Error creating company address')
    return
  }

  return response.data.createAddress.addressID
}

// Create billing address
const createBillingAddress = async () => {
  const response = await createAddress({
    address: {
      addressLine1: formData.billingAddress1,
      addressLine2: formData.billingAddress2,
      city: formData.billingCity,
      country: formData.billingCountry,
      postalCode: formData.billingZip,
      state: formData.billingState
    }
  })

  if (!response) {
    notificationStore.showError('Error', 'Error creating billing address')
    return
  }

  return response.data.createAddress.addressID
}

// Update company address
const updateCompanyAddress = async () => {
  const response = await updateAddress({
    addressID: resultGetByID.value.companies.nodes[0].companyAddress.addressID,
    address: {
      addressLine1: formData.companyAddress1,
      addressLine2: formData.companyAddress2,
      city: formData.companyCity,
      country: formData.companyCountry,
      postalCode: formData.companyZip,
      state: formData.companyState
    }
  })

  if (!response) {
    notificationStore.showError('Error', 'Error updating company address')
    return
  }

  return response.data.updateAddress.addressID
}

// Update billing address
const updateBillingAddress = async () => {
  const response = await updateAddress({
    addressID: resultGetByID.value.companies.nodes[0].billingAddress.addressID,
    address: {
      addressLine1: formData.billingAddress1,
      addressLine2: formData.billingAddress2,
      city: formData.billingCity,
      country: formData.billingCountry,
      postalCode: formData.billingZip,
      state: formData.billingState
    }
  })

  if (!response) {
    notificationStore.showError('Error', 'Error updating billing address')
    return
  }

  return response.data.updateAddress.addressID
}

// Styles
const saveButtonStyles: ButtonPassThroughOptions = {
  root: {
    class: ['bg-primary-500', 'hover:bg-primary-600', '!text-black', 'rounded-md', '!py-1 !px-4', 'my-3 me-4']
  }
}

const fileUploadStyles: FileUploadPassThroughOptions = {
  header: {
    class: ['hidden']
  },
  content: {
    class: [' rounded-lg']
  },
  empty: {
    class: ['p-fileupload-empty-content flex flex-col items-center justify-center']
  },
  root: {
    class: ['!relative', '!z-20']
  }
}
</script>
