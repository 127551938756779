<template>
  <div class="flex">
    <template v-if="fieldMode === 'view'">
      <!--View Mode-->
      <template v-if="model">
        <span class="text-left" :class="lineClampClass" v-html="model"></span>
      </template>
      <template v-else>
        <div class="h-6"></div>
      </template>
    </template>
    <template v-else>
      <template v-if="fieldParent === 'table'">
        <!--Edit Mode Table-->
        <span class="text-left z-10" :class="lineClampClass" v-html="model"></span>
        <!--this is a hidden input thats only purpose is the focus event-->
        <InputText size="small" v-model="model" class="absolute w-0 border-0" @focus="toggleEditor"/>
      </template>
      <template v-else>
        <!--Edit Mode Form-->
        <div class="w-full">
          <DataFieldEditorCustomEditor v-model="model" class="max-w-full"/>
        </div>
      </template>
    </template>
    <Popover ref="editor" class="-mt-12 -ml-2" pt:content:class="p-0" pt:root:class="before:hidden after:hidden">
      <DataFieldEditorCustomEditor v-model="model" class="max-w-xl" />
    </Popover>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import Popover from 'primevue/popover'
  import InputText from 'primevue/inputtext';
  import DataFieldEditorCustomEditor from './DataFieldEditorCustomEditor.vue';
  // v-model
  const model = defineModel() // The data for the field

  // Props
  const props = defineProps<{
    fieldMode: string,  // The mode of the field (EG: view, edit)
    fieldParent: string   // The type of the field (EG: table, card, form)
  }>()

  const editor = ref()

  // Adjusts the line clamp based on the field parent (form/card)
  const lineClampClass = computed(() => {
    return props.fieldParent === 'table' ? 'line-clamp-1' : 'line-clamp-2'
  })

  // Toggle the editor
  function toggleEditor(event: any) {
    editor.value.toggle(event)
  }
</script>