import { useCompanyStore } from '@/stores/company.store';
import { UserRoles } from "@/constants/Enums";
import { useUserStore } from '@/stores/user.store';

export const HasPermission = (permission: string, companyID: number = 0) => {
    if (companyID === 0) {
        const companyStore = useCompanyStore();
        companyID = companyStore.data.company.companyID;
    }
    // Get the userRole from enum.
    const userRole = UserRoles.find((role: any) => role.label === permission);
    // Check if the user has a policy and the userRole is less than or equal to the userRole value.
    const userStore = useUserStore();
    return (userRole?.value != null &&
        userStore?.UserObject?.userPolicies.some((policy) => policy.companyID == companyID &&
            policy.userRole?.userRoleID <= userRole.value)) ?? false;
};
