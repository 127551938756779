<template>
  <div class="flex">
    <template v-if="fieldMode === 'view'">
      <!--View Mode-->
      <template v-if="model">
        <a :href="urlPath" target="_blank" class="line-clamp-1 text-left underline">{{ model }}</a>
      </template>
      <template v-else>
        <div class="h-6"></div>
      </template>         
    </template>
    <template v-else>
      <!--Edit Mode-->
      <InputText size="small" v-model="model" class="w-full"/>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  // v-model
  const model = defineModel() // The data for the field

  // Props
  defineProps<{
    fieldMode: string,  // The mode of the field (EG: view, edit)
    fieldParent: string   // The type of the field (EG: table, card, form)
  }>()

  // Computed Url Path
  const urlPath = computed(() => {
    // We may need to add some url validation here in the future (force https if not present, etc)
    return model.value as string
  })
</script>
