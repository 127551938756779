<template>
  <div id="login-spinner">
    <div v-if="loading" class="flex flex-col items-center justify-center h-96">
      <ProgressSpinner/>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useMsal } from '@/composables/b2c/utils/useMsal'
import { loginRequest } from '@/composables/b2c/authConfig'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import { useUserStore } from '@/stores/user.store'

const route = useRoute()
const { instance, accounts } = useMsal()
const isAuthenticated = useIsAuthenticated()
const userStore = useUserStore()
const loading = ref(true)

onMounted(async () => {
  const redirectURI = ref(window.location.origin)
  if (route.query.redirect_uri) {
    redirectURI.value = route.query.redirect_uri as string
  }
  await instance.handleRedirectPromise().then(() => {
    if (!isAuthenticated.value) {
      instance.loginRedirect({
        ...loginRequest,
        state: btoa(redirectURI.value),
        loginHint: route.query.login_hint as string,
        prompt: 'select_account'
      })
    }
  })
})

watch(isAuthenticated, async (newVal, oldVal) => {
  if (oldVal !== newVal) {
    if (newVal) {
      window.location.hash = ''
      instance.handleRedirectPromise().then(async () => {
        await userStore.setUserInfo(accounts.value[0].localAccountId)
        await userStore.redirectUser()
      })
    }
  }
})
</script>