<template>
  <FolderForm :folderId="folderId" :isSharedPage="false" />
  <DynamicDialog />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import FolderForm from '@/components/FormFolder.vue'

// Initialize route
const route = useRoute()
const folderId = ref(parseInt(route.params.id as string))
</script>
