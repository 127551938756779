<template>
  <footer class="flex justify-between px-4 py-2 bg-gray-200 z-50 w-full">
    <div class="flex">
      <img src="../assets/prodifybeta_logo_black.svg" alt="Logo" class="h-6" />
    </div>
    <div>
      <p>Copyright &copy; {{ currentYear }} Prodify</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
// get current year
const currentYear = new Date().getFullYear();
</script>
