<template>
  <div>
    <!--Menu Sidebar-->
    <AppMenuSidebar @showFullMenu="showFullMenu">
      <template #items>
        <AppMenuSidebarLink routeName="folders" icon="fa-solid fa-folders" label="Folders"></AppMenuSidebarLink>
        <AppMenuSidebarLink routeName="assets" icon="fa-solid fa-file" label="All Assets"></AppMenuSidebarLink>
      </template>
    </AppMenuSidebar>
    <div class="bg-white transition-all duration-300 flex flex-col justify-between minAppHeight" :class="fullMenu ? 'ml-64' : 'ml-12'">
      <div>
        <RouterView v-if="companyLoaded"></RouterView>
      </div>
      <AppFooter></AppFooter>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppMenuSidebar from '@/components/AppMenuSidebar.vue'
import AppMenuSidebarLink from '@/components/AppMenuSidebarLink.vue'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import { useCompanyStore } from '@/stores/company.store'
import { useStatusStore } from '@/stores/status.store'
import { useAudienceStore } from '@/stores/audience.store'
import { useTagStore } from '@/stores/tag.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import { GenerateSasToken } from '@/composables/storageHelper'
import AppFooter from '@/components/AppFooter.vue'

const companyStore = useCompanyStore()
const statusStore = useStatusStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const assetTypeStore = useAssetTypeStore()

const route = useRoute()

const companyLoaded = ref(false)
const fullMenu = ref(false)

function showFullMenu(e: any) {
  fullMenu.value = e;
}

onMounted(async () => {
  const companyId = ref(parseInt(route.params.company as string))
  // reset the folder table info
  companyStore.folder.rowsPerPage = 20 
  companyStore.folder.page = 1
  companyStore.folder.selectedFolderID = 0

  // reset the asset table info
  companyStore.asset.rowsPerPage = 20
  companyStore.asset.page = 1

  // load company level data
  await companyStore.loadCompanyData(companyId.value)
  await assetTypeStore.loadAssetTypesData(companyId.value)
  await audienceStore.loadAudiencesData(companyId.value)
  await statusStore.loadStatusesData(companyId.value)
  await tagStore.loadTagsData(companyId.value)
  GenerateSasToken(companyId.value)
  companyLoaded.value = true
})
</script>
