<template>
  <div>
    <DataTable
      :value="userData"
      :emptyMessage="emptyMessage"
      :loading="props.loading"
      :pt="tableStyles"
      dataKey="userID"
      showGridlines
      size="small"
      v-model:selection="selectedUsers"
      v-model:filters="filters"
      :globalFilterFields="filterFields"
      @cell-edit-complete="onCellEditComplete"
      editMode="cell"
    >
      <template #header>
        <div class="justify-end flex p-0 w-full sm:w-60 pl-5 md:pl-0">
          <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="sm:w-60 justify-end" />
        </div>
      </template>

      <!-- Dynamic columns based on fields props -->
      <Column v-for="field in props.fields" :key="field" :field="field" :header="getFieldHeader(field)" :sortable="isSortable(field)" :class="getColumnClass(field)">
        <!-- Body template for display -->
        <template #body="slotProps">
          <div v-if="field === 'userRole'">
            <Select
              optionLabel="role"
              :optionValue="(role: any) => role"
              :options="userRoles"
              v-model="slotProps.data.userPolicies[0].userRole"
              @change="checkChangedFields"
              class="w-full ring-0 focus:ring-0 border-0 focus:border-0 active:border-0"
              placeholder="Select a role"
            />
          </div>
          <div v-else-if="field === 'userStatus'">
            {{ slotProps.data.userPolicies[0].userStatus.status }}
          </div>

          <div v-else>
            {{ slotProps.data[field] }}
          </div>
        </template>

        <!-- Editor template for editable fields -->
        <template v-if="isEditableField(field)" #editor="slotProps">
          <InputText v-model="slotProps.data[field]" class="w-full" :placeholder="getFieldHeader(field)" />
        </template>
      </Column>

      <!-- Actions Column -->
      <Column header="Actions" class="w-20">
        <template #body="slotProps">
          <div class="flex space-x-2">
            <!-- Always show delete -->
            <FontAwesomeIcon icon="fa-regular fa-trash" @click="deleteUser(slotProps.data)" class="text-lg hover:text-black text-gray-600 cursor-pointer" />
            <!-- Show email if user is pending -->
            <FontAwesomeIcon @click="sendEmail(slotProps.data)" icon="fa-regular fa-envelope" class="text-lg hover:text-black text-gray-600 cursor-pointer" />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import type { DataTablePassThroughOptions } from 'primevue/datatable'
import { FilterMatchMode } from '@primevue/core/api'
import type { UserRole } from '@/types/UserType'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Define ChangedField interface
interface ChangedField {
  userID: number
  field: string
  oldValue: any
  newValue: any
  userPolicyID: number
  isEnabled: boolean
}

const props = defineProps<{
  users: any[]
  loading: boolean
  fields: string[]
  userRoles?: UserRole[]
  superAdmin?: boolean
}>()

// Filters
const filterFields = ref(['displayName', 'mail', 'userStatus', 'userRole'])

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// userData (for editing)
const userData = ref([] as any[])
const userCopy = ref([] as any[])

// changedFields
const changedFields = ref<ChangedField[]>([])

// Watch for changes in props.users
watch(
  () => props.users,
  async () => {
    // If users are blank, don't do anything
    if (props.users.length === 0) {
      userData.value = []
      userCopy.value = []
      changedFields.value = []
      return
    }

    // Deep copy of users (for editing)
    userData.value = JSON.parse(JSON.stringify(props.users))
    // clean __typename from userPolicies (or the dropdown will not work)
    userData.value.forEach((user) => {
      user.userPolicies.forEach((policy: any) => {
        if (policy.userRole) delete policy.userRole.__typename
      })
    })
    userCopy.value = JSON.parse(JSON.stringify(props.users))

    // Clear changed fields
    changedFields.value = []
  },
  { immediate: true, deep: true }
)

const emptyMessage = ref('No users found')

// Computed property for changed user IDs
const changedUserIDs = computed(() => {
  return new Set(changedFields.value.map((field) => field.userID))
})

// Table styles with row class
const tableStyles = ref<DataTablePassThroughOptions>({
  header: {
    class: [
      'flex',
      'justify-end',
      'font-bold',
      'border-x',
      'border-t',
      'border-b-0',
      'p-1',
      'bg-surface-50',
      'dark:bg-surface-800',
      'border-surface-200',
      'dark:border-surface-700',
      'text-surface-700',
      'dark:text-white/80',
      'text-sm'
    ]
  },
  column: {
    bodyCell: {
      class: [
        // Default styles
        'text-left',
        'border-0',
        'border-b',
        'border-solid',
        'first:border-l',
        'border-r',
        'border-b',
        'border-surface-200',
        'dark:border-surface-700',

        // Custom styles
        '!p-1 !px-2',

        // Text
        'text-sm'
      ]
    }
  },
  row: {
    class: (data: any) => {
      return changedUserIDs.value.has(data.userID) ? 'bg-yellow-100 dark:bg-yellow-900' : ''
    }
  }
}) as DataTablePassThroughOptions

const emits = defineEmits(['delete', 'invite', 'changed', 'save'])

const selectedUsers = ref(null)

// Methods
const deleteUser = (user: any) => {
  user.mail = user.email
  user.displayName = user.displayName || user.mail
  emits('delete', user)
}

const sendEmail = (user: any) => {
  user.mail = user.email
  emits('invite', user)
}

const onCellEditComplete = (event: any) => {
  // Update the userData with the new value
  const updatedUser = userData.value.find((user) => user.userID === event.data.userID)
  if (updatedUser) {
    updatedUser[event.field] = event.newValue
  }

  // Check for changes
  checkChangedFields()
}

// Helper methods for dynamic columns
function getFieldHeader(field: string) {
  switch (field) {
    case 'displayName':
      return 'Name'
    case 'mail':
    case 'email':
      return 'Email'
    case 'userStatus':
      return 'Status'
    case 'signInActivity.lastSignInDateTime':
      return 'Last Activity'
    case 'userRole':
      return 'User Role'
    case 'firstName':
      return 'First Name'
    case 'lastName':
      return 'Last Name'
    default:
      return field
  }
}

function isSortable(field: string) {
  return ['displayName', 'email', 'mail', 'userRole', 'externalUserState'].includes(field)
}

function getColumnClass(field: string) {
  return ['userRole', 'externalUserState'].includes(field) ? 'w-20' : ''
}

// Determine if a field is editable
function isEditableField(field: string) {
  return !['userStatus', 'actions', 'email', 'userRole'].includes(field)
}

// Check for changed fields by comparing userData with userCopy
function checkChangedFields() {
  changedFields.value = []

  userData.value.forEach((user) => {
    const originalUser = userCopy.value.find((u) => u.userID === user.userID)
    if (originalUser) {
      props.fields.forEach((field) => {
        let originalValue = originalUser[field]
        let currentValue = user[field]

        // Handle nested fields
        if (field === 'userStatus') {
          originalValue = originalUser.userPolicies[0]?.userStatus.status
          currentValue = user.userPolicies[0]?.userStatus.status
        } else if (field === 'userRole') {
          originalValue = originalUser.userPolicies[0]?.userRole?.userRoleID
          currentValue = user.userPolicies[0]?.userRole?.userRoleID
        }

        if (originalValue !== currentValue) {
          changedFields.value.push({
            userID: user.userID,
            field: field,
            oldValue: originalValue,
            newValue: currentValue,
            userPolicyID: user.userPolicies[0].userPolicyID,
            isEnabled: user.userPolicies[0].isEnabled
          })
        }
      })
    }
  })

  emits('changed', changedFields.value)
}
</script>
