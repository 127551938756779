<template>
  <div class="m-5 mt-0">
    <FileUpload
      customUpload
      ref="fileUploadRef"
      multiple
      mode="advanced"
      pt:header:class="bg-gray-400 bg-opacity-30"
      @remove="updateTotals"
      @select="onSelectedFiles"
      @uploader="uploadFiles($event)"
      :disabled="processedFiles?.length > 0"
    >
      <template #header="{ chooseCallback }">
        <div class="p-1 flex justify-between w-full">
          <Button
            id="selectFiles"
            class="text-black hover:text-white"
            label="Select Files"
            icon="fas fa-plus"
            size="small"
            @click="chooseCallback"
            :disabled="isUploading || processedFiles?.length > 0"
          />
          <span v-if="isUploading" class="text-base my-auto">{{ processedFiles?.length - failedFiles.length }} / {{ totalFiles }}</span>
          <span v-else-if="!isUploading && processedFiles?.length > 0 && failedFiles.length == 0" class="text-base my-auto">
            {{ processedFiles?.length - failedFiles.length }} Uploaded
          </span>
          <span v-else-if="!isUploading && processedFiles?.length > 0 && failedFiles.length > 0" class="text-base my-auto">
            {{ processedFiles?.length - failedFiles.length }} / {{ processedFiles?.length }} Uploaded
          </span>
          <span v-else class="text-base my-auto">{{ totalFiles }} files selected</span>
        </div>
      </template>
      <template #empty>
        <div class="p-1 min-h-20" v-if="!isUploading && processedFiles?.length == 0">
          <span class="text-base">Drag and drop files to upload</span>
        </div>
        <div v-else-if="isUploading">
          <ProgressBar
            :value="progress"
            mode="determinate"
            :pt="{
              root: {
                class: 'h-12 md:ml-auto border-round-2xl mx-auto relative'
              }
            }"
            v-tooltip="`${currentStatus}`"
          >
          </ProgressBar>
        </div>
      </template>
      <template #content="{ files }">
        <div v-if="files.length > 0 && processedFiles?.length !== totalFiles && !isUploading" class="flex flex-col items-start w-full">
          <div class="block gap-5 w-full" v-for="item in files" :key="item.name">
            <div class="flex flex-row max-h-28">
              <!-- video ~ thumbnail (icon only) -->
              <span v-if="IsVideoFile(item.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                <FontAwesomeIcon icon="fa-light fa-file-video" class="p-0 h-full w-full my-auto !text-3xl"></FontAwesomeIcon>
              </span>
              <!-- pdf ~ thumbnail -->
              <span v-else-if="IsPDFFile(item.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                <FontAwesomeIcon icon="fa-light fa-file-pdf" class="p-0 h-full w-full my-auto !text-3xl"></FontAwesomeIcon>
              </span>
              <!-- document ~ thumbnail -->
              <span v-else-if="IsDocumentFile(item.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                <FontAwesomeIcon icon="fa-light fa-file-word" class="p-0 h-full w-full my-auto !text-3xl"></FontAwesomeIcon>
              </span>
              <!-- image ~ thumbnail -->
              <span v-else-if="IsImageFile(item.name) || IsStaticImageFile(item.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14 h-full">
                <img :src="item.objectURL" alt="Asset Preview" class="mx-auto max-w-28 w-full object-contain" />
              </span>
              <!-- if no preview available show no preview icon -->
              <span v-else class="mx-auto flex min-w-28 max-h-14 min-h-14 h-full">
                <FontAwesomeIcon icon="fa-light fa-file" class="p-0 h-full w-full my-auto !text-3xl"></FontAwesomeIcon>
              </span>
              <div class="flex flex-row justify-between w-full">
                <span class="text-base my-auto ml-1">{{ item.name }}</span>
              </div>
              <div class="flex">
                <span class="text-base my-auto w-full md:text-nowrap">{{ formatFileSize(item.size) }}</span>
                <Button class="!text-black hover:text-white my-1" text icon="fas fa-times" @click="removePendingFile(item)" />
              </div>
            </div>
            <Divider />
          </div>
        </div>
        <div v-else-if="processedFiles?.length === totalFiles && !isUploading">
          <div class="flex flex-col items-start w-full">
            <div class="block gap-5 w-full" v-for="(item, index) in processedFiles" :key="index">
              <div class="flex flex-row justify-between" v-if="!item.error">
                <!-- video ~ thumbnail (icon only) -->
                <span v-if="IsVideoFile(item.file.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                  <FontAwesomeIcon icon="fa-light fa-file-video" class="p-0 my-auto !text-3xl"></FontAwesomeIcon>
                </span>
                <!-- pdf ~ thumbnail -->
                <span v-else-if="IsPDFFile(item.file.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                  <FontAwesomeIcon icon="fa-light fa-file-pdf" class="p-0 my-auto !text-3xl"></FontAwesomeIcon>
                </span>
                <!-- document ~ thumbnail -->
                <span v-else-if="IsDocumentFile(item.file.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                  <FontAwesomeIcon icon="fa-light fa-file-word" class="p-0 my-auto !text-3xl"></FontAwesomeIcon>
                </span>
                <!-- image ~ thumbnail -->
                <span v-else-if="IsImageFile(item.file.name) || IsStaticImageFile(item.file.name)" class="mx-auto flex min-w-28 max-h-14 min-h-14">
                  <img :src="item.file.objectURL" alt="Asset Preview" class="mx-auto max-w-28 w-full max-h-20 object-contain" />
                </span>
                <!-- if no preview available show no preview icon -->
                <span v-else class="mx-auto h-full flex min-w-28 max-w-28">
                  <FontAwesomeIcon icon="fa-light fa-file" class="p-0 h-full w-full my-auto !text-3xl"></FontAwesomeIcon>
                </span>
                <div class="flex flex-row justify-between w-full">
                  <span class="text-base my-auto ml-1"
                    >{{ item.fileModel?.Name }} <br />
                    <span class="text-green-500">Uploaded</span>
                  </span>
                </div>
                <div class="flex flex-row justify-between">
                  <span class="text-base my-auto ml-4 w-full md:text-nowrap">{{ formatFileSize(item.fileModel?.Size ?? 0) }}</span>
                  <Button class="!text-black hover:text-white my-1" text icon="fas fa-times" @click="removeFileManually(item)" />
                </div>
              </div>
              <div class="flex flex-row" v-else>
                <FontAwesomeIcon icon="fal fa-exclamation-triangle" class="text-red-500 text-4xl my-auto max-w-32 w-full" />
                <div class="flex flex-row justify-between w-full">
                  <div class="text-base my-auto ml-4">
                    <span class="flex flex-row">{{ item.fileModel?.Name }}</span>
                    <span class="text-red-500">File Not Uploaded - {{ item.error }}</span>
                  </div>
                  <div class="flex">
                    <span class="text-base my-auto ml-4 w-full md:text-nowrap">{{ formatFileSize(item.fileModel?.Size ?? 0) }}</span>
                    <Button class="!text-black hover:text-white my-1" text icon="fas fa-times" @click="removeFileManually(item)" />
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<script lang="ts" setup>
import fileService, { type FileModel } from '@/services/api/file.service'
import { useUserStore } from '@/stores/user.store'
import { useUploaderStore } from '@/stores/uploader.store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { FileUploadUploaderEvent } from 'primevue/fileupload'
import { ref, watch } from 'vue'
import type { ProcessedFile, FailedFile } from '@/types/UploaderType'
import { useConfirm } from 'primevue/useconfirm'
import { useCompanyStore } from '@/stores/company.store'
import { IsImageFile, IsVideoFile, IsDocumentFile, IsPDFFile, IsStaticImageFile } from '@/composables/storageHelper'

const fileUploadRef = ref()
const emits = defineEmits(['update:files', 'update:processedFiles', 'update:failedFiles', 'uploadComplete', 'deleteSelectedAsset'])
const confirm = useConfirm()
const companyStore = useCompanyStore()
const userStore = useUserStore()
const uploaderStore = useUploaderStore()

const processedFiles = ref(uploaderStore.uploaderProfile.processedFiles as ProcessedFile[])
const failedFiles = ref(uploaderStore.uploaderProfile.failedFiles as FailedFile[])
const isUploading = ref(uploaderStore.uploaderProfile.isUploading as boolean)
const progress = ref(uploaderStore.uploaderProfile.progress as number)
const totalFiles = ref(uploaderStore.uploaderProfile.totalFiles as number)
const currentStatus = ref(uploaderStore.uploaderProfile.currentStatus as string)

const props = defineProps<{
  processedFileUpdate: ProcessedFile[]
  selectedFolderId: number
}>()

watch(
  () => props.processedFileUpdate,
  (newVal: any) => {
    processedFiles.value = [...newVal]
    failedFiles.value = processedFiles.value.filter((f) => f.error) as FailedFile[]
    if (fileUploadRef.value?.files.length > 0) totalFiles.value = processedFiles.value.length + fileUploadRef.value?.files.length
    else totalFiles.value = processedFiles.value.length
  },
  { deep: true, immediate: true }
)
function formatFileSize(size: number) {
  if (size === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(size) / Math.log(k))
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

const onSelectedFiles = (event: any) => {
  const files = event.files
  if (files.length === 0) return

  // clear everything
  progress.value = 0
  totalFiles.value = files.length
  isUploading.value = false

  emits('update:files', [...files])
  emits('update:processedFiles', [...processedFiles.value])
}

const uploadFiles = async (event: FileUploadUploaderEvent) => {
  // add files to completed files
  let fileList = event.files as File[]
  let userID = userStore.UserObject?.userID
  let companyId = companyStore.data.company.companyID
  if (!userID) {
    console.error('User ID not found')
    return
  }
  if (!companyId) {
    console.error('Company ID not found')
    return
  }
  isUploading.value = true
  uploaderStore.uploaderProfile.isUploading = true
  for (const file of fileList) {
    currentStatus.value = `Uploading ${file.name}`
    progress.value = 0
    const objUrl = URL.createObjectURL(file)
    // Create the file model
    const fileModel = {
      Name: file.name,
      CompanyID: companyId,
      UserID: userID,
      Size: file.size,
      FileName: file.name,
      ObjectURL: objUrl,
      FolderID: props.selectedFolderId === 0 ? null : props.selectedFolderId
    } as FileModel

    if (shouldSplitTheFile(file)) {
      const chunks = splitFile(file)
      await uploadChunks(chunks, fileModel)
        .then((asset) => updateProcessedFiles(file, fileModel, asset))
        .catch((error) => updateProcessedFiles(file, fileModel, undefined, error.message))
    } else {
      await fileService
        .uploadAsset(fileModel, file, onprogress)
        .then((asset) => updateProcessedFiles(file, fileModel, asset))
        .catch((error) => updateProcessedFiles(file, fileModel, undefined, error.message))
    }
  }
  isUploading.value = false
  emits('update:processedFiles', processedFiles.value)
  emits('update:failedFiles', failedFiles.value)
  emits('uploadComplete')
  updateTotals()
}

function updateProcessedFiles(file: File, fileModel: FileModel, asset: any, error?: string) {
  const fileIndex = processedFiles.value.findIndex((f) => f.file?.name === file.name)

  if (error) {
    // Handle the error case
    const failedFile = { file, error }
    if (fileIndex !== -1) {
      processedFiles.value[fileIndex].error = error
    } else {
      processedFiles.value.push(failedFile)
    }
    failedFiles.value.push(failedFile)
  }
  if (fileIndex !== -1) {
    delete processedFiles.value[fileIndex].error
    processedFiles.value[fileIndex] = {
      file,
      fileModel: fileModel,
      asset: asset.data
    }
    // Remove the file from failed files
    failedFiles.value = failedFiles.value.filter((f) => f.file.name !== file.name)
  } else if (!error) {
    processedFiles.value.push({
      file,
      fileModel,
      asset: asset.data
    })
  }
}

function onprogress(event: ProgressEvent) {
  const progressPercentage = Math.round((event.loaded / event.total) * 100)
  progress.value = progressPercentage
}

// SPLIT FILE STUFF
function shouldSplitTheFile(file: File) {
  // Check if the file is bigger than 100MB.
  if (file.size > 100000000) {
    return true
  }

  return false
}

async function uploadChunks(chunks: Blob[], fileModel: FileModel) {
  let index = 0
  fileModel.UploadID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  const successfulChunks: Blob[] = []

  for (const chunk of chunks) {
    currentStatus.value = `Uploading chunk ${index + 1} of ${chunks.length} for ${fileModel.Name}`
    index++
    fileModel.PartNumber = index
    try {
      await fileService.uploadChunkedAsset(fileModel, chunk, onChunkProgress(index, chunks.length))
      successfulChunks.push(chunk)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  if (successfulChunks.length === chunks.length) {
    try {
      // set to 99% cause we can't get the progress of the merge
      progress.value = 99
      currentStatus.value = `Merging chunks for ${fileModel.Name}`
      const asset = await fileService.mergeChunkedAsset(fileModel)
      return Promise.resolve(asset)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

function onChunkProgress(index: number, totalChunks: number) {
  return (event: ProgressEvent) => {
    const chunkProgress = Math.round((event.loaded / event.total) * 100)
    const totalProgress = Math.round(((index - 1) / totalChunks) * 100 + chunkProgress / totalChunks)
    if (totalProgress == 100) progress.value = 99
    else progress.value = totalProgress
  }
}

function splitFile(file: File): Blob[] {
  const chunks: Blob[] = []
  let offset = 0

  // The max value that the request accepts. 30MB.
  const chunkSize = 25000000

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunkSize, file.type)
    chunks.push(chunk)
    offset += chunk.size
  }

  return chunks
}

function updateTotals() {
  totalFiles.value = processedFiles.value.length
  uploaderStore.uploaderProfile.totalFiles = totalFiles.value
}
function removeFileManually(item: any) {
  //if item has error then just remove it
  if (item.error) {
    removeProcessedFile(item)
    return
  }
  // Confirm dialog to remove the file if it has been uploaded
  confirm.require({
    message: 'This file will be removed from the Assets table',
    header: 'Are you sure you want to remove this file?',
    acceptLabel: 'Yes, Remove File',
    acceptClass: 'bg-primary-500 !text-black border-0',
    acceptProps: { severity: 'danger' },
    rejectLabel: 'No, Keep File',
    rejectClass: '!text-black border-2 border-black',
    rejectProps: { outlined: true },
    accept: () => {
      removeProcessedFile(item)
      if (item.asset) emits('deleteSelectedAsset', [item.asset.assetID])
    }
  })
}

function removeProcessedFile(item: any) {
  // Remove the file from processedFiles array
  const index = processedFiles.value.findIndex((f) => f.file.name === item.file.name)
  if (index !== -1) {
    processedFiles.value.splice(index, 1)
  }

  // Remove the file from failedFiles array if it exists
  const failedIndex = failedFiles.value.findIndex((f) => f.file.name === item.file.name)
  if (failedIndex !== -1) {
    failedFiles.value.splice(failedIndex, 1)
  }

  // Remove the file from uploaderStore.uploaderProfile.files
  const uploaderFilesIndex = uploaderStore.uploaderProfile.files.findIndex((f: any) => f.name === item.file.name)
  if (uploaderFilesIndex !== -1) {
    const updatedFiles = [...uploaderStore.uploaderProfile.files] // Create a copy of the uploader files array
    updatedFiles.splice(uploaderFilesIndex, 1) // Remove the item from the copied array
    uploaderStore.uploaderProfile.files = updatedFiles // Update the store with the new array
    emits('update:files', updatedFiles, false)
  }

  // Update totals or any other calculations needed
  updateTotals()

  // Emit the updated lists to the parent component
  emits('update:processedFiles', processedFiles.value)
  emits('update:failedFiles', failedFiles.value)
}

function removePendingFile(item: any) {
  // Remove the specific item from the files array
  const index = fileUploadRef.value.files.indexOf(item)
  if (index !== -1) {
    const updatedFiles = [...fileUploadRef.value.files] // Create a copy of the files array
    updatedFiles.splice(index, 1) // Remove the item from the copied array
    fileUploadRef.value.files = updatedFiles // Update the FileUpload ref with the new array
    uploaderStore.uploaderProfile.files = updatedFiles // Sync the store with the updated files
    totalFiles.value = updatedFiles.length // Update the totalFiles count
    emits('update:files', updatedFiles, false) // Emit the updated files to the parent component
  }
}
</script>
