import DynamicDialog from "primevue/dynamicdialog"
import { defineAsyncComponent } from "vue"

export const genericDialogProps = {
    dismissibleMask: true,
    pt: {
        title: {
            class: 'text-xl font-bold mx-auto w-full'
        },
        header: {
            class: 'text-xl font-bold mx-auto w-full'
        },
        root: {
            class: 'min-w-96'
        },
        content: {
            class: 'w-full'
        },
    },
}

export const openShareDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormObjectShare = defineAsyncComponent(() => import('@/components/AppModalFormObjectShare.vue'))
    dialog.open(AppModalFormObjectShare, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Share Asset',
            modal: true,
            closable: true,
            // override if needed
            ...options.props,
            pt: {
                header: {
                    class: 'p-0 mx-auto w-full'
                },
                title: {
                    class: 'mx-auto mt-5'
                }
            },

        },
    })
}
export const openConfirmEntityDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalConfirmEntity = defineAsyncComponent(() => import('@/components/AppModalConfirmEntity.vue'))
    dialog.open(AppModalConfirmEntity, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Delete',
            modal: true,
            closable: false,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full pb-1 '
                }
            },
            // override if needed
            ...options.props,
        },
    })
}

export const openDuplicateFolder = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalConfirmEntity = defineAsyncComponent(() => import('@/components/AppModalConfirmEntity.vue'))
    dialog.open(AppModalConfirmEntity, {
        data: {
            subtitle: 'Duplicating this folder will make an exact copy of the folder, including its assets, and subfolders.',
            customLabels: {
                submitLabel: 'Duplicate'
            }
        },
        emits: options.emits,
        props: {
            header: 'Duplicate Folder',
            modal: true,
            closable: false,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                },
                root:{
                    class: 'max-w-md'
                }
            },
            // override if needed
            ...options.props,
        },
    })
}

export const openAssetDataExportDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormAssetExportData = defineAsyncComponent(() => import('@/components/AppModalFormAssetExportData.vue'))
    dialog.open(AppModalFormAssetExportData, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Download',
            modal: true,
            closable: false,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props,
        }
    })
}

export const openUserInviteDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormUserInvite = defineAsyncComponent(() => import('@/components/AppModalFormUserInvite.vue'))
    dialog.open(AppModalFormUserInvite, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Invite Users',
            dismissableMask: true,
            closable: true,
            modal: true,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props,
        },
    })
}

export const openFolderRenameDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormFolderRename = defineAsyncComponent(() => import('@/components/AppModalFormFolderRename.vue'))
    dialog.open(AppModalFormFolderRename, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Rename Folder',
            modal: true,
            closable: false,
            dismissableMask: true,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props,
        }
    })
}

export const openFolderCreateDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormFolderCreate = defineAsyncComponent(() => import('@/components/AppModalFormFolderCreate.vue'))
    dialog.open(AppModalFormFolderCreate, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Create Folder',
            modal: true,
            closable: false,
            dismissableMask: true,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props
        }
    })
}

export const openViewChangesDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormViewChanges = defineAsyncComponent(() => import('@/components/AppModalFormViewChanges.vue'))
    dialog.open(AppModalFormViewChanges, {
        emits: options.emits,
        props: {
            header: 'Confirm Save?',
            modal: true,
            closable: false,
            dismissableMask: true,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props,
        },
        data: {
            customLabels: {
                submitLabel: 'Save Changes'
            },
            subText: 'Summary of Changes',
            // override if needed
            ...options.data
        }
    })
}

export const openChangePasswordDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormUserChangePassword = defineAsyncComponent(() => import('@/components/AppModalFormUserChangePassword.vue'))
    dialog.open(AppModalFormUserChangePassword, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Change Password',
            modal: true,
            closable: false,
            dismissableMask: true,
            pt: {
                header: {
                    class: 'text-xl font-bold mx-auto w-full'
                }
            },
            // override if needed
            ...options.props,
        }
    })
}

export const openBatchEditSingleSelectFieldDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
  const AppModalFormBatchEditSingleSelectField = defineAsyncComponent(() => import('@/components/AppModalFormBatchEditSingleSelectField.vue'))
  dialog.open(AppModalFormBatchEditSingleSelectField, {
    data: options.data,
    emits: options.emits,
    props: {
      header: 'Field',
      modal: true,
      closable: false,
      dismissableMask: true,
      pt: {
        header: {
          class: 'text-xl font-bold mx-auto w-full'
        }
      },
      // override if needed
      ...options.props,
    }
  })
}

export const openBatchEditMultiSelectFieldDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
  const AppModalFormBatchEditMultiSelectField = defineAsyncComponent(() => import('@/components/AppModalFormBatchEditMultiSelectField.vue'))
  dialog.open(AppModalFormBatchEditMultiSelectField, {
    data: options.data,
    emits: options.emits,
    props: {
      header: 'Field',
      modal: true,
      closable: false,
      dismissableMask: true,
      pt: {
        header: {
          class: 'text-xl font-bold mx-auto w-full'
        }
      },
      // override if needed
      ...options.props,
    }
  })
}

export const openDeleteFolderDialog = (dialog: typeof DynamicDialog, options: ModalOptions) => {
    const AppModalFormFolderDelete = defineAsyncComponent(() => import('@/components/AppModalFormFolderDelete.vue'))
    dialog.open(AppModalFormFolderDelete, {
        data: options.data,
        emits: options.emits,
        props: {
            header: 'Trash Folder',
            modal: true,
            closable: false,
            dismissableMask: true,
            pt: {
                header: {
                    class: 'pb-0 mx-auto w-full'
                },
                title: {
                    class: ' mx-auto'
                }
            },
            // override if needed
            ...options.props,
        }
    })
}

export interface ModalOptions {
    data?: any
    emits?: any
    props?: any
}