<template>
  <!-- Display spinner while loading -->
  <div class="min-h-48 flex flex-col justify-center items-center w-full mx-auto" v-show="isLoading">
    <ProgressSpinner />
  </div>

  <!-- Display error message if loading fails -->
  <div class="min-h-48 flex flex-col justify-center items-center w-full mx-auto" v-show="hasError">
    <p class="text-center text-red-500">Failed to load PDF</p>
  </div>

  <!-- PDF Viewer and Pagination Controls -->
  <div class="pdf-viewer-container flex flex-col items-center h-full overflow-auto p-5 mx-auto">
    <div ref="pdfContainer" class="pdf-viewer w-full h-[65vh] overflow-auto mx-auto"></div>
    <!-- Restrict height to 80vh -->
    <div class="flex justify-between mt-4 w-full px-5" v-show="!isLoading && !hasError">
      <Button size="small" @click="prevPage" :disabled="pageNum <= 1">Previous</Button>
      <span class="text-base my-auto">Page {{ pageNum }} of {{ totalPages }}</span>
      <Button size="small" @click="nextPage" :disabled="pageNum >= totalPages">Next</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick } from 'vue'
import workerSrc from 'pdfjs-dist/build/pdf.worker?worker&url'
import * as pdfjs from 'pdfjs-dist'

// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc

// Refs and reactive properties
const pdfContainer = ref<HTMLDivElement | null>(null)
const pageNum = ref(1)
const totalPages = ref(0)
const isLoading = ref(true)
const hasError = ref(false) 

let pdfDocument = null as unknown as pdfjs.PDFDocumentProxy

// Set a dynamic scale factor
const scale = ref(1) // Default scale, will be adjusted dynamically

// Define props
const props = defineProps<{ pdfLink: string }>()

// Function to render a specific page
const renderPage = async (num: number) => {
  isLoading.value = true

  if (!pdfContainer.value || !pdfDocument) return

  pdfContainer.value.innerHTML = ''

  try {
    const page = await pdfDocument.getPage(num)

    const scaledViewport = page.getViewport({ scale: scale.value })

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d') as CanvasRenderingContext2D
    canvas.height = scaledViewport.height
    canvas.width = scaledViewport.width

    const renderContext = {
      canvasContext: context,
      viewport: scaledViewport
    }

    await page.render(renderContext).promise
    pdfContainer.value.appendChild(canvas)

    isLoading.value = false
  } catch (error) {
    console.error('Error rendering page:', error)
    isLoading.value = false
    hasError.value = true
  }
}

// Function to load and render the PDF
const renderPdf = async (url: string) => {
  if (!pdfContainer.value) return

  try {
    isLoading.value = true

    const loadingTask = pdfjs.getDocument(url)
    pdfDocument = await loadingTask.promise

    totalPages.value = pdfDocument.numPages

    renderPage(pageNum.value)
  } catch (error) {
    console.error('Error loading PDF:', error)
    isLoading.value = false
    hasError.value = true
  }
}

// Function to go to the next page
const nextPage = () => {
  if (pageNum.value < totalPages.value) {
    pageNum.value++
    renderPage(pageNum.value)
  }
}

// Function to go to the previous page
const prevPage = () => {
  if (pageNum.value > 1) {
    pageNum.value--
    renderPage(pageNum.value)
  }
}

// Load the PDF when the component is mounted
onMounted(() => {
  nextTick(() => {
    renderPdf(props.pdfLink)
  })
})

// Watch for changes in the pdfLink prop
watch(
  () => props.pdfLink,
  (newLink) => {
    pageNum.value = 1
    renderPdf(newLink)
  }
)
</script>
