<template>
  <div class="bg-black h-screen mb-12 fixed pt-2 overflow-x-hidden transition-all duration-300 z-40" :class="showFullMenu ? 'w-64' : 'w-12'">
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col gap-1">
        <div class="flex justify-end">
          <Button class="text-white mx-2 w-8 h-8 hover:bg-primary-emphasis" severity="contrast" rounded size="small" @click="showFullMenuToggle">
            <FontAwesomeIcon :icon="!showFullMenu ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></FontAwesomeIcon>
          </Button>
        </div>
        <slot name="items">
        </slot>
      </div>
      <div v-if="companySelected" class="mb-12 py-3 px-2 gap-2 flex items-center w-64">
        <div v-if="companyStore.data.companyLogo != ''">
          <img :src="companyStore.data.companyLogo" class="w-8 h-8 rounded-sm" alt="company logo">
        </div>
        <div v-else class="w-8 h-8 rounded-sm bg-emphasis flex items-center justify-center">
          <FontAwesomeIcon icon="fa-solid fa-building" class="text-black"></FontAwesomeIcon>
        </div>
        <span class="text-white line-clamp-1 w-48">{{ companyStore.data.company.companyName }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useCompanyStore } from '@/stores/company.store';

const emit = defineEmits(['showFullMenu']);

const showFullMenu = ref(false);

function showFullMenuToggle() {
  showFullMenu.value = !showFullMenu.value;
  emit('showFullMenu', showFullMenu.value);
}

const companyStore = useCompanyStore();
const companySelected = computed(() => companyStore.data.company.companyName != null);
</script>

<style scoped>
.slide-enter-active {
  transform: translate(200px, 0);
  -webkit-transform: translate(200px, 0);
}

.slide-leave-active {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

.slideMenu {
  width:48px; 
  overflow:hidden;

  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

.slideMenu.full {
  width:300px; 
  overflow:visible; 
  
  animation: .7s delay-overflow;
}

@keyframes delay-overflow {
  from { overflow: hidden; }
}
</style>