<template>
  <div>
    <div class="flex flex-row justify-between items-center">
      <label for="filter" class="text-white">Filters</label>
      <Button v-if="hasFilterSelected" for="filter" link class="text-white hover:text-primary p-0" @click="clearFilters">Clear</Button>
    </div>
    <div>
      <!-- Tags Filter -->
      <AppSidebarMultiselectFilter
        v-if="tagMultiselectOptions"
        class="mt-1"
        v-model:multiselect-values="tagMultiselectValues"
        :filter-title="'Tags'"
        :object-property-name="'tags.tagID'"
        :multiselect-options="tagMultiselectOptions"
        @filter-list-by-multi-select-values="filterByMultiSelect"
      ></AppSidebarMultiselectFilter>
      <!-- Status Filter -->
      <AppSidebarMultiselectFilter
        v-if="statusMultiselectOptions"
        class="mt-1"
        v-model:multiselect-values="statusMultiselectValues"
        :filter-title="'Status'"
        :object-property-name="'status.statusID'"
        :multiselect-options="statusMultiselectOptions"
        @filter-list-by-multi-select-values="filterByMultiSelect"
      ></AppSidebarMultiselectFilter>
      <!-- Favorite Filter -->
      <AppSidebarToggleFilter
        class="mt-1 ml-5"
        v-model:toggle-value="favoriteToggleValue"
        :filter-title="'Favorite'"
        :object-property-name="'isUserFavorite'"
        :is-specific-filter="true"
        @filter-list-by-toggle-switch="filterByToggleSwitch"
      ></AppSidebarToggleFilter>
      <!-- Field Type Filter -->
      <AppSidebarMultiselectFilter
        v-if="fieldTypeMultiselectOptions"
        class="mt-1"
        v-model:multiselect-values="fieldTypeMultiselectValues"
        :filter-title="'Field Type'"
        :object-property-name="'assetType.assetTypeID'"
        :multiselect-options="fieldTypeMultiselectOptions"
        @filter-list-by-multi-select-values="filterByMultiSelect"
      ></AppSidebarMultiselectFilter>
      <!-- Preview Filter -->
      <AppSidebarToggleFilter
        class="mt-1 mb-6 ml-5"
        v-model:toggle-value="previewToggleValue"
        :filter-title="'Preview'"
        :object-property-name="'previewURL'"
        @filter-list-by-toggle-switch="filterByToggleSwitch"
      ></AppSidebarToggleFilter>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useTagStore } from '@/stores/tag.store'
import { useStatusStore } from '@/stores/status.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import type { Filter } from '@/types/FilterType'
import AppSidebarMultiselectFilter from './AppSidebarMultiselectFilter.vue'
import AppSidebarToggleFilter from './AppSidebarToggleFilter.vue'

// Define Stores
const tagStore = useTagStore()
const statusStore = useStatusStore()
const assetTypeStore = useAssetTypeStore()

// Define variables
const hasFilterSelected = ref(false)
const tagMultiselectOptions = ref()
const statusMultiselectOptions = ref()
const fieldTypeMultiselectOptions = ref()
const tagMultiselectValues = ref([])
const statusMultiselectValues = ref([])
const fieldTypeMultiselectValues = ref([])
const favoriteToggleValue = ref(false)
const previewToggleValue = ref(false)
const filters = ref<Array<Filter>>([])

// Define emits
const emit = defineEmits(['filter'])

// Lifecycle Hooks
onMounted(() => {
  tagMultiselectOptions.value = tagStore.data.tags.map((tag) => {
    return { value: tag.tagID, label: tag.name }
  })

  statusMultiselectOptions.value = statusStore.data.statuses.map((status) => {
    return { value: status.statusID, label: status.name }
  })

  fieldTypeMultiselectOptions.value = assetTypeStore.data.assetTypes.map((assetType) => {
    return { value: assetType.assetTypeID, label: assetType.name }
  })
})

// Define functions
const clearFilters = () => {
  // Set hasFilterSelected to false
  hasFilterSelected.value = false
  // Clear the filters array
  filters.value = []
  // Clear all the filters values
  tagMultiselectValues.value = []
  statusMultiselectValues.value = []
  fieldTypeMultiselectValues.value = []
  favoriteToggleValue.value = false
  previewToggleValue.value = false
}

const filterByMultiSelect = (selectedValues: number[], objectPropertyName: string) => {
  const filter = { objectPropertyName: objectPropertyName, filterValue: selectedValues, isSpecificFilter: false } as Filter
  setFilters(filter)
}

const filterByToggleSwitch = (filterByToggleSwitch: boolean, objectPropertyName: string, isSpecificFilter: boolean) => {
  const filter = { objectPropertyName: objectPropertyName, filterValue: filterByToggleSwitch, isSpecificFilter: isSpecificFilter } as Filter
  setFilters(filter)
}

const setFilters = (filter: Filter) => {
  const existingFilterValue =
    (filter.filterValue && typeof filter.filterValue === 'string' && filter.filterValue !== '') ||
    (typeof filter.filterValue === 'boolean' && filter.filterValue) ||
    (Array.isArray(filter.filterValue) && filter.filterValue.length > 0)
  // Check if the filter already exists in the array
  const existingFilter = filters.value.find((f: Filter) => f.objectPropertyName === filter.objectPropertyName)
  if (existingFilter) {
    // Check if the value is null, empty, false or length is 0.
    if (!existingFilterValue) {
      // If the filter value is null, empty or length is 0, remove the filter from the array
      filters.value = filters.value.filter((f: Filter) => f !== existingFilter)
    } else {
      // If the filter already exists, update the filter value
      existingFilter.filterValue = filter.filterValue
    }
  } else if (existingFilterValue) {
    // If the filter does not exist, add it to the array
    filters.value.push(filter)
  }

  // Check if there are any filters selected
  hasFilterSelected.value = filters.value.length > 0

  // Emit the updated filters array
  emit('filter', filters.value)
}
</script>
