<template>
  <div>
    <AppHeaderPage class="border-b py-2 px-4">
      <template #title>
        <div class="flex items-center">
          <img v-show="logoUrl" class="w-12 max-h-12" :src="logoUrl" alt="company logo" />
          <h1 class="text-2xl font-bold p-5 pl-0 pb-0 pt-0">{{ companyName }}</h1>
        </div>
      </template>
    </AppHeaderPage>
    <!-- component -->
    <div>
      <component :is="component" v-bind="componentProps" @objectDownloaded="updateShareStatus(ShareStatuses.DOWNLOADED)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, shallowRef } from 'vue'
import { useUserStore } from '@/stores/user.store'
import { useCompanyStore } from '@/stores/company.store'
import { useStatusStore } from '@/stores/status.store'
import { useAudienceStore } from '@/stores/audience.store'
import { useTagStore } from '@/stores/tag.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import type { Component } from 'vue'
import AssetForm from '@/components/FormAsset.vue'
import FolderForm from '@/components/FormFolder.vue'
import fileService from '@/services/api/file.service'
import { GetAssetByObjectID } from '@/services/graphql/asset.graphql'
import { GetFolderByObjectID } from '@/services/graphql/folder.graphql'
import { GetUserGrantedAccess, UpdateUserGrantedStatus } from '@/services/graphql/userGrantedAccess.graphql'
import { CreateShareActivityLog } from '@/services/graphql/shareActivityLog.graphql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { ShareStatuses, ObjectType } from '@/constants/Enums'
import { GenerateSasToken } from '@/composables/storageHelper'
import AppHeaderPage from './AppHeaderPage.vue'

// props
const props = defineProps<{
  objectID: number
  objectType: string
  shareID: number
}>()

// Define Store
const userStore = useUserStore()
const companyStore = useCompanyStore()
const statusStore = useStatusStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const assetTypeStore = useAssetTypeStore()

// Define variables
const logoUrl = ref('')
const companyId = ref(0)
const companyName = ref('')
const isSharedPage = ref(true)
const component = shallowRef<null | Component>()
const componentProps = shallowRef<any>({})
const userGrantedCurrentStatus = ref(0)
const userGrantedAccessID = ref(0)
const userEmail = ref('')
const objectTypeID = ref(0)

// Define Apollo queries and mutations
const { load: loadGetAssetByID, variables: variableAssetID, result: resultGetAssetByID } = useLazyQuery(GetAssetByObjectID)
const { load: loadGetFolderByID, variables: variableFolderID, result: resultGetFolderByID } = useLazyQuery(GetFolderByObjectID)
const { load: loadGetUserGrantedAccess, variables: variableUserGrantedAccess, result: resultGetUserGrantedAccess } = useLazyQuery(GetUserGrantedAccess)
const { mutate: mutateUserGrantedStatus } = useMutation(UpdateUserGrantedStatus)
const { mutate: mutateShareActivityLog } = useMutation(CreateShareActivityLog)

onMounted(async () => {
  // Setup userEmail.
  userEmail.value = userStore.getSharedValidatedUserEmail()
  await loadObjectInfo()
})

// Load object info
const loadObjectInfo = async () => {
  if (props.objectType === 'Asset') {
    await setAssetInfo()
  } else if (props.objectType === 'Folder') {
    await setFolderInfo()
  }

  await loadCompanyLogo()

  variableUserGrantedAccess.value = {
    shareID: props.shareID,
    userEmail: userEmail.value
  }
  await loadGetUserGrantedAccess(GetUserGrantedAccess, variableUserGrantedAccess.value, { context: { headers: { AnonymousAuthentication: 'true' } } })

  if (resultGetUserGrantedAccess.value && resultGetUserGrantedAccess.value.userGrantedAccess) {
    userGrantedCurrentStatus.value = resultGetUserGrantedAccess.value.userGrantedAccess.shareStatusID
    userGrantedAccessID.value = resultGetUserGrantedAccess.value.userGrantedAccess.userGrantedAccessID
  }

  await updateShareStatus(ShareStatuses.VIEWED)
}

const setAssetInfo = async () => {
  objectTypeID.value = ObjectType.ASSET

  variableAssetID.value = {
    objectID: props.objectID
  }
  await loadGetAssetByID(GetAssetByObjectID, variableAssetID.value, { context: { headers: { AnonymousAuthentication: 'true' } } })

  if (resultGetAssetByID.value && resultGetAssetByID.value.assetByObjectID) {
    companyId.value = resultGetAssetByID.value.assetByObjectID.object.companyID
    await loadCompanyLevelData()
    companyName.value = resultGetAssetByID.value.assetByObjectID.object.company.companyName

    componentProps.value = {
      assetId: resultGetAssetByID.value.assetByObjectID.assetID,
      isSharedPage: isSharedPage.value
    }
    component.value = AssetForm
  }
}

const setFolderInfo = async () => {
  objectTypeID.value = ObjectType.FOLDER

  variableFolderID.value = {
    objectID: props.objectID
  }
  await loadGetFolderByID(GetFolderByObjectID, variableFolderID.value, { context: { headers: { AnonymousAuthentication: 'true' } } })

  if (resultGetFolderByID.value && resultGetFolderByID.value.folderByObjectID) {
    companyId.value = resultGetFolderByID.value.folderByObjectID.object.companyID
    await loadCompanyLevelData()
    companyName.value = resultGetFolderByID.value.folderByObjectID.object.company.companyName

    componentProps.value = {
      folderId: resultGetFolderByID.value.folderByObjectID.folderID,
      isSharedPage: isSharedPage.value
    }
    component.value = FolderForm
  }
}

const loadCompanyLevelData = async () => {
  // load company level data
  await companyStore.loadCompanyData(companyId.value)
  await assetTypeStore.loadAssetTypesData(companyId.value)
  await audienceStore.loadAudiencesData(companyId.value)
  await statusStore.loadStatusesData(companyId.value)
  await tagStore.loadTagsData(companyId.value)
  GenerateSasToken(companyId.value)
}

// Load company logo
const loadCompanyLogo = async () => {
  // Load company logo
  fileService.getLogo(companyId.value).then((response) => {
    if (response.data) {
      logoUrl.value = response.data
    } else {
      logoUrl.value = ''
    }
  })
}

async function updateShareStatus(newShareStatusID: number) {
  if (userGrantedCurrentStatus.value < newShareStatusID) {
    await mutateUserGrantedStatus(
      {
        shareID: props.shareID,
        userEmail: userEmail.value,
        shareStatusID: newShareStatusID
      },
      { context: { headers: { AnonymousAuthentication: 'true' } } }
    )
  }

  await createActivityLog(newShareStatusID)
}

async function createActivityLog(newShareStatusID: number) {
  let event = ''
  switch (newShareStatusID) {
    case ShareStatuses.VIEWED:
      event = 'Viewed'
      break
    case ShareStatuses.DOWNLOADED:
      event = 'Downloaded'
      break
  }

  await mutateShareActivityLog(
    {
      userGrantedAccessID: userGrantedAccessID.value,
      event: event,
      objectID: props.objectID,
      objectTypeID: objectTypeID.value
    },
    { context: { headers: { AnonymousAuthentication: 'true' } } }
  )
}
</script>
