import { PublicClientApplication } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: '3ddbfac6-07da-4b32-bb96-dd58d2b79aeb',
    authority: 'https://prodifynet.ciamlogin.com/83ba9069-6491-4a8b-a89d-b8c3a2a0df4b/v2.0',
    domain: 'prodifynet.onmicrosoft.com',
    redirectUri: 'https://login.prodify.net/login-redirect', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/', // Must be registered as a SPA redirectURI on your app registration,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    temporaryCacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
    claimsBasedCachingEnabled: true,
  },
}
export const msalInstance = new PublicClientApplication(msalConfig)

// Scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
  code_challenge: 'code_challenge',
  response_type: 'code',
  state: btoa(window.location.href) // Encode the current URL as the state
}