import apiClient from "./main";

//api resource endpoint
const RESOURCE_NAME = 'api/otp';

export default {
    GenerateOtp(otpGenerationRequest : any) {
        let requestHeaders = { 'Accept': 'application/json', 'AnonymousAuthentication': 'true' }
        const config = { params: otpGenerationRequest, headers: requestHeaders }
        return apiClient.get(RESOURCE_NAME + '/generate', config);
    },
    ValidateOtp(otpValidationRequest: any) {
        let requestHeaders = { 'AnonymousAuthentication': 'true' }
        return apiClient.post(RESOURCE_NAME + '/validate', otpValidationRequest, { headers: requestHeaders });
    }
}
