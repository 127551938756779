import { reactive } from 'vue'
import { defineStore } from 'pinia'

export const useBreadcrumbsStore = defineStore('BreadcrumbsStore', () => {
    const defaultBreadcrumb = { icon: 'fas fa-folders', to: { name: 'folders'} }
    const data = reactive({
        breadcrumbs: [defaultBreadcrumb] as any[]
    })
    
    function setBreadcrumb(breadcrumb: any) {
        data.breadcrumbs.push(breadcrumb)
    }

    function removeBreadcrumbs(clickedLink: string) {
        // Get the index of the clicked link in the breadcrumbs array.
        const index = data.breadcrumbs.findIndex(breadcrumb => breadcrumb.link === clickedLink)

        if (index > -1) {   
            // Remove the clicked link and all breadcrumbs after the clicked link.
            data.breadcrumbs = data.breadcrumbs.slice(0, index + 1)
        }
    }

    function cleanBreadcrumbs() {
        data.breadcrumbs = [defaultBreadcrumb]
    }
    
    return {
        data,
        setBreadcrumb,
        removeBreadcrumbs,
        cleanBreadcrumbs
    }
})