export function findObjectByPropertyValue<T>(objects: any[], property: keyof T, propertyChildren: keyof T, nameValue: string): any {
    for (const object of objects) {
      // Check if the property value matches the value
      if (object[property] === nameValue) {
        return object
      }
      // If the object does not match, then check the children objects
      if (object[propertyChildren]) {
        const found = findObjectByPropertyValue(object[propertyChildren], property, propertyChildren, nameValue)
        if (found) {
          return found
        }
      }
    }
    
    return undefined
}

export function setCopyNumberOnTheName<T>(objects: any[], objectName: string, propertyName: keyof T, propertyChildren: keyof T) {
    // check if the object name has the copy number
    let match = /\((\d+)\)$/.exec(objectName)
    if (match) {
      // if yes, then increase the number by 1
      let number = parseInt(match[1])
      objectName = `${objectName.slice(0, match.index)}(${number + 1})`
    } else {
      // else add the copy number as (1).
      objectName = `${objectName} - (1)`
    }
  
    // check if the objectName with the copy number already exists.
    // If it does, then recursively call the function to increase the number.
    const existingObject = findObjectByPropertyValue<T>(objects, propertyName, propertyChildren, objectName)
    if (existingObject) {
      return setCopyNumberOnTheName(objects, objectName, propertyName, propertyChildren)
    }
  
    return objectName
  }