<template>
  <div v-if="modalData.fields">
    <!-- name/title form -->
    <form class="flex flex-col" @submit.prevent="onSubmit">
      <div class="flex flex-wrap w-full mt-2">
        <div v-for="field in modalData.fields" :key="field.id" class="w-full my-2">
          <IconField iconPosition="left">
            <InputIcon class="!text-black" :class="field.icon"> </InputIcon>
            <InputText class="w-full" :id="field.id" v-model="modalData[field.id]" :placeholder="field.placeholder" @input="() => validateField(field.id)" />
          </IconField>
        </div>
      </div>
      <div class="flex justify-between mt-6">
        <Button class="text-black hover:text-white" severity="contrast" outlined size="small" label="Cancel" @click="onCancel" />
        <Button class="text-black hover:text-white hover:disabled:text-black" size="small" :label="modalData.customLabels?.submitLabel" type="submit" :disabled="hasErrors" />
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import {ref, defineEmits, computed, onMounted, onBeforeMount, inject } from 'vue'
import _ from 'lodash'

// dialog
const dialogRef = inject('dialogRef') as any

// emits
const emits = defineEmits(['submit'])

const modalData = ref({} as any)

onBeforeMount(() => {
  modalData.value = _.cloneDeep(dialogRef.value.data)
})

// validation errors
const validationErrors = ref<Record<string, string>>({})

// computed hasErrors
const hasErrors = computed(() => {
  return Object.keys(validationErrors.value).length > 0
})

// Initial validation
onMounted(() => {
  validateSchema()
})

const validateField = (fieldId: string) => {
  const field = modalData.value.fields.find((f:any) => f.id === fieldId)
  if (!field) return

  const { required } = field
  const value = modalData.value[fieldId]

  if (required && (value === undefined || value === '')) {
    validationErrors.value[fieldId] = `${fieldId} is required`
  } else {
    delete validationErrors.value[fieldId]
  }
}

const validateSchema = () => {
  const errors: Record<string, string> = {}

  // validate only fields defined in modalData.value.fields
  for (const field of modalData.value.fields) {
    const { id, required } = field
    const value = modalData.value[id]
    if (required && (value === undefined || value === '')) {
      errors[id] = `${id} is required`
    }
  }

  // set validation errors
  validationErrors.value = errors
  return Object.keys(errors).length === 0
}

// submit
const onSubmit = () => {
  if (validateSchema()) {
    emits('submit', modalData.value)
    // emit close event (closing the modal)
    dialogRef.value.close()
  } else {
    // log validation errors
    alert(`Validation errors: ${JSON.stringify(validationErrors.value)}`)
  }
}

const onCancel = () => {
  // emit close event (closing the modal)
  dialogRef.value.close()
}

export interface FormModalData {
  fields: Array<{ label: string; id: string; type: string; required: boolean; placeholder: string; icon: string }>
  schema: Record<string, { type: string; required: boolean }>
  customLabels?: { submitLabel: string }
  data: Record<string, any>
}
</script>
