<template>
  <div class="flex flex-col p-5 gap-5">
    <AppHeaderPage>
      <template #title> Manage Users </template>
      <template #buttons>
        <Button size="small" label="Invite Users" severity="contrast" outlined class="text-black hover:text-white" @click="showInviteModal()"></Button>
        <Button size="small" :disabled="!isSaveReady" label="Save" class="text-black hover:text-white hover:disabled:text-black" @click="saveUsers()"></Button>
      </template>
    </AppHeaderPage>
    <UserTable
      :fields="['displayName', 'email', 'userStatus']"
      @changed="updateChanged($event)"
      @delete="deleteUser"
      @invite="sendInvite"
      :users="userList"
      :loading="isLoading"
      :super-admin="true"
    ></UserTable>
  </div>
  <DynamicDialog />
</template>

<script lang="ts">
import UserTable from '@/components/UserTable.vue'
import authService from '@/services/api/auth.service'
import { computed, onMounted, ref } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useNotificationStore } from '@/stores/notification.store'
import type { MailInvite } from '@/types/MailInviteType'
import type { GraphUserTableType } from '@/types/GraphUserType'
import { useDialog } from 'primevue/usedialog'
import { openUserInviteDialog } from '@/composables/dialogOptions'
import AppHeaderPage from '@/components/AppHeaderPage.vue'
import type { InviteUserData, LocalUser } from '@/components/AppModalFormUserInvite.vue'
import mailService from '@/services/api/sendgrid/mail.service'
import { CreateUserPolicy, PartialUpdateUserPolicy, DeleteUserPolicy } from '@/services/graphql/userPolicy.graphql'
import { CreateUser, GetSuperAdminUsers, UpdatePartialUser } from '@/services/graphql/user.graphql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'

export default {
  components: {
    UserTable,
    AppHeaderPage
  },
  setup() {
    // variables - user list
    const userList = ref([] as GraphUserTableType[])
    const getAdminListCalled = ref(false)
    const changedFields = ref<any[]>([])

    // notification store
    const notificationStore = useNotificationStore()

    // composable
    const confirm = useConfirm()
    const dialog = useDialog()

    const { mutate: createUserPolicyMutation } = useMutation(CreateUserPolicy)
    const { mutate: createUserMutation } = useMutation(CreateUser)
    const { mutate: updateUserPolicyMutation } = useMutation(PartialUpdateUserPolicy)
    const { mutate: updateUserMutation } = useMutation(UpdatePartialUser)
    const { mutate: deleteUserPolicyMutation } = useMutation(DeleteUserPolicy)
    const { refetch: refetchUserPolicy, load: loadGetByID, result: resultGetByID, variables: queryVariables } = useLazyQuery(GetSuperAdminUsers)

    // lifecycle
    const isLoading = ref(false)
    const isSaveReady = computed(() => changedFields.value.length > 0)

    onMounted(() => {
      // fetch the user list
      getAdminUserList()
    })

    // (private) methods
    async function getAdminUserList() {
      isLoading.value = true

      queryVariables.value = {
        companyID: null
      }

      if (getAdminListCalled.value) await refetchUserPolicy()
      else await loadGetByID()

      getAdminListCalled.value = true
      const users = resultGetByID.value?.superAdminUsers ?? []
      isLoading.value = false
      userList.value = users
      return users
    }
    const updateChanged = (data: any[]) => {
      changedFields.value = data
    }
    // (public) methods
    const deleteUser = (user: any) => {
      confirm.require({
        header: 'Remove User',
        message: `Are you sure you want to remove user ${user.displayName} from the SuperAdmin group?`,
        rejectProps: {
          label: 'Cancel',
          severity: 'contrast',
          outlined: true
        },
        acceptProps: {
          label: 'Yes, Delete',
          class: 'text-black hover:text-white hover:disabled:text-black'
        },
        accept: () => {
          authService
            .removeUserSuperAdminAsync(user.entraID)
            .then(() => {
              notificationStore.showSuccess('User Removed', `User ${user.displayName} has been removed from the Super Admin role`)
            })
            .catch(() => {
              notificationStore.showInfo('Error Removing User', `An error occurred while removing user ${user.displayName}, most likely already removed or not found`)
            })
            .finally(() => {
              deleteUserP(user.userPolicies[0].userPolicyID)
            })
        }
      })
    }

    function deleteUserP(userPolicyID: any) {
      deleteUserPolicyMutation({
        userPolicyID: userPolicyID
      }).then(() => {
        getAdminUserList()
      })
    }

    // show invite modal
    const showInviteModal = () => {
      // modal options
      const modalOptions = {
        data: {
          superAdmin: true
        },
        emits: {
          onSubmit: async (data: any) => {
            // local user grabbed from the invite modal if it exists
            const localUser = {
              email: data.inviteUserData?.email,
              userID: data.localUser?.userID
            } as LocalUser
            // flags to help with the invite process
            const flags = {
              localUserButNoEntraID: data.localUserButNoEntraID,
              entraUserExists: data.entraUserExists,
              localUserExists: data.localUserExists,
              policyExists: data.policyExists
            }
            // invite the user
            inviteUser(localUser, flags, data.inviteUserData)
          }
        }
      }
      // open the dialog
      openUserInviteDialog(dialog, modalOptions)
    }
    async function inviteUser(localUser: LocalUser, flags: any, inviteUserData: InviteUserData | null = null) {
      isLoading.value = true

      // create local user if they dont exist, this will be temporary
      if (!flags.localUserExists && inviteUserData) {
        let userInfo = {
          displayName: inviteUserData.displayName,
          email: inviteUserData.email,
          updatedOn: new Date(),
          createdOn: new Date()
        }
        // create local user
        await createLocalUser(userInfo).then((response) => {

          createPolicyAndEmail(flags, response)
        })
      } else if (flags.localUserExists && inviteUserData) {
        let userInfo = {
          displayName: inviteUserData.displayName,
          userID: localUser.userID,
          updatedOn: new Date()
        }
        // create local user
        await updateLocalUser(userInfo).then(() => {
          createPolicyAndEmail(flags, localUser)
        })
      }
    }
    async function createPolicyAndEmail(flags: any, localUser: any) {
      // CREATE POLICY
      if (!flags.policyExists && localUser) {
        const newPolicy = {
          userID: localUser.userID,
          userRoleID: 1,
          userStatusID: 1
        }
        const upData = await createUserPolicyMutation({ userP: newPolicy })

        // send email
        await sendCompanyInvite(localUser, upData?.data.createUserPolicy.userPolicyID)

        isLoading.value = false
        // refresh the user list
        getAdminUserList()
      }
    }
    // updates the local user
    async function updateLocalUser(localUser: any) {
      const newUser = {
        displayName: localUser.displayName,
        updatedOn: new Date()
      }

      try {
        const response = await updateUserMutation({ userID: localUser.userID, user: newUser })
        return response?.data.updateUser
      } catch (error) {
        console.error('Error updating local user:', error)
        notificationStore.showError('User Update Failed', `An error occurred while updating user ${localUser.displayName}`)
        return null
      }
    }

    function sendInvite(localUser: any) {
      // update the user policy (status 1 and disabled)
      updateUserPolicyMutation({ userPolicyID: localUser.userPolicies[0].userPolicyID, userPolicy: { userStatusID: 1, isEnabled: false } })
      sendCompanyInvite(localUser, localUser.userPolicies[0].userPolicyID)
    }

    // saves the user roles (called from @changed event in UserTable.vue)
    async function saveUsers() {
      isLoading.value = true
      // if other fields, update the user
      for (const field of changedFields.value) {
        if (field.field !== 'userRole') {
          const user = {
            [field.field]: field.newValue
          }
          await updateUserMutation({ userID: field.userID, user: user })
        }
      }

      // refresh the user list
      getAdminUserList()
    }

    //  Creates a local user in the DB
    async function createLocalUser(localUser: any) {
      const newUser = {
        email: localUser.email,
        firstName: localUser.firstName,
        lastName: localUser.lastName,
        displayName: localUser.displayName,
        updatedOn: new Date(),
        createdOn: new Date()
      }
      try {
        const response = await createUserMutation({ user: newUser })
        return response?.data.createUser
      } catch (error) {
        console.error('Error creating local user:', error)
        notificationStore.showError('User Creation Failed', `An error occurred while creating user ${newUser.displayName}`)
        return null
      }
    }

    function sendCompanyInvite(localUser: any, userPolicyID: any) {
      const invitationUrl = `${window.location.origin}/redeem-invitation`
      isLoading.value = true
      // send email using sendgrid
      const mailData: MailInvite = {
        InvitationUrl: invitationUrl,
        UserName: localUser.email,
        UserEmail: localUser.email,
        Organization: 'Prodify - Super Admin',
        Recipients: [
          {
            Email: localUser.email,
            Name: localUser.email
          }
        ],
        Redirect: window.location.origin,
        UserPolicyID: userPolicyID,
        IsSuperAdmin: true
      }
      mailService
        .sendCompanyInviteMail(mailData)
        .then(() => {
          isLoading.value = false
          notificationStore.showSuccess('Invite Sent', `An invite has been sent to ${localUser.email}`)
        })
        .catch(() => {
          isLoading.value = false
          notificationStore.showError('Invite Failed', `An error occurred while sending an invite to ${localUser.email}`)
        })
        .finally(() => {
          // fetch the user list
          getAdminUserList()
        })
    }

    return {
      // pass the methods
      deleteUser,
      showInviteModal,
      sendInvite,
      saveUsers,
      updateChanged,
      isSaveReady,
      // pass the user list to the table
      userList,
      isLoading,
      // testing
      getAdminUserList
    }
  }
}
</script>
