<template>
  <div v-if="fieldMode === 'view'">
    <template v-if="model && model.length > 0">
      <!--View Mode-->
      <div class="flex gap-1">
        <template v-if="model.length > tagNumber">
          <div class="tag rounded-full px-2 flex gap-1 items-center bg-gray-200" :style="model[0].colour ? 'background-color:' + model[0].colour : ''">
            <span>{{ model[0].name }}</span>
          </div>
          <div class="moreTag rounded-full px-2 flex gap-1 items-center bg-gray-200">
            <span v-tooltip="getTooltipValues(model)">+{{ model.length - 1 }} more</span>
          </div>
        </template>
        <template v-else>
          <template v-for="(value, index) in model" :key="index">
            <div class="tag rounded-full px-2 flex gap-1 items-center bg-gray-200" :style="value.colour ? 'background-color:' + value.colour : ''">
              <span>{{ value.name }}</span>
            </div>
          </template>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="h-6"></div>
    </template>
  </div>
  <div v-else>
    <MultiSelect v-model="model" display="chip" :dataKey="selectID" :options="localOptions" optionLabel="name" class="w-full">
      <template #chip="{ value }">
        <div class="flex">
          <div class="tag rounded-full px-2 flex gap-1 items-center bg-gray-200" :style="value.colour ? 'background-color:' + value.colour : ''">
            <span>{{ value.name }}</span>
            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" @click.stop="deselect(value)"></FontAwesomeIcon>
          </div>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex w-full justify-between">
          <div class="tag rounded-full px-2 bg-gray-200" :style="option.colour ? 'background-color:' + option.colour : ''">
            <span>{{ option.name }}</span>
          </div>
          <div v-if="!isFolders" class="item-menu-icon" @click.stop="showEditOptionPop(option, $event)">
            <FontAwesomeIcon icon="fa-regular fa-ellipsis"></FontAwesomeIcon>
          </div>
        </div>
      </template>
      <template #footer v-if="!isFolders">
        <div class="flex p-2">
          <Button label="Add Option" size="small" class="w-full text-black hover:text-white" @click.stop="showNewOptionPop"></Button>
        </div>
      </template>
    </MultiSelect>
    <template v-if="!isFolders">
      <Popover ref="opNew">
        <DataFieldPopoverNewOption @newOptionAdded="addOption" />
      </Popover>
      <Popover ref="opEdit">
        <DataFieldPopoverEditOption :editingOption="editingOption" @saveOption="saveOption" @deleteOption="deleteOption" />
      </Popover>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch } from 'vue'
  import DataFieldPopoverNewOption from './DataFieldPopoverNewOption.vue';
  import DataFieldPopoverEditOption from './DataFieldPopoverEditOption.vue';
  import { createOptionInStore, updateOptionInStore, deleteOptionInStore } from '@/composables/optionUpdater';
  import { useNotificationStore } from '@/stores/notification.store';

  
  // Notification Store
  const notificationStore = useNotificationStore()

  // v-model
  const model = defineModel<any[]>() // The data for the field

  const emits = defineEmits(['update:modelValue'])

  // Props
  const props = defineProps<{
    fieldMode: string,  // The mode of the field (EG: view, edit)
    fieldParent: string   // The type of the field (EG: table, card, form)
    selectID: string    // The ID to use for the field and options
    options: any[]
  }>()
  
  // local variables
  const opNew = ref()
  const opEdit = ref()
  const editingOption = ref()

  const localOptions = computed(() => {
    return props.options.map((option) => {
      return {
        ...option,
        selected: model.value ? model.value.some((selectedOption: any) => selectedOption[props.selectID] === option[props.selectID]) : false
      }
    })
  })
  const dataType = computed(() => props.selectID.replace('ID', ''))
  const isFolders = computed(() => dataType.value === 'folder') // Note, we should handle folders and other relationships better later on, but for now this will work.
  // set the number of visible options to display based on the parent (form or table)
  const tagNumber = computed(() => {
    return props.fieldParent == 'form' ? 4 : 2
  })

  // When the model changes, sort it by the selectID
  watch(model, (newValue) => {
    if(newValue) {
      newValue.sort((a, b) => a[props.selectID] - b[props.selectID])
    }
  })

  // Get the values for the tooltip by removing the first value and joining the rest
  function getTooltipValues(valueArray: any) {
    let trimmedValueArray = valueArray.slice(1)
    return trimmedValueArray.map((value: any) => value.name).join(',\n')
  }

  // Manually deselect an option (called from the 'x' button on the chip)
  function deselect(option: any) {
    if(model.value && localOptions?.value) {
      const index = model.value.findIndex((selectedOption: any) => selectedOption[props.selectID] === option[props.selectID])
      model.value.splice(index, 1)
      const optionIndex = localOptions.value.findIndex((localOption: any) => localOption[props.selectID] === option[props.selectID])
      localOptions.value[optionIndex].selected = false
      emits('update:modelValue', model.value)
    }
  }

  // Toggle the new option popover
  function showNewOptionPop(event: any) {
    opNew.value.toggle(event)
  }
  // hide the new option popover
  function hideNewOptionPop() {
    opNew.value.hide()
  }

  // Toggle the edit option popover
  function showEditOptionPop(option: any, event: any) {
    editingOption.value = option
    opEdit.value.toggle(event)
  }

  // Add a new option to the store
  function addOption(option: any) {
    createOptionInStore(option, dataType.value).then((result) => {
      if (result && model.value) {
        // find the new option in the localOptions using the id from the result
        const newOption = localOptions.value.find((localOption: any) => localOption[props.selectID] === result)
        // add the new option to the model
        model.value.push(newOption)
        hideNewOptionPop()
        emits('update:modelValue', model.value)
      }
    }).catch((error) => {
      notificationStore.showError('Error creating new option', 'Error')
      console.error(error)
    })
  }

  // Save an option to the store
  function saveOption(option: any) {
    // update the option in the store.  don't show a notifcation as it happens automatically.
    updateOptionInStore(option, dataType.value).then((result) => {
      // after updating the option, if that option is in the model array, update it to the new value
      if (model.value) {
        const index = model.value.findIndex((selectedOption: any) => selectedOption[props.selectID] === result)
        if(index > -1) {
          model.value[index] = localOptions.value.find((localOption: any) => localOption[props.selectID] === result)
        }
      }
    }).catch((error) => {
      notificationStore.showError('Error updating option', 'Error')
      console.error(error)
    })
  }

  // Delete an option from the store
  function deleteOption(option: any) {
    deleteOptionInStore(option, dataType.value).then((result) => {
      if (result && model.value) {
        // check if the model array contains an object with the same id as the result
        const index = model.value.findIndex((selectedOption: any) => selectedOption[props.selectID] === result)
        if(index > -1) {
          model.value.splice(index, 1)
          emits('update:modelValue', model.value)
        }
        notificationStore.showSuccess('Option deleted', 'Success')
      }
    }).catch((error) => {
      notificationStore.showError('Error deleting option', 'Error')
      console.error(error)
    })
  }
</script>

<style scoped>
  .item-menu-icon {
    display: none;
  }

  .p-multiselect-option:hover .item-menu-icon {
    display: block;
  }
</style>