<template>
  <Editor v-model="model" >
    <template v-slot:toolbar>
      <span class="ql-formats">
        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
        <button v-tooltip.bottom="'Strikethrough'" class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <button v-tooltip.bottom="'Ordered List'" class="ql-list" value="ordered"></button>
        <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
      </span>
      <span class="ql-formats">
        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
      </span>
    </template>
  </Editor>
</template>

<script setup lang="ts">
  const model = defineModel()
</script>