<template>
  <div v-if="normalizedModel && normalizedModel.length > 0" class="flex">
    <!--Table Field-->
    <template v-if="fieldMode === 'view'">
      <!--View Mode-->
      <div class="flex gap-3">
        <template v-if="normalizedModel.length > tagNumber">
          <div class="flex gap-1 items-center">
            <FontAwesomeIcon :icon="getIconForRelationship()"/>
            <span class="tag text-left line-clamp-1 underline underline-offset-2">{{ getLabelForRelationship(normalizedModel[0]) }}</span>
          </div>
          <div class="rounded-full px-2 flex gap-1 items-center bg-gray-200">
            <span class="moreTag" v-tooltip="getTooltipValues(normalizedModel)">+{{ normalizedModel.length - 1 }} more</span>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-1 items-center" v-for="item in normalizedModel" :key="item">
            <FontAwesomeIcon :icon="getIconForRelationship()"/>
            <span class="tag text-left line-clamp-1 underline underline-offset-2">{{ getLabelForRelationship(item) }}</span>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <!--Edit Mode-->
      <InputText placeholder="NOT IMPLEMENTED" size="small" class="w-full"/>
    </template>
  </div>
  <div v-else>
    <!--Empty-->
    <div class="h-6"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

  // v-model
  const model = defineModel() // The data for the field
  const tagNumber = computed(() => {
    return props.fieldParent == 'form' ? 4 : 2
  })

  const normalizedModel = computed(() => {
    return Array.isArray(model.value) ? model.value : [model.value]
  })

  // Props
  const props = defineProps<{
    fieldMode: string,  // The mode of the field (EG: view, edit)
    fieldParent: string   // The type of the field (EG: table, card, form)
    relationshipType: string  // The type of relationship (EG: user, folder, etc)
  }>()

  // Get the values for the tooltip by removing the first value and joining the rest
  function getTooltipValues(valueArray: any) {
    let trimmedValueArray = valueArray.slice(1)
    return trimmedValueArray.map((value: any) => value.name).join(',\n')
  }

  function getLabelForRelationship(item: any) {
    if(!item) return ''
    switch(props.relationshipType) {
      case 'user':
        return item.firstName + ' ' + item.lastName
      case 'folder':
        return item.name
      case 'asset':
        return item.name
      default:
        return item
    }
  }

  function getIconForRelationship() {
    switch(props.relationshipType) {
      case 'user':
        return 'fa-regular fa-user'
      case 'folder':
        return 'fa-regular fa-folder'
      case 'asset':
        return ''
      default:
        return ''
    }
  }

</script>
