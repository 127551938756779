<template>
  <!-- thumbnail -->
  <div class="flex w-full h-full" v-if="type == 'thumbnail'">
    <!-- video ~ thumbnail -->
    <video
      v-if="IsVideoFile(object['blobUrl']) && !hasError"
      :src="getPreviewUrl(object.blobUrl, object.object.companyID)"
      alt="Asset Preview"
      class="w-full !mx-auto cursor-pointer h-12 w-12"
      @click="openFullPreview()"
      disablePictureInPicture
      @error="hasError = true"
    />
    <!-- pdf ~ thumbnail  -->
    <span v-else-if="IsPDFFile(object['blobUrl'])" class="!mx-auto h-12 flex !my-auto">
      <FontAwesomeIcon icon="fa-light fa-file-pdf" class="cursor-pointer hover:text-primary-600 !mx-auto !h-1/2 w-12 !my-auto" @click="openFullPreview()"></FontAwesomeIcon>
    </span>
    <!-- document ~ thumbnail  -->
    <span v-else-if="IsDocumentFile(object['blobUrl'])" class="!mx-auto h-12 flex !my-auto">
      <FontAwesomeIcon icon="fa-light fa-file-word" class="cursor-pointer hover:text-primary-600 !mx-auto !h-1/2 w-12 !my-auto" @click="openFullPreview()"></FontAwesomeIcon>
    </span>
    <!-- image ~ thumbnail (fallback to blobUrl) -->
    <img
      :src="getPreviewUrl(object.previewURL, object.object.companyID)"
      alt="Asset Preview"
      class="!mx-auto object-contain cursor-pointer hover:text-primary-600 p-0 h-12 w-12"
      v-else-if="IsImageFile(object.previewURL) && object.previewURL && !hasError"
      @click="openFullPreview()"
      @error="hasError = true"
    />
    <!-- static ~ thumbnail-->
    <img
      :src="getPreviewUrl(object.blobUrl, object.object.companyID)"
      alt="Asset Preview"
      class="!mx-auto object-contain cursor-pointer hover:text-primary-600 p-0 h-12 w-12"
      v-else-if="IsStaticImageFile(object.blobUrl) && !object.previewURL && !hasError"
      @click="openFullPreview()"
      @error="hasError = true"
    />
    <!-- if no preview available show no preview icon (error loading) -->
    <span v-else-if="hasError" v-tooltip.top="'No Preview Available'" class="!mx-auto h-12 flex !my-auto">
      <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto p-0 !h-1/2 w-12 !my-auto"></FontAwesomeIcon>
    </span>
    <!-- if no preview available show no preview icon -->
    <span v-else v-tooltip.top="'No Preview Available'" class="!mx-auto h-12 flex !my-auto">
      <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto p-0 !h-1/2 w-12 !my-auto"></FontAwesomeIcon>
    </span>
  </div>
  <!-- full preview (single asset) -->
  <div class="flex w-full" v-else-if="type == 'full'">
    <!-- video ~ full -->
    <video v-if="IsVideoFile(object['blobUrl'])" preload="metadata" :src="videoUrl" alt="Asset Preview" class="w-full !mx-auto" controls autoplay />
    <!-- pdf ~ full  -->
    <ObjectPreviewPDFViewer v-else-if="IsPDFFile(object['blobUrl'])" :pdfLink="getPreviewUrl(object.blobUrl, object.object.companyID)" />
    <!-- document ~ full  -->
    <ObjectPreviewDocViewer v-else-if="IsDocumentFile(object['blobUrl'])" :docUrl="getPreviewUrl(object.blobUrl, object.object.companyID)" />
    <!-- image ~ full -->
    <img
      :src="getPreviewUrl(object.blobUrl, object.object.companyID)"
      alt="Asset Preview"
      class="!mx-auto object-contain cursor-pointer w-full max-h-[75vh] p-5"
      v-else-if="(object['blobUrl'] && IsImageFile(object['blobUrl'])) || (IsStaticImageFile(object.blobUrl) && !hasError)"
      @error="hasError = true"
    />
    <!-- if no preview available show no preview icon (error loading) -->
    <span v-else-if="hasError" v-tooltip.top="'No Preview Available'" class="!mx-auto">
      <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto"></FontAwesomeIcon>
    </span>
    <!-- if no preview available show no preview icon -->
    <span v-else v-tooltip.top="'No Preview Available'" class="!mx-auto">
      <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto"></FontAwesomeIcon>
    </span>
  </div>
  <!-- Dialog -->
  <Dialog
    v-model:visible="showFullPreview"
    modal
    dismissable-mask
    :style="{ width: '60vw' }"
    :pt="{ header: { class: 'pb-0 p-0' }, content: { class: 'p-0' }, root: { class: 'rounded-none border-0 ring-2  ring-primary-600 shadow-2xl' } }"
    :class="{ 'rounded-xl': !IsDocumentFile(object['blobUrl']) }"
    :closable="false"
  >
    <div class="flex w-full h-full rounded-xl" v-if="showFullPreview">
      <!-- video ~ full -->
      <video v-if="IsVideoFile(object['blobUrl'])" :src="videoUrl" alt="Asset Preview" class="w-full !mx-auto h-full rounded-xl" controls autoplay preload="auto" />
      <!-- pdf ~ full  -->
      <ObjectPreviewPDFViewer v-else-if="IsPDFFile(object['blobUrl'])" :pdfLink="getPreviewUrl(object.blobUrl, object.object.companyID)" />
      <!-- document ~ full  -->
      <ObjectPreviewDocViewer v-else-if="IsDocumentFile(object['blobUrl'])" :docUrl="getPreviewUrl(object.blobUrl, object.object.companyID)" />
      <!-- image ~ full -->
      <img
        :src="getPreviewUrl(object.blobUrl, object.object.companyID)"
        alt="Asset Preview"
        class="h-full !mx-auto object-contain w-full max-h-[75vh] p-5"
        v-else-if="(object['blobUrl'] && IsImageFile(object['blobUrl'])) || IsStaticImageFile(object.blobUrl)"
        @error="hasError = true"
      />
      <!-- if no preview available show no preview icon (error loading) -->
      <span v-else-if="hasError" v-tooltip.top="'No Preview Available'" class="!mx-auto">
        <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto"></FontAwesomeIcon>
      </span>
      <!-- if no preview available show no preview icon -->
      <span v-else v-tooltip.top="'No Preview Available'" class="!mx-auto">
        <FontAwesomeIcon icon="fa-light fa-image-slash" class="!mx-auto"></FontAwesomeIcon>
      </span>
    </div>
  </Dialog>
</template>

<script swetup lang="ts" setup>
import type { Asset } from '@/types/AssetType'
import type { Folder } from '@/types/FolderType'
import { IsDocumentFile, IsImageFile, IsPDFFile, IsVideoFile, IsStaticImageFile } from '@/composables/storageHelper'
import { onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user.store'
import ObjectPreviewDocViewer from './ObjectPreviewDocViewer.vue'
import axios from 'axios'

const userStore = useUserStore()
const videoUrl = ref('')
const hasError = ref(false)

// Get the preview URL for the asset
function getPreviewUrl(previewUrl: string, companyID: number) {
  const container = `prdf-${companyID}`
  const sasToken = userStore.getStorageToken(companyID)
  const url = `${import.meta.env.VITE_BLOB_URL.replace('{0}', container).replace('{1}', previewUrl).replace('{2}', sasToken)}`
  return url
}

// Load the video into a blob URL to avoid sas token issues
async function getVideoUrl(blobUrl: string, companyID: number) {
  const container = `prdf-${companyID}`
  const sasToken = userStore.getStorageToken(companyID)
  const url = `${import.meta.env.VITE_BLOB_URL.replace('{0}', container).replace('{1}', blobUrl).replace('{2}', sasToken)}`

  try {
    // Fetch the video data from the blob URL using Axios with response type 'arraybuffer'
    const response = await axios.get(url, {
      responseType: 'arraybuffer', // Set response type to 'arraybuffer' to get binary data
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })

    // Convert the ArrayBuffer into a Blob
    const videoBlob = new Blob([response.data], { type: 'video/mp4' })

    // Create a blob URL to use as the video source
    const videoBlobUrl = URL.createObjectURL(videoBlob)

    videoUrl.value = videoBlobUrl
  } catch (error) {
    console.error('Error fetching video as ArrayBuffer:', error)
    return ''
  }
}
const props = defineProps<{
  object: Asset | Folder
  type?: string
}>()

const showFullPreview = ref(false)

onMounted(() => {
  if (props.type == 'full') {
    getVideoUrl(props.object.blobUrl, props.object.object.companyID)
  }
})

const openFullPreview = () => {
  showFullPreview.value = true
  getVideoUrl(props.object.blobUrl, props.object.object.companyID)
}
</script>
