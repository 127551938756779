<template>
  <Menubar v-if="selectedObjects.length > 0" :model="items" class="gap-4 z-20" pt:root:class="px-4 py-0">
    <template #start>
      <span class="cursor-pointer hover:text-primary-600" @click="$emit('clearSelectedObjects')">
        <FontAwesomeIcon icon="fa-solid fa-xmark" class="text-base" />
        <span class="text-base ml-2">{{ selectedObjectsText }}</span>
      </span>
    </template>
    <template #item="{ item, props, hasSubmenu }">
      <span class="cursor-pointer hover:text-primary-600" v-bind="props.action">
        <FontAwesomeIcon :icon="item.icon" class="text-base w-5" :class="item.iconClass" />
        <span class="text-base">{{ item.label }}</span>
        <FontAwesomeIcon v-if="hasSubmenu" icon="fa-solid fa-chevron-down" class="text-base ml-auto" />
      </span>
    </template>
  </Menubar>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Folder } from '@/types/FolderType'
import type { Asset } from '@/types/AssetType'

const props = defineProps<{
  items: any[]
  selectedObjects: Folder[] | Asset[]
}>()

defineEmits(['clearSelectedObjects'])

//Selected Objects text
const selectedObjectsText = computed(() => {
  return Object.keys(props.selectedObjects).length + ' Selected'
})
</script>

<style scoped>
:deep(.p-menubar-item-content:hover) {
  background: none;
}
</style>
