<template>
  <div>
    <Tree class="bg-black" :value="nodes" v-model:selectionKeys="selectedKey" selectionMode="single" @node-select="onNodeSelect" @node-unselect="onNodeUnselect" :pt="treeStyles">
      <template #default="{ node }">
        <div class="flex justify-between items-center w-full">
          <div class="max-w-32 line-clamp-1 mr-4">{{ node.label }} <FontAwesomeIcon v-if="IsFavorite(node.data.object)" icon="fa-solid fa-star" /></div>
          <div class="flex space-x-3 items-end">
            <Button
              v-if="props.hasEditPermission"
              class="w-fit h-0 text-white hover:bg-primary-emphasis"
              icon="far fa-ellipsis"
              text
              @click.stop="openObjectActionMenu($event, node)"
              aria-haspopup="true"
              aria-controls="overlay_menu"
            ></Button>
            <Button
              v-if="props.hasEditPermission"
              class="w-fit h-0 text-white hover:bg-primary-emphasis"
              icon="fas fa-plus"
              text
              @click.stop="createNewChildObject($event, node.key)"
            ></Button>
          </div>
        </div>
      </template>
    </Tree>
    <Menu
      ref="menu"
      id="overlay_menu"
      :model="menuItems"
      :popup="true"
      :pt="{ root: { class: ['bg-black', 'text-white'] }, itemContent: { class: ['text-white', 'hover:bg-primary-emphasis'] }, itemIcon: { class: ['text-white'] } }"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, defineEmits, onBeforeMount, watch } from 'vue'
import Tree from 'primevue/tree'
import type { TreePassThroughOptions } from 'primevue/tree'
import type { TreeNode } from '@/types/TreeTypes'
import { IsFavorite } from '@/composables/objectHelpers'

// Props
const props = defineProps<{
  data: TreeNode[]
  selectedNodeId: number
  hasEditPermission: boolean
}>()

// Stores
const favLabel = ref('Favorite');

// Emit
const emit = defineEmits(['createNewChildObject', 'filterListBySelectedNodeObject', 'openRenameObjectModal', 'openDuplicateObjectModal', 'openShareObjectModal', 'trashFoldersConfirm', 'favoriteFolder', 'unfavoriteFolder'])

const nodes = computed(() => {
  return props.data
})

const selectedKey = ref(null) as any
const selectedNodeId = ref(props.selectedNodeId) as any
const selectedObject = ref(null) as any

const updateFavLabel = () => {
  if (selectedObject.value && IsFavorite(selectedObject.value.object)) {
    favLabel.value = 'Unfavorite'
  } else {
    favLabel.value = 'Favorite'
  }
}


watch(selectedObject, updateFavLabel);

const menu = ref()
const menuItems = computed(() => [
  {
    label: favLabel.value,
    icon: favLabel.value === 'Favorite' ? 'fa-solid fa-star' : 'fa-regular fa-square-minus',
    command: () => {
      //emit the event based on whether or not the user has the folder favorited
      if(favLabel.value === 'Unfavorite')
        emit('unfavoriteFolder', selectedObject.value);
      else
        emit('favoriteFolder', selectedObject.value);
    }
  },
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: () => {
      emit('openShareObjectModal', selectedObject.value)
    }
  },
  {
    label: 'Delete',
    icon: 'fa-regular fa-trash-can',
    command: () => {
      emit('trashFoldersConfirm', selectedObject.value)
    }
  },
  {
    label: 'Duplicate',
    icon: 'fa-regular fa-copy',
    command: () => {
      emit('openDuplicateObjectModal', selectedObject.value)
    }
  },
  {
    label: 'Rename',
    icon: 'fa-regular fa-pen',
    command: () => {
      emit('openRenameObjectModal', selectedObject.value)
    }
  }
])

onBeforeMount(() => {
  if (selectedNodeId.value && selectedNodeId.value !== 0) {
    selectedKey.value = { [selectedNodeId.value]: true }
  }
})

const onNodeSelect = (event: any) => {
  selectedKey.value = { [event.key]: true }
  emit('filterListBySelectedNodeObject', event.key)
}

const onNodeUnselect = () => {
  selectedKey.value = {}
  emit('filterListBySelectedNodeObject', null)
}

const createNewChildObject = (event: any, key: number) => {
  emit('createNewChildObject', event, key)
}

const openObjectActionMenu = (event: any, node: any) => {
  selectedKey.value = { [node.key]: true }
  selectedNodeId.value = node.key
  selectedObject.value = node.data
  menu.value.toggle(event)
}

const treeStyles: TreePassThroughOptions = {
  nodeContent: (options) => ({
    class: ['text-white', 'sm:hover:bg-primary-emphasis', 'cursor-pointer', { 'bg-primary-emphasis': options.context.selected, 'bg-black': !options.context.selected }]
  }),
  nodeLabel: {
    class: ['w-full']
  },
  nodeToggleButton: {
    class: ['text-white', 'hover:bg-primary-emphasis']
  }
}
</script>
