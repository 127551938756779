import fileService from '@/services/api/file.service';
import { useUserStore } from '@/stores/user.store';
export const GenerateSasToken = async (companyID: number) => {
    try {
        // Check to see if the current SAS token is still valid for this company
        const userStore = useUserStore();
        const storageToken = userStore.StorageTokens.find((t: any) => t.companyID === companyID);
        if (storageToken && new Date(storageToken.expiresOn) > new Date()) {
            return storageToken.token;
        }

        const response = await fileService.getStorageToken(companyID);
        const sasToken = response.data.sasToken;
        const expiresOn = new Date(response.data.expiresOn).toISOString();

        userStore.addStorageToken({
            token: sasToken,
            expiresOn: expiresOn,
            companyID: companyID,
        });

        return sasToken;
    } catch (error) {
        console.error('Error generating SAS token', error);
        return null;
    }
};


export const IsImageFile = (path: string = '') => {
    if (!path) return false;
    const ext = path.split('.').pop() as string;
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(ext);
}

// images that don't have a seperate preview
export const IsStaticImageFile = (path: string = '') => {
    if (!path) return false;
    const ext = path.split('.').pop() as string;
    return ['svg'].includes(ext);
}

export const IsVideoFile = (path: string = '') => {
    if (!path) return false;
    const ext = path.split('.').pop() as string;
    return ['mp4', 'avi', 'mov', 'mkv', 'webm'].includes(ext);
}

export const IsDocumentFile = (path: string = '') => {
    if (!path) return false;
    const ext = path.split('.').pop() as string;
    return ['doc', 'docx'].includes(ext);
}

export const IsPDFFile = (path: string = '') => {
    if (!path) return false;
    return path.endsWith('.pdf');
}

export const IsPreviewableFile = (path: string = '') => {
    if (!path) return false;
    return IsImageFile(path) || IsVideoFile(path) || path.endsWith('.pdf') || path.endsWith('.doc') || path.endsWith('.docx');
}

export const getPreviewURL = (path: string = '', companyId: number) => {
    const userStore = useUserStore();
    const container = `prdf-${companyId}`
    const encodedPreviewUrl = path
        .split('/')
        .map((part) => encodeURIComponent(part))
        .join('/')
    const sasToken = userStore.getStorageToken(companyId)
    const url = `${import.meta.env.VITE_BLOB_URL.replace('{0}', container).replace('{1}', encodedPreviewUrl).replace('{2}', sasToken)}`
    return url
}
