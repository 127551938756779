import * as yup from 'yup';

export const changeEmailSchema = yup.object().shape({
    NewEmail: yup.string().when('isEmailDisplayed', (isEmailDisplayed, schema) => {
        if (isEmailDisplayed) {
            return schema
                .required()
                .test('New Email', 'New Email is required', (value) => {
                    if (!value) {
                        return createValidationError('NewEmail', 'New Email is required');
                    }
                    return true;
                })
                .test('New Email', 'New Email must be a valid email', (value) => {
                    if (value && !yup.string().email().isValidSync(value)) {
                        return createValidationError('NewEmail', 'New Email must be a valid email');
                    }
                    return true;
                });
        }
        return schema;
    }),
    Otp: yup.string().when('isEmailDisplayed', (isEmailDisplayed, schema) => {
        if (!isEmailDisplayed) {
            return schema
                .required()
                .test('Otp', 'Otp is required', (value) => {
                    if (!value) {
                        return createValidationError('Otp', 'Otp is required');
                    }
                    return true;
                });
        }
        return schema;
    }),
    isEmailDisplayed: yup.boolean().required()
})

// Helper function to create a validation error
const createValidationError = (path: string, message: string) => {
    return new yup.ValidationError({
        path,
        message,
    } as yup.ValidationError);
};
