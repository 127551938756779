import type { Folder } from '@/types/FolderType'
import type { TreeNode } from '@/types/TreeTypes'

export function adjustSubfolders(folders: Folder[]): Folder[] {
  const finalFolder = [] as Folder[]
  const folderMap = new Map<number, Folder>()

  // First, add all folders to the map and final list
  for (const folder of folders) {
    folder.subFolders = [] // Initialize the subFolders list
    folderMap.set(folder.folderID, folder)
    finalFolder.push(folder)
  }

  // Next, create the hierarchy of subFolders
  for (const folder of folders) {
    if (folder.parentFolderID !== null) {
      const parentFolder = folderMap.get(folder.parentFolderID)
      if (parentFolder) {
        parentFolder.subFolders.push(folder)
      }
    }
  }

  // Finally, run through the finalFolder and add the numberOfAssociatedObjects
  setAssociatedObjectsToFolders(finalFolder)

  return finalFolder
}

export function setAssociatedObjectsToFolders(folders: Folder[]) {
  for (const folder of folders) {
    folder.associatedObjects = ''

    if (folder.assets?.length > 0) {
      folder.associatedObjects = `${folder.assets.length} Asset${folder.assets.length > 1 ? 's' : ''}`
    }

    if (folder.subFolders?.length > 0) {
      const subFolders = `${folder.subFolders.length} Folder${folder.subFolders.length > 1 ? 's' : ''}`
      if (folder.associatedObjects !== '') {
        folder.associatedObjects += ', ' + subFolders
      } else {
        folder.associatedObjects = subFolders
      }
    }
  }
}

// Map folder objects to TreeNode objects (used to display folders in the objectTree component)
export function MapFoldersToTreeNodes(folders: Folder[]) {
  if (folders && folders.length > 0) {
    const treeNodeMap = new Map<number, TreeNode>()
    const tree = [] as TreeNode[]

    // create a map of folder TreeNodes using the FolderID as the key
    folders.forEach((folder) => {
      treeNodeMap.set(folder.folderID, {
        key: folder.folderID,
        label: folder.name,
        data: folder,
        type: 'Folder',
        children: [] as TreeNode[]
      } as TreeNode)
    })

    // Build a tree structure from the folder map by associating children with parents
    folders.forEach((folder) => {
      const mappedNode = treeNodeMap.get(folder.folderID)
      if (mappedNode) {
        if (folder.parentFolderID === null) {
          // No parent, it's a root folder
          tree.push(mappedNode)
        } else {
          // Has a parent, add it to the parent's children array
          const parentNode = treeNodeMap.get(folder.parentFolderID)
          if (parentNode?.children) {
            parentNode.children.push(mappedNode)
          }
        }
      }
    })

    return tree
  } else {
    return []
  }
}
