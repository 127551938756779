<template>
  <IconField iconPosition="left" class="w-full relative" v-if="!mask && placeholder">
    <!-- Icon -->
    <InputIcon>
      <FontAwesomeIcon :icon="props.icon"></FontAwesomeIcon>
    </InputIcon>
    <!-- Input with Placeholder -->
    <InputText v-model="localModelValue" :label="label" :required="required" :class="{ 'error-field': hasError }" :disabled="disabled" class="w-full" />
  </IconField>
  <!-- Input with Floating Label -->
  <IconField iconPosition="left" v-else-if="label && !placeholder" class="w-full">
    <FloatLabel>
      <!-- Icon -->
      <InputIcon v-if="!disabled">
        <FontAwesomeIcon :icon="props.icon"></FontAwesomeIcon>
      </InputIcon>
      <!-- Input Field -->
      <InputText
        v-model="localModelValue"
        :label="label"
        :required="required"
        :placeholder="placeholder"
        :class="{ 'error-field': hasError, 'pl-10': !disabled }"
        :disabled="disabled"
        :type="type"
        :id="labelId"
        class="w-full peer"
      />
      <!-- Label -->
      <label :for="labelId" class="text-sm ml-6 text-muted-color-emphasis transition-all pointer-events-none duration-200 peer-focus:!ml-0" :class="{ '!ml-0': localModelValue }">
        {{ label }}
      </label>
    </FloatLabel>
  </IconField>

  <!-- InputMask Component -->
  <InputMask
    v-else
    v-model="localModelValue"
    :mask="mask"
    :label="label"
    :required="required"
    :placeholder="placeholder"
    :class="{ 'error-field': hasError }"
    :disabled="disabled"
    class="w-full"
  />

  <!-- Error Message -->
  <small v-if="hasError" class="p-error">{{ errorMessage }}</small>
</template>
<script setup lang="ts">
import { defineEmits, ref, watch } from 'vue'

const emits = defineEmits(['update:modelValue', 'input'])

const props = defineProps({
  modelValue: String,
  label: {
    type: String,
    required: true
  },
  labelId: {
    type: String,
    required: false
  },
  icon: {
    type: String,
    default: 'fa-regular fa-pen'
  },
  required: Boolean,
  placeholder: String,
  errorMessage: String,
  hasError: Boolean,
  changedFields: Boolean,
  disabled: Boolean,
  mask: String,
  type: {
    type: String,
    default: 'text'
  }
})

const localModelValue = ref(props.modelValue)

watch(localModelValue, (newValue) => {
  emits('update:modelValue', newValue)
})

watch(
  () => props.modelValue,
  (newValue) => {
    localModelValue.value = newValue
    emits('input', localModelValue.value)
  }
)
</script>
