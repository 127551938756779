import { defineStore } from 'pinia'
import { reactive, computed, ref } from 'vue'
import { GetAssetsByCompanyID, GetAssetsByAssetIDs, UpdateAssetTable, FavoriteAssets, UnfavoriteAssets, SoftDeleteObjectForAsset } from '@/services/graphql/asset.graphql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { useNotificationStore } from './notification.store'
import type { Asset } from '@/types/AssetType'
import _ from 'lodash'
import type { AssetTableInputType } from '@/types/AssetTableInputType'
import { fieldValuesAreEqual } from '@/composables/fieldHelpers'

export const useAssetStore = defineStore('AssetStore', () => {
  const notificationStore = useNotificationStore()

  const data = ref({
    assets: [] as Asset[],
    initialAssets: [] as Asset[],
    selectedAssets: [] as Asset[],
    loading: false,
    trashedAssets: [] as Asset[],
  })

  const tableView = ref('table' as string)

  // Getters
  // assets that have been updated
  const updatedAssets = computed(() => data.value.assets.filter((asset: Asset) => trackedChanges.value.find((change: any) => change.assetID === asset.assetID)))
  // shows changes to the assets compared to the initialAssets
  const trackedChanges = computed(() => trackChanges())

  // Query & Variables
  const { load: loadByCompanyID, refetch: refetchByCompanyID } = useLazyQuery(GetAssetsByCompanyID)
  const companyIDQueryVariables = reactive({ companyID: 0 as number })
  const { load: loadByAssetIDs, refetch: refetchByAssetIDs } = useLazyQuery(GetAssetsByAssetIDs)
  const assetIDsQueryVariables = reactive({ assetIDs: [] as number[] })

  // Mutations
  const { mutate: updateAssets } = useMutation(UpdateAssetTable)
  const { mutate: addAsFavorite } = useMutation(FavoriteAssets)
  const { mutate: removeFromFavorite } = useMutation(UnfavoriteAssets)
  const { mutate: softDeleteObjectForAsset } = useMutation(SoftDeleteObjectForAsset)

  // Functions
  // load assets data from the server
  async function loadAssetsData(companyID: number) {
    data.value.loading = true;
    companyIDQueryVariables.companyID = companyID;
    try {
      // Run the query and check the result
      let result = await loadByCompanyID(GetAssetsByCompanyID, companyIDQueryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })
      if (!result) {
        // If result is false, then use refetch instead
        result = await refetchByCompanyID(companyIDQueryVariables)
        result = result.data // Reformat the result because vue apollo is weird.
      }

      // Set the assets data using the result
      data.value.assets = _.cloneDeep(result.assets)
      data.value.initialAssets = _.cloneDeep(result.assets)
      data.value.loading = false
    }
    catch (error) {
      notificationStore.showError('There was an error loading the assets.', 'Error');
      console.error('Error loading assets data:', error);
    }
  }

  // load specific assets data from the server
  async function loadSpecificAssetsData(assetIDs: number[]) {
    data.value.loading = true;
    assetIDsQueryVariables.assetIDs = assetIDs;

    try {
      // Run the query and check the result
      let result = await loadByAssetIDs(GetAssetsByAssetIDs, assetIDsQueryVariables)
      if (!result) {
        // If result is false, then use refetch instead
        result = await refetchByAssetIDs(assetIDsQueryVariables)
        result = result.data // Reformat the result because vue apollo is weird.
      }

      // Set the assets data using the result
      data.value.assets = _.cloneDeep(result.assetsByAssetIDs)
      data.value.initialAssets = _.cloneDeep(result.assetsByAssetIDs)
      data.value.loading = false
    }
    catch (error) {
      notificationStore.showError('There was an error loading the assets.', 'Error');
      console.error('Error loading assets data:', error);
    }
  }

  // save the assets data to the server
  async function saveAssetsData(userID: number) {
    // get the updated assets and map them to the AssetTableInputType
    const assetInputs = updatedAssets.value.map((asset) => {
      return {
        objectID: asset.objectID,
        assetID: asset.assetID,
        referenceID: asset.referenceID,
        name: asset.name,
        description: asset.description,
        fileSize: asset.fileSize,
        fileExtension: asset.fileExtension,
        blobUrl: asset.blobUrl,
        previewURL: asset.previewURL,
        statusID: asset.statusID,
        assetTypeID: asset.assetTypeID,
        audienceID: asset.audienceID,
        folderIDs: asset.folders.map((folder) => folder.folderID),
        tagIDs: asset.tags.map((tag) => tag.tagID)
      } as AssetTableInputType
    })

    // call the updateAssets mutation and return the result directly to the caller
    const result = await updateAssets({ userID: userID, assets: assetInputs })
    return result
  }

  // favorite an array of assets for a user
  async function favoriteAssets(assetIDs: number[], userID: number) {
    // call the addAsFavorite mutation and return the result directly to the caller
    const result = await addAsFavorite({ assetsID: assetIDs, userID: userID })
    return result
  }

  // unfavorite an array of assets for a user
  async function unfavoriteAssets(assetIDs: number[], userID: number) {
    // call the removeFromFavorite mutation and return the result directly to the caller
    const result = await removeFromFavorite({ assetsID: assetIDs, userID: userID })
    return result
  }

  // soft delete an array of assets
  async function softDeleteAssets(assetIDs: number[]) {
    // call the softDeleteObjectForAsset mutation and return the result directly to the caller
    const result = await softDeleteObjectForAsset({ assetIDs: assetIDs })
    return result
  }

  // clear changes to assets by setting them back to the initialAssets
  function clearPendingChanges() {
    data.value.assets = _.cloneDeep(data.value.initialAssets)
    data.value.selectedAssets = []
  }

  // clear the selected assets
  function clearSelection() {
    data.value.selectedAssets = []
  }

  // track changes to the assets compared to the initialAssets (used by the trackedChanges computed property)
  function trackChanges() {
    const changes = [] as any[]
    data.value.assets.forEach((asset: Asset) => {
      const initialAsset = data.value.initialAssets.find((a: Asset) => a.assetID === asset.assetID)
      if (!initialAsset) return
      for(const key in asset) {
        if(key === 'object') continue
        const newValue = asset[key]
        const oldValue = initialAsset[key]
        if(!fieldValuesAreEqual(newValue, oldValue)) {
          changes.push({ assetID: asset.assetID, referenceID: asset.referenceID, field: key, value: asset[key] })
        }
      }
    })
    return changes;
  }

  // Resets the entire store state
  function $reset() {
    data.value.assets = []
    data.value.initialAssets = []
    data.value.selectedAssets = []
    data.value.loading = false
  }

  return {
    data,
    updatedAssets,
    loadAssetsData,
    loadSpecificAssetsData,
    saveAssetsData,
    favoriteAssets,
    unfavoriteAssets,
    softDeleteAssets,
    clearPendingChanges,
    clearSelection,
    $reset,
    tableView,
    trackedChanges
  }
})