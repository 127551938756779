import type { Ref } from 'vue'
import { ref, watch } from 'vue'
import { useMsal } from '@/composables/b2c/utils/useMsal'
import authService from '@/services/api/auth.service'
export function useIsAuthenticated(): Ref<boolean> {
  const { accounts } = useMsal()
  const isAuthenticated = ref(accounts.value.length > 0)

  watch(accounts, () => {
    isAuthenticated.value = accounts.value.length > 0
  })

  return isAuthenticated
}

export function useIsSuperAdminGroup(accountId: string): Promise<Ref<boolean>> {
  const isSuperAdmin = ref(false)
  const checkSuperAdminGroup = async () => {
    if (!accountId) return
    try {
      await authService.checkSuperAdminExistsByEntraID(accountId).then((response) => {
        isSuperAdmin.value = response.data
      })
    } catch (error) {
      console.error("Error acquiring token silently:", error)
      isSuperAdmin.value = false
    }
  }
  return checkSuperAdminGroup().then(() => isSuperAdmin)
}