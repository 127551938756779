import apiClient from "./main";

//api resource endpoint
const RESOURCE_NAME = 'graphql';

export default {
    GetUserObject(entraId: string) {
        return apiClient.post(RESOURCE_NAME, {
            query: `query GetUserObject($entraId: String!) {
                userEnabledPolicy(
                  where: {
                    entraID: { eq: $entraId }
                  }
                ) {
                  createdOn
                  email
                  firstName
                  lastName
                  updatedOn
                  userID
                  entraID
                  userPolicies {
                    companyID
                    userPolicyID
                    isEnabled
                    userRole {
                      role
                      userRoleID
                    }
                  }
                }
              }`,
            variables: { entraId }
        });
    }
}
