<template>
  <div>
    <div class="hidden sm:flex flex-col bg-black border-solid border-2 border-white w-full overflow-y-auto overflow-x-hidden p-4" style="height: calc(100vh - 98px)">
      <!-- Add The Filters Here -->
      <AppSidebarFilter @filter="$emit('filter', $event)"></AppSidebarFilter>
      <AppSidebarFolderList :selectedNodeFolderId="selectedNodeFolderId" @filterListBySelectedNodeFolder="$emit('filterListBySelectedNodeFolder', $event)"></AppSidebarFolderList>
    </div>
    <div class="card flex sm:hidden justify-center">
      <Drawer
        v-model:visible="visible"
        class="bg-black !text-white"
        position="full"
        :closeButtonProps="{ class: ['bg-black', 'border-black', 'text-white'] }"
        :pt="{ header: { class: ['justify-end'] } }"
      >
        <AppSidebarFolderList :selectedNodeFolderId="selectedNodeFolderId" @filterListBySelectedNodeFolder="$emit('filterListBySelectedNodeFolder', $event)"></AppSidebarFolderList>
      </Drawer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue'
import AppSidebarFolderList from './AppSidebarFolderList.vue'
import AppSidebarFilter from './AppSidebarFilter.vue'

// v-model:visible.  used to bind 2-way control of the visibility of the drawer
const visible = defineModel('visible')

// Props
defineProps<{
  selectedNodeFolderId: number
}>()

// Emit
defineEmits(['filterListBySelectedNodeFolder', 'filter'])
</script>
