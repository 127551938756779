<template>
  <div v-if="!dataLoaded">
    <div class="flex justify-center">
      <div class="mt-20">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col p-5 pb-0 gap-5">
    <AppHeaderPage>
      <template #title> {{ objectListCount }} Folders </template>
      <template #buttons>
        <Button v-if="userCanEdit" class="text-black hover:text-white" size="small" label="Add" severity="primary" @click="openCreateFolderModal()"></Button>
      </template>
    </AppHeaderPage>
    <AppToolbarPage 
      :isTableViewSelected="false"
      :isDisplayViewButtonsVisible="false" 
      :sortFields="cardFields"
      @search="searchQuery = $event"
      @selected-filter-action="filterViewBySelectedFilter"
      @set-sort-order="setSortOrder"
    >
    </AppToolbarPage>
    <AppToolbarSelection :selectedObjects="folderStore.data.selectedFolders" :items="folderTableSelectionMenuItems" @clear-selected-objects="unselectAll" />
    <ObjectGallery
      :objectList="filteredList"
      :selectedObjects="folderStore.data.selectedFolders"
      :galleryHeight="hasSelectedFolder ? 'calc(100vh - 355px)' : 'calc(100vh - 286px)'"
      :fields="cardFields"
      :menu-items="menuItems"
      :is-select-checkbox-visible="true"
      :is-edit-mode="userCanEdit"
      objectType="folder"
      @toggleSelect="galleryToggleSelect"
      :page="companyStore.folder.page"
      :rowsPerPage="companyStore.folder.rowsPerPage"
      @update:page="companyStore.folder.page = $event"
      @update:rowsPerPage="companyStore.folder.rowsPerPage = $event"
    >
    </ObjectGallery>
  </div>
  <DynamicDialog />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import _ from 'lodash'
import { onBeforeRouteLeave } from 'vue-router'
import ObjectGallery from '@/components/ObjectGallery.vue'
import { useDialog } from 'primevue/usedialog'
import { useNotificationStore } from '@/stores/notification.store'
import { useFolderStore } from '@/stores/folder.store'
import { useUserStore } from '@/stores/user.store'
import { useCompanyStore } from '@/stores/company.store'
import type { Folder } from '@/types/FolderType'
import type { Field } from '@/types/FieldType'
import { downloadFile, expandAsset, exportCSV } from '@/composables/downloadHelper'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import fileService from '@/services/api/file.service'
import { HasPermission } from '@/composables/permissionsHelper'
import {
  openDuplicateFolder,
  openFolderCreateDialog,
  openFolderRenameDialog,
  openShareDialog,
  openAssetDataExportDialog,
  openDeleteFolderDialog
} from '@/composables/dialogOptions'
import AppToolbarSelection from '@/components/AppToolbarSelection.vue'
import { IsFavorite } from '@/composables/objectHelpers'
import { searchObjects } from '@/composables/filterHelper'
import AppHeaderPage from '@/components/AppHeaderPage.vue'
import AppToolbarPage from '@/components/AppToolbarPage.vue'
import { SortObjectFieldValue } from '@/composables/sortObjectFieldValue';

// Check if user is authenticated
const isAuthenticated = useIsAuthenticated()

const dialog = useDialog()

// STORES
const notificationStore = useNotificationStore()
const companyStore = useCompanyStore()
const folderStore = useFolderStore()
const userStore = useUserStore()

onBeforeRouteLeave(() => {
  folderStore.$patch((state: any) => {
    state.data.selectedFolders = []
  })
})

// LOCAL PROPERTIES
const dataLoaded = ref(false)
const searchQuery = ref('')
const menuItems = ref(Array<any>())
const isFavorited = ref(false)
const entityList = ref<Array<any>>([])
const sortOrder = ref('asc');
const sortFilter = ref({} as Field);

// Fields array that will be displayed on card.
const cardFields = ref<Array<Field>>([
  { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSearchable:true, isSortable: true },
  { fieldKey: 2, fieldName: 'AssociatedObjects', fieldValue: 'associatedObjects', fieldType: 'text', isVisible: true }
])

// Menu items for the gallery.
menuItems.value = [
  {
    label: isFavorited.value ? 'Unfavorite' : 'Favorite',
    icon: isFavorited.value ? 'fa-solid fa-star' : 'fa-regular fa-square-minus',
    command: async (folderID: any) => {
      const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
      if (folder) {
        isFavorited.value = IsFavorite(folder.object)
        if (isFavorited.value) {
          await unfavoriteFolders([folder.folderID])
        } else {
          await favoriteFolders([folder.folderID])
        }
      }
    }
  },
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: (folderID: number) => {
      const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
      if (folder) {
        openSharedFoldersModal([folder])
      }
    }
  },
  {
    label: 'Delete',
    icon: 'fa-regular fa-trash-can',
    command: (folderID: number) => {
      const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
      if (folder) {
        trashFoldersConfirm(folder)
      }
    }
  },
  {
    label: 'Duplicate',
    icon: 'fa-regular fa-copy',
    command: (folderID: number) => {
      const modalOptions = {
        data: {
          subtitle: 'Duplicating this folder will make an exact copy of the folder, including its assets, and subfolders.',
          customLabels: {
            submitLabel: 'Duplicate'
          }
        },
        emits: {
          onSubmit: async () => {
            const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
            if (!folder) {
              notificationStore.showError('Error duplicating the folder. Cannot find the folder.', 'Error')
            } else {
              // Create the duplicated folder
              duplicateFolder(folder)
            }
          }
        }
      }
      openDuplicateFolder(dialog, modalOptions)
    }
  },
  {
    label: 'Rename',
    icon: 'fa-regular fa-pen',
    command: (folderID: number) => {
      const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
      const modalOptions = {
        data: {
          folderID: folder?.folderID,
          folderName: folder?.name
        },
        emits: {
          onFolderRename: () => {
            folderStore.loadFoldersData(companyStore.data.company.companyID)
          }
        }
      }
      openFolderRenameDialog(dialog, modalOptions)
    }
  }
]

// COMPUTED PROPERTIES
// Count of objects in the objectList
const objectListCount = computed(() => (folderStore.data.folders ? folderStore.data.folders.length : 0))
const hasSelectedFolder = computed(() => folderStore.data.selectedFolders.length > 0)
const userCanEdit = computed(() => HasPermission('Editor', companyStore.data.company.companyID))
const selectionIsFavs = computed(() => folderStore.data.selectedFolders.every((folder: Folder) => IsFavorite(folder.object)))
// Selection Menu Items
const folderTableSelectionMenuItems = computed(() => [
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: () => {
      openSharedFoldersModal(folderStore.data.selectedFolders)
    }
  },
  {
    label: 'Favorite',
    icon: 'fa-regular fa-star',
    visible: !selectionIsFavs.value,
    command: () => {
      favoriteSelectedFolders()
    }
  },
  {
    label: 'Unfavorite',
    icon: 'fa-regular fa-square-minus',
    visible: selectionIsFavs.value,
    command: () => {
      unfavoriteSelectedFolders()
    }
  },
  {
    label: 'Download',
    icon: 'fa-regular fa-arrow-down-to-bracket',
    visible: !notificationStore.isDownloading,
    command: () => {
      if (!isAuthenticated.value) {
        notificationStore.showInfo('Please login to download assets')
        return
      }
      const modalOptions = {
        emits: {
          onConfirm: (exportData: boolean, downloadData: boolean) => {
            downloadAssets(downloadData, exportData)
          }
        }
      }
      openAssetDataExportDialog(dialog, modalOptions)
    }
  },
  {
    label: 'Downloading',
    icon: 'fa-solid fa-spinner',
    iconClass: 'animate-spin animate-ease-in-out',
    visible: notificationStore.isDownloading
  },
  {
    label: 'Trash',
    icon: 'fa-regular fa-trash-can',
    command: () => {
      trashFoldersConfirm()
    }
  }
])

const filteredList = computed(() => {
  const fieldsToSearch = cardFields.value.filter((column) => column.isSearchable)
  // if search is empty, return the full list
  if (!searchQuery.value || searchQuery.value === '') {
    return folderStore.data.folders
  }
  return searchObjects(folderStore.data.folders, fieldsToSearch, searchQuery.value)
})

// METHODS
// load folders data on component mount
onMounted(async () => {
  await folderStore.loadFoldersData(companyStore.data.company.companyID)
  dataLoaded.value = true
})

// Functions
// Toggle's selection of an object from the gallery/card view
function galleryToggleSelect(object: Folder) {
  if (folderStore.data.selectedFolders.includes(object)) {
    folderStore.data.selectedFolders = folderStore.data.selectedFolders.filter((folder: Folder) => folder.folderID !== object.folderID)
  } else {
    folderStore.data.selectedFolders.push(object)
  }
}

// Unselect All event handler
function unselectAll() {
  folderStore.data.selectedFolders = []
}

function filterViewBySelectedFilter(filter?: Field) {
   //check if the field value is an object.* field
   if(filter && filter.fieldValue && filter.fieldValue.includes('object.')) {
    sortFilter.value = filter
    folderStore.data.folders =  SortObjectFieldValue(filter, folderStore.data.folders, sortOrder.value)
  }
  else if(filter) {
    sortFilter.value = filter
    //filter the asset list based on the filter
    folderStore.data.folders = _.orderBy(folderStore.data.folders, [sortFilter.value.fieldValue], [sortOrder.value])
  }
  else {
    sortFilter.value = { fieldKey: 0, fieldName: '', fieldValue: '', fieldType: '', isVisible: false }; 
    folderStore.data.folders = _.orderBy(folderStore.data.folders, ['folderID'], ['asc'])
  }
}

function setSortOrder(isAscending: boolean) {
  if(isAscending)
    sortOrder.value = 'asc'
  else 
    sortOrder.value = 'desc'

  //after the order gets set, sort the list 
  if(sortFilter.value)
    filterViewBySelectedFilter(sortFilter.value)
}

function downloadFolders() {
  notificationStore.showInfo('Download selected folders', 'This feature is not yet implemented.')
}

function favoriteSelectedFolders() {
  //if more than 1 folder is selected pass in the full list
  if (folderStore.data.selectedFolders.length > 1) {
    let folderIDs = folderStore.data.selectedFolders.map((folder) => {
      return folder.folderID
    })
    favoriteFolders(folderIDs)
  } else {
    favoriteFolders([folderStore.data.selectedFolders[0].folderID])
  }
}

function unfavoriteSelectedFolders() {
  //if more than 1 folder is selected pass in the full list
  if (folderStore.data.selectedFolders.length > 1) {
    let folderIDs = folderStore.data.selectedFolders.map((folder) => {
      return folder.folderID
    })
    unfavoriteFolders(folderIDs)
  } else {
    unfavoriteFolders([folderStore.data.selectedFolders[0].folderID])
  }
}

// Open the create folder modal
function openCreateFolderModal() {
  const modalOptions = {
    data: {
      folderName: '', // default folder name
      parentID: null,
      companyID: companyStore.data.company.companyID
    },
    emits: {
      onCreate: () => {
        folderStore.loadFoldersData(companyStore.data.company.companyID)
      }
    }
  }
  openFolderCreateDialog(dialog, modalOptions)
}

// duplicate a folder and all of it's subfolders
function duplicateFolder(folder: Folder) {
  createDuplicatedFolderRecursive(folder, folder.parentFolderID || undefined).then(() => {
    // Show the success message
    notificationStore.showSuccess(`${folder.name} Duplicated`, 'Success')
    folderStore.loadFoldersData(companyStore.data.company.companyID)
  })
}

// recursively create duplicated folders.
async function createDuplicatedFolderRecursive(folder: Folder, parentFolderID?: number) {
  await folderStore.createDuplicatedFolder(companyStore.data.company.companyID, userStore.getUserID, folder, parentFolderID).then(async (result) => {
    // if the folder is created, then create the children folders
    if (result && folder.subFolders) {
      for (const child of folder.subFolders) {
        await createDuplicatedFolderRecursive(child, parseInt(result.data.createDuplicatedFolder.folderID))
      }
    }
  })
}

// Download selected assets
async function downloadAssets(downloadData: boolean, exportData: boolean) {
  if (isAuthenticated.value && folderStore.data.selectedFolders.length > 0) {
    const copySelectedAssets = _.cloneDeep(folderStore.data.selectedFolders.map((folder: Folder) => folder.assets).flat())
    notificationStore.showDownloadToast('Downloading selected folders', 'Downloading')
    let csvFile: File | null = null

    if (exportData) {
      // get expanded assets (with relationships) for export
      const expandedAssets = copySelectedAssets.map((asset: any) => expandAsset(asset))
      // get file safe date time string
      const dateTime = new Date().toISOString().replace(/:/g, '-').split('.')[0]
      // check if we are downloading a single asset, if that and not downloading data, we can just download the csv directly
      // export the data to csv
      const csv = await exportCSV(expandedAssets, `selected_assets_${dateTime}`, !downloadData)

      // if for whatever reason the csv export fails, return
      if (!csv) {
        notificationStore.showError('Selected assets download failed. Could not export data.', 'Download Failed')
        notificationStore.closeDownloadToast()
        return
      }

      // if we are downloading a single asset and not downloading data, just download the csv and return
      if (!downloadData) {
        notificationStore.showSuccess('Exported selected asset data', 'Export Successful')
        notificationStore.closeDownloadToast()
        return
      }

      // prepare the file for download (zip the csv)
      csvFile = new File([csv], `selected_assets_${dateTime}.csv`, { type: 'text/csv' })
    }
    const selectedFolderIds = folderStore.data.selectedFolders.map((folder: Folder) => folder.folderID)
    // download the assets as a zip
    fileService
      .downloadFolderAssets(selectedFolderIds, csvFile, downloadData, exportData)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const dateTime = new Date().toISOString().replace(/:/g, '-').split('.')[0]
        const filename = `selected_assets_${dateTime}.zip`
        downloadFile(url, filename)
        notificationStore.showSuccess('Downloaded selected assets', 'Download Successful')
      })
      .catch((error) => {
        console.error(error)
        notificationStore.showError('Selected assets download failed. ' + error.response?.data, 'Download Failed')
      })
      .finally(() => {
        notificationStore.closeDownloadToast()
      })
  } else {
    notificationStore.showError('You must be logged in to download assets.', 'Not Authenticated')
  }
}

function openSharedFoldersModal(folders: Folder[]) {
  const modalOptions = {
    props: {
      header: 'Share Folder'
    },
    data: {
      objects: folders,
      objectType: 'folder'
    },
    emits: {
      onAssetShared: async () => {}
    }
  }
  openShareDialog(dialog, modalOptions)
}

async function favoriteFolders(folderIDs: number[]) {
  await folderStore.favoriteFolders(folderIDs, userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been added to favorites')
    folderStore.clearSelection()
    await folderStore.loadFoldersData(companyStore.data.company.companyID)
  })
}

async function unfavoriteFolders(folderIDs: number[]) {
  await folderStore.unfavoriteFolders(folderIDs, userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been removed from favorites')
    folderStore.clearSelection()
    await folderStore.loadFoldersData(companyStore.data.company.companyID)
  })
}

// Function to open the confirmation modal and handle trashing folders
function trashFoldersConfirm(folder?: Folder) {
  // Prepare the list for the modal
  entityList.value = folder
    ? [{ entityID: folder.folderID, entityName: folder.name }]
    : folderStore.data.selectedFolders.map((folder: Folder) => ({ entityID: folder.folderID, entityName: folder.name }))

  const modalOptions = {
    props: {
      header: 'Trash Folder'
    },
    data: {
      subtitle: folder ? ` ${folder.name} ` : ` ${entityList.value[0].entityName} `
    },
    emits: {
      onConfirm: async (trashAssets: boolean) => {
        // Determine whether to trash a single folder or multiple folders
        const folderIDs = folder ? [folder.folderID] : folderStore.data.selectedFolders.map((folder: Folder) => folder.folderID)
        await folderStore.trashFolders(trashAssets, folderIDs).then(async () => {
          //reload folders and assets
          folderStore.loadFoldersData(companyStore.data.company.companyID)
        })
        // Clear the selected folders and entity list
        entityList.value = []
        folderStore.$patch((state: any) => {
          state.data.selectedFolders = []
        })
      }
    }
  }

  // Open the confirmation modal
  openDeleteFolderDialog(dialog, modalOptions)
}
</script>
