<template>
  <div class="flex flex-row justify-between">
    <label :for="props.objectPropertyName" class="text-white">{{ props.filterTitle }}</label>
    <ToggleSwitch v-model="filterByToggleSwitch" class="w-9" :pt="toggleSwitchStyle"></ToggleSwitch>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue'

// Define Props
const props = withDefaults(
  defineProps<{
    filterTitle: string
    objectPropertyName: string
    isSpecificFilter?: boolean
    toggleValue: boolean
  }>(),
  {
    isSpecificFilter: false
  }
)

// Define variables
const filterByToggleSwitch = ref(props.toggleValue)

// Define Computed
const toggleSwitchStyle = computed(() => {
  return {
    slider: {
      class: [
        'w-[25px]',
        'h-[15px]',
        'bg-transparent',
        'border-2',
        'before:w-2',
        'before:h-2',
        'before:top-4',
        `${filterByToggleSwitch.value ? 'border-primary before:bg-primary before:left-3' : 'border-white before:bg-white'}`
      ]
    }
  }
})

// Define Emits
const emits = defineEmits(['update:toggleValue', 'filterListByToggleSwitch'])

// Degine Watchers
watch(filterByToggleSwitch, () => {
  emits('update:toggleValue', filterByToggleSwitch.value)
  emits('filterListByToggleSwitch', filterByToggleSwitch.value, props.objectPropertyName, props.isSpecificFilter)
})

watch(
  () => props.toggleValue,
  (newValue) => {
    filterByToggleSwitch.value = newValue
  }
)
</script>
