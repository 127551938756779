
import { useStatusStore } from "@/stores/status.store";
import { useTagStore } from "@/stores/tag.store";
import { useAudienceStore } from "@/stores/audience.store";
import { useAssetTypeStore } from "@/stores/assetType.store";

interface OptionData {
  companyID: number;
  name: string;
  colour: string;
}

export async function createOptionInStore(data: OptionData, store: string) {
  switch(store) {
    case 'status':
      return useStatusStore().createStatus(data).then(async (res) => {
        if(res) {
          const newStatus = {...res.data.createStatus}
          await useStatusStore().loadStatusesData(data.companyID);
          return newStatus.statusID;
        }
      })
    case 'tag':
      return useTagStore().createTag(data).then(async (res) => {
        if(res) {
          const newTag = {...res.data.createTag}
          await useTagStore().loadTagsData(data.companyID);
          return newTag.tagID;
        }
      })
    case 'audience':
      return useAudienceStore().createAudience(data).then(async (res) => {
        if(res) {
          const newAudience = {...res.data.createAudience}
          await useAudienceStore().loadAudiencesData(data.companyID);
          return newAudience.audienceID;
        }
      })
    case 'assetType':
      return useAssetTypeStore().createAssetType(data).then(async (res) => {
        if(res) {
          const newAssetType = {...res.data.createAssetType}
          await useAssetTypeStore().loadAssetTypesData(data.companyID);
          return newAssetType.assetTypeID;
        }
      })
    default:
      // return error promise
      return Promise.reject(new Error('Invalid store'));
  }
}

export async function updateOptionInStore(data: OptionData, store: string) {
  switch(store) {
    case 'status':
      return useStatusStore().updateStatus(data).then(async (res) => {
        if(res) {
          const updatedStatus = {...res.data.updateStatus}
          await useStatusStore().loadStatusesData(data.companyID);
          return updatedStatus.statusID;
        }
      })
    case 'tag':
      return useTagStore().updateTag(data).then(async (res) => {
        if(res) {
          const updatedTag = {...res.data.updateTag}
          await useTagStore().loadTagsData(data.companyID);
          return updatedTag.tagID;
        }
      })
    case 'audience':
      return useAudienceStore().updateAudience(data).then(async (res) => {
        if(res) {
          const updatedAudience = {...res.data.updateAudience}
          await useAudienceStore().loadAudiencesData(data.companyID);
          return updatedAudience.audienceID;
        }
      })
    case 'assetType':
      return useAssetTypeStore().updateAssetType(data).then(async (res) => {
        if(res) {
          const updatedAssetType = {...res.data.updateAssetType}
          await useAssetTypeStore().loadAssetTypesData(data.companyID);
          return updatedAssetType.assetTypeID;
        }
      })
    default:
      // return error promise
      return Promise.reject(new Error('Invalid store'));
  }
}

export async function deleteOptionInStore(data: OptionData, store: string) {
  switch(store) {
    case 'status':
      return useStatusStore().deleteStatus(data).then(async (res) => {
        if(res) {
          const deletedStatus = {...res.data.deleteStatus}
          await useStatusStore().loadStatusesData(data.companyID);
          return deletedStatus.statusID;
        }
      })
    case 'tag':
      return useTagStore().deleteTag(data).then(async (res) => {
        if(res) {
          const deletedTag = {...res.data.deleteTag}
          await useTagStore().loadTagsData(data.companyID);
          return deletedTag.tagID;
        }
      })
    case 'audience':
      return useAudienceStore().deleteAudience(data).then(async (res) => {
        if(res) {
          const deletedAudience = {...res.data.deleteAudience}
          await useAudienceStore().loadAudiencesData(data.companyID);
          return deletedAudience.audienceID;
        }
      })
    case 'assetType':
      return useAssetTypeStore().deleteAssetType(data).then(async (res) => {
        if(res) {
          const deletedAssetType = {...res.data.deleteAssetType}
          await useAssetTypeStore().loadAssetTypesData(data.companyID);
          return deletedAssetType.assetTypeID;
        }
      })
    default:
      // return error promise
      return Promise.reject(new Error('Invalid store'));
  }
}