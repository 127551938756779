<template>
  <div class="flex flex-col p-5 gap-5">
    <AppPageHeader>
      <template #title>
        Companies
      </template>
      <template #buttons>
        <Button @click="showCompanyModal" size="small" label="Add Company" class="text-black hover:text-white"></Button>
      </template>
    </AppPageHeader>
    <DataTable :loading="isLoading" :value="companies" :pt="tableStyle" show-gridlines size="small" scrollable v-model:filters="filters" :globalFilterFields="filterFields">
      <template #header>
        <div class="justify-end flex p-0 w-full sm:w-60 pl-5 md:pl-0">
          <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="sm:w-60" />
        </div>
      </template>
      <Column class="w-5 text-center" field="Actions" header="Actions">
        <template #body="slotProps">
          <div class="flex justify-center">
            <RouterLink :to="{ name: 'CompanyRouterView', params: { company: slotProps.data.companyID } }">
              <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" class="text-lg text-black hover:text-gray-600" />
            </RouterLink>
          </div>
        </template>
      </Column>
      <Column field="companyName" header="Company Name">
        <template #body="slotProps">
          <RouterLink class="text-black hover:text-gray-600 underline font-normal" :to="{ name: 'CompanyRouterView', params: { company: slotProps.data.companyID } }">
            {{ slotProps.data.companyName }}
          </RouterLink>
        </template>
      </Column>
    </DataTable>
  </div>
  <DynamicDialog />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { RouterLink, useRouter } from 'vue-router'
import { GetAllCompanies, CreateCompany } from '@/services/graphql/company.graphql'
import { FilterMatchMode } from '@primevue/core/api'
import type { Company } from '@/types/CompanyType'
import AppModalForm from '@/components/AppModalForm.vue'
import { useNotificationStore } from '@/stores/notification.store'
import { useQuery, useMutation } from '@vue/apollo-composable'
import type { DataTablePassThroughOptions } from 'primevue/datatable'
import { useDialog } from 'primevue/usedialog'
import { genericDialogProps } from '@/composables/dialogOptions'
import AppPageHeader from '@/components/AppHeaderPage.vue'

// notification store
const notificationStore = useNotificationStore()

// apollo
const { refetch, onResult } = useQuery(GetAllCompanies)
const { mutate: createCompany, onDone: displayNewCompanyCreated } = useMutation(CreateCompany)

// router
const router = useRouter()

// variables
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  companyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  companyID: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// loading
const isLoading = ref(true)

// filters
const filterFields = ref(['companyName', 'companyID'])

// data
const companies = ref<Company[]>([])

// modal
const dialog = useDialog()

// pass through styles
const tableStyle = ref({
  column: {
    headercell: () => ({
      class: 'font-bold text-left border-0 border-b border-solid p-1 px-2 bg-surface-50 text-surface-700 first:border-l border-r border-t'
    })
  },
  thead: {
    class: 'z-30'
  },
  header: {
    class:
      'flex justify-end font-bold border-x border-t border-b-0 p-2 bg-surface-50 dark:bg-surface-800 border-surface-200 dark:border-surface-700 text-surface-700 dark:text-white/80'
  }
}) as DataTablePassThroughOptions

// create company
const createNewCompany = (companyName: string) => {
  const currentDate = new Date().toISOString()

  createCompany({
    company: {
      companyName: companyName,
      billingAddressID: null,
      companyAddressID: null,
      // graphql string datetime format
      createdOn: currentDate,
      logoBlobUrl: null,
      phone: null,
      updatedOn: currentDate,
      website: null
    }
  })
}

// show create company modal
const showCompanyModal = () => {
  // props
  dialog.open(AppModalForm, {
    props: {
      modal:true,
      header: 'Add Company',
      closable: false,
      ...genericDialogProps
    },
    data: {
      fields: [{ label: 'Company Name', id: 'companyName', type: 'text', required: true, placeholder: 'Company Name', icon: 'fa-regular fa-pen' }],
      companyName: '',
      schema: {
        companyName: { type: 'string', required: true }
      },
      customLabels: {
        submitLabel: 'Add Company'
      }
    },
    emits: {
      onSubmit: async (company: Company) => {
        await createNewCompany(company.companyName)
      }
    }
  })
}

// handle result
onResult((queryResult) => {
  if (queryResult.data?.companiesAll) {
    companies.value = queryResult.data.companiesAll
  }
})

// display success message
displayNewCompanyCreated((newCompany) => {
  // show success message
  notificationStore.showSuccess('Company Created', 'The company has been created successfully')
  router.push({ name: 'companySettings', params: { company: newCompany.data.createCompany.companyID } })
})

// ensure data is fetched on mount
onMounted(async () => {
  isLoading.value = true
  await refetch()
  isLoading.value = false
})
</script>
