<template>
    <div class="flex flex-col p-5 gap-5">
      <AppPageHeader>
        <template #title>
          Companies
        </template>
      </AppPageHeader>
      <DataTable :loading="loading" :value="companies" :pt="tableStyle" show-gridlines size="small" scrollable v-model:filters="filters" :globalFilterFields="filterFields">
        <template #header>
          <div class="justify-end flex p-0 w-full sm:w-60 pl-5 md:pl-0">
            <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="sm:w-60" />
          </div>
        </template>
        <Column class="w-5 text-center" field="Actions" header="Actions">
          <template #body="slotProps">
            <div class="flex justify-center">
              <RouterLink :to="{ name: 'CompanyRouterView', params: { company: slotProps.data.companyID } }">
                <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" class="text-lg text-black hover:text-gray-600" />
              </RouterLink>
            </div>
          </template>
        </Column>
        <Column field="companyName" header="Company Name">
          <template #body="slotProps">
            <RouterLink class="text-black hover:text-gray-600 underline font-normal" :to="{ name: 'CompanyRouterView', params: { company: slotProps.data.companyID } }">
              {{ slotProps.data.companyName }}
            </RouterLink>
          </template>
        </Column>
      </DataTable>
      <DynamicDialog />
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted, watch } from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import { RouterLink } from 'vue-router'
  import { GetCompaniesByIDs } from '@/services/graphql/company.graphql'
  import { FilterMatchMode } from '@primevue/core/api'
  import type { Company } from '@/types/CompanyType'
  import { useLazyQuery   } from '@vue/apollo-composable'
  import { useUserStore } from '@/stores/user.store'
import AppPageHeader from '@/components/AppHeaderPage.vue';
  
  const userStore = useUserStore()
  
  // variables
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyID: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  
  // loading
  const isLoading = ref(true)
  
  // filters
  const filterFields = ref(['companyName', 'companyID'])
  
  // data
  const companies = ref<Company[]>([])
  
  //get company IDs from the userstores userPolicies
  const companyIDs = userStore.UserObject?.userPolicies.map((policy) => policy.companyID) || []
  
  const { load, result, loading,refetch } = useLazyQuery(GetCompaniesByIDs)
  
  onMounted(async () => {
    await userStore.setUserInfo()
    let result = await load(GetCompaniesByIDs, { companyIDs })
    if(!result) {
      result = await refetch({ companyIDs })
      result = result.data
      companies.value = result.companiesByIDs || []
    }
  })
  
  watch(result, (newResult) => {
    if (newResult) {
      companies.value = newResult.companiesByIDs || []
    }
    isLoading.value = false
  })
  
  // pass through styles
  const tableStyle = ref({
    column: {
      headercell: () => ({
        class: 'font-bold text-left border-0 border-b border-solid p-1 px-2 bg-surface-50 text-surface-700 first:border-l border-r border-t'
      })
    },
    thead: {
      class: 'z-30'
    },
    header: {
      class:
        'flex justify-end font-bold border-x border-t border-b-0 p-2 bg-surface-50 dark:bg-surface-800 border-surface-200 dark:border-surface-700 text-surface-700 dark:text-white/80'
    }
  })
  
  
  </script>