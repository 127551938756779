import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { useUserStore } from '@/stores/user.store'
import { useCompanyStore } from '@/stores/company.store'

// HTTP connection to the API
const httpLink = createHttpLink({
    uri: `${import.meta.env.VITE_API_URL}/graphql/`,
})

// Middleware to add headers to the request
const authLink = setContext((_, { headers }) => {

    // Declare store
    const userStore = useUserStore()
    const companyStore = useCompanyStore()

    let customHeaders = {
        ...headers,
        'X-Api-Key': import.meta.env.VITE_APP_API_KEY
    }

    if(companyStore.companyID) {
        customHeaders.CompanyID = companyStore.companyID   
    }

    //try to get the access token from the user store
    let bearerToken = userStore.Token?.idToken
    if (bearerToken) {
        //token exists, set the authorization header
        customHeaders.Authorization = `Bearer ${bearerToken}`
    }

    // Return the headers to the context so httpLink can read them
    return {
        headers: customHeaders
    }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
})