

import { useNotificationStore } from '@/stores/notification.store'
import { useAssetStore } from '@/stores/asset.store'
import fileService from '@/services/api/file.service'
import type { Asset } from '@/types/AssetType'
import _ from 'lodash'


export const downloadFile = async (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
// Function to export data to CSV, handling special characters and null/undefined values
export const exportCSV = (data: any[], fileName: string, download = false) => {
    if (data.length === 0) return

    const csvHeaders = Object.keys(data[0]).join(',')

    const csvData = data.map((row: any) => {
        return Object.values(row)
            .map(value => {
                // Convert value to string, handle null/undefined, and escape special characters
                const stringValue = (value !== undefined && value !== null) ? value.toString() : ''
                const escapedValue = stringValue
                    .replace(/\\/g, '\\\\') // Escape backslashes
                    .replace(/"/g, '""')    // Escape quotes
                return `"${escapedValue}"`
            })
            .join(',')
    })


    const csvString = csvHeaders + '\n' + csvData.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const csvFile = new File([blob], `${fileName}.csv`, { type: 'text/csv' });
    if (download)
        downloadFile(URL.createObjectURL(csvFile), `${fileName}.csv`)
    return csvFile

}

// Helper function to expand objects and nested arrays, removing "__typename"
export const expandAsset = (asset: any) => {
    const expanded: any = {}
    for (const key in asset) {
        if (key === '__typename') {
            continue
        }
        const updatedKey = toTitleCase(key)
        if (key === 'folders') {
            expanded[updatedKey] = asset[key].map((folder: any) => folder.name).join(', ')
        } else if (key === 'tags') {
            expanded[updatedKey] = asset[key].map((tag: any) => tag.name).join(', ')
        } else if (key === 'object') {
            // combine first and last name of created by
            const createdBy = asset[key].createdBy.firstName + ' ' + asset[key].createdBy.lastName
            expanded['Created By'] = createdBy
            expanded['Created On'] = asset[key].createdOn.toString().split('T')[0]

            // combine first and last name of last modified by
            const lastModifiedBy = asset[key].lastModifiedBy.firstName + ' ' + asset[key].createdBy.lastName
            expanded['Last Modified By'] = lastModifiedBy
            expanded['Last Edited'] = asset[key].updatedOn.toString().split('T')[0]

        } else if (key == 'audience' || key === 'assetType' || key === 'status') {
            expanded[updatedKey] = asset[key]?.name
        } else {
            expanded[updatedKey] = asset[key]
        }
    }
    return expanded
}

// convert lower case camel case to title case
const toTitleCase = (str: string) => {
    return str
        .replace(/([A-Z])/g, ' $1') // Insert space before uppercase letters
        .replace(/ I D\b/g, ' ID') // Correctly format 'ID' that might have been split
        .replace(/^./, (s) => s.toUpperCase()) // Capitalize the first letter of the string
        .replace(/\bId\b/g, 'ID') // Fix any instance of 'Id' to 'ID'
        .trim() // Trim any leading or trailing spaces
}


export async function downloadAssets(downloadData: boolean, exportData: boolean, isAuthenticated: boolean = false, selectedAssets: Asset[] = []) {
    const assetStore = useAssetStore()
    const notificationStore = useNotificationStore()
    if(selectedAssets.length == 0) {
        selectedAssets = assetStore.data.selectedAssets
    }
    if (selectedAssets.length === 1 && !exportData) {
        downloadAction(selectedAssets[0].assetID)
        return
    }
    if (isAuthenticated && selectedAssets.length > 0) {
        notificationStore.showDownloadToast('Downloading selected assets', 'Downloading')
        const copySelectedAssets = _.cloneDeep(selectedAssets) as any[]
        const selectedAssetIDs = copySelectedAssets.map((asset: Asset) => asset.assetID)
        let csvFile: File | null = null

        if (exportData) {
            // get expanded assets (with relationships) for export
            const expandedAssets = copySelectedAssets.map((asset: any) => expandAsset(asset))
            // get file safe date time string
            const dateTime = new Date().toISOString().replace(/:/g, '-').split('.')[0]
            // export the data to csv
            const csv = await exportCSV(expandedAssets, `selected_assets_${dateTime}`, !downloadData)

            // if for whatever reason the csv export fails, return
            if (!csv) {
                notificationStore.showError('Selected assets download failed. Could not export data.', 'Download Failed')
                notificationStore.closeDownloadToast()
                return
            }

            // if we are downloading a single asset and not downloading data, just download the csv and return
            if (!downloadData) {
                notificationStore.showSuccess('Downloaded selected assets', 'Download Successful')
                notificationStore.closeDownloadToast()
                return
            }

            // prepare the file for download (zip the csv)
            csvFile = new File([csv], `selected_assets_${dateTime}.csv`, { type: 'text/csv' })
        }

        // download the assets as a zip
        fileService
            .downloadAssets(selectedAssetIDs, csvFile, downloadData, exportData)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const dateTime = new Date().toISOString().replace(/:/g, '-').split('.')[0]
                const filename = `selected_assets_${dateTime}.zip`
                downloadFile(url, filename)
                notificationStore.showSuccess('Downloaded selected assets', 'Download Successful')
            })
            .catch((error) => {
                console.error(error)
                notificationStore.showError('Selected assets download failed. ' + error.response?.data, 'Download Failed')
            })
            .finally(() => {
                notificationStore.closeDownloadToast()
            })
    } else {
        notificationStore.showError('You must be logged in to download assets.', 'Not Authenticated')
    }
}

export async function downloadAction(assetID: number, isAuthenticated: boolean = false) {
    const assetStore = useAssetStore()
    const notificationStore = useNotificationStore()
    const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === assetID)
    if (isAuthenticated && asset) {
        notificationStore.showDownloadToast(asset.name, 'Downloading')
        fileService
            .downloadAsset(assetID)
            .then(async (response) => {
                // simulate button press to avoid popup blockers
                await downloadFile(response.data, asset.name)
                // show success notification
                notificationStore.showSuccess('Downloaded ' + asset.name, 'Download Successful')
            })
            .catch((error) => {
                console.error(error)
                notificationStore.showError(asset.name + ` ${error.response?.data ?? error}`, 'Download Failed')
            })
            .finally(() => {
                notificationStore.closeDownloadToast()
            })
    } else {
        notificationStore.showError('You must be logged in to download assets.', 'Not Authenticated')
    }
}

export function formatSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
} 