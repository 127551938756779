import { reactive } from 'vue';
import { defineStore } from 'pinia';
import type { Audience } from '@/types/AudienceType';
import { useNotificationStore } from '@/stores/notification.store';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { GetAudiencesByCompanyID, CreateAudience, UpdateAudience, DeleteAudience } from '@/services/graphql/audience.graphql';
import _ from 'lodash';

export const useAudienceStore = defineStore('AudienceStore', () => {
  const notifcationStore = useNotificationStore();

  const data = reactive({
    audiences: [] as Audience[],
    loading: false as boolean
  })

  const { load, refetch } = useLazyQuery(GetAudiencesByCompanyID);
  const queryVariables = reactive({
    companyID: 0
  })

  const { mutate: createAudienceMutation } = useMutation(CreateAudience);
  const { mutate: updateAudienceMutation } = useMutation(UpdateAudience);
  const { mutate: deleteAudienceMutation } = useMutation(DeleteAudience);

  // load/refetch asset types data
  async function loadAudiencesData(companyID: number) {
    data.loading = true;
    queryVariables.companyID = companyID;

    try {
      let result = await load(GetAudiencesByCompanyID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } });
      if(!result) {
        result = await refetch(queryVariables);
        result = result.data;
      }

      // clone, then Sort the audiences by the id field
      let audiences = _.cloneDeep(result.audiences);
      audiences = _.sortBy(audiences, ['audienceID']);
      // Update the asset types array with the new data by splicing the array.
      // This will maintain the original array reference and therby maintain the reactivity of the array.
      data.audiences.splice(0, data.audiences.length, ...audiences);
      data.loading = false;
    }
    catch (error) {
      notifcationStore.showError('Error loading asset types data', 'Error');
      console.error('Error loading asset types data:', error);
      data.loading = false;
    }
  }

  // function to call createAudience mutation and return it's result
  async function createAudience(audience: any) {
    const result = await createAudienceMutation({ input: audience });
    return result;
  }
  
  // function to call updateAudience mutation and return it's result.
  async function updateAudience(audience: any) {
    const audienceID = audience.audienceID;
    // clone the audience object and remove the __typename and audienceID fields (required for the mutation)
    const updatingAudience = { ...audience };
    delete updatingAudience.__typename;
    delete updatingAudience.audienceID;
    const result = await updateAudienceMutation({ audienceID: audienceID, input: updatingAudience });
    return result;
  }

  // function to call deleteAudience mutation and return it's result
  async function deleteAudience(audience: any) {
    const audienceID = audience.audienceID;
    const result = await deleteAudienceMutation({ audienceID: audienceID });
    return result;
  }

  function $reset() {
    data.audiences = [];
    data.loading = false;
  }

  return {
    data,
    loadAudiencesData,
    createAudience,
    updateAudience,
    deleteAudience,
    $reset
  }
});