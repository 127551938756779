import { ref } from 'vue'
import { defineStore } from 'pinia'

export interface ToastMessage {
  severity?: string
  summary?: string
  detail: string,
  position?: string,
  group?: string
}

export const useNotificationStore = defineStore('NotificationStore', () => {
  const toasts = ref([] as ToastMessage[])
  const downloadToasts = ref([] as ToastMessage[])
  const isDownloading = ref(false)

  function showSuccess(message: string, summary: string = 'Success') {
    toasts.value.push({ severity: 'success', summary: summary, detail: message })
  }
  function showInfo(message: string, summary: string = 'Info') {
    toasts.value.push({ severity: 'info', summary: summary, detail: message })
  }
  function showWarning(message: string, summary: string = 'Warning') {
    toasts.value.push({ severity: 'warn', summary: summary, detail: message })
  }
  function showError(message: string, summary: string = 'Error') {
    toasts.value.push({ severity: 'error', summary: summary, detail: message })
  }
  function showDownloadToast(message: string, summary: string = 'Secondary') {
    isDownloading.value = true
    downloadToasts.value.push({ severity: 'secondary', summary: summary, detail: message, position: 'top-right' })
  }
  function closeDownloadToast() {
    setTimeout(() => {
      isDownloading.value = false
      downloadToasts.value.splice(0, downloadToasts.value.length)
    }, 500)
  }
  function clear() {
    toasts.value.splice(0, toasts.value.length)
  }

  return {
    toasts,
    downloadToasts,
    showSuccess,
    showInfo,
    showWarning,
    showError,
    showDownloadToast,
    closeDownloadToast,
    isDownloading,
    clear
  }
})
