

import apiClient from "../main";
import type { MailInvite } from "@/types/MailInviteType";

//api resource endpoint
const RESOURCE_NAME = 'api/mail';

export default {
    sendInviteMail(mailData: MailInvite) {
        return apiClient.post(RESOURCE_NAME + '/send-invite', mailData);
    },
    sendTempPasswordMail(mailData: MailInvite) {
        return apiClient.post(RESOURCE_NAME + '/send-temp-pass', mailData);
    },
    sendCompanyInviteMail(mailData: MailInvite) {
        return apiClient.post(RESOURCE_NAME + '/send-company-invite', mailData);
    },
    sendShareObjectLinks(mailData: MailInvite) {
        return apiClient.post(RESOURCE_NAME + '/send-share-object-links', mailData);
    },
}