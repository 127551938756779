import { reactive } from 'vue';
import { defineStore } from 'pinia';
import type { AssetType } from '@/types/AssetTypeType';
import { useNotificationStore } from '@/stores/notification.store';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { GetAssetTypesByCompanyID, CreateAssetType, UpdateAssetType, DeleteAssetType } from '@/services/graphql/assetType.graphql';
import _ from 'lodash';

export const useAssetTypeStore = defineStore('AssetTypeStore', () => {
  const notifcationStore = useNotificationStore();

  const data = reactive({
    assetTypes: [] as AssetType[],
    loading: false as boolean
  })

  const { load, refetch } = useLazyQuery(GetAssetTypesByCompanyID);
  const queryVariables = reactive({
    companyID: 0
  })

  const { mutate: createAssetTypeMutation } = useMutation(CreateAssetType);
  const { mutate: updateAssetTypeMutation } = useMutation(UpdateAssetType);
  const { mutate: deleteAssetTypeMutation } = useMutation(DeleteAssetType);

  // load/refetch asset types data
  async function loadAssetTypesData(companyID: number) {
    data.loading = true;
    queryVariables.companyID = companyID;

    try {
      let result = await load(GetAssetTypesByCompanyID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } });
      if(!result) {
        result = await refetch(queryVariables);
        result = result.data;
      }

      // clone, then Sort the assetTypes by the id field
      let assetTypes = _.cloneDeep(result.assetTypes);
      assetTypes = _.sortBy(assetTypes, ['assetTypeID']);
      // Update the asset types array with the new data by splicing the array.
      // This will maintain the original array reference and therby maintain the reactivity of the array.
      data.assetTypes.splice(0, data.assetTypes.length, ...assetTypes);
      data.loading = false;
    }
    catch (error) {
      notifcationStore.showError('Error loading asset types data', 'Error');
      console.error('Error loading asset types data:', error);
      data.loading = false;
    }
  }

  // function to call createAssetType mutation and return it's result
  async function createAssetType(assetType: any) {
    const result = await createAssetTypeMutation({ input: assetType });
    return result;
  }
  
  // function to call updateAssetType mutation and return it's result.
  async function updateAssetType(assetType: any) {
    const assetTypeID = assetType.assetTypeID;
    // clone the assetType object and remove the __typename and assetTypeID fields (required for the mutation)
    const updatingAssetType = { ...assetType };
    delete updatingAssetType.__typename;
    delete updatingAssetType.assetTypeID;
    const result = await updateAssetTypeMutation({ assetTypeID: assetTypeID, input: updatingAssetType });
    return result;
  }

  // function to call deleteAssetType mutation and return it's result
  async function deleteAssetType(assetType: any) {
    const assetTypeID = assetType.assetTypeID;
    const result = await deleteAssetTypeMutation({ assetTypeID: assetTypeID });
    return result;
  }

  function $reset() {
    data.assetTypes = [];
    data.loading = false;
  }

  return {
    data,
    loadAssetTypesData,
    createAssetType,
    updateAssetType,
    deleteAssetType,
    $reset
  }
});