<template>
  <DataView
    :value="filteredList"
    layout="grid"
    dataKey="objectID"
    paginator
    :rows="props.rowsPerPage"
    :rowsPerPageOptions="[10, 20, 50, 100]"
    class="border-0"
    :first="(props.page - 1) * props.rowsPerPage"
    @page="updatePage($event)"
    @update:rows="updatePage($event)"
  >
    <template #grid="slotProps">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-5 p-1" :style="{ height: props.galleryHeight, 'overflow-y': props.galleryHeight ? 'scroll' : 'hidden' }">
        <template v-for="object in slotProps.items" :key="object.objectID">
          <ObjectGalleryCard
            :object="object"
            :fields="props.fields"
            :objectType="props.objectType"
            :menu-items="props.menuItems"
            :selected="props.selectedObjects.includes(object)"
            :is-select-checkbox-visible="props.isSelectCheckboxVisible"
            :is-edit-mode="props.isEditMode"
            @toggleSelect="emits('toggleSelect', object)"
          >
          </ObjectGalleryCard>
        </template>
      </div>
    </template>
  </DataView>
</template>

<script setup lang="ts">
import type { Asset } from '@/types/AssetType'
import type { Folder } from '@/types/FolderType'
import type { Filter } from '@/types/FilterType'
import ObjectGalleryCard from '@/components/ObjectGalleryCard.vue'
import type { Field } from '@/types/FieldType'
import { computed } from 'vue'
import { searchObjects, filterObjects } from '@/composables/filterHelper'
const props = withDefaults(
  defineProps<{
    objectList: Asset[] | Folder[]
    filteredList?: Asset[] | Folder[]
    selectedObjects: Asset[] | Folder[]
    isSelectCheckboxVisible: boolean
    fields: Field[]
    galleryHeight?: string
    objectType: string
    menuItems: any
    isEditMode: boolean
    page?: number
    rowsPerPage?: number
    search?: string
    filters?: Filter[]
  }>(),
  {
    page: 1,
    rowsPerPage: 20
  }
)

const emits = defineEmits(['toggleSelect', 'update:page', 'update:rowsPerPage', 'update:filteredList'])

const filteredList = computed(() => {
  const isSearch = props.search && props.search !== ''
  const isFilter = props.filters && props.filters.length > 0

  let filteredList = props.objectList

  if (isSearch) {
    const fieldsToSearch = props.fields.filter((column) => column.isSearchable)
    filteredList = searchObjects(filteredList, fieldsToSearch, props.search)
  }

  if (isFilter) {
    filteredList = filterObjects(filteredList, props.filters)
  }

  // update the filtered list to be able to count the total number of rows in the parent component.
  emits('update:filteredList', filteredList)

  return filteredList
})

function updatePage(event: { first: number; rows: number }) {
  emits('update:page', event.first / event.rows + 1)
  emits('update:rowsPerPage', event.rows)
}
</script>
