<template>
  <div v-if="loading">
    <div class="flex justify-center">
      <div class="mt-20 text-center">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="shouldDisplayOTPPage" class="flex items-center justify-center h-screen p-14 -mt-24">
      <Card v-if="cardToDisplay === 'email'" class="w-1/3">
        <template #title><div class="flex items-center justify-center">Verify You Identity</div></template>
        <template #content>
          <p>You've received a secure link to:</p>
          <ul class="list-disc ml-12">
            <li>{{ specificObjectName }}</li>
          </ul>
          <p>To open this secure link, we'll need you to enter the email that this item was shared to.</p>
          <InputText class="w-full mt-3" @update:modelValue="handleEmailUpdate" v-model="email" placeholder="Enter email" />
          <Button
            class="text-black hover:text-white w-full mt-5"
            size="small"
            :loading="isLoading"
            @click="checkEmail()"
            :disabled="shouldDisableEmailButton"
            label="Next"
          ></Button>
          <p class="mt-3 font-bold">
            By clicking next you allow {{ companyName }} to use your email address in accordance with their privacy statement. {{ companyName }} has not provided links to their
            terms for you to review.
          </p>
        </template>
      </Card>
      <Card v-else-if="cardToDisplay === 'otp'" class="w-1/3">
        <template #title><div class="flex items-center justify-center">Enter Verification Code</div></template>
        <template #content>
          <p>You've received a secure link to:</p>
          <ul class="list-disc ml-12">
            <li>{{ specificObjectName }}</li>
          </ul>
          <p>To open this link, enter the code we just emailed to {{ email }}.</p>
          <InputText class="w-full mt-3" v-model="code" placeholder="Enter code" />
          <Button class="text-black hover:text-white w-full mt-5" size="small" label="Verify" :loading="isLoading" @click="checkOTP()" :disabled="code === ''"></Button>
          <Checkbox class="mt-3" v-model="keepSignedIn" binary inputId="keepSignedInID"></Checkbox>
          <label for="keepSignedInID" class="ml-2">Keep me signed in</label>
        </template>
      </Card>
      <Card v-else-if="cardToDisplay === 'request'" class="w-1/3">
        <template #title><div class="flex items-center justify-center">Request Access</div></template>
        <template #content>
          <p>Email {{ originalSharedUser }} to request access to this file:</p>
          <ul class="list-disc ml-12">
            <li>{{ specificObjectName }}</li>
          </ul>
        </template>
      </Card>
    </div>
    <objectSharedView v-else :object-i-d="objectID" :object-type="objectType" :share-i-d="shareID"></objectSharedView>
  </div>
  <DynamicDialog />
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { ref, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user.store'
import { useNotificationStore } from '@/stores/notification.store'
import { GetAssetByObjectID } from '@/services/graphql/asset.graphql'
import { GetFolderByObjectID } from '@/services/graphql/folder.graphql'
import { ValidateIfUserHasAccessToSharedObject } from '@/services/graphql/share.graphql'
import { useLazyQuery } from '@vue/apollo-composable'
import otpService from '@/services/api/otp.service'
import objectSharedView from '@/components/ObjectSharedInfo.vue'

// Define route
const route = useRoute()

// Define Store
const userStore = useUserStore()
const notificationStore = useNotificationStore()

// Define variables
const cardToDisplay = ref('email')
const specificObjectName = ref('')
const email = ref('')
const code = ref('')
const keepSignedIn = ref(false)
const shouldDisableEmailButton = ref(true)
const companyName = ref('')
const isLoading = ref(false)
const originalSharedUser = ref('steve@caddetails.com')
const objectID = ref(0)
const objectType = ref('')
const shareID = ref(0)
const shouldDisplayOTPPage = ref(true)
const loading = ref(false)

const { load: loadGetAssetByObjectID } = useLazyQuery(GetAssetByObjectID)
const { load: loadGetFolderByObjectID } = useLazyQuery(GetFolderByObjectID)
const { load: loadValidateIfUserHasAccessToSharedObject, refetch: refetchValidateIfUserHasAccessToSharedObject } = useLazyQuery(ValidateIfUserHasAccessToSharedObject)

const variableAssetByObjectID = reactive({
  objectID: 0
})
const variableFolderByObjectID = reactive({
  objectID: 0
})
const variablesValidateIfUserHasAccessToSharedObject = reactive({
  userEmail: '',
  objectID: 0
})

// Lifecycle hooks
onMounted(async () => {
  loading.value = true

  // Convert the router paramiter from base64 to string.
  const decodedParam = atob(route.params.urlKey as string)
  const decodedParamArray = decodedParam.split(';')
  objectID.value = parseInt(decodedParamArray[1].split('=')[1])
  objectType.value = decodedParamArray[2].split('=')[1]

  // Check if the user was already validated and if the user still has access to the object.
  const sharedIDThatUserHasAccessTo = await checkIfValidatedUserHasAccessToSharedObject(objectID.value)
  if (sharedIDThatUserHasAccessTo != null) {
    shareID.value = sharedIDThatUserHasAccessTo
    // Display object shared component.
    shouldDisplayOTPPage.value = false
  } else {
    await getInfoForInputEmailPage()
  }

  loading.value = false
})

// Define functions
async function getInfoForInputEmailPage() {
  if (objectType.value === 'Asset') {
    variableAssetByObjectID.objectID = objectID.value
    const assetResult = await loadGetAssetByObjectID(GetAssetByObjectID, variableAssetByObjectID, { context: { headers: { AnonymousAuthentication: 'true' } } })
    specificObjectName.value = assetResult.assetByObjectID.name
    companyName.value = assetResult.assetByObjectID.object.company.companyName
  } else if (objectType.value === 'Folder') {
    variableFolderByObjectID.objectID = objectID.value
    const folderResult = await loadGetFolderByObjectID(GetFolderByObjectID, variableFolderByObjectID, { context: { headers: { AnonymousAuthentication: 'true' } } })
    specificObjectName.value = folderResult.folderByObjectID.name
    companyName.value = folderResult.folderByObjectID.object.company.companyName
  }
}

// debounce the email validation function
const debouncedCheckUserExists = _.debounce(async (emailAddress: string) => {
  // first check if the email is valid (regex)
  if (!emailAddress.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
    shouldDisableEmailButton.value = true
  } else {
    shouldDisableEmailButton.value = false
  }
}, 500)

// wrapper function to handle email update
function handleEmailUpdate(emailAddress: any) {
  email.value = emailAddress
  debouncedCheckUserExists(emailAddress)
}

async function checkEmail() {
  // Check if the user has access to the object
  const sharedIDThatUserHasAccessTo = await checkIfUserHasAccessToSharedObject(email.value, objectID.value)
  if (sharedIDThatUserHasAccessTo != null) {
    shareID.value = sharedIDThatUserHasAccessTo
    await sendOTP()
    cardToDisplay.value = 'otp'
  } else {
    cardToDisplay.value = 'request'
  }
}

async function sendOTP() {
  await otpService
    .GenerateOtp({ Email: email.value, ObjectID: objectID.value })
    .then((response: any) => {
      if (response.status != 200) {
        notificationStore.showError('Error generating OTP')
      }
    })
    .catch(() => {
      notificationStore.showError('Error generating OTP')
    })
}

async function checkOTP() {
  await otpService
    .ValidateOtp({ Email: email.value, Otp: code.value, ObjectID: objectID.value })
    .then((response: any) => {
      if (response.status === 200) {
        userStore.setValidatedSharedUser(email.value, keepSignedIn.value)
        // Display object shared component.
        shouldDisplayOTPPage.value = false
      } else {
        notificationStore.showError('Invalid OTP')
      }
    })
    .catch(() => {
      notificationStore.showError('Error validating OTP')
    })
}

async function checkIfValidatedUserHasAccessToSharedObject(objectID: number) {
  let sharedIDThatUserHasAccessTo = null

  // If user is already validated or there is an authenticated user, check if this user still has access to the shared link.
  if (userStore.isSharedUserValidated) {
    const userEmail = userStore.getSharedValidatedUserEmail()
    sharedIDThatUserHasAccessTo = await checkIfUserHasAccessToSharedObject(userEmail, objectID)
  }

  return sharedIDThatUserHasAccessTo
}
async function checkIfUserHasAccessToSharedObject(userEmail: string, objectID: number) {
  variablesValidateIfUserHasAccessToSharedObject.userEmail = userEmail
  variablesValidateIfUserHasAccessToSharedObject.objectID = objectID
  let result = await loadValidateIfUserHasAccessToSharedObject(ValidateIfUserHasAccessToSharedObject, variablesValidateIfUserHasAccessToSharedObject, {
    context: { headers: { AnonymousAuthentication: 'true' } }
  })

  if (!result) {
    result = await refetchValidateIfUserHasAccessToSharedObject(variablesValidateIfUserHasAccessToSharedObject)
    result = result.data // Reformat the result because vue apollo is weird.
  }

  if (result.validateIfUserHasAccessToSharedObject != null) {
    return result.validateIfUserHasAccessToSharedObject.shareID
  } else {
    return null
  }
}
</script>
