import { createApp } from 'vue'
import './style.css'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes'
import App from './App.vue'
import router from './router'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import { library, type IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { all } from '@awesome.me/kit-d8ead6f040/icons'
import * as Sentry from '@sentry/vue'
import ConfirmationService from 'primevue/confirmationservice'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from './apollo-client'
//Import MSAL plugin
import { msalPlugin } from '@/composables/b2c/plugins/msalPlugin'
import { msalInstance } from '@/composables/b2c/authConfig'
import { EventType } from '@azure/msal-browser'
import type { AuthenticationResult } from '@azure/msal-browser'
import DialogService from 'primevue/dialogservice';

//create app
const app = createApp(App)

//Add Apollo
app.provide(DefaultApolloClient, apolloClient)

//Add Pinia
app.use(createPinia())

// dynamic dialogservice
app.use(DialogService)

//Add FontAwesome
library.add(all as IconDefinition[])
app.component('FontAwesomeIcon', FontAwesomeIcon)

//Add MSAL plugin
//Microsoft Authentication Library

// Account selection logic is app dependent.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

//Final add and mount
app.use(ConfirmationService)

// Theme configuration
const customLaraPreset = definePreset(Lara, {
  semantic: {
    primary: {
      200: '#00EE95',
      500: '#00EE95',
      600: '#008051'
    }
  }
})

app.use(PrimeVue, {
  // Default theme configuration
  theme: {
    preset: customLaraPreset,
    options: {
      darkModeSelector: '.my-app-dark',
      cssLayer: {
        name: 'primevue',
        order: 'tailwind-base, primevue, tailwind-utilities'
      }
    }
  }
})
app.directive('tooltip', Tooltip)
app.use(router)
app.use(ToastService)
app.use(msalPlugin, msalInstance)

//Sentry
Sentry.init({
  app,
  trackComponents: true,
  debug: false,
  environment: `${import.meta.env.VITE_APP_ENV}`,
  release: `${import.meta.env.VITE_SENTRY_RELEASE}`,
  dsn: 'https://87f90fb40e149aea96f72b2d7200204e@o152560.ingest.us.sentry.io/4507352729124864',
  integrations: [
    Sentry.browserTracingIntegration({
      router: router
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0
})

app.config.errorHandler = (err) => {
  console.error(err)
  Sentry.captureException(err)
}

app.mount('#app')
