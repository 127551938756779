<template>
  <Toast position="bottom-right" group="bottom-right" />
  <Toast position="top-right" group="download" @life-end="notificationStore.closeDownloadToast">
    <template #container="{ message }">
      <div class="flex items-center justify-between w-full p-2">
        <div class="flex flex-col">
          <div class="flex w-full">
            <div class="text-md">{{ message.summary }}</div>
          </div>
          <div class="flex w-full">
            <div class="flex w-full">
              <div class="text-xs font-bold">{{ message.detail }}</div>
            </div>
          </div>
        </div>
        <font-awesome-icon :icon="['fas', 'fa-spinner']" class="text-blue-500 animate-spin ml-2" />
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import Toast, { type ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { useNotificationStore } from '../stores/notification.store'
import { watch } from 'vue'

//Notification system
const notificationStore = useNotificationStore()
const toast = useToast()

// Add a watcher for the toastMessage inside notificationStore to show a toast message
watch(notificationStore.toasts, (newMessages: any) => {
  if (newMessages.length > 0) {
    for (const newMessage of newMessages) {
      toast.add({
        severity: newMessage.severity,
        summary: newMessage.summary,
        detail: newMessage.detail,
        group: 'bottom-right',
        life: 4000
      } as ToastMessageOptions)
    }
    notificationStore.clear()
  }
})

watch(notificationStore.downloadToasts, (newMessages: any) => {
  // if the new message removes the old message, then we need to remove the old message
  if (newMessages.length === 0) {
    toast.removeGroup('download')
  }
  if (newMessages.length > 0) {
    for (const newMessage of newMessages) {
      toast.add({
        severity: newMessage.severity,
        summary: newMessage.summary,
        detail: newMessage.detail,
        group: 'download',
        life: 30000
      })
    }
  }
})
</script>
