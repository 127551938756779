<template>
  <div class="flex w-full">
    <!--Menu Sidebar-->
    <AppMenuSidebar @showFullMenu="showFullMenu">
      <template #items>
        <AppMenuSidebarLink routeName="adminCompanySelector" icon="fa-solid fa-industry" label="Companies"></AppMenuSidebarLink>
        <AppMenuSidebarLink routeName="adminManageUsers" icon="fa-solid fa-users" label="Super Admin Users"></AppMenuSidebarLink>
      </template>
    </AppMenuSidebar>
    <!--Admin Viewport-->
    <div class="flex-1 bg-white transition-all duration-300 flex flex-col justify-between minAppHeight" :class="fullMenu ? 'ml-64' : 'ml-12'">
      <div>
        <RouterView></RouterView>
      </div>
      <AppFooter></AppFooter>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppMenuSidebar from '@/components/AppMenuSidebar.vue'
import AppMenuSidebarLink from '@/components/AppMenuSidebarLink.vue';
import AppFooter from '@/components/AppFooter.vue';

const fullMenu = ref(false);

function showFullMenu(e: any) {
  fullMenu.value = e;
}
</script>