<template>
  <div ref="card" class="group flex flex-col border-4 p-1 rounded-xl cursor-pointer" :class="cardBorderColour" @click="$emit('toggleSelect')" @dblclick="navigateToObjectPage">
    <template v-if="objectType !== 'folder' && IsImageFile(object.blobUrl) && object.previewURL">
      <!--Card Preview w/ image-->
      <div
        ref="imagePreview"
        class="relative w-full h-60 rounded-t-lg"
        :style="{ backgroundImage: `url('${getPreviewURL(object.previewURL, object.object.companyID)}')`, backgroundSize: 'cover' }"
      >
        <div class="absolute text-left w-full h-full rounded-t-lg bg-gradient-to-t from-gray-500 to-transparent opacity-45"></div>
        <h3 class="absolute bottom-0 left-0 m-4 text-white text-xl font-extrabold">{{ object.name }}</h3>
        <FontAwesomeIcon class="absolute bottom-0 right-0 mb-5 mr-2 text-xl font-extrabold" v-if="isFavorited" icon="fa-solid fa-star" />
        <ObjectGalleryCardMenuButton v-if="isEditMode" :menu-items="menuItemsAdjustedForTheUser" />
        <Checkbox
          v-if="props.isSelectCheckboxVisible"
          :modelValue="selected"
          :binary="true"
          class="absolute top-0 left-0 m-4"
          :class="checkboxVisibility"
          @click.stop="$emit('toggleSelect')"
          @dblclick.stop
        />
      </div>
    </template>
    <template v-else-if="objectType !== 'folder' && IsVideoFile(object.blobUrl)">
      <div ref="videoPreview" class="relative w-full h-60 rounded-t-lg">
        <video class="w-full h-full object-cover rounded-t-lg" :src="getPreviewURL(object.blobUrl, object.object.companyID)"></video>
        <h3 class="absolute bottom-0 left-0 m-4 text-white text-xl font-extrabold">{{ object.name }}</h3>
        <FontAwesomeIcon class="absolute bottom-0 right-0 mb-5 mr-2 text-xl font-extrabold" v-if="isFavorited" icon="fa-solid fa-star" />
        <ObjectGalleryCardMenuButton v-if="isEditMode" :menu-items="menuItemsAdjustedForTheUser" />
        <Checkbox
          v-if="props.isSelectCheckboxVisible"
          :modelValue="selected"
          :binary="true"
          class="absolute top-0 left-0 m-4"
          :class="checkboxVisibility"
          @click.stop="$emit('toggleSelect')"
          @dblclick.stop
        />
      </div>
    </template>
    <template v-else>
      <!--Card Preview w/o image-->
      <div ref="noImagePreview" class="relative w-full h-60 rounded-t-lg">
        <div class="w-full h-full flex bg-gray-50 rounded-t-lg">
          <FontAwesomeIcon v-if="objectType == 'folder'" icon="fa-regular fa-folder" class="text-7xl text-gray-300 m-auto" />
          <FontAwesomeIcon v-else icon="fa-regular fa-image" class="text-7xl text-gray-300 m-auto" />
        </div>
        <h3 class="absolute bottom-0 left-0 m-4 text-xl font-extrabold">{{ object.name }}</h3>
        <FontAwesomeIcon class="absolute bottom-0 right-0 mb-5 mr-2 text-xl font-extrabold" v-if="isFavorited" icon="fa-solid fa-star" />
        <ObjectGalleryCardMenuButton v-if="isEditMode" :menu-items="menuItemsAdjustedForTheUser" />
        <Checkbox
          v-if="props.isSelectCheckboxVisible"
          :modelValue="selected"
          :binary="true"
          class="absolute top-0 left-0 m-4"
          :class="checkboxVisibility"
          @click.stop="$emit('toggleSelect')"
          @dblclick.stop
        />
      </div>
    </template>
    <!--Card Body-->
    <div ref="cardFields" class="bg-neutral-100 rounded-b-lg flex flex-col gap-3 p-4 flex-grow">
      <template v-for="field in fields" :key="field.fieldKey">
        <component
          :is="getFieldType(field.fieldType)"
          :modelValue="_.get(object, field.fieldValue)"
          @update:modelValue="_.set(object, field.fieldValue, $event)"
          fieldMode="view"
          fieldParent="card"
          :formatType="field.fieldValue"
          :relationshipType="field.relationshipType"
          :options="field.selectOptions"
          :selectID="field.selectID"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import _ from 'lodash'
import ObjectGalleryCardMenuButton from './ObjectGalleryCardMenuButton.vue'
import type { Field } from '@/types/FieldType'
import type { Asset } from '@/types/AssetType'
import type { Folder } from '@/types/FolderType'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useRouter, useRoute } from 'vue-router'
import { IsImageFile, IsVideoFile, getPreviewURL } from '@/composables/storageHelper'
import { getFieldType } from '@/composables/fieldHelpers'
import { IsFavorite } from '@/composables/objectHelpers'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs.store'

const props = defineProps<{
  object: Asset | Folder
  selected: boolean
  isSelectCheckboxVisible: boolean
  fields: Field[]
  objectType: string
  menuItems: any
  isEditMode: boolean
}>()

// Define router
const router = useRouter()
const route = useRoute()

// Define stores
const breadcrumbsStore = useBreadcrumbsStore()

// assign the object prop to a ref so we can mutate it
const object = toRef(props, 'object')
const menuItems = toRef(props, 'menuItems')
//const isFavorited = ref(IsFavorite(object.value.object))

// Sets the card border colour based on selected state
const cardBorderColour = computed(() => {
  return props.selected ? 'border-primary-500' : 'border-neutral-100 hover:border-neutral-400'
})

const isFavorited = computed(() => {
  if (object.value.object) return IsFavorite(object.value.object)
  else return false
})

// Sets the checkbox visibility based on selected state
const checkboxVisibility = computed(() => {
  return props.selected ? 'block' : 'hidden group-hover:block'
})

const objectID = computed(() => {
  return object.value[props.objectType + 'ID']
})

// Navigate to the form page for the object
function navigateToObjectPage(this: any) {
  const routeNavigation = { name: props.objectType + 'Form', params: { company: route.params.company, id: objectID.value } }

  // Only set breadcrumbs when the user navigates from the folders page or the folder form page.
  if (route.name === 'folders' || route.name === 'folderForm') {
    const breadcrumb = { link: object.value.name, to: routeNavigation }
    breadcrumbsStore.setBreadcrumb(breadcrumb)
  }

  router.push(routeNavigation)
}

const menuItemsAdjustedForTheUser = computed(() =>
  menuItems.value.map((item: any) => {
    if (item.label === 'Favorite' || item.label === 'Unfavorite') {
      return {
        ...item,
        label: isFavorited.value ? 'Unfavorite' : 'Favorite',
        icon: isFavorited.value ? 'fa-regular fa-square-minus' : 'fa-regular fa-star',
        command: () => {
          item.command(objectID.value, !isFavorited.value)
        }
      }
    } else {
      return {
        ...item,
        command: () => {
          item.command(objectID.value)
        }
      }
    }
  })
)
</script>
