import type { Field } from '@/types/FieldType';
import type { Filter } from '@/types/FilterType';
import { IsFavorite } from '@/composables/objectHelpers';
import _ from 'lodash';


export const searchObjects = (objects: any[], fields: Field[], search: string) => {
    return _.filter(objects, (object: any) => {
        return _.some(fields, (field: any) => {
            const fieldValue = _.get(object, field.fieldValue);
            if (fieldValue && Array.isArray(fieldValue)) {
                return _.some(fieldValue, (nestedItem: any) => {
                    return _.includes(_.toLower(nestedItem.name || ''), _.toLower(search));
                });
            }
            // if single select object
            else if(field.fieldObject) {
                const nestedObject = _.get(object, field.fieldObject);
                return _.includes(_.toLower(nestedObject?.name || ''), _.toLower(search));
            }
            return _.includes(_.toLower(String(fieldValue || '')), _.toLower(search));
        });
    });
}

export const filterObjects = (objects: any[], filters: Filter[]) => {
    return _.filter(objects, (object: any) => {
        return _.every(filters, (filter: Filter) => {
            // Get the object property names from the filter objectPropertyName.
            // If it's a nested object, the objectPropertyName will be separated by a dot.
            const objectPropertyNames = filter.objectPropertyName.split('.');
            // Variable to keep the object value. It starts with the object itself.
            let objectValue = object;

            // If the filter is a specific filter (user favorite), do a separate check.
            if (filter.isSpecificFilter) {
                return specificFilters(objectPropertyNames, objectValue);
            // Else get the value of the object from the object property names.
            } else {
                // Get the object value from the object property names.
                objectValue = getObjectValueFromObjectPropertyNames(objectPropertyNames, objectValue);

                return objectMatchesFilter(objectValue, filter);
            }
        });
    });
}

function specificFilters(fieldNames: string[], objectValue: any) {
    if (fieldNames[0] === 'isUserFavorite') {
        return IsFavorite(objectValue.object);
    }
}

function getObjectValueFromObjectPropertyNames(objectPropertyNames: string[], objectValue: any) {
    // Loop through the objectPropertyNames to get the value of the object.
    objectPropertyNames.forEach((objectPropertyName: string) => {
        // If the object value is an array, map the array to get the values.
        if (objectValue && Array.isArray(objectValue)) {
            objectValue = _.map(objectValue, (item: any) => {
                return _.get(item, objectPropertyName);
            });
        // Else get the value of the object directly.
        } else {
            objectValue = _.get(objectValue, objectPropertyName);
        }
    });

    return objectValue
}

function objectMatchesFilter(objectValue: any, filter: Filter) {
    // If the filter value is an array, do the some function to check if any of the values match.
    if (Array.isArray(filter.filterValue)) {
        return _.some(filter.filterValue, (filterValue: any) => {
            // If the object value is also an array use the includes function to check if the value is in the array.
            if(objectValue && Array.isArray(objectValue)) {
                return _.includes(objectValue, filterValue);
            // Else use the isEqual function to check if the values are equal.
            } else {
                return _.isEqual(filterValue, objectValue);
            }
        });
    // If the filter value is a boolean, means that the object value should not be empty.
    } else if (typeof filter.filterValue === 'boolean') {
        return objectValue !== null && objectValue !== '';
    // If the filter value is anything else and the object value is an array, use the includes function to check if the value is in the array.
    } else if (objectValue && Array.isArray(objectValue)) {
        return _.includes(objectValue, filter.filterValue);
    // If the filter value is anything else and the object value is not an array, use the isEqual function to check if the values are equal.
    } else {
        return _.isEqual(filter.filterValue, objectValue);
    }   
}