<template>
  <!--dblclick.stop is here to prevent the routing action from happening from the parent card component-->
  <div class="absolute top-0 right-0 m-4 bg-white w-8 h-8 rounded flex items-center justify-center hover:bg-neutral-100" @click.stop="menuClick" @dblclick.stop>
    <Button icon="fa-regular fa-ellipsis-vertical" class="text-xl bg-white text-black border-none w-fit h-0"></Button>
  </div>
  <Menu
    ref="menu"
    id="overlay_menu"
    :model="menuItems"
    :popup="true"
    :pt="{ root: { class: ['bg-black', 'text-white'] }, itemContent: { class: ['text-white', 'hover:bg-primary-emphasis'] }, itemIcon: { class: ['text-white'] } }"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
defineProps<{
  menuItems: any
}>()

const menu = ref()

function menuClick() {
  menu.value.toggle(event)
}
</script>
