import _ from 'lodash'
import DataFieldText from '@/components/DataFieldText.vue'
import DataFieldNumber from '@/components/DataFieldNumber.vue'
import DataFieldUrl from '@/components/DataFieldUrl.vue'
import DataFieldEditor from '@/components/DataFieldEditor.vue'
import DataFieldDate from '@/components/DataFieldDate.vue'
import DataFieldSingleSelect from '@/components/DataFieldSingleSelect.vue'
import DataFieldMultiSelect from '@/components/DataFieldMultiSelect.vue'
import DataFieldRelationship from '@/components/DataFieldRelationship.vue'

// Get the DataField component that matches the field type
export function getFieldType(fieldType: string) {
  switch (fieldType) {
    case 'text':
      return DataFieldText
    case 'number':
      return DataFieldNumber
    case 'url':
      return DataFieldUrl
    case 'editor':
      return DataFieldEditor
    case 'date':
      return DataFieldDate
    case 'singleSelect':
      return DataFieldSingleSelect
    case 'multiSelect':
      return DataFieldMultiSelect
    case 'relationship':
      return DataFieldRelationship
    default:
      return DataFieldText
  }
}

// This function converts a nested field string to a better format.
// Example: 'nested.field' to 'nested[field]' or 'nested.field.another' to 'nested[field][another]'
export function convertNestedField(field: string) {
  if(field.includes('.')) {
    const fieldArray = field.split('.')
    return fieldArray[0] + fieldArray.slice(1).map((part) => '[' + part + ']').join('');
  }
  return field
}

// Checks if the updated field values are equal to the initial field values.
// Checks if the field values are arrays, objects, or direct values and compares them accordingly.
export function fieldValuesAreEqual(updatedField: any, initialField: any) {
  const updatedFieldClone = _.cloneDeep(updatedField);
  const initialFieldClone = _.cloneDeep(initialField);

  // check if the field values are arrays
  if (Array.isArray(updatedFieldClone) && Array.isArray(initialFieldClone)) {
    // If the arrays are not the same length, they are not equal
    if (updatedFieldClone.length !== initialFieldClone.length) {
      return false;
    }

    // compare each object in the arrays
    let arraysAreEqual = true;
    updatedFieldClone.forEach((updatedFieldObj, index) => {
      if (!compareObjectsByMatchingFields(updatedFieldObj, initialFieldClone[index])) {
        arraysAreEqual = false;
      }
    })
    return arraysAreEqual;
  }
  // check if one of the fields is null or undefined
  else if (updatedFieldClone === null || updatedFieldClone === undefined || initialFieldClone === null || initialFieldClone === undefined) {
    return updatedFieldClone === initialFieldClone;
  }
  // check if the field values are objects
  else if (typeof updatedFieldClone === 'object' && typeof initialFieldClone === 'object') {
    return compareObjectsByMatchingFields(updatedFieldClone, initialFieldClone);
  }
  else {
    // If the field values are not arrays or objects, compare the fields directly
    return updatedFieldClone === initialFieldClone;
  }
}

// Compare 2 objects by their matching fields, ignoring fields that don't exist in both objects
function compareObjectsByMatchingFields(updatedObject: any, initialObject: any) {
  const keys = Object.keys(updatedObject);
  let objectsAreEqual = true;
  keys.forEach((key) => {
    //check if both objects have a field with that key
    if (key in initialObject) {
      //if they both have the field, compare the values
      if (updatedObject[key] !== initialObject[key]) {
        objectsAreEqual = false;
      }
    }
  });
  return objectsAreEqual;
}