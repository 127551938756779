<template>
  <div class="flex flex-col overflow-y-auto p-3 pb-5" :style="'height:calc(100vh - ' + 98 + 'px)'">
    <div v-if="isLoading" class="mt-14 flex-grow w-full flex items-center max-w-xl mx-auto">
      <ProgressSpinner />
    </div>
    <!-- error -->
    <div v-else-if="(!validToken || localUser?.userPolicies[0]?.isEnabled) && !isLoading" class="mt-14 flex-grow w-full flex items-center max-w-xl mx-auto">
      <Card pt:header:class="p-5 pb-1" pt:body:class="pt-2" class="drop-shadow-lg ring-primary">
        <template #header>
          <span class="text-3xl">Invalid Token</span>
        </template>
        <template #content>
          <p v-if="localUser?.userPolicies[0]?.isEnabled">The token has already been redeemed. Please contact the administrator for a new token if you need to register.</p>
          <p v-else>The token provided is invalid or expired. Please contact the administrator for a new token.</p>
        </template>
      </Card>
    </div>
    <form
      @submit.prevent="onSubmit"
      v-else-if="validToken && !userEntraID && !isLoading && !localUser.userPolicies[0]?.isEnabled"
      class="mt-14 flex-grow w-full flex items-center max-w-md mx-auto"
    >
      <Card pt:header:class="p-5 pb-1" pt:body:class="pt-2" class="drop-shadow-lg ring-primary">
        <template #header>
          <h3 class="text-2xl text-primary-emphasis">Register</h3>
          <span class="text-sm text-muted-color-emphasis">
            <strong v-if="!tokenObject.isSuperAdmin">{{ companyName }}</strong>
            <strong v-else>Prodify</strong> has invited you as a <em v-if="tokenObject.isSuperAdmin">Super</em> <strong>{{ localUser.userPolicies[0].userRole.role }}</strong>
            <span v-if="!tokenObject.isSuperAdmin"> you to join their team. <br />Please fill out the form below to complete your registration.</span></span
          >
        </template>
        <template #content>
          <div class="flex flex-wrap gap-2 mt-4 first:mt-10">
            <div class="my-2 w-full">
              <InputTextForm v-model="emailAddress" label="Email" labelId="email" disabled />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['email'] }}</span>
            </div>

            <!-- Display name -->
            <div class="my-2 w-full">
              <InputTextForm v-model="formData.displayName" label="Display Name" labelId="displayName" />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['displayName'] }}</span>
            </div>
            <!-- First name -->
            <div class="my-2 w-full">
              <InputTextForm v-model="formData.firstName" label="First Name" labelId="firstName" />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['firstName'] }}</span>
            </div>

            <!-- Last name -->
            <div class="my-2 w-full">
              <InputTextForm v-model="formData.lastName" label="Last Name" labelId="lastName" />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['lastName'] }}</span>
            </div>

            <!-- Password -->
            <div class="my-2 w-full">
              <InputTextForm v-model="formData.password" label="Password" type="password" labelId="password" />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['password'] }}</span>
            </div>

            <!-- Confirm Password -->
            <div class="my-2 w-full">
              <InputTextForm v-model="formData.confirmPassword" label="Confirm Password" type="password" labelId="confirmPassword" />
              <span class="text-red-500 text-xs italic w-full">{{ validationErrors['confirmPassword'] }}</span>
            </div>

            <Button label="Submit " type="submit" class="w-1/2 mx-auto" :disabled="formLoading">
              <template #icon>
                <FontAwesomeIcon icon="fa-spinner fa-spin" v-if="formLoading" class="fa fa-spin" />
              </template>
            </Button>
          </div>
        </template>
      </Card>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import * as yup from 'yup'
import inviteService from '@/services/api/invite.service'
import authService from '@/services/api/auth.service'
import { useNotificationStore } from '@/stores/notification.store'
import type { NewUser } from '@/types/UserType'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { UpdatePartialRegisterUser, GetUsersByEmailCompany } from '@/services/graphql/user.graphql'
import { PartialUpdateUserPolicyRegister } from '@/services/graphql/userPolicy.graphql'
import InputTextForm from '@/components/InputTextForm.vue'

const { mutate: updateUserRegisterMutation } = useMutation(UpdatePartialRegisterUser)
const { mutate: updateUserPolicyMutation } = useMutation(PartialUpdateUserPolicyRegister)
const { load, onError } = useLazyQuery(GetUsersByEmailCompany)

// Define refs for form data
const route = useRoute()
const token = ref('')
const tokenObject = ref({} as any)

// query params
const emailAddress = ref('')
const companyName = ref('')
const companyID = ref(0)

// tracking user status
const validToken = ref(null as boolean | null)
const userEntraID = ref(null as string | null)
const localUser = ref(null) as any

// flags
const isLoading = ref(true)
const formLoading = ref(false)

// Notification store
const notificationStore = useNotificationStore()

// validation errors
const validationErrors = ref<Record<string, string>>({})

// Form fields
const formData = ref({
  displayName: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: ''
})
// onMounted lifecycle hook to fetch query params and validate token
onMounted(async () => {
  token.value = route.query.token as string
  emailAddress.value = route.query.login_hint as string
  companyName.value = route.query.companyName as string
  companyID.value = parseInt(route.query.companyID as string)
  formData.value.displayName = emailAddress.value.split('@')[0]
  const queryVariables = { email: emailAddress.value, token: token.value }

  const assetResult = await load(GetUsersByEmailCompany, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })

  localUser.value = assetResult?.usersByEmailCompany[0]

  formData.value.displayName = localUser.value?.displayName ?? emailAddress.value.split('@')[0]
  formData.value.firstName = localUser.value?.firstName || ''
  formData.value.lastName = localUser.value?.lastName || ''
  await validateToken()
})

// watch formData
watch(
  formData.value,
  () => {
    if (Object.keys(validationErrors.value).length > 0) {
      validateSchema()
    }
  },
  { deep: true }
)

// Token validation
async function validateToken() {
  await inviteService
    .validateToken(token.value)
    .then(async (response: any) => {
      validToken.value = response.data !== null
      if (validToken.value) {
        tokenObject.value = response.data
        // if everything is valid, check user status
        await checkUserStatus()
      } else {
        isLoading.value = false
      }
    })
    .catch((error: any) => {
      console.error(error)
    })
}

// Check user status
async function checkUserStatus() {
  try {
    // does graph user exist
    const response = await authService.checkEmailExists(emailAddress.value)
    userEntraID.value = response.data

    // if user exists, redirect to company because they have already registered
    if (userEntraID.value && localUser.value) {
      if (tokenObject.value.isSuperAdmin) {
        await inviteSuperAdmin(userEntraID.value, token.value)
      }
      if (!localUser.value.entraID) {
        // update local user with entra user
        await updateLocalUser(userEntraID.value)
      }
      await updateUserPolicyMutation(
        {
          userPolicyID: localUser.value.userPolicies[0].userPolicyID,
          token: token.value,
          userPolicy: { userStatusID: 2, isEnabled: true }
        },
        { context: { headers: { AnonymousAuthentication: 'true' } } }
      ).then((response) => {
        localUser.value = response?.data.updatePartialUser
        // wait 1 second and redirect to company
        window.location.href = `/${companyID.value}`
      })
    }

    isLoading.value = false
  } catch (error) {
    console.error(error)
  }
}

// Define the yup schema
const registrationSchema = yup.object({
  displayName: yup
    .string()
    .required('Display Name is required')
    // no spaces
    .min(2, 'Display Name must be at least 2 characters'),
  firstName: yup.string().required('First Name is required').min(2, 'First Name must be at least 2 characters'),
  lastName: yup.string().required('Last Name is required').min(2, 'Last Name must be at least 2 characters'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>=]/, 'Password must contain at least one special character'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match')
})

// submit
const onSubmit = async () => {
  formLoading.value = true
  if (await validateSchema()) {
    createEntraUser()
  }
}

// Validation function
const validateSchema = async () => {
  // Reset validation errors
  validationErrors.value = {}

  try {
    // Validate form data with Yup
    await registrationSchema.validate(formData.value, { abortEarly: false })
    return true // Return true if validation passed
  } catch (validationError: any) {
    if (validationError.inner) {
      // Iterate over each validation error and store it in the validationErrors object
      validationError.inner.forEach((error: any) => {
        validationErrors.value[error.path] = error.message
      })
    }
    formLoading.value = false
    return false // Return false if validation failed
  }
}

// update local user with entra user
async function updateLocalUser(entraID: string) {
  // update local user with entraID if it hasn't been updated but a local user exists
  await updateUserRegisterMutation(
    {
      userID: localUser.value.userID,
      token: token.value,
      user: { entraID: entraID, firstName: formData.value.firstName, lastName: formData.value.lastName, displayName: formData.value.displayName }
    },
    { context: { headers: { AnonymousAuthentication: 'true' } } }
  ).catch(() => {
    formLoading.value = false
    notificationStore.showError('Update Failed', `An error occurred while updating the user ${emailAddress.value}`)
  })
}

// Creates a user in entra
async function createEntraUser() {
  // random password (Letters and numbers and symbols)
  const newEntaUser = {
    accountEnabled: true,
    displayName: formData.value.displayName,
    mailNickname: emailAddress.value.split('@')[0],
    mail: emailAddress.value,
    passwordProfile: {
      forceChangePasswordNextSignIn: false,
      password: formData.value.password
    },
    identities: [
      {
        signInType: 'emailAddress',
        issuer: 'prodifynet.onmicrosoft.com',
        issuerAssignedId: emailAddress.value
      }
    ]
  } as NewUser

  // create entra user
  await authService
    .createRegisterUser(newEntaUser, token.value)
    .then(async (response) => {
      // create app role assignment
      await createAppRoleAssignment(response.data.id)

      // if the token shows isSA then update SA status
      if (tokenObject.value.isSuperAdmin) {
        await inviteSuperAdmin(response.data.id, token.value)
      }

      // update local user policy
      await updateLocalUserPolicy(response.data.id)
    })
    .catch(() => {
      formLoading.value = false
      notificationStore.showError('User Creation Failed', `An error occurred while creating the user ${emailAddress.value}`)
    })
}

async function createAppRoleAssignment(entraID: string) {
  // add appRoleAssignments (required for the app to work with the user)
  await authService
    .createRegisterAppRoleAssignment(entraID, token.value)
    .then(async () => {
      notificationStore.showSuccess('User Created', `User ${emailAddress.value} has been created successfully`)
    })
    .catch(() => {
      formLoading.value = false
      notificationStore.showError('App Role Assignment Failed', `An error occurred while assigning app role to ${emailAddress.value}`)
    })
}

async function updateLocalUserPolicy(entraID: string) {
  // update local user with entra user
  await updateLocalUser(entraID)

  // update user status
  await updateUserPolicyMutation(
    {
      userPolicyID: localUser.value.userPolicies[0].userPolicyID,
      token: token.value,
      userPolicy: { userStatusID: 2, isEnabled: true }
    },
    { context: { headers: { AnonymousAuthentication: 'true' } } }
  )
    .then(() => {
      // wait 2 second and redirect to company
      setTimeout(() => {
        window.location.href = `/company/${companyID.value}`
      }, 1000)
    })
    .catch(() => {
      formLoading.value = false
      notificationStore.showError('User Status Update Failed', `An error occurred while updating the user status for ${emailAddress.value}`)
    })
}

async function inviteSuperAdmin(entraID: string, token: string) {
  await authService
    .inviteUserToSuperAdminAsync(entraID, token)
    .then(() => {
      notificationStore.showSuccess('User Added as Super Admin', `User has been added as a super admin`)
    })
    .catch(() => {
      notificationStore.showError('Setting Super Admin Failed', `An error occurred while setting the user as a super admin`)
    })
}

onError((error) => {
  console.error(error)
  isLoading.value = false
  validToken.value = false
})
</script>
