

import apiClient from "./main";

//api resource endpoint
const RESOURCE_NAME = 'api/invite';

export default {
    validateToken(token: string) {
        return apiClient.get(RESOURCE_NAME + '/validate-token?token=' + token);
    }
}