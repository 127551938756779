
import { defineStore } from 'pinia'
import type { FailedFile, ProcessedFile } from '@/types/UploaderType'
import { ref } from 'vue'

export const useUploaderStore = defineStore('UploaderStore', () => {
    const uploaderProfile = ref({
        processedFiles: [] as ProcessedFile[],
        completedFiles: [] as ProcessedFile[],
        failedFiles: [] as FailedFile[],
        files: [] as ProcessedFile[],
        companyId: '0',
        totalFiles: 0,
        isUploading: false,
        progress: 0,
        currentStatus: '',
        activeTab: 0,
    })

    return {
        uploaderProfile
    }
})