import * as yup from 'yup';

export const companySettingsSchema = yup.object().shape({
    subscription: yup.string().nullable(),
    companyName: yup.string().required().test('Company Name', 'Company name is required', (value) => {
        if (!value) {
            return createValidationError('Company Name', 'Company name is required');
        }
        return true;
    }),
    website: yup.string().url('Invalid URL').nullable().test('website', 'Invalid URL', (value) => {
        if (value && !yup.string().url().isValidSync(value)) {
            return createValidationError('Website', 'Invalid URL');
        }
        return true;
    }),
    companyCountry: yup.string().nullable(),
    companyState: yup.string().nullable(),
    companyCity: yup.string().nullable(),
    companyAddress1: yup.string().nullable(),
    companyAddress2: yup.string().nullable(),
    phone: yup.string().matches(/^(\(\d{3}\) \d{3}-\d{4}|)$/, 'Invalid phone number').nullable().notRequired().test('Phone', 'Invalid phone number', (value) => {
        if (value && !/^(\(\d{3}\) \d{3}-\d{4}|)$/.test(value)) {
            return createValidationError('Phone', 'Invalid phone number');
        }
        return true;
    }),
    companyZip: yup.string().nullable(),
    billingCountry: yup.string().nullable(),
    billingState: yup.string().nullable(),
    billingCity: yup.string().nullable(),
    billingAddress1: yup.string().nullable(),
    billingAddress2: yup.string().nullable(),
    billingZip: yup.string().nullable(),
}).test('companyAddressValidation',
    // Custom validation for company and billing address fields (to ensure all fields are filled if any are filled)
    function (values) {
        const {
            companyCountry,
            companyState,
            companyCity,
            companyAddress1,
            companyZip,
            billingCountry,
            billingState,
            billingCity,
            billingAddress1,
            billingZip,
        } = values;

        const companyFields = {
            companyCountry,
            companyState,
            companyCity,
            companyAddress1,
            companyZip,
        };

        const billingFields = {
            billingCountry,
            billingState,
            billingCity,
            billingAddress1,
            billingZip,
        };

        // variables to store the field names and values
        const companyFieldNames = fixFieldName(Object.keys(companyFields));
        const billingFieldNames = fixFieldName(Object.keys(billingFields));

        const companyValues = Object.values(companyFields);
        const billingValues = Object.values(billingFields);

        const isCompanyValid = companyValues.some(field => field) ? companyValues.every(field => field) : true;
        const isBillingValid = billingValues.some(field => field) ? billingValues.every(field => field) : true;

        let companyErrors = '' as string;
        let billingErrors = '' as string;

        // If any of the company fields are filled, all must be filled
        if (!isCompanyValid) {
            const missingCompanyFields = companyFieldNames.filter((_, index) => !companyValues[index]);
            companyErrors = missingCompanyFields.join(', ');
        }

        // If any of the billing fields are filled, all must be filled
        if (!isBillingValid) {
            const missingBillingFields = billingFieldNames.filter((_, index) => !billingValues[index]);
            billingErrors = missingBillingFields.join(', ');
        }

        // custom error message
        const path = `${companyErrors ? `Company Address ` : ''}${billingErrors && companyErrors ? ' and ' : ''}${billingErrors ? `Billing Address ` : ''}fields are required`
        const message = `${billingErrors}${companyErrors && billingErrors ? ' and ' : ''}${companyErrors ? `${companyErrors}` : ''}`

        if (companyErrors || billingErrors) {
            return createValidationError(path, message);
        }

        return true;
    });

// Helper function to create a validation error
const createValidationError = (path: string, message: string) => {
    return new yup.ValidationError({
        path,
        message,
    } as yup.ValidationError);
};

// Helper function to fix field names (capitalize first letter and space between words for better readability)
const fixFieldName = (fields: string[]) => {
    return fields.map(field => {
        return field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    });
};

export type CompanySettingsSchema = yup.InferType<typeof companySettingsSchema>;
