import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useErrorStore = defineStore('ErrorStore', () => {
  const errorTitle = ref('')
  const errorMessage = ref('')
  const displaySignOut = ref(false)

  function setErrorMessage(message: string, title: string, shouldDisplaySignOut: boolean = false) {
    errorTitle.value = title
    errorMessage.value = message
    displaySignOut.value = shouldDisplaySignOut
  }
  
  return {
    errorTitle,
    errorMessage,
    displaySignOut,
    setErrorMessage
  }
})
