<template>
  <div class="flex items-center justify-center">
    <Card class="w-1/3 mt-40 bg-red-50">
      <template #title>
        <div class="flex items-center justify-center text-red-500 text-2xl">{{ errorStore.errorTitle }}</div>
      </template>
      <template #content>
        <div class="flex flex-col gap-5 items-center justify-center">
          <p>{{ errorStore.errorMessage }}</p>
          <Button v-if="errorStore.displaySignOut" size="small" class="text-black hover:text-white" severity="contrast" outlined @click="logout()">Sign out</Button>
        </div>
      </template>
    </Card>
  </div>
</template>
<script setup lang="ts">
import { useErrorStore } from '@/stores/error.store'
import { useUserStore } from '@/stores/user.store'
import { useMsal } from '@/composables/b2c/utils/useMsal'

const { instance, accounts } = useMsal()

// Define store.
const errorStore = useErrorStore()
const userStore = useUserStore()

// Define Functions.
const logout = () => {
  const account = instance.getAccount({ homeAccountId: accounts.value[0].homeAccountId })

  if (account) {
    userStore.logout()
    instance.logoutRedirect({
      account: account
    })
  } else {
    console.error('Account not found')
  }
}
</script>
