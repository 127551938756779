<template>
  <DataTable
    ref="dt"
    dataKey="assetID"
    :value="filteredList"
    v-model:selection="selectedObject"
    @row-select-all="emits('rowSelectAll', $event)"
    @row-unselect-all="emits('rowUnselectAll', $event)"
    scrollable
    :scrollHeight="props.tableHeight"
    showGridlines
    paginator
    v-model:rows="rowsPerPage"
    :rowsPerPageOptions="[10, 20, 50, 100]"
    size="small"
    :editMode="props.isEditMode ? 'cell' : ''"
    @cell-edit-complete="emits('cellEditComplete', $event)"
    resizableColumns
    columnResizeMode="expand"
    v-model:first="firstRow"
    :pt="{
      column: {
        bodyCell: (data: any) => {
          if (data.props.field === 'blobUrl') {
            return 'p-0'
          }
        },
        // z-index fix for selection column
        headerCell: (data: any) => {
          if (data.props.selectionMode) {
            return 'z-10'
          }
        }
      },
      // z-index fix for selection column
      thead: {
        class: 'z-10'
      }
    }"
  >
    <!--Selection Column-->
    <Column selectionMode="multiple"></Column>
    <!--Preview Column-->
    <Column field="blobUrl" key="1">
      <template #header>
        <div class="flex items-center">
          <span>Preview</span>
        </div>
      </template>
      <template #body="{ data }">
        <div class="flex flex-nowrap w-full !p-0 !m-0">
          <ObjectPreview :object="data" type="thumbnail" />
        </div>
      </template>
    </Column>
    <!--ID and Route Column-->
    <Column field="referenceID" key="2">
      <template #header>
        <div class="flex items-center">
          <span>Asset ID</span>
        </div>
      </template>
      <template #body="{ data, field }">
        <div class="flex flex-nowrap mx-1 justify-between">
          <span>{{ data[field] }}</span>
          <RouterLink :to="{ name: 'assetForm', params: { id: data['assetID'] } }">
            <FontAwesomeIcon icon="fa-regular fa-arrow-up-right-from-square" class="ml-2 cursor-pointer hover:text-primary-600"></FontAwesomeIcon>
          </RouterLink>
        </div>
      </template>
    </Column>
    <!--Actions Column-->
    <Column key="3">
      <template #header>
        <div class="flex items-center">
          <span>Actions</span>
        </div>
      </template>
      <template #body="{ data }">
        <div class="flex flex-nowrap gap-2 justify-center">
          <FontAwesomeIcon
            icon="fa-regular fa-arrow-down-to-bracket"
            class="cursor-pointer hover:text-primary-600"
            @click="emits('downloadAction', data.assetID, isAuthenticated)"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            v-if="isAuthenticated && IsFavorite(data.object)"
            icon="fa-solid fa-star"
            class="cursor-pointer hover:text-primary-600"
            @click="emits('unfavoriteAction', data)"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            v-else-if="isAuthenticated"
            icon="fa-regular fa-star"
            class="cursor-pointer hover:text-primary-600"
            @click="emits('favoriteAction', data)"
          ></FontAwesomeIcon>
        </div>
      </template>
    </Column>
    <!--Dynamic Columns-->
    <template v-for="(column, index) in props.columns" :key="index">
      <Column v-if="column.isVisible" :field="column.fieldValue" :type="column.fieldType" :style="'max-width:' + column.width">
        <template #header>
          <!--We need to set the column width (if defined) in the header or else it doesn't work-->
          <div class="flex items-center">
            <span>{{ column.fieldName }}</span>
          </div>
        </template>
        <template #body="{ data, field }">
          <component
            :is="getFieldType(column.fieldType)"
            :formatType="field"
            :modelValue="_.get(data, convertNestedField(field))"
            @update:modelValue="_.set(data, field, $event)"
            fieldMode="view"
            fieldParent="table"
            :relationshipType="column.relationshipType"
            :options="column.selectOptions"
            :selectID="column.selectID"
          />
        </template>
        <template v-if="props.isEditMode && column.isEditable" #editor="{ data, field }">
          <component
            :is="getFieldType(column.fieldType)"
            :modelValue="_.get(data, convertNestedField(field))"
            @update:modelValue="_.set(data, convertNestedField(field), $event)"
            fieldMode="edit"
            fieldParent="table"
            :relationshipType="column.relationshipType"
            :options="column.selectOptions"
            :selectID="column.selectID"
          />
        </template>
      </Column>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import type { Asset } from '@/types/AssetType'
import type { Folder } from '@/types/FolderType'
import type { Field } from '@/types/FieldType'
import type { Filter } from '@/types/FilterType'
import _ from 'lodash'
import { convertNestedField, getFieldType } from '@/composables/fieldHelpers'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import { IsFavorite } from '@/composables/objectHelpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ObjectPreview from './ObjectPreview.vue'
import { computed } from 'vue'
import { searchObjects, filterObjects } from '@/composables/filterHelper'

const props = withDefaults(
  defineProps<{
    objectList: Asset[] | Folder[]
    filteredList?: Asset[] | Folder[]
    selectedObjects: Asset[] | Folder[]
    tableHeight: string
    columns: Field[]
    isEditMode: boolean
    page?: number
    rowsPerPage?: number
    search?: string
    isTableLoading?: boolean
    filters?: Filter[]
  }>(),
  {
    page: 1,
    rowsPerPage: 20
  }
)

const selectedObject = computed({
  get() {
    // get the selected objects from the parent component
    return props.selectedObjects
  },
  set(value) {
    // keep the parent component in sync with the selected objects
    emits('update:selectedObjects', value)
  }
})

const rowsPerPage = computed({
  get() {
    return props.rowsPerPage
  },
  set(value) {
    emits('update:rowsPerPage', value)
  }
})

const firstRow = computed({
  get() {
    return (props.page - 1) * props.rowsPerPage
  },
  set(value) {
    emits('update:page', Math.floor(value / props.rowsPerPage) + 1)
  }
})

const filteredList = computed(() => {
  const isSearch = props.search && props.search !== ''
  const isFilter = props.filters && props.filters.length > 0

  let filteredList = props.objectList

  if (isSearch) {
    const fieldsToSearch = props.columns.filter((column) => column.isSearchable)
    filteredList = searchObjects(filteredList, fieldsToSearch, props.search)
  }

  if (isFilter) {
    filteredList = filterObjects(filteredList, props.filters)
  }

  // update the filtered list to be able to count the total number of rows in the parent component.
  emits('update:filteredList', filteredList)

  return filteredList
})

const emits = defineEmits([
  'update:rowsPerPage',
  'update:page',
  'update:filteredList',
  'rowSelectAll',
  'rowUnselectAll',
  'cellEditComplete',
  'downloadAction',
  'favoriteAction',
  'unfavoriteAction',
  'update:selectedObjects',
  'update:filteredList'
])

const isAuthenticated = useIsAuthenticated()
</script>

<style scoped>
/* Remove border from paginator */
:deep(.p-datatable-paginator-bottom) {
  border: 0px;
}
</style>
