<template>
  <div class="p-3 bg-gray-100 rounded-lg shadow-lg">
    <div class="flex w-full justify-end">
      <span v-tooltip.top="'refresh the page'" @click="refreshPage">
        <font-awesome-icon icon="far fa-arrow-rotate-left" class="h-6 mr-1 pb-1 cursor-pointer scale-x-[-1]" />
      </span>
    </div>
    <div
      v-for="option in options"
      :key="option.value"
      @click="() => actAsRole(option)"
      :class="[
        'flex items-center p-4 pl-2 mb-2 cursor-pointer rounded-lg transition-colors',
        actingAs && actingAs.value === option.value ? 'bg-secondary-700 text-white ' : 'bg-white hover:bg-gray-200'
      ]"
    >
      <font-awesome-icon :icon="option.icon" class="h-6 w-6 mr-1" />
      <div>
        <div class="text-lg font-semibold">{{ option.label }}</div>
        <div class="text-sm">{{ option.description }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user.store'
import { onMounted, ref } from 'vue'
const actingAs = ref<{ label: string; value: number } | null>(null)
const userStore = useUserStore()

onMounted(() => {
  if (userStore.ActingAsSA) actingAs.value = userStore.ActingAsSA
  else actingAs.value = { label: 'SuperAdmin', value: 0 }
})

const options = [
  { label: 'SuperAdmin', value: 0, icon: 'far fa-user-secret', description: 'Has full access' },
  { label: 'Admin', value: 1, icon: 'far fa-user-shield', description: 'Can manage settings' },
  { label: 'Editor', value: 2, icon: 'far fa-pen', description: 'Can edit content' },
  { label: 'Viewer', value: 3, icon: 'far fa-eyes', description: 'Can view content' }
]

function actAsRole(role: { label: string; value: number }) {
  if (userStore.isSuperAdmin) {
    const newRole = {
      label: role.label,
      value: role.value
    }
    actingAs.value = newRole
    userStore.ActingAsSA = newRole
  } else {
    console.error('Only SuperAdmins can act as another role')
  }
}

function refreshPage() {
  window.location.reload()
}
</script>
