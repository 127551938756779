<template>
  <Accordion value="null">
    <AccordionPanel>
      <AccordionHeader :pt="accordionHeaderStyle">{{ props.filterTitle }}</AccordionHeader>
      <AccordionContent :pt="accordionContentStyle">
        <div class="flex flex-col gap-3">
          <div v-for="multiselectOption of props.multiselectOptions" :key="multiselectOption.value">
            <Checkbox
              v-model="selectedValues"
              :inputId="multiselectOption.label"
              :name="props.objectPropertyName"
              :value="multiselectOption.value"
              :pt="{ icon: { class: ['text-black'] }, box: { class: { 'bg-transparent': !selectedValues.includes(multiselectOption.value) } } }"
            />
            <label :for="multiselectOption.label" class="text-white ml-2">{{ multiselectOption.label }}</label>
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import _ from 'lodash'

// Define Props
const props = defineProps<{
  filterTitle: string
  objectPropertyName: string
  multiselectOptions: { value: number; label: string }[]
  multiselectValues: number[]
}>()

// Define variables
const selectedValues = ref(props.multiselectValues)
const accordionHeaderStyle = {
  root: { class: ['text-white', 'font-normal', 'bg-transparent', 'border-0', 'h-1'] },
  toggleicon: { class: ['text-white'] }
}
const accordionContentStyle = { content: { class: ['text-white', 'bg-transparent', 'border-0', 'ml-3'] } }

// Define Emits
const emits = defineEmits(['update:multiselectValues', 'filterListByMultiSelectValues'])

// Define Watchers
watch(selectedValues, () => {
  filterList()
})

watch(
  () => props.multiselectValues,
  (newValue) => {
    selectedValues.value = newValue
  }
)

// Use lodash debounce to limit the number of times the filterList function is called
const filterList = _.debounce(() => {
  emits('update:multiselectValues', selectedValues.value)
  emits('filterListByMultiSelectValues', selectedValues.value, props.objectPropertyName)
}, 1000)
</script>
