<template>
  <div v-if="!dataLoaded">
    <div class="flex justify-center">
      <div class="mt-20">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else>
    <Splitter style="height: calc(100vh - 96px)">
      <SplitterPanel :size="20">
        <!--FOLDER SIDEBAR-->
        <AppSidebar
          v-model:visible="isSidebarMobileVisible"
          :selected-node-folder-id="companyStore.folder.selectedFolderID"
          @filter-list-by-selected-node-folder="filterAssetsBySelectedNode"
          @filter="filters = $event"
        >
        </AppSidebar>
      </SplitterPanel>
      <SplitterPanel :size="80" :minSize="20">
        <div class="flex flex-col p-5 gap-5">
          <!--Page Header-->
          <AppHeaderPage>
            <template #title> {{ objectListCount }} Assets </template>
            <template #buttons>
              <Button
                v-if="userCanEdit && !isTableEditMode && isTableViewSelected"
                class="text-black hover:text-white"
                size="small"
                label="Edit"
                severity="contrast"
                outlined
                @click="toggleEditModeAssets()"
              ></Button>
              <Button v-if="userCanEdit && !isTableEditMode" class="text-black hover:text-white" size="small" label="Add" severity="primary" @click="goToAssetUploader()"></Button>
              <Button
                v-if="userCanEdit && isTableEditMode && isTableViewSelected"
                class="text-black hover:text-white"
                size="small"
                label="Cancel"
                severity="contrast"
                outlined
                @click="cancelEdit()"
              ></Button>
              <Button
                v-if="userCanEdit && isTableEditMode && isTableViewSelected"
                class="text-black hover:text-white hover:disabled:text-black"
                size="small"
                label="Save"
                severity="primary"
                :disabled="disableSave"
                @click="openSaveChangesModal()"
              ></Button>
            </template>
          </AppHeaderPage>
          <!--Page Toolbar-->
          <AppToolbarPage 
            :isTableViewSelected="isTableViewSelected" 
            :isDisplayViewButtonsVisible="true" 
            @isTableViewSelected="toggleTableView($event)" 
            :sortFields="isTableViewSelected ? tableColumns : cardFields"
            @search="searchQuery = $event"
            @selected-filter-action="filterViewBySelectedFilter"
            @set-sort-order="setSortOrder"
          >
        </AppToolbarPage>
          <!--Folders Toolbar Mobile-->
          <Button class="block sm:hidden text-white bg-black w-fit" label="Folders" @click="isSidebarMobileVisible = !isSidebarMobileVisible"></Button>
          <!--Selection toolbar-->
          <AppToolbarSelection :selectedObjects="assetStore.data.selectedAssets" :items="assetTableSelectionMenuItems" @clearSelectedObjects="unselectAll" />
          <!--ASSETS VIEW-->
          <template v-if="isTableViewSelected">
            <ObjectTable
              :tableHeight="hasSelectedAssets ? 'calc(100vh - 351px)' : 'calc(100vh - 282px)'"
              :objectList="filteredAssets"
              v-model:filtered-list="ultimateFilteredList"
              v-model:selectedObjects="assetStore.data.selectedAssets"
              :columns="tableColumns"
              :isEditMode="isTableEditMode"
              :page="companyStore.asset.page"
              :rowsPerPage="companyStore.asset.rowsPerPage"
              :search="searchQuery"
              :filters="filters"
              @cell-edit-complete="cellEditComplete"
              @row-select-all="selectAll"
              @row-unselect-all="unselectAll"
              @downloadAction="downloadAction"
              @favoriteAction="favoriteAction"
              @unfavoriteAction="unfavoriteAction"
              @update:page="companyStore.asset.page = $event"
              @update:rowsPerPage="companyStore.asset.rowsPerPage = $event"
            >
            </ObjectTable>
          </template>
          <template v-else>
            <ObjectGallery
              :objectList="filteredAssets"
              v-model:filtered-list="ultimateFilteredList"
              :selectedObjects="assetStore.data.selectedAssets"
              :galleryHeight="hasSelectedAssets ? 'calc(100vh - 351px)' : 'calc(100vh - 282px)'"
              :fields="cardFields"
              :search="searchQuery"
              :filters="filters"
              :menu-items="menuItems"
              :is-select-checkbox-visible="true"
              :is-edit-mode="userCanEdit"
              objectType="asset"
              @toggleSelect="galleryToggleSelect"
              :page="companyStore.asset.page"
              :rowsPerPage="companyStore.asset.rowsPerPage"
              @update:page="companyStore.asset.page = $event"
              @update:rowsPerPage="companyStore.asset.rowsPerPage = $event"
            >
            </ObjectGallery>
          </template>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
  <DynamicDialog />
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import { onBeforeRouteLeave, useRouter } from 'vue-router';
  import { useNotificationStore } from '@/stores/notification.store';
  import { useCompanyStore } from '@/stores/company.store';
  import { useUserStore } from '@/stores/user.store';
  import { useAssetStore } from '@/stores/asset.store';
  import { useFolderStore } from '@/stores/folder.store';
  import { useStatusStore } from '@/stores/status.store';
  import { useAudienceStore } from '@/stores/audience.store';
  import { useTagStore } from '@/stores/tag.store';
  import { useAssetTypeStore } from '@/stores/assetType.store';
  import AppSidebar from '@/components/AppSidebar.vue';
  import ObjectTable from '@/components/ObjectTable.vue';
  import ObjectGallery from '@/components/ObjectGallery.vue';
  import type { Field } from '@/types/FieldType';
  import type { Asset } from '@/types/AssetType';
  import type { DataTableCellEditCompleteEvent } from 'primevue/datatable';
  import type { Filter } from '@/types/FilterType'
  import { downloadAction, downloadAssets } from '@/composables/downloadHelper'
  import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated';
  import { useDialog } from  'primevue/usedialog'
  import { HasPermission } from '@/composables/permissionsHelper'
  import { openViewChangesDialog, openConfirmEntityDialog, openShareDialog, openAssetDataExportDialog, openBatchEditSingleSelectFieldDialog, openBatchEditMultiSelectFieldDialog } from '@/composables/dialogOptions';
  import AppToolbarSelection from '@/components/AppToolbarSelection.vue';
  import { IsFavorite } from '@/composables/objectHelpers';
  import AppHeaderPage from '@/components/AppHeaderPage.vue';
  import AppToolbarPage from '@/components/AppToolbarPage.vue';
import { SortAssetList } from '@/composables/sortObjectFieldValue';


const isAuthenticated = useIsAuthenticated()
const router = useRouter()
const dialog = useDialog()

// STORES
const notificationStore = useNotificationStore()
const companyStore = useCompanyStore()
const userStore = useUserStore()
const assetStore = useAssetStore()
const folderStore = useFolderStore()
const statusStore = useStatusStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const assetTypeStore = useAssetTypeStore()

// LOCAL PROPERTIES
const isTableViewSelected = ref(true)
const isTableEditMode = ref(false)
const isSidebarMobileVisible = ref(false)
const entityList = ref([]) as any
const dataLoaded = ref(false)
const isFavorited = ref(false)
const ultimateFilteredList = ref<Array<Asset>>([])

const tableColumns = ref<Array<Field>>([])
const cardFields = ref<Array<Field>>([])
const sortOrder = ref('asc');
const sortFilter = ref(null)

// filter search query
const searchQuery = ref('')

// sidebar filters
const filters = ref<Array<Filter>>([])

onBeforeRouteLeave(() => {
  assetStore.$patch((state: any) => {
    state.data.selectedAssets = []
  })
})

// Gallery Card Menu Items
const menuItems = ref([
  {
    label: isFavorited.value ? 'Unfavorite' : 'Favorite',
    icon: isFavorited.value ? 'fa-solid fa-star' : 'fa-regular fa-square-minus',
    command: async (assetID: any) => {
      const asset = assetStore.data.assets.find((folder: Asset) => folder.assetID === assetID)
      if (asset) {
        isFavorited.value = IsFavorite(asset.object)
        if (isFavorited.value) {
          await unfavoriteAssets([asset.assetID])
        } else {
          await favoriteAssets([asset.assetID])
        }
      }
    }
  },
  {
    label: 'Download',
    icon: 'fa-regular fa-arrow-down-to-bracket',
    command: (assetID: any) => {
      downloadAction(assetID, isAuthenticated.value)
    }
  },
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: (assetID: number) => {
      const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === assetID)
      if (asset) {
        openSharedAssetsModal([asset])
      }
    }
  },
  HasPermission('Editor')
    ? {
        label: 'Delete',
        icon: 'fa-regular fa-trash-can',
        command: (assetID: number) => {
          //get the asset name from the asset store based on the id
          const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === assetID)
          trashAssetsConfirm(asset)
        }
      }
    : null,
  {
    label: 'Duplicate',
    icon: 'fa-regular fa-copy',
    command: (assetID: number) => {
      notificationStore.showInfo(`Delete object action menu. AssetID ${assetID}`, 'This feature is not yet implemented.')
    }
  },
  {
    label: 'Rename',
    icon: 'fa-regular fa-pen',
    command: (assetID: number) => {
      notificationStore.showInfo(`Rename object action menu. AssetID ${assetID}`, 'This feature is not yet implemented.')
    }
  }
])

// COMPUTED PROPERTIES
const disableSave = computed(() => assetStore.trackedChanges.length <= 0)
const objectListCount = computed(() => ultimateFilteredList.value.length)
const hasSelectedAssets = computed(() => assetStore.data.selectedAssets.length > 0)
const userCanEdit = computed(() => HasPermission('Editor', companyStore.data.company.companyID))
const selectionIsFavs = computed(() => assetStore.data.selectedAssets.every((asset: Asset) => IsFavorite(asset.object)))
// Selection Menu Items
const assetTableSelectionMenuItems = computed(() => [
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: () => {
      openSharedAssetsModal(assetStore.data.selectedAssets)
    }
  },
  {
    label: 'Favorite',
    icon: 'fa-regular fa-star',
    visible: !selectionIsFavs.value,
    command: () => {
      favoriteSelectedAssets()
    }
  },
  {
    label: 'Unfavorite',
    icon: 'fa-regular fa-square-minus',
    visible: selectionIsFavs.value,
    command: () => {
      unfavoriteSelectedAssets()
    }
  },
  {
    label: 'Download',
    icon: 'fa-regular fa-arrow-down-to-bracket',
    visible: !notificationStore.isDownloading,
    command: () => {
      if (!isAuthenticated.value) {
        notificationStore.showInfo('Please login to download assets')
        return
      }
      const modalOptions = {
        emits: {
          onConfirm: (exportData: boolean, downloadData: boolean) => {
            downloadAssets(downloadData, exportData, isAuthenticated.value)
          }
        }
      }
      openAssetDataExportDialog(dialog, modalOptions)
    }
  },
  {
    label: 'Downloading',
    icon: 'fa-solid fa-spinner',
    iconClass: 'animate-spin animate-ease-in-out',
    visible: notificationStore.isDownloading
  },
  {
    label: 'Trash',
    icon: 'fa-regular fa-trash-can',
    command: () => {
      trashAssetsConfirm()
    }
  },
  {
    label: 'Batch Edit',
    icon: 'fa-regular fa-ellipsis-vertical',
    items: [
      {
        label: 'Status',
        icon: 'fa-regular fa-circle-dashed',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Status'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'statusID',
              selectOptions: statusStore.data.statuses
            },
            emits: {
              onSave: async () => {
                saveChanges()
              }
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Tags',
        icon: 'fa-regular fa-tag',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Tags'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'tags',
              selectID: 'tagID',
              selectOptions: tagStore.data.tags
            },
            emits: {
              onSave: async () => {
                saveChanges()
              }
            }
          }
          openBatchEditMultiSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Folders',
        icon: 'fa-regular fa-folder',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Folders'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'folders',
              selectID: 'folderID',
              selectOptions: folderStore.data.folders
            },
            emits: {
              onSave: async () => {
                saveChanges()
              }
            }
          }
          openBatchEditMultiSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Audience',
        icon: 'fa-regular fa-users',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Audience'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'audienceID',
              selectOptions: audienceStore.data.audiences
            },
            emits: {
              onSave: async () => {
                saveChanges()
              }
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      },
      {
        label: 'Asset Type',
        icon: 'fa-light fa-file',
        command: () => {
          const modalOptions = {
            props: {
              header: 'Asset Type'
            },
            data: {
              entityList: assetStore.data.selectedAssets,
              field: 'assetTypeID',
              selectOptions: assetTypeStore.data.assetTypes
            },
            emits: {
              onSave: async () => {
                saveChanges()
              }
            }
          }
          openBatchEditSingleSelectFieldDialog(dialog, modalOptions)
        }
      }
    ]
  }
])

// Filter the assets by selected folder
const filteredAssets = computed(() => {
  if (companyStore.folder.selectedFolderID === 0) {
    return assetStore.data.assets
  } else {
    return assetStore.data.assets.filter((asset: Asset) => asset.folders.some((folder) => folder.folderID === companyStore.folder.selectedFolderID))
  }
})

// METHODS
// Watch for changes in the filtered assets and update the ultimate filtered list and
// be able to count the total number of rows in the parent component.
watch(filteredAssets.value, () => {
  ultimateFilteredList.value = filteredAssets.value
})

// load assets and folders data on component mount
onMounted(async () => {
  if (assetStore.tableView === 'gallery') {
    isTableViewSelected.value = false
  }
  await assetStore.loadAssetsData(companyStore.companyID)
  await folderStore.loadFoldersData(companyStore.companyID)

    // Set fields for the table and gallery.  Note: Eventually these will both come from the DB or a store
    tableColumns.value = [
      // Key needs to start at 4 to make room for static columns
      { fieldKey: 4, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: true, isEditable: true, isSearchable:true, width: '200px' },
      { fieldKey: 5, fieldName: 'Status', fieldValue: 'statusID', fieldType: 'singleSelect', fieldObject:'status', isVisible: true, isSortable: true, isEditable: true, width: '100px', isSearchable:true, selectID: 'statusID', selectOptions: statusStore.data.statuses },
      { fieldKey: 6, fieldName: 'Created By', fieldValue: 'object.createdBy', fieldType: 'relationship', relationshipType: 'user', isVisible: true, isSortable: true, isEditable: false },
      { fieldKey: 7, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
      { fieldKey: 8, fieldName: 'Last Edited', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
      { fieldKey: 6, fieldName: 'Edited By', fieldValue: 'object.lastModifiedBy', fieldType: 'relationship', relationshipType: 'user', isVisible: true, isSortable: true, isEditable: false },
      { fieldKey: 9, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isSortable: false, isEditable: true, isSearchable:true,  width: '300px', selectID: 'tagID', selectOptions: tagStore.data.tags },
      { fieldKey: 10, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true, isSortable: false, isEditable: false },
      { fieldKey: 11, fieldName: 'Folders', fieldValue: 'folders', fieldType: 'multiSelect', relationshipType: 'folder', isVisible: true, isSortable: false, isSearchable:true, isEditable: true, width: '260px', selectID: 'folderID', selectOptions: folderStore.data.folders },
      { fieldKey: 12, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSortable: false, isEditable: true, isSearchable:true, width: '300px' },
      { fieldKey: 13, fieldName: 'Audience', fieldValue: 'audienceID', fieldType: 'singleSelect',  isVisible: true, isSortable: false, isSearchable:true, fieldObject:'audience', isEditable: true, selectID: 'audienceID', selectOptions: audienceStore.data.audiences },
      { fieldKey: 14, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: false, isSearchable:true,  isEditable: false },
      { fieldKey: 15, fieldName: 'Asset Type', fieldValue: 'assetTypeID', fieldType: 'singleSelect', fieldObject:'assetTypes', isVisible: true, isSortable: false, isEditable: true, selectID: 'assetTypeID', selectOptions: assetTypeStore.data.assetTypes }
    ]
    cardFields.value = [
      { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSearchable:true, isSortable: true  },
      { fieldKey: 2, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSearchable:true  },
      { fieldKey: 3, fieldName: 'Created By', fieldValue: 'object.createdBy', fieldType: 'relationship', isVisible: true, isSearchable:true, isSortable: true, relationshipType: 'user' },
      { fieldKey: 4, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true },
      { fieldKey: 5, fieldName: 'Updated By', fieldValue: 'object.lastModifiedBy', fieldType: 'relationship', isVisible: true, isSearchable:true, isSortable: true,  relationshipType: 'user' },
      { fieldKey: 6, fieldName: 'Updated On', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true },
      { fieldKey: 7, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true },
      { fieldKey: 8, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true },
      { fieldKey: 9, fieldName: 'Url', fieldValue: 'blobUrl', fieldType: 'url', isVisible: true },
      { fieldKey: 10, fieldName: 'Asset Type', fieldValue: 'assetTypeID', fieldObject:'assetTypes', fieldType: 'singleSelect', isVisible: true, selectID: 'assetTypeID', selectOptions: assetTypeStore.data.assetTypes  },
      { fieldKey: 11, fieldName: 'Status', fieldValue: 'statusID', fieldType: 'singleSelect', fieldObject:'status', isVisible: true, isSearchable:true, selectID: 'statusID', isSortable: true, selectOptions: statusStore.data.statuses },
      { fieldKey: 12, fieldName: 'Audience', fieldValue: 'audienceID', fieldType: 'singleSelect', fieldObject:'audience', isSearchable:true, isVisible: true, selectID: 'audienceID', selectOptions: audienceStore.data.audiences },
      { fieldKey: 13, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isSearchable:true,  selectID: 'tagID', selectOptions: tagStore.data.tags },
      { fieldKey: 14, fieldName: 'Folders', fieldValue: 'folders', fieldType: 'relationship', isSearchable:true,  isVisible: true, relationshipType: 'folder' }
    ]

  dataLoaded.value = true
})

function toggleTableView(isTableView: boolean) {
  if (isTableEditMode.value) cancelEdit()
  isTableViewSelected.value = isTableView
  assetStore.tableView = isTableView ? 'table' : 'gallery'
}

async function  filterViewBySelectedFilter(filter?: any) {
  //set the sortFilter to the filter if there is one
  //if not the sort was toggled
  if(filter) {
    sortFilter.value = filter;
  }
  else {
    sortFilter.value = null; 
  }
  assetStore.data.assets = SortAssetList(sortFilter.value, assetStore.data.assets, sortOrder.value)
}

function setSortOrder(isAscending: boolean) {
  if(isAscending)
    sortOrder.value = 'asc'
  else 
    sortOrder.value = 'desc'
  //after the order gets set, sort the list 
  if(sortFilter.value) 
    filterViewBySelectedFilter(sortFilter.value)
}

// Row Select All event handler
function selectAll() {
  // Set the selectedAssets list to all assets
  // filtered assets
  assetStore.data.selectedAssets = assetStore.data.assets
}

// Row Unselect All event handler
function unselectAll() {
  // Clear the selectedAssets list
  assetStore.clearSelection()
}

// Toggle edit mode for assets table
function toggleEditModeAssets() {
  isTableEditMode.value = !isTableEditMode.value
}

// Go to Asset Uploader
function goToAssetUploader() {
  router.push({ name: 'assetUploader', params: { company: companyStore.companyID } })
}

// Clears the changes in the store and exits edit mode
function cancelEdit() {
  assetStore.clearPendingChanges()
  isTableEditMode.value = false
}

// Handle cell edit complete event
function cellEditComplete(event: DataTableCellEditCompleteEvent) {
  if (event) {
    let { field, newValue, data } = event
    const asset = assetStore.data.assets.find((asset: Asset) => asset.assetID === data.assetID) as Asset
    asset[field] = newValue
  }
}

// Confirm and save changes
async function openSaveChangesModal() {
  const modalOptions = {
    data: {
      entityList: assetStore.trackedChanges
    },
    emits: {
      onSubmit: async () => {
        saveChanges()
        isTableEditMode.value = false
      }
    }
  }
  openViewChangesDialog(dialog, modalOptions)
}

function saveChanges() {
  // call the saveAssetsData function in the store and handle the response from the mutation
  assetStore
    .saveAssetsData(userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Changes saved successfuly', 'Changes Saved')
      assetStore.$reset()
      assetStore.loadAssetsData(companyStore.companyID)
      folderStore.loadFoldersData(companyStore.companyID)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error saving changes', 'Error')
    })
}

// Favorite action event handler
async function favoriteAction(asset: any) {
  // Call the favoriteAsset function in the store and handle the response from the mutation
  assetStore
    .favoriteAssets([asset.assetID], userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Added ${asset.name} as Favorite`, 'Favorite Added')
      assetStore.loadAssetsData(companyStore.companyID)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error favoriting asset', 'Error')
    })
}

// Unfavorite action event handler
async function unfavoriteAction(asset: any) {
  // Call the unfavoriteAsset function in the store and handle the response from the mutation
  assetStore
    .unfavoriteAssets([asset.assetID], userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess(`Removed ${asset.name} as Favorite`, 'Favorite Removed')
      assetStore.loadAssetsData(companyStore.companyID)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error unfavoriting asset', 'Error')
    })
}

// Favorite action event handler
async function favoriteSelectedAssets() {
  let assetIDsToFavorite = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
  // Call the favoriteAssets function in the store and handle the response from the mutation
  assetStore
    .favoriteAssets(assetIDsToFavorite, userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Added selected assets as Favorites', 'Favorites Added')
      assetStore.clearSelection()
      assetStore.loadAssetsData(companyStore.companyID)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error favoriting assets', 'Error')
    })
}

// Unfavorite action event handler
async function unfavoriteSelectedAssets() {
  let assetIDsToUnfavorite = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
  // Call the unfavoriteAssets function in the store and handle the response from the mutation
  assetStore
    .unfavoriteAssets(assetIDsToUnfavorite, userStore.getUserID)
    .then(() => {
      notificationStore.showSuccess('Removed selected assets as Favorites', 'Favorites Removed')
      assetStore.clearSelection()
      assetStore.loadAssetsData(companyStore.companyID)
    })
    .catch((error: any) => {
      console.error(error)
      notificationStore.showError('Error unfavoriting assets', 'Error')
    })
}

// shows a confirmation modal before trashing assets
function trashAssetsConfirm(asset?: any) {
  entityList.value = []
  //map assetID and assetName to the entity list from the asset store
  assetStore.data.selectedAssets.map((asset: Asset) => {
    entityList.value.push({ entityID: asset.assetID, entityName: asset.name })
  })

  const modalOptions = {
    props: {
      header: 'Move Items to Trash?'
    },
    data: {
      entityList: !asset ? entityList.value : [{ entityID: asset.assetID, entityName: asset.name }],
      isConfirmListModal: true,
      customLabels: {
        submitLabel: 'Move to Trash'
      },
      subText: 'Assets will be moved from all folders to trash.'
    },
    emits: {
      onSubmit: async () => {
        if (asset) trashAssets(asset.assetID)
        else trashAssets()
        //clear the entity list
        entityList.value = []
        asset = undefined
        //clear the selected assets
        assetStore.$patch((state: any) => {
          state.data.selectedAssets = []
        })
      }
    }
  }
  openConfirmEntityDialog(dialog, modalOptions)
}

// Trash assets
function trashAssets(assetID?: number) {
  if (assetID) {
    // Call the trashAssets function in the store and handle the response from the mutation
    assetStore
      .softDeleteAssets([assetID])
      .then(() => {
        notificationStore.showSuccess('Asset moved to trash', 'Asset Trashed')
        assetStore.loadAssetsData(companyStore.companyID)
      })
      .catch((error: any) => {
        console.error(error)
        notificationStore.showError('Error trashing asset', 'Error')
      })
  } else {
    let assetIDsToTrash = assetStore.data.selectedAssets.map((asset: Asset) => asset.assetID)
    // Call the trashAssets function in the store and handle the response from the mutation
    assetStore
      .softDeleteAssets(assetIDsToTrash)
      .then(() => {
        notificationStore.showSuccess('Assets moved to trash', 'Assets Trashed')
        assetStore.clearSelection()
        assetStore.loadAssetsData(companyStore.companyID)
      })
      .catch((error: any) => {
        console.error(error)
        notificationStore.showError('Error trashing assets', 'Error')
      })
  }
}

// Toggle's selection of an object from the gallery/card view
function galleryToggleSelect(object: Asset) {
  if (assetStore.data.selectedAssets.includes(object)) {
    assetStore.data.selectedAssets = assetStore.data.selectedAssets.filter((asset: Asset) => asset.assetID !== object.assetID)
  } else {
    assetStore.data.selectedAssets.push(object)
  }
}

// Open the shared assets modal
function openSharedAssetsModal(assets: Asset[]) {
  const modalOptions = {
    data: {
      objects: assets,
      objectType: 'asset'
    },
    emits: {
      onAssetShared: async () => {}
    }
  }
  openShareDialog(dialog, modalOptions)
}

// Filter assets by selected folder
async function filterAssetsBySelectedNode(folderID?: number) {
  if (!folderID) {
    companyStore.folder.selectedFolderID = 0
  } else {
    companyStore.folder.selectedFolderID = folderID
  }
}

async function favoriteAssets(assetIDs: number[]) {
  await assetStore.favoriteAssets(assetIDs, userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been added to favorites')
    assetStore.clearSelection()
    await assetStore.loadAssetsData(companyStore.data.company.companyID)
  })
}

async function unfavoriteAssets(assetIDs: number[]) {
  await assetStore.unfavoriteAssets(assetIDs, userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been removed from favorites')
    assetStore.clearSelection()
    await assetStore.loadAssetsData(companyStore.data.company.companyID)
  })
}
</script>
