import type { Status } from '@/types/StatusType'
import type { Tag } from '@/types/TagType'
import type { Folder } from '@/types/FolderType'
import type { Audience } from '@/types/AudienceType'
import type { AssetType } from '@/types/AssetTypeType'

export const subFolderFields = () => [
    { fieldKey: 1, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true },
    { fieldKey: 2, fieldName: 'AssociatedObjects', fieldValue: 'associatedObjects', fieldType: 'text', isVisible: true }
  ]

export const folderFields = (statuses: Status[], tags: Tag[], folders: Folder[], audiences: Audience[], assetTypes: AssetType[]) => [
    // Key needs to start at 4 to make room for static columns
    { fieldKey: 4, fieldName: 'Name', fieldValue: 'name', fieldType: 'text', isVisible: true, isSortable: false, isEditable: true, isSearchable: true, width: '200px' },
    {
      fieldKey: 5,
      fieldName: 'Status',
      fieldValue: 'statusID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: true,
      isEditable: true,
      isSearchable: true,
      width: '100px',
      selectID: 'statusID',
      selectOptions: statuses
    },
    {
      fieldKey: 6,
      fieldName: 'Created By',
      fieldValue: 'object.createdBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    { fieldKey: 7, fieldName: 'Created On', fieldValue: 'object.createdOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    { fieldKey: 8, fieldName: 'Last Edited', fieldValue: 'object.updatedOn', fieldType: 'date', isVisible: true, isSortable: true, isEditable: false, width: '100px' },
    {
      fieldKey: 6,
      fieldName: 'Edited By',
      fieldValue: 'object.lastModifiedBy',
      fieldType: 'relationship',
      relationshipType: 'user',
      isVisible: true,
      isSortable: true,
      isEditable: false
    },
    {
      fieldKey: 9,
      fieldName: 'Tags',
      fieldValue: 'tags',
      fieldType: 'multiSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      width: '300px',
      selectID: 'tagID',
      selectOptions: tags
    },
    { fieldKey: 10, fieldName: 'File Size', fieldValue: 'fileSize', fieldType: 'number', isVisible: true, isSortable: false, isEditable: false },
    {
      fieldKey: 11,
      fieldName: 'Folders',
      fieldValue: 'folders',
      fieldType: 'multiSelect',
      relationshipType: 'folder',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      width: '260px',
      selectID: 'folderID',
      selectOptions: folders
    },
    { fieldKey: 12, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isSortable: false, isEditable: true, isSearchable: true, width: '300px' },
    {
      fieldKey: 13,
      fieldName: 'Audience',
      fieldValue: 'audienceID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      isSearchable: true,
      selectID: 'audienceID',
      selectOptions: audiences
    },
    { fieldKey: 14, fieldName: 'Extension', fieldValue: 'fileExtension', fieldType: 'text', isVisible: true, isSortable: false, isEditable: false, isSearchable: true },
    {
      fieldKey: 15,
      fieldName: 'Asset Type',
      fieldValue: 'assetTypeID',
      fieldType: 'singleSelect',
      isVisible: true,
      isSortable: false,
      isEditable: true,
      selectID: 'assetTypeID',
      selectOptions: assetTypes
    }
  ]