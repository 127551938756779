<template>
  <div v-if="fieldMode === 'view'" class="flex">
    <!--View Mode-->
    <template v-if="selectedOption">
      <div class="tag rounded-full px-2" :style="selectedOption.colour ? 'background-color:' + selectedOption.colour : ''">
        <span>{{ selectedOption.name }}</span>
      </div>
    </template>
    <template v-else>
      <div class="h-6"></div>
    </template> 
  </div>
  <div v-else>
    <!--Edit Mode-->
    <Select class="w-full" v-model="model" filter :options="localOptions" showClear :optionValue="selectID" optionLabel="name">
      <template #value>
        <template v-if="selectedOption">
          <div class="tag w-max rounded-full px-2" :style="selectedOption.colour ? 'background-color:' + selectedOption.colour : ''">
            <span>{{ selectedOption.name }}</span>
          </div>
        </template>
        <template v-else>
          <div class="h-6"></div>
        </template> 
      </template>
      <template #option="{ option }">
        <div class="flex w-full justify-between">
          <div class="tag rounded-full px-2" :style="option.colour ? 'background-color:' + option.colour : ''">
            {{ option.name }}
          </div>
          <div class="item-menu-icon" @click.stop="showEditOptionPop(option, $event)">
            <FontAwesomeIcon icon="fa-regular fa-ellipsis"></FontAwesomeIcon>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex p-2">
          <Button label="Add Option" size="small" class="w-full text-black hover:text-white" @click.stop="showNewOptionPop"></Button>
        </div>
      </template>
    </Select>
    <Popover ref="opNew">
      <DataFieldPopoverNewOption @newOptionAdded="addOption" />
    </Popover>
    <Popover ref="opEdit">
      <DataFieldPopoverEditOption :editingOption="editingOption" @saveOption="saveOption" @deleteOption="deleteOption" />
    </Popover>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import DataFieldPopoverNewOption from './DataFieldPopoverNewOption.vue';
  import DataFieldPopoverEditOption from './DataFieldPopoverEditOption.vue';
  import { createOptionInStore, updateOptionInStore, deleteOptionInStore } from '@/composables/optionUpdater';
  import { useNotificationStore } from '@/stores/notification.store';

  // Notification Store
  const notificationStore = useNotificationStore()

  // model ID
  const model = defineModel<number>()

  // Props
  const props = defineProps<{
    fieldMode: string,        // The mode of the field (EG: view, edit)
    fieldParent: string,      // The type of the field (EG: table, card, form)
    selectID: string,         // The type of relationship the field has (EG: tag, folder, etc)
    options: any[],           // The options for the select
  }>()

  // Local Variables
  const opNew = ref()
  const opEdit = ref()
  const editingOption = ref()
  const localOptions = ref(props.options)

  // Computed Variables
  // The option that matches the current ID in the model 
  const selectedOption = computed(() => {
    if (!model.value || !localOptions.value) {
      return null
    }
    else if(model.value === -1) {
      return { name: 'Mixed', colour: 'white' }
    }
    else {
      return localOptions.value.find((option: { [x: string]: number | undefined; }) => option[props.selectID] === model.value)
    }
  })
  const dataType = computed(() => props.selectID.replace('ID', ''))

  // Functions
  // Toggle the new option popover
  function showNewOptionPop(event: any) {
    opNew.value.toggle(event)
  }
  // hide the new option popover
  function hideNewOptionPop() {
    opNew.value.hide()
  }

  // Toggle the edit option popover
  function showEditOptionPop(option: any, event: any) {
    editingOption.value = option
    opEdit.value.toggle(event)
  }

  // Add a new option to the store
  function addOption(option: any) {
    createOptionInStore(option, dataType.value).then((result) => {
      if (result) {
        // set the selected option to the new one
        model.value = result
        hideNewOptionPop()
      }
    }).catch((error) => {
      notificationStore.showError('Error creating new option', 'Error')
      console.error(error)
    })
  }

  // Save an option to the store
  function saveOption(option: any) {
    // update the option in the store.  don't show a notifcation as it happens automatically.
    updateOptionInStore(option, dataType.value).catch((error) => {
      notificationStore.showError('Error updating option', 'Error')
      console.error(error)
    })
  }

  // Delete an option from the store
  function deleteOption(option: any) {
    deleteOptionInStore(option, dataType.value).then((result) => {
      if (result) {
        // check if deleted option is the current value
        if(model.value === result) {
          model.value = 0
        }
        notificationStore.showSuccess('Option deleted', 'Success')
      }
    }).catch((error) => {
      notificationStore.showError('Error deleting option', 'Error')
      console.error(error)
    })
  }
</script>

<style scoped>
  .item-menu-icon {
    display: none;
  }

  .p-select-option:hover .item-menu-icon {
    display: block;
  }
</style>
