<template>
  <div class="flex flex-col p-5 gap-2">
    <AppHeaderPage>
      <template #title>
        Upload
      </template>
      <template #buttons>
        <Button class="text-black hover:text-white" severity="contrast" outlined size="small" label="Cancel" @click="backToAssets()"></Button>
        <Button
          class="text-black hover:text-white hover:disabled:text-black"
          size="small"
          label="Upload"
          :disabled="!filesReady || uploaderStore.uploaderProfile.isUploading"
          @click="startUpload"
          v-if="uploaderStore.uploaderProfile.activeTab === 0 && (processedFiles?.length === 0 || newFilesAdded)"
        />
        <Button
          class="text-black hover:text-white hover:disabled:text-black"
          size="small"
          label="Next"
          @click="next()"
          :disabled="nextDisabled || uploaderStore.uploaderProfile.isUploading"
          v-tooltip="nextDisabled ? 'please upload valid files' : ''"
          v-else-if="uploaderStore.uploaderProfile.activeTab === 0 && processedFiles?.length > 0 && !newFilesAdded"
        />
        <Button
          class="text-black hover:text-white hover:disabled:text-black"
          size="small"
          label="Save"
          @click="backToAssetsPage"
          v-else
          :disabled="isLoading || isEditMode"
          v-tooltip="isEditMode && !isLoading && filesReady ? 'Please save changes before finishing' : 'Save changes and go back to Assets page'"
        />
      </template>
    </AppHeaderPage>
    <Steps
      v-model:activeStep="uploaderStore.uploaderProfile.activeTab"
      :model="items"
      class="w-full md:w-2/3 lg:w-2/3 flex flex-row items-center justify-start my-5"
      pt:root:class="w-full md:w-2/3 lg:w-2/3 flex flex-row p-0 m-0"
      pt:list:class="p-0 m-0 list-style-none flex flex-row items-center justify-start w-full"
      pt:item:class="relative flex flex-col items-center justify-start flex-1 before:hidden [&:not(:first-child)]:ml-1"
    >
      <template #item="{ item, active, index }">
        <div class="flex items-center justify-start w-full">
          <span
            :class="[
              'inline-flex justify-center items-center rounded-full border-surface-300 border-2 h-6 w-6 z-10 cursor-pointer ',
              { 'border-2 text-primary font-extrabold': active, 'bg-surface-0 dark:bg-surface-900 ': !active }
            ]"
          >
            {{ index + 1 }}
          </span>
          <span class="ml-1 text-surface-600" :class="[{ '!text-primary font-extrabold': active }]">{{ item.label }}</span>
          <span v-if="index < items.length - 1" class="flex-grow border-t border-2 border-surface-200 ml-1"></span>
        </div>
      </template>
    </Steps>
  </div>
  <div v-show="uploaderStore.uploaderProfile.activeTab === 0" class="animate-fadeinleft animate-ease-in-out">
    <AssetUploader
      ref="assetUploaderRef"
      @update:processed-files="updateProcessedFiles"
      @update:files="updateFiles"
      @update:failed-files="updateFailedFiles"
      @upload-complete="uploadComplete"
      :processed-file-update="processedFiles"
      @delete-selected-asset="trashItems"
      :selected-folder-id="companyStore.folder.selectedFolderID"
    />
  </div>
  <div v-show="uploaderStore.uploaderProfile.activeTab === 1" class="animate-fadeoutleft">
    <AssetUploaderDetails
      ref="assetDetailsViewRef"
      :assets="completedFiles?.map((x: any) => x.asset)"
      :selected-folder-id="companyStore.folder.selectedFolderID"
      @update:is-edit-mode="isEditMode = $event"
      @update:remove-file="removeFileAfterUpload"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue'
import AssetUploader from '@/components/AssetUploader.vue'
import AssetUploaderDetails from '@/components/AssetUploaderDetails.vue'
import { useConfirm } from 'primevue/useconfirm'
import { useRouter } from 'vue-router'
import { useUploaderStore } from '@/stores/uploader.store'
import { useCompanyStore } from '@/stores/company.store'
import type { ProcessedFile, FailedFile } from '@/types/UploaderType'
import { useNotificationStore } from '@/stores/notification.store'
import { useMutation } from '@vue/apollo-composable'
import { SoftDeleteObjectForAsset } from '@/services/graphql/asset.graphql'
import { useAssetStore } from '@/stores/asset.store'
import AppHeaderPage from '@/components/AppHeaderPage.vue'

const confirm = useConfirm()
const filesReady = ref(false)
const assetUploaderRef = ref(null) as any
const router = useRouter()

const isEditMode = ref(false)

const items = ref([
  { label: 'Upload', icon: 'pi pi-upload' },
  { label: 'Asset Details', icon: 'pi pi-list' }
])

const uploaderStore = useUploaderStore()
const companyStore = useCompanyStore()
const assetStore = useAssetStore()

const notificationStore = useNotificationStore()
const files = ref(uploaderStore.uploaderProfile.files as ProcessedFile[])
const completedFiles = ref(uploaderStore.uploaderProfile.completedFiles as ProcessedFile[])
const processedFiles = ref(uploaderStore.uploaderProfile.processedFiles as ProcessedFile[])
const failedFiles = ref(uploaderStore.uploaderProfile.failedFiles as FailedFile[])
const nextDisabled = computed(() => failedFiles.value.length > 0 && completedFiles.value.length === 0)
const isLoading = ref(false)
const newFilesAdded = ref(false)

const assetDetailsViewRef = ref(null) as any

function updateFiles(value: any, fileAdded: boolean = true) {
  if (value.length > 0 && fileAdded) {
    newFilesAdded.value = true
  }
  files.value = value
  uploaderStore.uploaderProfile.files = files.value
  filesReady.value = files.value.length > 0
}

function updateProcessedFiles(value: any) {
  processedFiles.value = value
  completedFiles.value = processedFiles.value.filter((file: any) => !file.error)
  if (completedFiles.value) uploaderStore.uploaderProfile.completedFiles = completedFiles.value
  if (processedFiles.value) uploaderStore.uploaderProfile.processedFiles = processedFiles.value
  uploaderStore.uploaderProfile.totalFiles = processedFiles.value.length
}

function updateFailedFiles(value: any) {
  failedFiles.value = value
}

function startUpload() {
  newFilesAdded.value = false // Reset after upload starts
  assetUploaderRef.value.$refs.fileUploadRef.upload()
}

function next() {
  if (failedFiles.value.length > 0) {
    confirm.require({
      header: 'Files selected have errors.',
      message: 'Assets with errors will not be uploaded.',
      acceptLabel: 'Ok, Upload Valid Files',
      acceptClass: 'bg-primary-500 !text-black border-0',
      acceptProps: { severity: 'danger', subHeader: 'Invalid files will not be uploaded' },
      rejectLabel: ' l',
      rejectClass: '!text-black border-2 border-black',
      rejectProps: { outlined: true },
      accept: () => {
        uploaderStore.uploaderProfile.activeTab = 1
        processedFiles.value = [...processedFiles.value.filter((file: any) => !file.error)]
        completedFiles.value = processedFiles.value.filter((file: any) => !file.error)
        if (completedFiles.value) {
          uploaderStore.uploaderProfile.completedFiles = [...completedFiles.value]
          uploaderStore.uploaderProfile.processedFiles = [...processedFiles.value]
          let filesWithoutErrors = files.value.filter((file: any) => !file.error).map((file: any) => file.name)
          files.value = files.value.filter((file: any) => filesWithoutErrors.includes(file.name))
          uploaderStore.uploaderProfile.files = [...files.value]
        }
        failedFiles.value = []
      },
      reject: () => {
        uploaderStore.uploaderProfile.activeTab = 0
      }
    })
  } else {
    uploaderStore.uploaderProfile.activeTab = 1
  }
}

function uploadComplete() {
  if (failedFiles.value.length === 0) {
    uploaderStore.uploaderProfile.activeTab = 1
  }
  uploaderStore.uploaderProfile.isUploading = false
}

function backToAssets() {
  // if files are uploaded, ask for confirmation
  if (files.value.length > 0) {
    confirm.require({
      header: 'Are you sure you want to cancel the upload?',
      message: 'All files will be lost.',
      acceptLabel: 'Yes, Cancel Upload',
      acceptClass: 'bg-primary-500 text-black border-0 hover:text-white hover:bg-primary-900',
      acceptProps: { severity: 'danger' },
      rejectLabel: 'No, Continue Upload',
      rejectClass: 'text-black border-2 border-black hover:text-white hover:bg-black',
      rejectProps: { outlined: true },
      accept: () => {
        processedFiles.value = [...processedFiles.value.filter((file: any) => !file.error)]
        let assetIDs = processedFiles.value.map((x: any) => x.asset.assetID)
        if (assetIDs.length > 0) {
          removeFileAfterUpload(assetIDs)
          trashItems(assetIDs)
        } else {
          backToAssetsPage()
        }
      }
    })
  } else {
    backToAssetsPage()
  }
}

async function backToAssetsPage(saveChanges = false) {
  if (saveChanges && assetStore.trackedChanges.length > 0) {
    await assetDetailsViewRef.value.openSaveChangesModal()
  } else {
    isLoading.value = true
    router.push({ name: 'CompanyRouterView' })
    processedFiles.value = []
  }
}

function removeFileAfterUpload(assetID: any[]) {
  processedFiles.value = processedFiles.value.filter((x: any) => !assetID.includes(x.asset.assetID))
  completedFiles.value = completedFiles.value.filter((x: any) => !assetID.includes(x.asset.assetID))
  failedFiles.value = failedFiles.value.filter((x: any) => !assetID.includes(x.assetID))
  files.value = files.value.filter((x: any) => !assetID.includes(x.assetID))
  uploaderStore.uploaderProfile.files = files.value
  uploaderStore.uploaderProfile.processedFiles = processedFiles.value
  uploaderStore.uploaderProfile.completedFiles = completedFiles.value
  uploaderStore.uploaderProfile.failedFiles = failedFiles.value

  if (completedFiles.value.length === 0 && uploaderStore.uploaderProfile.activeTab === 1) {
    uploaderStore.uploaderProfile.activeTab = 0
  }
}

onBeforeUnmount(() => {
  // clear the store
  uploaderStore.uploaderProfile = {
    activeTab: 0,
    files: [],
    processedFiles: [],
    completedFiles: [],
    failedFiles: []
  } as any
})
const { mutate: softDeleteObjectForAsset } = useMutation(SoftDeleteObjectForAsset)

function trashItems(assetIDs: number[]) {
  //mark the the objects as deleted and then refetch the assets
  softDeleteObjectForAsset({ assetIDs: assetIDs })
    .then(() => {
      notificationStore.showSuccess('Assets moved to trash', 'Assets moved to trash successfully')
    })
    .catch(() => {
      notificationStore.showError('See console for details.', 'Error deleting assets')
    })
}
</script>
