<template>
  <Breadcrumb v-if="breadcrumbsStore.data.breadcrumbs.length > 1" :model="breadcrumbsStore.data.breadcrumbs" class="p-0 pb-1">
    <template #item="{ item }">
      <RouterLink :to="item.to">
        <FontAwesomeIcon v-if="item.icon" :icon="item.icon" class="mr-2 text-gray-400"></FontAwesomeIcon>
        <span v-if="item.link" :class="isTheLastBreadcrumbItem(item) ? 'text-black font-bold' : 'text-gray-400'" @click="removeBreadcrumbs(item.link)">{{ item.link }}</span>
      </RouterLink>
    </template>
  </Breadcrumb>
  <RouterLink v-else :to="backRouterLink" class="w-fit">
    <FontAwesomeIcon icon="fas fa-arrow-left" class="mr-2 text-gray-400"></FontAwesomeIcon>
    <span class="text-gray-400">Back</span>
  </RouterLink>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs.store'
import { useRoute } from 'vue-router'

// Define stores
const breadcrumbsStore = useBreadcrumbsStore()

// Initialize route
const route = useRoute()

// Define computed
const backRouterLink = computed(() => {
  return route.name === 'assetForm' ? { name: 'assets' } : { name: 'folders' }
})

// Define functions
const isTheLastBreadcrumbItem = (item: any) => {
  return breadcrumbsStore.data.breadcrumbs.indexOf(item) + 1 === breadcrumbsStore.data.breadcrumbs.length
}

const removeBreadcrumbs = (clickedLink: string) => {
  breadcrumbsStore.removeBreadcrumbs(clickedLink)
}
</script>
