<template>
  <div v-if="!dataLoaded">
    <div class="flex justify-center">
      <div class="mt-20">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col p-5 gap-5">
    <!-- Breadcrumbs -->
    <Breadcrumbs></Breadcrumbs>
    <!--Form Header-->
    <AppHeaderPage>
      <template #title>
        <FontAwesomeIcon icon="fa-regular fa-folder" class="mr-2"></FontAwesomeIcon>
        {{ initialFolder.name }}
        <Button
          v-if="userCanEdit && formMode === 'edit'"
          icon="fa-regular fa-ellipsis-vertical"
          class="text-xl rounded bg-white text-black hover:bg-neutral-100 border-none p-1"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          @click="menuClick"
        >
        </Button>
        <Menu
          ref="menu"
          id="overlay_menu"
          :model="folderMenuItemsWithId"
          :popup="true"
          :pt="{ root: { class: ['bg-black', 'text-white'] }, itemContent: { class: ['text-white', 'hover:bg-primary-emphasis'] }, itemIcon: { class: ['text-white'] } }"
        >
        </Menu>
        <FontAwesomeIcon class="text-xl font-extrabold mb-1" v-if="isFolderFavorited" icon="fa-solid fa-star" />
      </template>
      <template #buttons>
        <Button v-if="!isSharedPage && formMode === 'view'" class="text-black hover:text-white" size="small" outlined severity="contrast" @click="sharefolder">
          <FontAwesomeIcon icon="fa-regular fa-paper-plane"></FontAwesomeIcon>
          <span class="p-button-label">Share</span>
        </Button>
        <Button v-if="!isSharedPage && userCanEdit && formMode === 'view'" class="text-black hover:text-white" size="small" label="Edit" @click="toggleEditMode"></Button>
        <Button class="text-black hover:text-white" v-if="formMode === 'edit'" size="small" label="Cancel" severity="contrast" outlined @click="cancelEdit"></Button>
        <Button
          class="text-black hover:text-white hover:disabled:text-black"
          v-if="formMode === 'edit'"
          size="small"
          label="Save"
          severity="primary"
          :disabled="disableSave"
          @click="saveFolder"
        ></Button>
      </template>
    </AppHeaderPage>
    <!--Form Body-->
    <div class="col-span-4 flex flex-col px-3 gap-4">
      <!--Visible Fields-->
      <template v-for="field in formFields" :key="field.fieldKey">
        <div class="flex gap-1" :class="toggleFieldMode(field) === 'edit' ? 'flex-col' : ''">
          <!--Field Label-->
          <div class="font-bold">{{ field.fieldName }}:</div>
          <!--Field-->
          <component
            :is="getFieldType(field.fieldType)"
            :modelValue="_.get(folder, field.fieldValue)"
            @update:modelValue="_.set(folder, field.fieldValue, $event), updateChanges(field.fieldValue, folder)"
            :fieldName="field.fieldName"
            :formatType="field.fieldValue"
            :fieldMode="toggleFieldMode(field)"
            fieldParent="form"
            :relationshipType="field.relationshipType"
            :selectID="field.selectID"
            :options="field.selectOptions"
          />
        </div>
      </template>
    </div>
    <div>
      <Divider class="mb-1" />
      <!--Folders-->
      <Accordion :activeIndex="folder.subFolders?.length > 0 ? 0 : null">
        <template #collapseicon><FontAwesomeIcon icon="fa-solid fa-chevron-down"></FontAwesomeIcon></template>
        <template #expandicon><FontAwesomeIcon icon="fa-solid fa-chevron-up"></FontAwesomeIcon></template>
        <AccordionTab :pt="accordionTabStyle">
          <template #header>
            <span class="text-lg text-black font-extrabold">Folders</span>
          </template>
          <ObjectGallery
            :objectList="folder.subFolders"
            :selectedObjects="selectedAssets"
            :fields="subFolderCardFields"
            :menu-items="folderMenuItems"
            :is-select-checkbox-visible="false"
            :is-edit-mode="userCanEdit && formMode === 'edit'"
            objectType="folder"
          >
          </ObjectGallery>
        </AccordionTab>
      </Accordion>
      <!--Assets-->
      <Accordion :activeIndex="folder.assets?.length > 0 ? 0 : null">
        <template #collapseicon><FontAwesomeIcon icon="fa-solid fa-chevron-down"></FontAwesomeIcon></template>
        <template #expandicon><FontAwesomeIcon icon="fa-solid fa-chevron-up"></FontAwesomeIcon></template>
        <AccordionTab :pt="accordionTabStyle">
          <template #header>
            <span class="text-lg text-black font-extrabold">Assets</span>
          </template>
          <div class="flex flex-col gap-5">
            <AppHeaderPage>
              <template #buttons>
                <Button class="text-black hover:text-white" size="small" label="Download Assets" icon="fa-regular fa-arrow-down-to-bracket" @click="downloadAction"></Button>
              </template>
            </AppHeaderPage>
            <AppToolbarPage
              :isTableViewSelected="isTableViewSelected"
              :isDisplayViewButtonsVisible="true" 
              :sortFields="assetCardFields"
              @isTableViewSelected="isTableViewSelected = $event"
              @selected-filter-action="filterViewBySelectedFilter"
              @set-sort-order="setSortOrder"
            >
            </AppToolbarPage>
            <template v-if="isTableViewSelected">
              <ObjectTable
                tableHeight="100%"
                :objectList="folder.assets"
                :selectedObjects="selectedAssets"
                :columns="tableColumns"
                :search="searchQuery"
                @downloadAction="downloadSingleAsset"
                @favouriteAction="favouriteAction"
              >
              </ObjectTable>
            </template>
            <template v-else>
              <ObjectGallery
                :objectList="folder.assets"
                :selectedObjects="selectedAssets"
                :fields="assetCardFields"
                :search="searchQuery"
                :menu-items="[]"
                :is-select-checkbox-visible="true"
                :is-edit-mode="userCanEdit && formMode === 'edit'"
                objectType="asset"
                @toggleSelect="galleryToggleSelect"
              >
              </ObjectGallery>
            </template>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, onMounted, computed, defineEmits, watch } from 'vue'
import { SortObjectFieldValue } from '@/composables/sortObjectFieldValue'
import _ from 'lodash'
import { useMutation } from '@vue/apollo-composable'
import { CreateDuplicatedFolder } from '@/services/graphql/folder.graphql'
import { CreateObject } from '@/services/graphql/object.graphql'
import { useFolderFormStore } from '@/stores/folderForm.store'
import { useNotificationStore } from '@/stores/notification.store'
import { useFolderStore } from '@/stores/folder.store'
import { useUserStore } from '@/stores/user.store'
import { useStatusStore } from '@/stores/status.store'
import { useAssetTypeStore } from '@/stores/assetType.store'
import { useAudienceStore } from '@/stores/audience.store'
import { useTagStore } from '@/stores/tag.store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { Field } from '@/types/FieldType'
import type { Folder } from '@/types/FolderType'
import { setCopyNumberOnTheName } from '@/composables/duplicateObjectHelpers'
import ObjectGallery from '@/components/ObjectGallery.vue'
import { subFolderFields, folderFields } from '@/composables/folderFieldsHelper'
import { assetFields } from '@/composables/assetFieldsHelper'
import type { Asset } from '@/types/AssetType'
import { downloadAssets } from '@/composables/downloadHelper'
import { useIsAuthenticated } from '@/composables/b2c/utils/useIsAuthenticated'
import { useDialog } from 'primevue/usedialog'
import { openViewChangesDialog, openAssetDataExportDialog, openDuplicateFolder, openFolderRenameDialog, openShareDialog, openDeleteFolderDialog } from '@/composables/dialogOptions'
import { useCompanyStore } from '@/stores/company.store'
import { HasPermission } from '@/composables/permissionsHelper'
import { fieldValuesAreEqual, getFieldType } from '@/composables/fieldHelpers'
import AppHeaderPage from '@/components/AppHeaderPage.vue'
import { useAssetStore } from '@/stores/asset.store'
import { useRouter, useRoute } from 'vue-router'
import { IsFavorite } from '@/composables/objectHelpers'
import AppToolbarPage from './AppToolbarPage.vue'
import Breadcrumbs from './Breadcrumbs.vue'

const props = defineProps<{
  folderId: number
  isSharedPage: boolean
}>()

// Define emits
const emits = defineEmits(['objectDownloaded'])

// Initialize
const isAuthenticated = useIsAuthenticated()
const dialog = useDialog()

// STORES
const notificationStore = useNotificationStore()
const folderFormStore = useFolderFormStore()
const folderStore = useFolderStore()
const statusStore = useStatusStore()
const assetTypeStore = useAssetTypeStore()
const audienceStore = useAudienceStore()
const tagStore = useTagStore()
const userStore = useUserStore()
const companyStore = useCompanyStore()
const assetStore = useAssetStore()

// Initialize route
const router = useRouter()
const route = useRoute()

// LOCAL PROPERTIES
const { folder, initialFolder } = toRefs(folderFormStore.data)
const dataLoaded = ref(false)
const formMode = ref('view')
let folderId = ref(props.folderId)

const menu = ref()
const assetCardFields = ref<Array<Field>>([])
const subFolderCardFields = ref<Array<Field>>([])
const tableColumns = ref<Array<Field>>([])
const isTableViewSelected = ref(false)
const selectedAssets = ref([] as Asset[])
const formFields = ref<Array<Field>>([])
const isSubfolderFavorited = ref(false)
const sortOrder = ref('asc')
const sortFilter = ref({} as Field)

// filter search query
const searchQuery = ref('')

//check the top level folder for it being favorited
const isFolderFavorited = computed(() => {
  const folder = folderStore.data.folders.find((folder: Folder) => folderId.value === folder.folderID)
  if (folder!.object) return IsFavorite(folder!.object)
  else return false
})

// Menu items for the gallery.
const folderMenuItems = ref([
  {
    label: isSubfolderFavorited.value ? 'Unfavorite' : 'Favorite',
    icon: isSubfolderFavorited.value ? 'fa-solid fa-star' : 'fa-regular fa-square-minus',
    command: async (folderID: any) => {
      //check if its a subfolder
      const subfolder = folderFormStore.data.folder.subFolders.find((folder: Folder) => folder.folderID === folderID)
      if (subfolder) {
        isSubfolderFavorited.value = IsFavorite(subfolder.object)
        if (isSubfolderFavorited.value) {
          await unfavoriteFolder(subfolder)
        } else {
          await favoriteFolder(subfolder)
        }
      } else {
        //check subfolders for the folder
        const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID && folder.parentFolderID === null)
        if (folder) {
          if (isFolderFavorited.value) {
            await unfavoriteFolder(folder!)
          } else {
            await favoriteFolder(folder!)
          }
        }
      }
    }
  },
  {
    label: 'Share',
    icon: 'fa-regular fa-share-nodes',
    command: (folderID: number) => {
      notificationStore.showInfo(`Share object action menu. FolderID ${folderID}`, 'This feature is not yet implemented.')
    }
  },
  {
    label: 'Delete',
    icon: 'fa-regular fa-trash-can',
    command: (folderID: number) => {
      //get the folder, check the parent id to see if its the top level folder
      const folder = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID && folder.parentFolderID === null)
      let isSubfolder = null
      if (folder) {
        isSubfolder = false
        trashFoldersConfirm(folder, isSubfolder)
      } else {
        const subfolder = folderFormStore.data.folder.subFolders.find((folder: Folder) => folder.folderID === folderID)
        isSubfolder = true
        trashFoldersConfirm(subfolder!, isSubfolder)
      }
    }
  },
  {
    label: 'Duplicate',
    icon: 'fa-regular fa-copy',
    command: (folderID: number) => {
      const modalOptions = {
        emits: {
          onSubmit: async () => {
            const folderToUpdate = folderStore.data.folders.find((folder: Folder) => folder.folderID === folderID)
            if (!folderToUpdate) {
              notificationStore.showError('Error duplicating the folder')
            } else {
              // Create the duplicated folder
              await createDuplicatedFolder(folderToUpdate, folderToUpdate.parentFolderID)
              // Show the success message
              notificationStore.showSuccess(`${folderToUpdate.name} Duplicated`)

              await folderFormStore.loadFolderData(companyStore.companyID, folderId.value)
              await folderStore.loadFoldersData(companyStore.companyID)
            }
          }
        }
      }
      openDuplicateFolder(dialog, modalOptions)
    }
  },
  {
    label: 'Rename',
    icon: 'fa-regular fa-pen',
    command: (folderID: number) => {
      const folderToUpdate = folderID == folderId.value ? folder.value : folder.value.subFolders.find((folder: Folder) => folder.folderID === folderID)
      const modalOptions = {
        data: {
          folderID: folderToUpdate?.folderID,
          folderName: folderToUpdate?.name
        },
        emits: {
          onFolderRename: async () => {
            await folderFormStore.loadFolderData(companyStore.companyID, folderId.value)
            await folderStore.loadFoldersData(companyStore.companyID)
          }
        }
      }
      openFolderRenameDialog(dialog, modalOptions)
    }
  }
])

// COMPUTED PROPERTIES
const userCanEdit = computed(() => HasPermission('Editor'))
const disableSave = computed(() => folderFormStore.data.changes.length <= 0)
// Add the folderID to the menu items.
const folderMenuItemsWithId = computed(() =>
  folderMenuItems.value.map((item: any) => {
    if (item.label === 'Favorite' || item.label === 'Unfavorite') {
      return {
        ...item,
        label: isFolderFavorited.value ? 'Unfavorite' : 'Favorite',
        icon: isFolderFavorited.value ? 'fa-regular fa-square-minus' : 'fa-regular fa-star',
        command: () => {
          item.command(folderId.value, !isFolderFavorited.value)
        }
      }
    } else {
      return {
        ...item,
        command: () => {
          item.command(folderId.value)
        }
      }
    }
  })
)

onMounted(async () => {
  // Load the stores so they can be accessed in the Folder Form component
  await folderStore.loadFoldersData(companyStore.companyID)
  await folderFormStore.loadFolderData(companyStore.companyID, folderId.value)

  formFields.value = [
    { fieldKey: 1, fieldName: 'Description', fieldValue: 'description', fieldType: 'editor', isVisible: true, isEditable: true, isSortable: true },
    { fieldKey: 2, fieldName: 'Tags', fieldValue: 'tags', fieldType: 'multiSelect', isVisible: true, isEditable: true, selectID: 'tagID', selectOptions: tagStore.data.tags },
  ]
  assetCardFields.value = assetFields(assetTypeStore.data.assetTypes, audienceStore.data.audiences, statusStore.data.statuses, tagStore.data.tags)
  subFolderCardFields.value = subFolderFields()
  tableColumns.value = folderFields(statusStore.data.statuses, tagStore.data.tags, folderStore.data.folders, audienceStore.data.audiences, assetTypeStore.data.assetTypes)
  dataLoaded.value = true
})

// WATCHERS
// Watch for changes to the folder ID because when the user clicks to the subfolder, the route does not change. Only the folder ID changes.
// So we need to watch for the folder ID change and load the folder data.
watch(
  () => route.params.id,
  async () => {
    folderId = ref(parseInt(route.params.id as string))
    await folderFormStore.loadFolderData(companyStore.companyID, folderId.value)
  }
)

function toggleFieldMode(field: Field) {
  if (field.isEditable) {
    return formMode.value
  } else {
    return 'view'
  }
}

function toggleEditMode() {
  formMode.value = formMode.value === 'view' ? 'edit' : 'view'
}

function cancelEdit() {
  folderFormStore.clearPendingChanges()
  toggleEditMode()
}

function menuClick() {
  menu.value.toggle(event)
}

function filterViewBySelectedFilter(filter?: Field) {
  if(filter && filter.fieldValue && filter.fieldValue.includes('object.')) {
    sortFilter.value = filter
    folderFormStore.data.folder.assets =  SortObjectFieldValue(filter, folderFormStore.data.folder.assets, sortOrder.value)
  }
  else if(filter) {
    sortFilter.value = filter
    //filter the asset list based on the filter
    folderFormStore.data.folder.assets = _.orderBy(folderFormStore.data.folder.assets , [sortFilter.value.fieldValue], [sortOrder.value])
  }
  else {
    sortFilter.value = { fieldKey: 0, fieldName: '', fieldValue: '', fieldType: '', isVisible: false }; 
    folderFormStore.data.folder.assets = _.orderBy(folderFormStore.data.folder.assets, ['folderID'], ['asc'])
  }
}

function setSortOrder(isAscending: boolean) {
  if(isAscending)
    sortOrder.value = 'asc'
  else 
    sortOrder.value = 'desc'

 //after the order gets set, sort the list 
 if(sortFilter.value)
    filterViewBySelectedFilter(sortFilter.value)
}

// Download action event handler
function downloadSingleAsset(value: any) {
  // If it's a shared page, emit and event to change the ShareStatusID to downloaded.
  if (props.isSharedPage) {
    emits('objectDownloaded')
  }
  notificationStore.showInfo('Download action clicked - ID: ' + value.assetID, 'Not Implemented Yet')
}

// Favourite action event handler
function favouriteAction(value: any) {
  notificationStore.showInfo('Favourite action clicked - ID: ' + value.assetID, 'Not Implemented Yet')
}

const { mutate: createObject } = useMutation(CreateObject)
const { mutate: createFolder } = useMutation(CreateDuplicatedFolder)
async function createDuplicatedFolder(folder: Folder, parentFolderID: number | null = null) {
  const currentDate = new Date().toISOString()
  const object = {
    companyID: companyStore.companyID,
    createdByID: userStore.UserObject?.userID,
    createdOn: currentDate,
    lastModifiedByID: userStore.UserObject?.userID,
    objectTypeID: 2,
    updatedOn: currentDate
  } as any

  // call the service to create the object
  await createObject({ object: object })
    .then(async (response: any) => {
      // if the object is created, then create the folder
      const newFolder = {
        name: setCopyNumberOnTheName<Folder>(folderStore.data.folders, folder.name, 'name', 'subFolders'),
        objectID: response.data.createObject.objectID,
        parentFolderID: parentFolderID
      } as any

      // Get the asset IDs from the folder
      const assetIDs = folder.assets.map((asset: any) => asset.assetID)

      // call the service to create the folder
      await createFolder({ folder: newFolder, assetIDs: assetIDs })
        .then(async (response: any) => {
          // if the folder is created, then create the children folders
          if (folder.subFolders) {
            for (const child of folder.subFolders) {
              await createDuplicatedFolder(child, parseInt(response.data.createDuplicatedFolder.folderID))
            }
          }
        })
        .catch(() => {
          notificationStore.showError('Error duplicating the folder')
        })
    })
    .catch(() => {
      notificationStore.showError('Error duplicating the folder')
    })
}

function downloadAction() {
  const isUserAuthenticated = props.isSharedPage || isAuthenticated.value
  if (!isUserAuthenticated) {
    notificationStore.showInfo('Please login to download assets')
    return
  }
  const modalOptions = {
    emits: {
      onConfirm: (exportData: boolean, downloadData: boolean) => {
        let assetList: Asset[] = selectedAssets.value
        if (assetList.length === 0) {
          // assume they want to download all assets
          assetList = folder.value.assets
        }
        downloadAssets(downloadData, exportData, isUserAuthenticated, assetList)
      }
    }
  }
  openAssetDataExportDialog(dialog, modalOptions)
}
function sharefolder() {
  const modalOptions = {
    props: {
      header: 'Share Folder'
    },
    data: {
      objects: [folder.value],
      objectType: 'folder'
    },
    emits: {
      onAssetShared: async () => {}
    }
  }
  openShareDialog(dialog, modalOptions)
}

function updateChanges(field: string, folder: Folder) {
  // Find the asset and original Asset.  This is used to compare the original value to the new value
  const ogFolder = folderFormStore.data.initialFolder
  const ogValue = _.get(ogFolder, field)
  const newValue = _.get(folder, field)
  // Check if the value has changed from the original value
  if (!fieldValuesAreEqual(newValue, ogValue)) {
    // Check if the asset field is already in the changes list.
    if (!folderFormStore.data.changes.find((change: any) => change.field === field)) {
      // Add the asset field to the changedAssets list
      folderFormStore.data.changes.push({ folderID: folder.folderID, referenceID: folder.name, field: field, value: newValue })
    }
  } else {
    // Remove the asset field from the changes list
    folderFormStore.data.changes = folderFormStore.data.changes.filter((change: any) => change.field !== field)
  }
}

async function saveFolder() {
  // data to be passed to the modal
  const modalOptions = {
    data: {
      entityList: folderFormStore.data.changes
    },
    emits: {
      onSubmit: async () => {
        // call the saveAssetsData function in the store and handle the response from the mutation
        folderFormStore
          .saveFolderData(userStore.getUserID)
          .then(() => {
            notificationStore.showSuccess('Changes saved successfuly', 'Changes Saved')
            const folderID = folderFormStore.data.folder.folderID
            folderFormStore.$reset()
            folderFormStore.loadFolderData(companyStore.companyID, folderID)
            folderStore.loadFoldersData(companyStore.companyID)
          })
          .catch((error: any) => {
            console.error(error)
            notificationStore.showError('Error saving changes', 'Error')
          })
        toggleEditMode()
      }
    }
  }
  openViewChangesDialog(dialog, modalOptions)
}

function galleryToggleSelect(selectedObjects: Asset) {
  if (!selectedAssets.value) selectedAssets.value = []

  if (!selectedObjects) {
    selectedAssets.value = []
  }

  if (selectedAssets.value.includes(selectedObjects)) {
    selectedAssets.value = selectedAssets.value.filter((asset: Asset) => asset.assetID !== selectedObjects.assetID)
  } else {
    selectedAssets.value.push(selectedObjects)
  }
}

function trashFoldersConfirm(folder: Folder, isSubfolder: boolean) {
  // Data to be passed to the modal
  const modalOptions = {
    props: {
      header: 'Trash Folder'
    },
    data: {
      subtitle: ` ${folder.name} `
    },
    emits: {
      onConfirm: (trashAssets: boolean) => {
        // Determine whether to trash a single folder or multiple folders
        const folderIDs = [folder.folderID]
        folderStore.trashFolders(trashAssets, folderIDs).then(async () => {
          //reload folders and assets
          await folderStore.loadFoldersData(companyStore.data.company.companyID)
          await assetStore.loadAssetsData(companyStore.data.company.companyID)

          if (isSubfolder) {
            await folderFormStore.$reset()
            await folderFormStore.loadFolderData(companyStore.data.company.companyID, folderId.value)
          } else router.push({ name: 'folders' })

          companyStore.$patch((state: any) => {
            state.folder.selectedFolderID = 0
          })
        })
        folderStore.$patch((state: any) => {
          state.data.selectedFolders = []
        })
      }
    }
  }

  // Open the confirmation modal
  openDeleteFolderDialog(dialog, modalOptions)
}

async function favoriteFolder(folder: Folder) {
  await folderStore.favoriteFolders([folder.folderID], userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been added to favorites')
    await folderStore.loadFoldersData(companyStore.data.company.companyID)
    await folderFormStore.loadFolderData(companyStore.data.company.companyID, folderId.value)
  })
}

async function unfavoriteFolder(folder: Folder) {
  await folderStore.unfavoriteFolders([folder.folderID], userStore.getUserID).then(async () => {
    notificationStore.showSuccess('Folder has been removed from favorites')
    await folderStore.loadFoldersData(companyStore.data.company.companyID)
    await folderFormStore.loadFolderData(companyStore.data.company.companyID, folderId.value)
  })
}
const accordionTabStyle = { header: { class: ['bg-transparent border-0 border-b'] }, headerIcon: { class: [] }, content: { class: ['border-none'] } }
</script>
