import apiClient from "./main";

//api resource endpoint
const RESOURCE_NAME = 'api/admin';

export default {
    getCountries() {
        return apiClient.get(RESOURCE_NAME + '/countries');
    },
    getStates(country: string) {
        return apiClient.get(RESOURCE_NAME + '/states?country=' + country);
    },
    getCities(state: string) {
        return apiClient.get(RESOURCE_NAME + '/cities?selectedState=' + state);
    }
}
