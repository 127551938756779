import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { useNotificationStore } from './notification.store'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { GetAssetByID, FavoriteAssets, UnfavoriteAssets, UpdateAssetTable  } from '@/services/graphql/asset.graphql'
import _ from 'lodash'
import type { Asset } from '@/types/AssetType'
import type { AssetTableInputType } from '@/types/AssetTableInputType'

export const useAssetFormStore = defineStore('AssetFormStore', () => {
  const notificationStore = useNotificationStore()

  const data = reactive({
    asset: {} as Asset,
    initialAsset: {} as Asset,
    changes: [] as any[],
    loading: false
  })

  // Getters
  // true if there are changes to the assets
  const hasChanges = computed(() => data.changes.length > 0)

  // Query & Variables
  const { load: loadAsset, refetch: refetchAsset } = useLazyQuery(GetAssetByID)
  const assetQueryVariables = reactive({ companyID: 0 as number, assetID: 0 as number })

  // Mutations
  const { mutate: favoriteAssetsMutation } = useMutation(FavoriteAssets)
  const { mutate: unfavoriteAssetsMutation } = useMutation(UnfavoriteAssets)
  const { mutate: updateAssetsMutation } = useMutation(UpdateAssetTable)

  // Set the initial state
  async function loadAssetData(companyID: number, assetID: number) {
    data.loading = true;
    data.changes = []
    assetQueryVariables.companyID = companyID;
    assetQueryVariables.assetID = assetID;

    try {
      // Run the query and check the result
      let result = await loadAsset(GetAssetByID, assetQueryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })
      if (!result) {
        // If result is false, then use refetch instead
        result = await refetchAsset(assetQueryVariables)
        result = result.data // Reformat the result because vue apollo is weird.
      }

      // Set the asset data using the result
      // assign the asset and initialAsset to the result asset
      Object.assign(data.asset, _.cloneDeep(result.asset[0]))
      Object.assign(data.initialAsset, _.cloneDeep(result.asset[0]))
      data.loading = false
    }
    catch (error) {
      notificationStore.showError('Failed to load asset data', 'Error')
      console.error('Error loading asset data:', error)
    }
  }

  // save the assets data to the server
  async function saveAssetData(userID: number) {
    // get the updated assets and map them to the AssetTableInputType
    const assetInput = {
      objectID: data.asset.objectID,
      assetID: data.asset.assetID,
      referenceID: data.asset.referenceID,
      name: data.asset.name,
      description: data.asset.description,
      fileSize: data.asset.fileSize,
      fileExtension: data.asset.fileExtension,
      blobUrl: data.asset.blobUrl,
      previewURL: data.asset.previewURL,
      statusID: data.asset.statusID,
      assetTypeID: data.asset.assetTypeID,
      audienceID: data.asset.audienceID,
      folderIDs: data.asset.folders.map((folder) => folder.folderID),
      tagIDs: data.asset.tags.map((tag) => tag.tagID)
    } as AssetTableInputType

    // call the updateAssets mutation and return the result directly to the caller
    const result = await updateAssetsMutation({ userID: userID, assets: [assetInput] })
    return result
  }

  // favorite an array of assets for a user
  async function favoriteAsset(userID: number) {
    // call the addAsFavorite mutation and return the result directly to the caller
    const result = await favoriteAssetsMutation({ assetsID: [data.asset.assetID], userID: userID })
    return result
  }

  // unfavorite an array of assets for a user
  async function unfavoriteAsset(userID: number) {
    // call the removeFromFavorite mutation and return the result directly to the caller
    const result = await unfavoriteAssetsMutation({ assetsID: [data.asset.assetID], userID: userID })
    return result
  }

  function clearPendingChanges() {
    data.asset = _.cloneDeep(data.initialAsset)
    data.changes = []
  }

  // Reset the state
  function $reset() {
    data.asset = {} as Asset;
    data.initialAsset = {} as Asset;
    data.changes = []
    data.loading = false
  }

  return {
    data,
    hasChanges,
    loadAssetData,
    saveAssetData,
    favoriteAsset,
    unfavoriteAsset,
    clearPendingChanges,
    $reset
  }
})
