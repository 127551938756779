import apiClient from "./main";

//api resource endpoint
const RESOURCE_NAME = 'api/file';

export default {
    uploadLogo(companyId: number, file: File) {
        const formData = new FormData();
        formData.append('companyID', companyId.toString());
        formData.append('file', file);
        return apiClient.post(RESOURCE_NAME + '/UploadLogoURL/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getLogo(companyId: number) {
        let requestHeaders = { 'AnonymousAuthentication': 'true' }
        return apiClient.get(RESOURCE_NAME + '/GetLogoURL?companyID=' + companyId, { headers: requestHeaders });
    },
    uploadAsset(fileModel: FileModel, file: File, uploadProgress: any) {
        const formData = new FormData();
        formData.append('fileModel.CompanyID', fileModel.CompanyID.toString());
        formData.append('fileModel.UserID', fileModel.UserID.toString());
        if(fileModel.FolderID !== undefined && fileModel.FolderID !== null)
            formData.append('fileModel.FolderID', fileModel.FolderID.toString());
        formData.append('fileModel.Name', fileModel.Name.toString());
        formData.append('file', file);
        const requestHeaders = { 'Content-Type': 'multipart/form-data' }
        const config = {
            headers: requestHeaders,
            onUploadProgress: (progressEvent: any) => uploadProgress(progressEvent),
        }
        return apiClient.post(RESOURCE_NAME + '/UploadAsset/', formData, config);
    },
    downloadAsset(assetID: number) {
        let requestHeaders = { 'AnonymousAuthentication': 'true' }
        return apiClient.get(RESOURCE_NAME + '/DownloadAsset?assetID=' + assetID, { headers: requestHeaders });
    },
    downloadAssets(assetIDs: number[], csvFile: File | null, downloadData: boolean, exportData: boolean) {
        const formData = new FormData();
        formData.append('assetModel.AssetIds', assetIDs.join(','));
        const requestHeaders = { 'Content-Type': 'multipart/form-data', 'AnonymousAuthentication': 'true' }
        const config = {
            headers: requestHeaders,
            responseType: 'blob' as 'json',  // makes the response a blob, as json to make sure typescript is happy
        }
        if (csvFile !== null) {
            formData.append('csvFile', csvFile);
        }

        formData.append('assetModel.downloadData', downloadData.toString());
        formData.append('assetModel.exportData', exportData.toString());

        return apiClient.post(RESOURCE_NAME + '/DownloadMultipleAssets', formData, config);
    },
    downloadFolderAssets(folderIDs: number[], csvFile: File | null, downloadData: boolean, exportData: boolean) {
        const formData = new FormData();
        formData.append('assetModel.FolderIds', folderIDs.join(','));
        const requestHeaders = { 'Content-Type': 'multipart/form-data', 'AnonymousAuthentication': 'true' }
        const config = {
            headers: requestHeaders,
            responseType: 'blob' as 'json', // makes the response a blob, as json to make sure typescript is happy
        }
        if (csvFile !== null) {
            formData.append('csvFile', csvFile);
        }

        formData.append('assetModel.downloadData', downloadData.toString());
        formData.append('assetModel.exportData', exportData.toString());

        return apiClient.post(RESOURCE_NAME + '/DownloadMultipleFolderAssets', formData, config);
    },

    uploadChunkedAsset(fileModel: FileModel, file: Blob, uploadProgress: any) {
        if (fileModel.UploadID === undefined || fileModel.UploadID === null || fileModel.PartNumber === undefined || fileModel.PartNumber === null) {
            return Promise.reject('UploadID and PartNumber are required for chunked upload');
        }
        const formData = new FormData();
        formData.append('fileModel.CompanyID', fileModel.CompanyID.toString());
        formData.append('fileModel.UserID', fileModel.UserID.toString());
        formData.append('fileModel.Name', fileModel.Name.toString());
        formData.append('fileModel.UploadID', fileModel.UploadID.toString());
        formData.append('fileModel.PartNumber', fileModel.PartNumber.toString());
        formData.append('file', file);
        const requestHeaders = { 'Content-Type': 'multipart/form-data' }
        const config = {
            headers: requestHeaders,
            onUploadProgress: (progressEvent: any) => uploadProgress(progressEvent),
        }
        return apiClient.post(RESOURCE_NAME + '/UploadChunkedAsset/', formData, config);
    },
    mergeChunkedAsset(fileModel: FileModel) {
        if (fileModel.UploadID === undefined || fileModel.UploadID === null) {
            return Promise.reject('UploadID and PartNumber are required for chunked upload');
        }
        const formData = new FormData();
        formData.append('fileModel.CompanyID', fileModel.CompanyID.toString());
        formData.append('fileModel.UserID', fileModel.UserID.toString());
        formData.append('fileModel.Name', fileModel.Name.toString());
        formData.append('fileModel.UploadID', fileModel.UploadID.toString());
        if(fileModel.FolderID !== undefined && fileModel.FolderID !== null)
            formData.append('fileModel.FolderID', fileModel.FolderID.toString());
        const requestHeaders = { 'Content-Type': 'multipart/form-data' }
        const config = {
            headers: requestHeaders,
        }
        return apiClient.post(RESOURCE_NAME + '/MergeChunkedAsset/', formData, config);
    },
    getStorageToken(companyID: number) {
        let requestHeaders = { 'AnonymousAuthentication': 'true' }
        return apiClient.get(RESOURCE_NAME + '/GenerateSasToken?companyID=' + companyID, { headers: requestHeaders });
    },
}

export interface FileModel {
    CompanyID: number;
    UserID: number;
    Name: string;
    UploadID?: string;
    FolderID?: number;
    PartNumber?: number;
    Size: number;
    FileName: string;
    ObjectURL: string;
}

