import { reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import type { Company } from '@/types/CompanyType'
import { useLazyQuery } from '@vue/apollo-composable'
import { GetCompanyByID } from '@/services/graphql/company.graphql'
import { useNotificationStore } from '@/stores/notification.store'
import fileService from '@/services/api/file.service'

export const useCompanyStore = defineStore('CompanyStore', () => {
  const notificationStore = useNotificationStore()

  const data = reactive({
    company: {} as Company,
    companyLogo: '' as string,
    loading: true as boolean,
  })

  const folder = reactive({
    rowsPerPage: 10 as number,
    page: 1 as number,
    selectedFolderID: 0 as number
  })

  const asset = reactive({
    rowsPerPage: 10 as number,
    page: 1 as number,
  })

  // query & variables
  const { load, refetch } = useLazyQuery(GetCompanyByID)
  const queryVariables = reactive({
    companyID: 0
  })

  const companyID = computed(() => data.company.companyID)

  // function to load company data using the GetCompanyByID query
  async function loadCompanyData(companyID: number) {
    $reset()
    data.loading = true;
    queryVariables.companyID = companyID;

    try {
      // run the query and check the result
      let result = await load(GetCompanyByID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })
      if(!result) {
        //if result is false, then use refetch instead
        result = await refetch(queryVariables)
        result = result.data // reformat the result because vue apollo is weird.
      }

      // set the company data using the result
      data.company = result.company[0]
      loadCompanyLogo()
      data.loading = false
    } 
    catch (error) {
      notificationStore.showError('There was an error loading the company.', 'Error');
      console.error('Error loading company data:', error);
    }
  }

  function loadCompanyLogo() {
    // Load company logo
    fileService.getLogo(companyID.value).then((response) => {
      if (response.data) {
        data.companyLogo = response.data
      } else {
        data.companyLogo = ''
      }
    }).catch(() => {
      data.companyLogo = ''
    })
  }

  function $reset() {
    data.company = {} as Company
    data.companyLogo = ''
    data.loading = false
  }

  return {
    data,
    companyID,
    loadCompanyData,
    loadCompanyLogo,
    folder,
    asset,
    $reset
  }
})