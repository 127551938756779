<template> 
    <div class="group relative ml-2">
        <Select 
            v-model="selectedFilter" 
            :options="sortableFields" 
            filter 
            optionLabel="fieldName" 
            placeholder="Sort"  
            :showClear="true"
            class="text-black bg-white border-1 border-black hover:bg-black rounded "
            :pt="{
                root: { class: 'rounded hover:bg-black relative group-hover:text-white shadow-none' },
                dropdown: { class: 'hidden' },
                clearIcon: { class: 'absolute right-0 mr-1 hover:text-primary' },
                label: { class: 'text-black' } 
            }"
        >
            <template #value="slotProps">
                <div class="flex items-center pointer-events-auto">
                    <span class="text-black group-hover:text-white">Sort</span>
                    <span v-if="slotProps.value !== null" class="w-3 h-3 ml-2 bg-primary rounded-full"></span>
                </div>
            </template>
        </Select>
    </div>
    <Button class="h-8 w-14 text-black hover:text-white ml-2 " severity="contrast" outlined @click="setSortOrder">
    <template #icon>
      <FontAwesomeIcon v-if="isAscending" icon="fa-solid fa-arrow-up" /> 
      <FontAwesomeIcon v-if="!isAscending" icon="fa-solid fa-arrow-down" /> 
    </template>
  </Button>
</template>

<script lang="ts" setup>
import type { Field } from '@/types/FieldType';
import { ref, watch, computed } from 'vue'

const props = defineProps<{
    sortFields: Field[]
}>()

//filter out non sortable fields
const sortableFields = computed(() => {
    return props.sortFields.filter(field => field.isSortable);
})

const emits = defineEmits(['selectedFilterAction', 'setSortOrder'])

const selectedFilter = ref(null)
//set ascedning sort order by default
const isAscending = ref(true);

function setSortOrder() {
    isAscending.value = !isAscending.value;
    emits('setSortOrder', isAscending.value)
}

watch(selectedFilter, () => {
    if(!selectedFilter.value){
        isAscending.value = true;
        emits('setSortOrder', isAscending.value)
    }
    emits('selectedFilterAction', selectedFilter.value);
})
</script>

