<template>
  <div class="flex">
    <template v-if="fieldMode === 'view'">
      <!--View Mode-->
      <template v-if="model">
        <span class="line-clamp-1 text-left" v-if="formatType == 'fileSize'">{{ formatSize(parseInt(model as string)) }} </span>
        <span class="line-clamp-1 text-left" v-else>{{ model }}</span>
      </template>
      <template v-else>
        <div class="h-6"></div>
      </template>
    </template>
    <template v-else>
      <!--Edit Mode-->
      <InputNumber size="small" v-model="model" class="w-full" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { formatSize } from '@/composables/downloadHelper'

// v-model
const model = defineModel() // The data for the field

// Props
defineProps<{
  fieldMode: string // The mode of the field (EG: view, edit)
  fieldParent: string // The type of the field (EG: table, card, form)
  formatType?: string // The format string for the date field
}>()
</script>
