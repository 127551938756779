import { reactive } from 'vue';
import { defineStore } from 'pinia';
import type { Status } from '@/types/StatusType';
import { useNotificationStore } from '@/stores/notification.store';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { GetStatusesByCompanyID, CreateStatus, UpdateStatus, DeleteStatus } from '@/services/graphql/status.graphql';
import _ from 'lodash';

export const useStatusStore = defineStore('StatusStore', () => {
  const notifcationStore = useNotificationStore();

  const data = reactive({
    statuses: [] as Status[],
    loading: false as boolean
  })

  // Query & Variables
  const { load, refetch } = useLazyQuery(GetStatusesByCompanyID);
  const queryVariables = reactive({
    companyID: 0
  })

  // Mutations
  const { mutate: createStatusMutation } = useMutation(CreateStatus);
  const { mutate: updateStatusMutation } = useMutation(UpdateStatus);
  const { mutate: deleteStatusMutation } = useMutation(DeleteStatus);

  // load/refetch statuses data
  async function loadStatusesData(companyID: number) {
    data.loading = true;
    queryVariables.companyID = companyID;

    try {
      let result = await load(GetStatusesByCompanyID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } });
      if(!result) {
        result = await refetch(queryVariables);
        result = result.data;
      }

      // clone, then Sort the statuses by the id field
      let statuses = _.cloneDeep(result.statuses);
      statuses = _.sortBy(statuses, ['statusID']);
      // Update the statuses array with the new data by splicing the array.  
      // This will maintain the original array reference and therby maintain the reactivity of the array.
      data.statuses.splice(0, data.statuses.length, ...statuses);
      data.loading = false;
    }
    catch (error) {
      notifcationStore.showError('Error loading status data', 'Error');
      console.error('Error loading status data:', error);
      data.loading = false;
    }
  }

  // function to call createStatus mutation and return it's result
  async function createStatus(status: any) {
    const result = await createStatusMutation({ input: status });
    return result;
  }
  
  // function to call updateStatus mutation and return it's result.
  async function updateStatus(status: any) {
    const statusID = status.statusID;
    // clone the status object and remove the __typename and statusID fields (required for the mutation)
    const updatingStatus = { ...status };
    delete updatingStatus.__typename;
    delete updatingStatus.statusID;
    const result = await updateStatusMutation({ statusID: statusID, input: updatingStatus });
    return result;
  }

  // function to call deleteStatus mutation and return it's result
  async function deleteStatus(status: any) {
    const statusID = status.statusID;
    const result = await deleteStatusMutation({ statusID: statusID });
    return result;
  }

  // state reset
  function $reset() {
    data.statuses = [];
    data.loading = false;
  }

  return {
    data,
    loadStatusesData,
    createStatus,
    updateStatus,
    deleteStatus,
    $reset
  }
});