<template>
  <div class="w-full h-full">
    <iframe :src="msEmbedUrl" width="100%" height="623px" frameborder="0"
      >This is an embedded <a target="_blank" href="http://office.com">Microsoft Office</a> document, powered by
      <a target="_blank" href="http://office.com/webapps">Office Online</a>.</iframe
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ docUrl: string }>()

const msEmbedUrl = computed(() => {
  const encodedUrl = encodeURIComponent(props.docUrl)
  return `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`
})
</script>
