import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { GetFoldersByCompanyID, CreateDuplicatedFolder, CreateFolder, PartialUpdateFolder, FavoriteFolders, UnfavoriteFolders, SoftDeleteObjectForFolder } from '@/services/graphql/folder.graphql'
import { CreateObject } from '@/services/graphql/object.graphql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { useNotificationStore } from './notification.store'
import { setCopyNumberOnTheName } from '@/composables/duplicateObjectHelpers'
import type { Folder } from '@/types/FolderType'
import _ from 'lodash'

export const useFolderStore = defineStore('FolderStore', () => {
  const notificationStore = useNotificationStore()

  const data = reactive({
    folders: [] as Folder[],
    initialFolders: [] as Folder[],
    selectedFolders: [] as Folder[],
    loading: false,
    trashedFolders: [] as Folder[]
  })

  // Query & Variables
  const { load, refetch } = useLazyQuery(GetFoldersByCompanyID)
  const queryVariables = reactive({
    companyID: 0
  })

  // Mutations
  const { mutate: createObjectMutation } = useMutation(CreateObject)
  const { mutate: createFolderMutation } = useMutation(CreateFolder)
  const { mutate: partialUpdateFolderMutation } = useMutation(PartialUpdateFolder)
  const { mutate: createDuplicateFolderMutation } = useMutation(CreateDuplicatedFolder)
  const { mutate: softDeleteObjectForFolder } = useMutation(SoftDeleteObjectForFolder)
  const { mutate: favoriteFoldersMutation } = useMutation(FavoriteFolders)
  const { mutate: unfavoriteFoldersMutation } = useMutation(UnfavoriteFolders)

  // Functions
  async function loadFoldersData(companyID: number) {
    data.loading = true
    queryVariables.companyID = companyID

    try {
      // Run the query and check the result
      let result = await load(GetFoldersByCompanyID, queryVariables, { context: { headers: { AnonymousAuthentication: 'true' } } })
      if (!result) {
        // If result is false, then use refetch instead
        result = await refetch(queryVariables)
        result = result.data // Reformat the result because vue apollo is weird.
      }

      // Set the folders data using the result
      let folders = _.cloneDeep(result.foldersAll)
      folders = _.sortBy(folders, ['folderID'])
      // update the folders array with the new data by splicing the array.
      // This will maintain the original array reference and therby maintain the reactivity of the array.
      data.folders.splice(0, data.folders.length, ...folders)
      data.initialFolders.splice(0, data.initialFolders.length, ...folders)
      data.loading = false
    } catch (error) {
      notificationStore.showError('There was an error loading the folders.', 'Error')
      console.error('Error loading folders data:', error)
    }
  }

  async function createFolder(companyID: number, userID: number, folderName: string, parentID?: number) {
    // create the object for folder
    const objectResult = await createObjectForFolder(companyID, userID)

    // if object was created successfully, create the folder
    if (objectResult) {
      const objectID = objectResult.data.createObject.objectID
      const folder = {
        name: folderName,
        objectID: objectID,
        parentFolderID: parentID
      } as any

      // create the folder
      const folderResult = await createFolderMutation({ folder: folder })
      return folderResult
    } else {
      throw new Error('Error creating object for folder.  ObjectCreation returned null.')
    }
  }

  async function createDuplicatedFolder(companyID: number, userID: number, folder: Folder, parentID?: number) {
    // create the object for folder
    const objectResult = await createObjectForFolder(companyID, userID)

    // if object was created successfully, create the folder
    if (objectResult) {
      const objectID = objectResult.data.createObject.objectID
      const folderToDuplicate = {
        name: setCopyNumberOnTheName<Folder>(data.folders, folder.name, 'name', 'subFolders'),
        objectID: objectID,
        parentFolderID: parentID
      } as any

      // get the linked assets for the folder we are duplicating
      const assetIDs = folder.assets.map((asset: any) => asset.assetID)

      // create the folder with the same linked assets
      const folderResult = await createDuplicateFolderMutation({ folder: folderToDuplicate, assetIDs: assetIDs })
      return folderResult
    } else {
      throw new Error('Error creating object for folder.  ObjectCreation returned null.')
    }
  }

  async function renameFolder(userID: number, folderID: number, newName: string) {
    const folderInput = {
      name: newName
    }

    const folderResult = await partialUpdateFolderMutation({ userID: userID, folderID: folderID, folder: folderInput })
    return folderResult
  }

  async function createObjectForFolder(companyID: number, userID: number) {
    const currentDate = new Date().toISOString()
    const object = {
      objectTypeID: 2, // Folder Object Type
      companyID: companyID,
      createdByID: userID,
      createdOn: currentDate,
      lastModifiedByID: userID,
      updatedOn: currentDate
    }

    const result = await createObjectMutation({ object: object })
    return result
  }

  async function favoriteFolders(folderIDs: number[], userID: number){
    const result = await favoriteFoldersMutation({folderIDs, userID});
    return result;
  }

  async function unfavoriteFolders(folderIDs: number[], userID: number){
    const result = await unfavoriteFoldersMutation({folderIDs, userID});
    return result
  }

  async function trashFolders(trashAssets: boolean, folderIDs?: number[]) {
    await softDeleteObjectForFolder({ folderIDs: folderIDs, trashAssets: trashAssets })
    .then(async () => {
      notificationStore.showSuccess('Folder moved to trash', 'Success')
    })
    .catch(() => {
        notificationStore.showError('Error moving folder to trash', 'Error')
    })
  }

  function clearPendingChanges() {
    data.folders = [...data.initialFolders]
    data.selectedFolders = []
  }

  function clearSelection() {
    data.selectedFolders = [];
  }

  // Resets the entire store state
  function $reset() {
    data.folders = []
    data.initialFolders = []
    data.selectedFolders = []
    data.loading = false
  }

  return {
    data,
    loadFoldersData,
    createFolder,
    createDuplicatedFolder,
    renameFolder,
    favoriteFolders,
    unfavoriteFolders,
    clearPendingChanges,
    clearSelection,
    trashFolders,
    $reset
  }
})
