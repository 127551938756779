<template>
  <div v-if="modalData">
    <Message v-if="serviceError" severity="error">{{ serviceError }}</Message>
    <!-- name/title form -->
    <form class="flex flex-col w-full" @submit.prevent="onSubmit">
      <div v-if="modalData.isEmailDisplayed" class="w-full my-2">
        <IconField iconPosition="left">
          <InputIcon class="!text-black fa-regular fa-pen"> </InputIcon>
          <InputText  pt:root:class="w-full" id="NewEmail" v-model="modalData.NewEmail" placeholder="Enter Email" :invalid="validationErrors['NewEmail'] != null" />
        </IconField>
        <p v-if="validationErrors['NewEmail']" class="text-red-500 text-xs italic">{{ validationErrors['NewEmail'] }}</p>
      </div>
      <div v-else class="w-full my-2">
        <IconField iconPosition="left">
          <InputIcon class="!text-black fa-regular fa-pen"> </InputIcon>
          <InputText type="password" id="Otp" v-model="modalData.Otp" placeholder="OTP" :invalid="validationErrors['Otp'] != null" />
        </IconField>
        <p v-if="validationErrors['Otp']" class="text-red-500 text-xs italic">{{ validationErrors['Otp'] }}</p>
      </div>
      <div class="flex justify-between mt-6">
        <Button
          class="text-black hover:text-white"
          severity="contrast"
          outlined
          size="small"
          label="Cancel"
          @click="onCancel"
        />
        <Button class="text-black hover:text-white" size="small" :label="submitButtonLabel" type="submit" :disabled="confirmDisabled" />
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, computed, inject, onBeforeMount } from 'vue'
import { useNotificationStore } from '@/stores/notification.store'
import { useUserStore } from '@/stores/user.store'
import { changeEmailSchema } from '@/schemas/changeEmail.schema'
import otpService from '@/services/api/otp.service'
import authService from '@/services/api/auth.service'
import { UpdatePartialUser } from '@/services/graphql/user.graphql'
import { useMutation } from '@vue/apollo-composable'
import type { UpdatedUserType, UserUpdate } from '@/types/UserSettingsType'
import type { UserApi } from '@/types/UserApiType'
import { msalConfig } from '@/composables/b2c/authConfig'
import { useMsal } from '@/composables/b2c/utils/useMsal'
import _ from 'lodash'

const { instance, accounts } = useMsal()
const dialogRef = inject('dialogRef') as any

// Stores
const notificationStore = useNotificationStore()
const userStore = useUserStore()

// make sure modalData is reactive and contains only defined keys
const modalData = ref({} as any)

onBeforeMount(() => {
  modalData.value = _.cloneDeep(dialogRef.value.data)
})

// close
const emits = defineEmits(['OTPSent'])

// validation errors
const validationErrors = ref<Record<string, string>>({})
// const to disable the save changes button
// enable save changes button if all the prop.data fields are filled
const confirmDisabled = computed(() => {
  return modalData.value.isEmailDisplayed ? !modalData.value.NewEmail : !modalData.value.Otp
})
// submit button label
const submitButtonLabel = computed(() => {
  return modalData.value.isEmailDisplayed ? 'Confirm' : 'Submit'
})

const { mutate: updateUser } = useMutation(UpdatePartialUser)

const serviceError = ref<string | null>(null)
// submit
const onSubmit = async () => {
  if (await validateSchema()) {
    if (modalData.value.isEmailDisplayed) {
      // Generate OTP
      await generateOtp()
    } else if (await validateOtp()) {
      // Change email
      await changeEmail()
    } else {
      serviceError.value = 'Error validating OTP'
    }
  }
}

const generateOtp = async () => {
  await otpService
    .GenerateOtp({ Email: modalData.value.NewEmail })
    .then((response) => {
      if (response.status === 200) {
        modalData.value.isEmailDisplayed = false
        emits('OTPSent')
        dialogRef.value.close()
      } else {
        serviceError.value = 'Error generating OTP'
      }
    })
    .catch(() => {
      serviceError.value = 'Error generating OTP'
    })
}

const validateOtp = async () => {
  return await otpService
    .ValidateOtp({ Email: modalData.value.NewEmail, Otp: modalData.value.Otp })
    .then((response) => {
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

const changeEmail = async () => {
  const changeEmailRequest = {
    Email: modalData.value.NewEmail,
    Identities: [
      {
        SignInType: 'emailAddress',
        Issuer: msalConfig.auth.domain,
        IssuerAssignedId: modalData.value.NewEmail
      }
    ]
  } as UserApi

  await authService
    .UpdateUser(userStore.AccountId, changeEmailRequest)
    .then(async () => {
      const updatedUser: UpdatedUserType = {
        userID: modalData.value.userId as number,
        user: {
          email: modalData.value.NewEmail
        } as UserUpdate
      }

      const response = await updateUser(updatedUser)
      if (!response) {
        notificationStore.showError('Error', 'Error updating user')
        return
      }

      notificationStore.showSuccess('Email Updated', 'The email has been updated successfully')

      // wait some time so the user can see the success message before being logged out
      setTimeout(() => {
        instance.logoutRedirect({
          account: accounts.value[0],
          postLogoutRedirectUri: window.location.origin
        })
      }, 3000)
    })
    .catch(() => {
      serviceError.value = 'Error updating email'
    })
}

const validateSchema = async () => {
  // Reset validation errors
  validationErrors.value = {}
  // Validate schema
  await changeEmailSchema.validate(modalData.value, { abortEarly: false }).catch((error: any) => {
    for (const err of error.errors) {
      validationErrors.value[err.path] = err.message
    }
  })
  // return true if there are no validation errors
  return Object.keys(validationErrors.value).length === 0
}
const onCancel = () => {
  // emit close event (closing the modal)
  dialogRef.value.close()
}
</script>
