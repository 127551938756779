import { useUserStore } from "@/stores/user.store";
import { Client } from '@microsoft/microsoft-graph-client';
import { useNotificationStore } from "@/stores/notification.store";
import type { GraphUserUpdate } from "@/types/UserSettingsType";

const getAccessToken = async (): Promise<string> => {
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    if (!userStore.AccessToken.accessToken) {
        notificationStore.showError('error', 'User not authenticated');
        return '';
    }
    return userStore.AccessToken.accessToken;
}

// create a new instance of the Graph client (with middleware) (beta)
const graphClient = Client.initWithMiddleware({
    authProvider: { getAccessToken }
});

export default {
    getMe() {
        return graphClient
            .api('/me')
            .version('beta')
            .get();
    },
    updateMe(User: GraphUserUpdate) {
        return graphClient
            .api(`/me`)
            .version('beta')
            .patch(User);
    },
    updateUserPassword(currentPassword: string, newPassword: string) {
        return graphClient
            .api(`/me/changePassword`)
            .version('beta')
            .post({
                currentPassword: currentPassword,
                newPassword: newPassword
            });
    },
}